import { useSceneEntities } from "@/stores/SceneEntities";

import { useMaterialMetalsFromFaucetUI } from "@/scripts/rooms/actions/general/MaterialMetalsFromFaucetUI";


export function useAssetFaucett27959(payload, viewport) {


    const sceneEntities = useSceneEntities();

    let showers = sceneEntities.getUIData("showers").enabled;

    //handle child assets first
    //to allow system to create viewport asset ahead of actual asset load

    let t27959_control = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t27959_control");
    t27959_control.enabled = payload.enabled;
    viewport.setEnabled(t27959_control);
    viewport.invalidateTransformForKey("t27959_control","showers");

    let t27959_head = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t27959_head");
    t27959_head.enabled = payload.enabled;
    viewport.setEnabled(t27959_head);

    let t27959_spout = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t27959_spout");
    //spout also validates if showers are selected
    t27959_spout.enabled = payload.enabled && !showers;
    viewport.setEnabled(t27959_spout);

    //now handle main asset
    
    let t27959 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t27959");
    t27959.enabled = payload.enabled;
    viewport.setEnabled(t27959);


  
    useMaterialMetalsFromFaucetUI(null,viewport)



}