import { useSceneEntities } from "@/stores/SceneEntities";
export function useMaterialAccents(payload, viewport) {
  const sceneEntities = useSceneEntities();


sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({key:payload.key});
   //wall_interior_accent--------------------------------------------------------------------

   let materialUpdateObject = {};
   materialUpdateObject.name = "wall_interior_accent";
   materialUpdateObject.textures = {};

   materialUpdateObject.textures.albedoTexture = {};
   const strArr = payload.key.split('_');
   materialUpdateObject.textures.albedoTexture.key = strArr[strArr.length - 1];
   materialUpdateObject.textures.albedoTexture.textureProperties = {};
   materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;

   

   viewport.updateMaterial(materialUpdateObject)
 

  
 
  
  
 
}
