import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useFloors() {

  let colorRadioGroup = useButtonRadioGroup();
  const sceneEntities = useSceneEntities();
  let target = useAccordion1();
  target.attributes.componentData.display.label = "Floors";
  target.attributes.componentData.key = "floors";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

  //Flex Container--------------------------------------------------
  let flexContainer1 = useFlexContainer1();
  targetChildren.push(flexContainer1);
  let flexContainer1Children = flexContainer1.attributes.componentData.children;

  let option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Delacy";
  option.attributes.componentData.display.thumb = "assets/images/cg/floors/wood/thumbs/wood_delacy_thumb.jpg"; 
  option.attributes.componentData.interaction.radioGroup =
  colorRadioGroup;
  option.attributes.componentData.key = "wood_delacy";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Logden";
  option.attributes.componentData.display.thumb = "assets/images/cg/floors/wood/thumbs/wood_logden_thumb.jpg"; 
  option.attributes.componentData.interaction.radioGroup =
  colorRadioGroup;
  option.attributes.componentData.key = "wood_logden";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Silver Spurr";
  option.attributes.componentData.display.thumb = "assets/images/cg/floors/wood/thumbs/wood_silver_spurr_thumb.jpg"; 
  option.attributes.componentData.interaction.radioGroup =
  colorRadioGroup;
  option.attributes.componentData.key = "wood_silver_spurr";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Windsong Oak";
  option.attributes.componentData.display.thumb = "assets/images/cg/floors/wood/thumbs/wood_windsong_oak_thumb.jpg"; 
  option.attributes.componentData.interaction.radioGroup =
  colorRadioGroup;
  option.attributes.componentData.key = "wood_windsong_oak";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  

  

  return target;
}
