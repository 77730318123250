export default class ConsoleLogAdvanced {
  constructor() {
this.enabled = false;

  }
  log(value) {
    if(this.enabled)
    setTimeout(console.log.bind(console,value));
  }
  log1(value) {
    if(this.enabled)
    setTimeout(console.log.bind(console,"%c " + value, "background: #fff; font-size:20px;font-weight:900;color:#000"));
  }

  //using for function calls
  log2(value) {
    if(this.enabled)
    setTimeout(console.log.bind(console,"%c " + value,
     "background: #000; color: #bada55;font-size:15px;font-weight:600;"
     
     ));
  }
  //using for function calls
  log2b(value1,value2) {
    if(this.enabled)
    setTimeout(console.log.bind(console,"%c " + (value1 ?? "") + " %c " + (value2 ?? ""),
     "background: #000; color: #bada55;font-size:15px;font-weight:600;",
     "color: #fff;font-size:12px;font-weight:600;"
     
     ));
  }
  //using for function calls
  log2c(value1,value2) {
    if(this.enabled)
    setTimeout(console.log.bind(console,"%c " + (value1 ?? "") + " %c " + (value2 ?? ""),
     "background: #000; color: #00adff;font-size:15px;font-weight:600;",
     "color: #fff;font-size:12px;font-weight:600;"
     
     ));
  }

  //green white green
  log3(value1, value2, value3) {
    if(this.enabled)
    setTimeout(console.log.bind(console,
      "→   %c " + (value1 ?? "") + " %c " + (value2 ?? "") + " %c " + (value3 ?? ""),
      "color: #baa755",
      "color: #fff",
      "color: #baa755"
    ));
  }
 //green white blue
  log4(value1, value2, value3) {
    if(this.enabled)
    setTimeout(console.log.bind(console,
      "→   %c " + (value1 ?? "") + " %c " + (value2 ?? "") + " %c " + (value3 ?? ""),
      "color: #baa755",
      "color: #fff",
      "color: #55B7BA"
    ));
  }
//orange white orange
  log5(value1, value2, value3) {
    if(this.enabled)
    setTimeout(console.log.bind(console,
      "→   %c " + (value1 ?? "") + " %c " + (value2 ?? "") + " %c " + (value3 ?? ""),
      "color: #ff6100",
      "color: #fff",
      "color: #ff6100"
    ));
  }

  //red white red
  error(value1, value2, value3) {
    if(this.enabled)
    setTimeout(console.log.bind(console,
      "→   %c " + (value1 ?? "") + " %c " + (value2 ?? "") + " %c " + (value3 ?? ""),
      "color: #b30000",
      "color: #fff",
      "color: #b30000"
    ));
  }
 
}
