import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetGrabbarsValidate(payload,viewport){
    const sceneEntities = useSceneEntities();

    let accessories_recess_regular = sceneEntities.getUIData("accessories_recess_regular");
    let accessoriesgrabbarcenter = sceneEntities.getUIData("accessoriesgrabbarcenter");
    viewport.invalidateTransformForKey("accessoriesgrabbarcenter","x48");
    let accessoriesgrabbarcenter_asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessoriesgrabbarcenter");
    accessoriesgrabbarcenter_asset.disabled = !accessories_recess_regular.enabled;
    accessoriesgrabbarcenter_asset.enabled = accessories_recess_regular.enabled && accessoriesgrabbarcenter.enabled
    viewport.setEnabled(accessoriesgrabbarcenter_asset);
    //handle ui again 
    accessoriesgrabbarcenter.disabled =  !accessories_recess_regular.enabled;
    if( accessoriesgrabbarcenter.enabled &&  accessoriesgrabbarcenter.disabled){
      accessoriesgrabbarcenter.enabled = false;
    }
    
}