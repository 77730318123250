
export function useComponentStylingType4() {
   
    const data = {
        classStringContainerDefault:"container-type-3 py-2",
        classStringContainerSelected:"container-type-3 py-2",
        classStringTextDefault:"text-type-2 text-color-6",
        classStringTextSelected:"text-type-2 text-color-6",
        classStringThumbDefault:"thumb-1",
        classStringThumbSelected:"thumb-1",
      
     
          };

 
  return data;
}