<template>
  <div
    class="w-100"
    :style="
      'background-image:url(' +
      window.location_url+
      'assets/images/4tile1.png' +
      ');background-position: center;overflow:hidden'
    "
  ></div>
  <div
    class="layout-group py-4 w-100 h-100 d-flex align-items-center justify-content-center"
    style="position: absolute"
  >
    <div
      class="rounded-3 d-flex align-items-center justify-content-center elevation-8 w-50 h-50 text-center m-0 p-1"
      style="color: #6193a7; background-color: #efedeb"
    >
      <div>
        <div class="h1 display-1 fw-bold">Page</div>
        <div class="h1 display-6 fw-bold">Not Found</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRequests } from "@/stores/Requests";
const requests = useRequests();
</script>
<style scoped>
@media (min-width: 1200px) {
  .display-1 {
    font-size: 8rem;
  }
  .display-6 {
    font-size: 3rem;
  }
}
</style>
