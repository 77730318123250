
export function useButtonInteraction() {
   
  const data = {
   
      toggle:true,
      deselectSelf:true,      
      radioGroup:null,     
      actions:{}
     
        };


return data;
}