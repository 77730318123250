<template>
  <div style="display: flex; flex-direction:row-reverse; gap: 10px; flex-wrap: wrap">
    <div class="card card-settings-1" >
      <div
        class="fs-2 fw-bold text-color-6 p-0 m-0 pb-2"
      
        style="white-space: nowrap; border-bottom: 1px solid rgb(76, 76, 76)"
      >
        Sessions
      </div>
      <component
        v-for="(item, index) in presetsSession"
        :key="'topnavitem_' + index"
        :is="item.component"
        v-bind="item.attributes"
        :keyref="'topnavitem__' + index"
        @select="onSelect"
      >
      </component>
    </div>
    <div class="card card-settings-1" >
      <div
        class="fs-2 fw-bold text-color-6 p-0 m-0 pb-2"
       
        style="white-space: nowrap; border-bottom: 1px solid rgb(76, 76, 76)"
      >
        Presets
      </div>
      <component
        v-for="(item, index) in presetsPersistant"
        :key="'topnavitem_' + index"
        :is="item.component"
        v-bind="item.attributes"
        :keyref="'topnavitem__' + index"
        @select="onSelect"
      >
      </component>
    </div>
    <div class="card card-settings-1" >
      <div
        class="fs-2 fw-bold text-color-6 p-0 m-0 mb-2 pb-2"
       
        style="white-space: nowrap; border-bottom: 1px solid rgb(76, 76, 76)"
      >
        Start Up Default
      </div>
      <div class="d-flex flex-column align-items-end">
      <button class="button-minimal pr-2 mb-2" @click="startUpSession">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">recent session</div>
            <span class="mdi icon icon-color-8" :class="getStartUpSessionIcon"></span>
          </div>
        </button>
        <button class="button-minimal pr-2 mb-2" @click="startUpPreset">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">preset</div>
            <span class="mdi icon icon-color-8" :class="getStartUpPresetIcon"></span>
          </div>
        </button>
        <button class="button-minimal pr-2 mb-2" @click="startUpDefault">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">default</div>
            <span class="mdi icon icon-color-8" :class="getStartUpDefaultIcon"></span>
          </div>
        </button>
      </div>
    </div>

   
   
  </div>

 
</template>

<script setup>
import { useSceneEntities } from "@/stores/SceneEntities";
import { computed } from "vue";
import EventNames from "@/scripts/events/EventNames";
import PublishSubscribe from "@/scripts/events/PublishSubscribe";

const sceneEntities = useSceneEntities();
const publishSubscribe = new PublishSubscribe();



const presetsPersistant = computed(() => {
  return sceneEntities.uiBuildDataTopNav?.presetsPersistant;
});

const presetsSession = computed(() => {
  return sceneEntities.uiBuildDataTopNav?.presetsSession;
});

function onSelect(payload, event) {
  publishSubscribe.dispatchEventGlobal(EventNames.MENU_INTERACTION, payload);
}

let getStartUpSessionIcon = computed(() => {
  return sceneEntities.config.startUpPresetPreferred === 0
    ? "mdi-checkbox-marked-outline"
    : "mdi-checkbox-blank-outline";
});

let getStartUpPresetIcon = computed(() => {
  return sceneEntities.config.startUpPresetPreferred === 1
    ? "mdi-checkbox-marked-outline"
    : "mdi-checkbox-blank-outline";
});

let getStartUpDefaultIcon = computed(() => {
  return sceneEntities.config.startUpPresetPreferred === 2
    ? "mdi-checkbox-marked-outline"
    : "mdi-checkbox-blank-outline";
});

async function  startUpSession(){
  sceneEntities.config.startUpPresetPreferred = 0;
  sceneEntities.saveConfig()
  let data = await sceneEntities.getPreferredStartupData();
  sceneEntities.configuratorView.loadPreset(data);
}
async function startUpPreset(){
  sceneEntities.config.startUpPresetPreferred = 1;
  sceneEntities.saveConfig()
  let data = await sceneEntities.getPreferredStartupData();
  sceneEntities.configuratorView.loadPreset(data);
}
async function startUpDefault(){
  sceneEntities.config.startUpPresetPreferred = 2;
  sceneEntities.saveConfig()
  let data = await sceneEntities.getPreferredStartupData();
  sceneEntities.configuratorView.loadPreset(data);
}


</script>
