import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetFaucett17438 } from "@/scripts/rooms/actions/general/AssetFaucett17438";
import { useAssetFaucett17453 } from "@/scripts/rooms/actions/general/AssetFaucett17453";
import { useAssetFaucett17464 } from "@/scripts/rooms/actions/general/AssetFaucett17464";
import { useAssetFaucett17464i } from "@/scripts/rooms/actions/general/AssetFaucett17464i";
import { useAssetFaucett17494i } from "@/scripts/rooms/actions/general/AssetFaucett17494i";
import { useAssetFaucett27959 } from "@/scripts/rooms/actions/general/AssetFaucett27959";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
export function useAssetFaucetsValidate1(payload, viewport) {
  //faucet UI
  const sceneEntities = useSceneEntities();

  let t17438 = sceneEntities.getUIData("t17438");
  let t17453 = sceneEntities.getUIData("t17453");
  let t17464 = sceneEntities.getUIData("t17464");
  let t17464i = sceneEntities.getUIData("t17464i");
  let t17494i = sceneEntities.getUIData("t17494i");
  let t27959 = sceneEntities.getUIData("t27959");

  let showers = sceneEntities.getUIData("showers");
  let tubs = sceneEntities.getUIData("tubs");

  let interactionFunctions = {};

  interactionFunctions.t17438 = useAssetFaucett17438;
  interactionFunctions.t17453 = useAssetFaucett17453;
  interactionFunctions.t17464 = useAssetFaucett17464;
  interactionFunctions.t17464i = useAssetFaucett17464i;
  interactionFunctions.t17494i = useAssetFaucett17494i;
  interactionFunctions.t27959 = useAssetFaucett27959;

  if (tubs.enabled) {
    t27959.disabled = true;
    if (t27959.enabled) {
      t17438.enabled = true;
      let previousSelection = useUIRadioGroup(t17438, viewport);
     
      if (previousSelection) {
        let f = interactionFunctions[previousSelection.key];
        if (f) {
          f(previousSelection, viewport);
        }
      }
    }
  }
  if (showers.enabled) {
    t27959.disabled = false;
  }

  //walkin tubs
  let tub2852 = sceneEntities.getUIData("walkin2852");
  let tub2653 = sceneEntities.getUIData("walkin2653");
  let tub3053 = sceneEntities.getUIData("walkin3053");

  let faucets = sceneEntities.getUIData("faucets");

  let mustDisable = false;

  if (tub2852.enabled || tub2653.enabled || tub3053.enabled) {
    mustDisable = true;
  }

  faucets.enabled = faucets.enabled && !mustDisable;
  faucets.disabled = mustDisable;

  t17438.disabled = mustDisable;
  t17453.disabled = mustDisable;
  t17464.disabled = mustDisable;
  t17464i.disabled = mustDisable;
  t17494i.disabled = mustDisable; 
  t27959.disabled = mustDisable;

  t27959.disabled =  !showers.enabled;

  // new logic if disabled then set enabled to false
  
  if (t17438.enabled && t17438.disabled) {
    t17438.enabled = false;
  }
  if (t17453.enabled && t17453.disabled) {
    t17453.enabled = false;
  }
  if (t17464.enabled && t17464.disabled) {
    t17464.enabled = false;
  }
  if (t17464i.enabled && t17464i.disabled) {
    t17464i.enabled = false;
  }
  if (t17494i.enabled && t17494i.disabled) {
    t17494i.enabled = false;
  }
  if (t27959.enabled && t27959.disabled) {
    t27959.enabled = false;
  }

  if (!mustDisable) {
    if (
      !t17438.enabled &&
      !t17453.enabled &&
      !t17464.enabled &&
      !t17464i.enabled &&
      !t17494i.enabled &&
      !t27959.enabled
    ) {
      t17438.enabled = true;
      useUIRadioGroup(t17438, viewport);
    }
  }

  useAssetFaucett17438(t17438, viewport);

  useAssetFaucett17453(t17453, viewport);

  useAssetFaucett17464(t17464, viewport);

  useAssetFaucett17464i(t17464i, viewport);

  useAssetFaucett17494i(t17494i, viewport);

  useAssetFaucett27959(t27959, viewport);
}
