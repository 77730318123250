
import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetGrabbarsValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  //walkin tubs
  let tub2852 = sceneEntities.getUIData("walkin2852");
  let tub2653 = sceneEntities.getUIData("walkin2653");
  let tub3053 = sceneEntities.getUIData("walkin3053");
  let mustDisable = false;
  if (tub2852.enabled || tub2653.enabled || tub3053.enabled) {
    mustDisable = true;
  }

  let accessories_grabbarleft = sceneEntities.getUIData("accessories_grabbarleft");
  let accessories_grabbarright = sceneEntities.getUIData("accessories_grabbarright");
  let accessoriesgrabbarcenter = sceneEntities.getUIData("accessoriesgrabbarcenter");
  let accessoriesgrabbarcenter45 = sceneEntities.getUIData("accessoriesgrabbarcenter45");

  let divider_grabbar = sceneEntities.getUIData("divider_grabbar");

  accessories_grabbarleft.disabled = mustDisable;
  accessories_grabbarright.disabled = mustDisable;
  accessoriesgrabbarcenter.disabled = mustDisable;
  accessoriesgrabbarcenter45.disabled = mustDisable;
  divider_grabbar.disabled = mustDisable;

   // new logic if disabled then set enabled to false

  if( accessories_grabbarleft.enabled &&  accessories_grabbarleft.disabled){
    accessories_grabbarleft.enabled = false;
  }
  if( accessories_grabbarright.enabled &&  accessories_grabbarright.disabled){
    accessories_grabbarright.enabled = false;
  }
  if( accessoriesgrabbarcenter.enabled &&  accessoriesgrabbarcenter.disabled){
    accessoriesgrabbarcenter.enabled = false;
  }
  if( accessoriesgrabbarcenter45.enabled &&  accessoriesgrabbarcenter45.disabled){
    accessoriesgrabbarcenter45.enabled = false;
  }
  divider_grabbar.enabled =  !divider_grabbar.disabled;

  let accessories_grabbarleft_asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_grabbarleft");
  accessories_grabbarleft_asset.disabled = mustDisable;
  accessories_grabbarleft_asset.enabled = accessories_grabbarleft.enabled
  viewport.setEnabled(accessories_grabbarleft_asset);

  let accessories_grabbarright_asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_grabbarright");
  accessories_grabbarright_asset.disabled = mustDisable;
  accessories_grabbarright_asset.enabled = accessories_grabbarright.enabled
  viewport.setEnabled(accessories_grabbarright_asset);

  //additional checks for center bar
//if regular wall is not enabled , then its another wall and all those cant have a center grabbar present
  let accessories_recess_regular = sceneEntities.getUIData("accessories_recess_regular").enabled;
  let accessories_recess_window = sceneEntities.getUIData("accessories_recess_window").enabled;

  let accessoriesgrabbarcenter_asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessoriesgrabbarcenter");
  accessoriesgrabbarcenter_asset.disabled = mustDisable || (!accessories_recess_regular  && !accessories_recess_window);
  accessoriesgrabbarcenter_asset.enabled = accessoriesgrabbarcenter.enabled
  viewport.setEnabled(accessoriesgrabbarcenter_asset);
  //handle ui again 
  accessoriesgrabbarcenter.disabled = mustDisable || (!accessories_recess_regular  && !accessories_recess_window);
  if( accessoriesgrabbarcenter.enabled &&  accessoriesgrabbarcenter.disabled){
    accessoriesgrabbarcenter.enabled = false;
  }


  let accessoriesgrabbarcenter45_asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessoriesgrabbarcenter45");
  accessoriesgrabbarcenter45_asset.disabled = mustDisable || (!accessories_recess_regular  && !accessories_recess_window);
  accessoriesgrabbarcenter45_asset.enabled = accessoriesgrabbarcenter45.enabled
  viewport.setEnabled(accessoriesgrabbarcenter45_asset);
  //handle ui again 
  accessoriesgrabbarcenter45.disabled = mustDisable || (!accessories_recess_regular  && !accessories_recess_window);
  if( accessoriesgrabbarcenter45.enabled &&  accessoriesgrabbarcenter45.disabled){
    accessoriesgrabbarcenter45.enabled = false;
  }
 
}
