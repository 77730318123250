
export function useComponentStylingType7() {
   
    const data = {
        classStringContainerDefault:"container-type-5 py-3",
        classStringContainerSelected:"container-type-5 py-3",
        classStringTextDefault:"text-type-6 text-color-6",
        classStringTextSelected:"text-type-6 text-color-5",       
      
     
          };

 
  return data;
}