<template>

   
    <div class="mb-2">
      <component
        v-for="(item, index) in componentData.children"
        :key="keyref+ '_child_' + index"
        :keyref="keyref+ '_child_' + index"
        :is="item.component"
        @select="onChildSelect"
        v-bind="item.attributes"
      >
      </component>
    </div>
    
  </template>
  
  <script setup>
  
  import { ref,reactive,watch,computed } from "vue";
  const emit = defineEmits(["select"]);
  const props = defineProps({
    componentData: {
      type: Object,
      default: () => {
        return;
      },
    },
    keyref:{
      type:String,
      default:""
    }
  });
  
  const componentDataLocal = reactive({ value: props.componentData });
  watch(
    () => props.componentData,
    () => {
      componentDataLocal.value = { ...props.componentData };
    }
  );
  
  let expanded = computed(() => {
    return componentDataLocal.value.enabled;
     
  });
  
  
  
  function onChildSelect(payload,event) {
    emit("select", payload,event);
   
  }
  </script>
  
  <style></style>
  