import { useSceneEntities } from "@/stores/SceneEntities";
import { useMaterialGlassClear } from "@/scripts/rooms/actions/general/MaterialGlassClear";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
export function useAssetDoorsGlassValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();
 
  let showerenclosure_glass_clear = sceneEntities.getUIData("showerenclosure_glass_clear");
  let showerenclosure_glass_obscure = sceneEntities.getUIData("showerenclosure_glass_obscure");
  let showerenclosure_glass_rain = sceneEntities.getUIData("showerenclosure_glass_rain");
  let divider_shower_glass = sceneEntities.getUIData("divider_shower_glass");

  if (
    !showerenclosure_glass_clear.enabled &&
    !showerenclosure_glass_obscure.enabled &&
    !showerenclosure_glass_rain.enabled
  ) {
    showerenclosure_glass_clear.enabled = true;
    useUIRadioGroup(showerenclosure_glass_clear)
    useMaterialGlassClear(showerenclosure_glass_clear, viewport);
  }

  //if these door are selected then you have to force select clear glass and disable other glass
  let showerenclosure_doors_rotolo_barn = sceneEntities.getUIData("showerenclosure_doors_rotolo_barn");
  let showerenclosure_doors_tuscon = sceneEntities.getUIData("showerenclosure_doors_tuscon");

  if (
    (showerenclosure_doors_rotolo_barn.enabled && !showerenclosure_doors_rotolo_barn.disaabled) ||
    (showerenclosure_doors_tuscon.enabled && !showerenclosure_doors_tuscon.disabled)
  ) {
    showerenclosure_glass_clear.enabled = true;
    useUIRadioGroup(showerenclosure_glass_clear)
    useMaterialGlassClear(showerenclosure_glass_clear, viewport);

    showerenclosure_glass_obscure.disabled = true;
    showerenclosure_glass_rain.disabled = true;
  } else {
    showerenclosure_glass_obscure.disabled = false;
    showerenclosure_glass_rain.disabled = false;
  }

  let biPassBasco = sceneEntities.getUIData("biPassBasco");
  let biPassOriginal = sceneEntities.getUIData("biPassOriginal");
  let infinity_hinge = sceneEntities.getUIData("infinity_hinge");

  //if all doors not selected ( or disabled )  deselect glass and remove disabled and remove serialization
  if (
    (!biPassBasco.enabled || biPassBasco.disabled) &&
    (!biPassOriginal.enabled || biPassOriginal.disabled) &&
    (!infinity_hinge.enabled || infinity_hinge.disabled) &&
    (!showerenclosure_doors_rotolo_barn.enabled || showerenclosure_doors_rotolo_barn.disabled) &&
    (!showerenclosure_doors_tuscon.enabled || showerenclosure_doors_tuscon.disabled)
  ) {
    showerenclosure_glass_clear.enabled = false;
    showerenclosure_glass_obscure.enabled = false;
    showerenclosure_glass_rain.enabled = false;
    divider_shower_glass.enabled = false;

    showerenclosure_glass_clear.disabled = true;
    showerenclosure_glass_obscure.disabled = true;
    showerenclosure_glass_rain.disabled = true;
    divider_shower_glass.disabled = true;

    sceneEntities.configuratorView.viewportComponent.removeMaterialUpdateForSerialization("useMaterialGlassClear");
    sceneEntities.configuratorView.viewportComponent.removeMaterialUpdateForSerialization("useMaterialGlassObscure");
    sceneEntities.configuratorView.viewportComponent.removeMaterialUpdateForSerialization("useMaterialGlassRain");

    return
  }

  showerenclosure_glass_clear.disabled = false;
  divider_shower_glass.disabled = false;
}
