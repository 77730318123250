import { useSceneEntities } from "@/stores/SceneEntities";
export function useMaterialWoodColor1(payload, viewport) {
   
    const sceneEntities = useSceneEntities();
   
    sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization(payload);
  

    //wall_exterior--------------------------------------------------------------------

    let materialUpdateObject = {};
    materialUpdateObject.name = "w_1";
    materialUpdateObject.colors = {};
    materialUpdateObject.colors.albedoColor = payload.data?.color||payload.color;
    viewport.updateMaterial(materialUpdateObject)

     

}