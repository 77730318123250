<template>
  <AssetPreloaderComponent />
  <canvas ref="bjsCanvas" touch-action="none" id="bjs-canvas"></canvas>
</template>

<script setup>
import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { PBRBaseMaterial } from "@babylonjs/core/Materials/PBR/pbrBaseMaterial";
import { RawTexture } from "@babylonjs/core/Materials/Textures/rawTexture";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { ImageProcessingConfiguration } from "@babylonjs/core/Materials/imageProcessingConfiguration";
import { GlowLayer } from "@babylonjs/core/Layers/glowLayer";
import {CreateScreenshot} from "@babylonjs/core/Misc/screenshotTools";
import { DefaultRenderingPipeline } from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline";
import { SSAO2RenderingPipeline } from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssao2RenderingPipeline";
import { SSAORenderingPipeline } from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssaoRenderingPipeline";
import { ColorCurves } from "@babylonjs/core/Materials/colorCurves";
import { Color3 } from "@babylonjs/core/Maths/math";
import { Color4 } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { PointLight } from "@babylonjs/core/Lights/pointLight";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Materials/Textures/Loaders/envTextureLoader";
import BabylonSceneAsset from "@/scripts/viewport/3d/BabylonSceneAsset";
import BabylonTextureAsset from "@/scripts/viewport/3d/BabylonTextureAsset";
import AssetPreloaderComponent from "@/components/AssetPreloaderComponent";
import { ref, onUnmounted, onBeforeUnmount, onMounted } from "vue";
import { useSceneEntities } from "@/stores/SceneEntities";
//import "@babylonjs/core/Debug/debugLayer";
//import "@babylonjs/inspector";
import "@babylonjs/core/Rendering/prePassRendererSceneComponent";
import "@babylonjs/core/Rendering/geometryBufferRendererSceneComponent";
import { useTheme } from "@/stores/Theme";
import { watch, nextTick } from "vue";
import JSUtil from "@/scripts/utils/JSUtil";
import EventNames from "@/scripts/events/EventNames";
import PublishSubscribe from "@/scripts/events/PublishSubscribe";
import { useScreen } from "vue-screen";
import envFile from "@/assets/images/babylon/autoshop_02_2k_bw.env";
import ConsoleLogAdvanced from "@/scripts/utils/ConsoleLogAdvanced";
import { useRequests } from "@/stores/Requests";

const publishSubscribe = new PublishSubscribe();

const theme = useTheme();
const consoleLogAdvanced = new ConsoleLogAdvanced();
const sceneEntities = useSceneEntities();
const bjsCanvas = ref(null);
const dataCollectionMaterials = {};

defineExpose({
  invalidate,
  setEnabled,
  invalidateTransformForKey,
  updateMaterial,
  loadData,
  getViewportAsset,
  getColor,
  getTexture,
  storeMaterialUpdateForSerialization,
  removeMaterialUpdateForSerialization,
  serializeViewportAssets,
  serializeMaterials,
  dataCollectionMaterials,
  getDataCollectionMaterial,
});

var scene = null;
var engine = null;
var hdrTexture = null;
var canvasDom = null;
var SSAOPipeline = null;
var camera = null;
var glowLayer = null;
var defaultPLR = null;
var isDebugShowing = false;
var pointLights = [];
var disposableMaterials = [];
var collectionMaterials = {};
var updateMaterialPending = {};
var rootTransformNode;
var light = null;
var resizeCompleteListener = onWindowResizeComplete.bind(this);
var resizeBeginListener = onWindowResizeBegin.bind(this);
var renderListener = onRenderLoop.bind(this);
var cameraResetListener = onCameraCurrent.bind(this);
var changeRenderIndexListener = changeRenderingLevel.bind(this);
var keyListener = onKeyListener.bind(this);
var textureCache = {};
var colorCache = {};
const buildNumber = ref(process.env.VUE_APP_BUILD_NUMBER || 0);
var renderIndex = theme.readRenderIndex();

var dataCollectionColors = null;
var dataCollectionTextures = null;
var dataCollectionAssetsViewport = {};

var logAssetsDev = false;
const screen = useScreen();
var currentCameraFunction = onCameraReset3;

watch(screen, invalidateScreen);
async function invalidateScreen() {
  await nextTick();
  if (screen.portrait) {
    currentCameraFunction = onCameraReset2;
  }
  if (screen.landscape) {
    currentCameraFunction = onCameraReset3;
  }
  currentCameraFunction();
}
invalidateScreen();

watch(
  () => theme.themeKey,
  (newType) => {
    updateThemeTargetedItems(newType);
  }
);

async function loadData() {
  await loadAssetsData(sceneEntities.configuratorView.roomController.dataPaths.assetsURL);
  await loadColorsData(sceneEntities.configuratorView.roomController.dataPaths.colorsURL);
  await loadTexturesData(sceneEntities.configuratorView.roomController.dataPaths.texturesURL);
}

async function loadAssetsData(url) {
  const requests = useRequests();
  let result;
  try {
    let ob = { url };
    result = await requests.get({ url });
  } catch (error) {
    console.log(error);
    return;
  }
  if (result) {
    parseAssetsData(result.data);
  }
}

function parseAssetsData(data) {
  for (let prop in data) {
    let assetDataItem = data[prop];
    let asset = (dataCollectionAssetsViewport[assetDataItem.key] = new BabylonSceneAsset(assetDataItem.key));
    for (let prop2 in assetDataItem) {
      asset[prop2] = assetDataItem[prop2];
    }
  }
}

async function loadColorsData(url) {
  const requests = useRequests();
  let result;
  try {
    result = await requests.get({ url });
  } catch (error) {
    console.log(error);
    return;
  }
  if (result) {
    dataCollectionColors = result.data;
  }
}

async function loadTexturesData(url) {
  const requests = useRequests();
  let result;
  try {
    result = await requests.get({ url });
  } catch (error) {
    console.log(error);
    return;
  }
  if (result) {
    dataCollectionTextures = result.data;
  }
}

function getColor(key) {
  let item = dataCollectionColors[key];
  if (!item) {
    consoleLogAdvanced.error("getColor failed for key: ", key);
  }
  return item;
}
function getTexture(key) {
  let item = dataCollectionTextures[key];
  if (!item) {
    consoleLogAdvanced.error("getTexture failed for key: ", key);
  }
  return item;
}

function serializeViewportAssets() {
  let viewportassets = {};
  for (let prop in dataCollectionAssetsViewport) {
    let item = dataCollectionAssetsViewport[prop];

    //ok , i think i dont need to store assets currently not enabled or  enabled but disabled

    //but if this viewport asset represents a child of a load glb,
    //the false states to need to be serialized , so handle this

    //eg for a walkin tub , somechildren are not enabled but the parent is ( like regular shower or slider )
    //also for tubs ( like different panels , only one is enabled while others disabled)

    // they must be serialized because during runtime the action loading these hides some of these by default
    //but the loaded scene never calls any actions , and if these are not serialized with their false values
    //then when the main asset is loaded these children will get their viewport asset enabled set to true
    //which you do not want

    //added "isExternalAsset" property now , which is only false for children of loaded assets
    //this works because  if it is true , then the check for enabled is enough since there are no chances of the property
    //expected to be false becoming true during children handling on asset load

    let ancestorIsEnabled = false;
    if (!item.isExternalAsset) {
      if (item.node?.parent && item.loaded) {
        //for now assets parented to root cannot use this logic .. since the root is always enabled
        if (item.node.parent.name !== "root_transform_fix") {
          ancestorIsEnabled = item.node.parent.isEnabled();
        }
      }
    }
    if ((item.enabled && item.loaded && !item.disabled) || ancestorIsEnabled) {
      //assuming item has a node value since it is enabled
      //check if actual node is enabled , because if it has a disabled parent then technically it is disabled
      if (item.node.isEnabled() || ancestorIsEnabled) {
        let ob = (viewportassets[prop] = {});
        ob.enabled = item.enabled;
        ob.disabled = item.disabled;
      }
    }
  }

  return viewportassets;
}

function getDataCollectionMaterial(key){
  return dataCollectionMaterials[key]
}

function serializeMaterials() {
  let serializationMaterials = {};

  for (let prop in dataCollectionMaterials) {
    let item = sceneEntities.dataCollectionUI[prop];

    //if keys in dataCollectionMaterials map to ui , then do ui checking for if to
    //serialize the data , if no ui map is valid , then just serialize it , since it maps to a action directly
    if (item) {
      //added this in because child UI need to possible check the state of their parent

      let enabled = item.isEnabled();
      if (enabled) {
        serializationMaterials[prop] = dataCollectionMaterials[prop];
      }
    } else {
      serializationMaterials[prop] = dataCollectionMaterials[prop];
    }
  }
  return serializationMaterials;
}

function storeMaterialUpdateForSerialization(data) {
  //the key used here needs to map to a composable in the same way the ui did
 
  dataCollectionMaterials[data.key] = data.data;
}
function removeMaterialUpdateForSerialization(key) {
 
  delete dataCollectionMaterials[key];
}

function getViewportAsset(key) {
  let item = dataCollectionAssetsViewport[key];
  if (!item) {
   

    item = dataCollectionAssetsViewport[key] = new BabylonSceneAsset(key);
  }
  return item;
}

function updateThemeTargetedItems(newType) {
  let color = null;
  let color2 = null;
  newType === "light" ? (color = "#eeeeee") : (color = "#111111");
  newType === "light" ? (color2 = "#DDDDDD") : (color2 = "#333333");
  changeBackgroundColor(color);

  var asset = collectionMaterials["wall_exterior_shell"];
  if (asset) {
    asset.albedoColor = Color3.FromHexString(color2).toLinearSpace();
  }
}

onMounted(() => {
  

  if (bjsCanvas.value) {
    create(bjsCanvas.value);
    updateThemeTargetedItems(theme.themeKey);
  }
});

onUnmounted(() => {
 

  publishSubscribe.removeEventListenerGlobal(EventNames.CONTENT_SIZING_COMPLETE, resizeCompleteListener);
  publishSubscribe.removeEventListenerGlobal(EventNames.CONTENT_SIZING_BEGIN, resizeBeginListener);
  publishSubscribe.removeEventListenerGlobal(EventNames.RESET_CAMERA, cameraResetListener);
  publishSubscribe.removeEventListenerGlobal(EventNames.TOGGLE_HIGH_RENDERING, changeRenderIndexListener);
  window.removeEventListener("resize", resizeCompleteListener);
  window.removeEventListener("keydown", keyListener);
  engine.stopRenderLoop(renderListener);
  scene.dispose();

  dataCollectionAssetsViewport = {};

  for (let prop in textureCache) {
    let texAsset = textureCache[prop];
    if (texAsset.texture) {
      texAsset.texture.dispose(false, true);
    }

    texAsset.texture = null;
    texAsset = null;
  }
  textureCache = null;

  for (let prop in colorCache) {
    let colorAsset = colorCache[prop];
    colorAsset = null;
  }
  colorCache = null;

  defaultPLR = null;
  isDebugShowing = false;
  for (let [key, value] of pointLights) {
    value.dispose();
    value = null;
  }
  pointLights = [];
  disposableMaterials = [];
  updateMaterialPending = {};

  scene = null;
  engine = null;
  hdrTexture = null;
  canvasDom = null;
  SSAOPipeline = null;
  camera = null;
  glowLayer = null;
});

function updateMaterial(materialData) {
  
  let mat = collectionMaterials[materialData.name];

  if (!mat) {
  
    let pendingUpdate = updateMaterialPending[materialData.name];
    if (!pendingUpdate) {
      pendingUpdate = updateMaterialPending[materialData.name] = { ...materialData };
    } else {
      for (let prop in pendingUpdate) {
        let existing = materialData[prop];
        if (existing) {
          if (JSUtil.isObject(existing)) {
            for (let prop2 in existing) {
            pendingUpdate[prop][prop2] = existing[prop2];
          }
          }else{
            pendingUpdate[prop] = materialData[prop];
          }

         
        }
      }
    }
    return;
  } else {
   //
  }
  //props
  for (let prop in materialData.props) {
    mat[prop] = materialData.props[prop];
  }
  //colors
  for (let prop in materialData.colors) {
    let colorKey = materialData.colors[prop];
    let colorAsset = colorCache[colorKey];
    if (!colorAsset) {
      let colorHex = getColor(colorKey);
      if (colorHex.length > 7) {
        colorAsset = colorCache[colorKey] = Color4.FromHexString(colorHex).toLinearSpace();
      } else {
        colorAsset = colorCache[colorKey] = Color3.FromHexString(colorHex).toLinearSpace();
      }
    }
    mat[prop] = colorAsset;
  }

  //textures
  for (let prop in materialData.textures) {
    let textureData = materialData.textures[prop];
    let textureURL = getTexture(textureData.key);
    //the cache key is using the url now instead of key , since te same texture is listed under several keys for some assets
    //and if I dont use the url , then it will create redundant textures in memory
    let textureAsset = textureCache[textureURL];
    if (!textureAsset) {
      textureAsset = textureCache[textureURL] = new BabylonTextureAsset(textureURL);
      textureAsset.texture = new Texture(window.location_url + textureURL + "?cache_uid=" + buildNumber.value);
      for (let textureProperty in textureData.textureProperties) {
        textureAsset.texture[textureProperty] = textureData.textureProperties[textureProperty];
      }
      textureAsset.texture.onLoadObservable.addOnce((eventData) => {
        var texCacheKey = textureURL;
        var mk = materialData.name;
        var ck = prop;
        collectionMaterials[mk][ck] = eventData;
        textureCache[texCacheKey].loadSuccess();
      });
      textureAsset.load();
    } else {
      if (textureAsset.texture.isReadyOrNotBlocking()) {
        mat[prop] = textureAsset.texture;
      } else {
        //more than one material might want to use this same texture , and the first call to use it would cause a creation and load,
        //since subsequent calls will find it exists they then also need a callback for applying it once loaded
        textureAsset.texture.onLoadObservable.addOnce((eventData) => {
          var mk = materialData.name;
          var ck = prop;
          collectionMaterials[mk][ck] = eventData;
        });
      }
    }
  }
}

async function invalidate() {
  for (let prop in dataCollectionAssetsViewport) {
    let asset = getViewportAsset(prop);
    await setEnabled(asset);

    for (let prop in asset.collectionTransforms) {
      if (prop === "default") continue;
      let bool = sceneEntities.getUIData(prop)?.enabled;

      if (bool) {
        invalidateTransformForKey(asset.key, prop);
      }
    }
  }
}

async function setEnabled(viewportAsset) {
  //if node hasa value it means the asset is loaded or was a child of a loaded asset, so interact with it
  if (viewportAsset.node) {
    viewportAsset.node.setEnabled(viewportAsset.enabled && !viewportAsset.disabled);
  } else {
    // node had no value so if a url was past in then we load an asset
    //IF the asset is not already loading!
    //IF enabled is true , as the first call to enable could be false and we dont need to load the asset until a true is required
    if (viewportAsset.url) {
      if (!viewportAsset.loading && viewportAsset.enabled && !viewportAsset.disabled) {
        viewportAsset.isExternalAsset = true;
        viewportAsset.load();
        SceneLoader.ImportMesh(
          "",
          "",
          
          window.location_url + viewportAsset.url+ "?cache_uid=" + buildNumber.value,
          scene,
          //niffty feature of bind I did not know .. can have it call the function in more than one context
          loadSuccess.bind(this, viewportAsset),
          viewportAsset.loadProgress.bind(viewportAsset),
          viewportAsset.loadError.bind(viewportAsset)
        );
        if (viewportAsset.syncLoading) {
          await viewportAsset.loadingPromise();
        }
      }
    }
  }
}

function loadSuccess(viewportAsset, event) {
  buildAsset(viewportAsset, event);
  viewportAsset.loadSuccess();
}
function buildAsset(viewportAsset, event) {
  let children = event[0].getChildren();
  //handle scene materials , which sets up references to existing materials if they dont exist yet
  handleSceneMaterials();
  //handle dulicate materials , removes same name materials of loaded object if a existing materials with same name is already cached
  handleDuplicateMaterials(children);
  //disposes of  duplicate materials
  disposeMaterials();

  //I hate glb format injecting a root node in my asset , i just want the asset itself to be the root

  // so for assets with children i create a new transform with the name of the asset , then I remove
  //the children from the original and place them in this

  //assets with no children just get the root removed.

  // there is another custom parenting logic after this
  // assets with a parent( stipulated in the data ) will be reparented again

  //assets are all parented at a minimum to a custom transform (rootTransformNode) at the scene root
  //this transform is rotated and scaled so that all children assets can have altered TRS properties to be used in the same manner as how the exist in the authoring file.
  //without this , they have weird rotations and scales

  let nodeChild;
  if (children.length > 1) {
    nodeChild = new TransformNode(viewportAsset.key);
    processNode3D(children, nodeChild, true);
    viewportAsset.node = nodeChild;
  } else {
    processNode3D(children, nodeChild, false);
    viewportAsset.node = children[0];
  }

  //this is where the GLB root node is trashed .. goodbye
  scene.removeMesh(event[0]);

  handleTransforms(viewportAsset);
  viewportAsset.node.parent = rootTransformNode;
  handleParenting(viewportAsset);
  viewportAsset.node.setEnabled(viewportAsset.enabled);
}
function handleParenting(viewportAsset) {
  let sceneEntity = getViewportAsset(viewportAsset.key);
  if (sceneEntity.parent) {
    let parentAsset = getViewportAsset(sceneEntity.parent);
    if (parentAsset) {
      viewportAsset.node.parent = parentAsset.node;
    }
  }
}
//transform keys must map to some ui key
function invalidateTransformForKey(assetKey, transformKey) {
 
  let bool = sceneEntities.getUIData(transformKey).enabled;

  let viewportAsset = getViewportAsset(assetKey);

  //it is possible the asset had a weak transform setup , which badically means
  //it needs to still have its transforms built properly
  if (viewportAsset.transformValuesWeak === true) {
   
    handleTransforms(viewportAsset);
    return;
  }
  if (viewportAsset.collectionTransforms) {
    let transformFound = false;
    //only if key is true
    if (bool) {
      for (let prop2 in viewportAsset.collectionTransforms) {
        if (prop2 === transformKey) {
          transformFound = true;

          //transformKeyCurrent is a cache of the last set transform
          //this is used to avoid setting the same transform over itself
          if (viewportAsset.transformKeyCurrent !== transformKey) {
            //apply transform
          
            let transform = viewportAsset.collectionTransforms[transformKey];
            setTransform(viewportAsset, transform);
            viewportAsset.transformKeyCurrent = transformKey;
          }
        }
      }
    }
    if (!transformFound) {
      if (viewportAsset.transformKeyCurrent !== viewportAsset.transformKeyDefault) {
        let transform = viewportAsset.collectionTransforms[viewportAsset.transformKeyDefault];
        if (transform) {
         
          setTransform(viewportAsset, transform);
          viewportAsset.transformKeyCurrent = viewportAsset.transformKeyDefault;
        }
      }
    }
  }
}

function setTransform(viewportAsset, transform) {
  if (!viewportAsset.node) return;
  if (transform.location) {
    viewportAsset.node.position.copyFrom(transform.location);
  }
  if (transform.rotation) {
    viewportAsset.node.rotation.copyFrom(transform.rotation);
  }
  if (transform.scaling) {
    viewportAsset.node.scaling.copyFrom(transform.scaling);
  }
}

function handleTransforms(viewportAsset) {
 
  if (viewportAsset.transform) {
    for (let prop in viewportAsset.transform) {
      createTransform(viewportAsset, prop, viewportAsset.transform[prop]);
    }
  }
  viewportAsset.transformValuesWeak = false;

  if (viewportAsset.collectionTransforms) {
    if (viewportAsset.collectionTransforms.default) {
      if (viewportAsset.collectionTransforms.default.location) {
        viewportAsset.node.position.copyFrom(viewportAsset.collectionTransforms.default.location);
      }
      if (viewportAsset.collectionTransforms.default.rotation) {
        viewportAsset.node.rotation.copyFrom(viewportAsset.collectionTransforms.default.rotation);
      }
      if (viewportAsset.collectionTransforms.default.scaling) {
        viewportAsset.node.scaling.copyFrom(viewportAsset.collectionTransforms.default.scaling);
      }
    }
  }

  for (let prop in viewportAsset.collectionTransforms) {
    
    if (prop === "default") continue;
    let bool = sceneEntities.getUIData(prop)?.enabled;
  //
    if (bool) {
    
      invalidateTransformForKey(viewportAsset.key, prop);
    }
  }
}
function createTransform(viewportAsset, transformKey, transformData) {
  if (!viewportAsset.collectionTransforms) {
    viewportAsset.collectionTransforms = {};
  }
  let t = (viewportAsset.collectionTransforms[transformKey] = {});
  if (transformData.l) {
    t.location = new Vector3();
    t.location.x = parseFloat(transformData.l[0]);
    t.location.y = parseFloat(transformData.l[1]);
    t.location.z = parseFloat(transformData.l[2]);
  }

  if (transformData.r) {
    t.rotation = new Vector3();
    t.rotation.x = parseFloat(transformData.r[0]) * 0.0174533;
    t.rotation.y = parseFloat(transformData.r[1]) * 0.0174533;
    t.rotation.z = parseFloat(transformData.r[2]) * 0.0174533;
  }

  if (transformData.s) {
    t.scaling = new Vector3();
    t.scaling.x = parseFloat(transformData.s[0]);
    t.scaling.y = parseFloat(transformData.s[1]);
    t.scaling.z = parseFloat(transformData.s[2]);
  }
}

//removes GLB "root" node and process children of 3D node create viewport assets for them as well
function processNode3D(childrenArray, parentNode, nestInTransform) {
  if (childrenArray === null || childrenArray === undefined) return;

  if (childrenArray[0].name.indexOf("primitive") !== -1) {
    //crude early out , assumes if first child name has this value within it
    //then it and all other childen are submesh create by the frame work and not objects i need to or ant to process and control
    return;
  }

  for (var i = 0; i < childrenArray.length; i++) {
    if (!(childrenArray[i] instanceof TransformNode)) {
      if (logAssetsDev) {
        //.. what was i doing here , what else are the children , a mesh? this never seems to be a true conditional, could be removed .. investigate
        console.log(
          " child is NOT INSTANCE OF TRANSFORM while looping in childrenArray processNode3D, will call to continue"
        );
        console.log(childrenArray[i]);
      }
      continue;
    }

    let childNode = childrenArray[i];

    childNode.setParent(null);
    childNode.rotation = new Vector3(0, 0, 0);
    childNode.scaling = new Vector3(1, 1, 1);
    var p = childNode.position;
    p.x *= -1;
    childNode.position.copyFrom(p);
    if (nestInTransform) {
      childNode.parent = parentNode;

      var cn = dataCollectionAssetsViewport[childNode.name];
      if (cn === null || cn === undefined) {
      

        cn = dataCollectionAssetsViewport[childNode.name] = new BabylonSceneAsset(childNode.name);
        cn.enabled = true;
        if (!cn.collectionTransforms) {
          cn.collectionTransforms = {};
        }
        cn.transformValuesWeak = true;
        cn.collectionTransforms.default = {};
        cn.collectionTransforms.default.location = new Vector3();
        cn.collectionTransforms.default.rotation = new Vector3();
        cn.collectionTransforms.default.scaling = new Vector3();
        cn.collectionTransforms.default.location.copyFrom(childNode.position);
        cn.collectionTransforms.default.rotation.copyFrom(childNode.rotation);
        cn.collectionTransforms.default.scaling.copyFrom(childNode.scaling);
      }
      cn.loaded = true;
      cn.node = childNode;
      handleTransforms(cn);
      cn.node.setEnabled(cn.enabled);
    }

    var nc = childNode.getChildren();
    if (nc !== null && nc !== undefined) {
      if (nc.length > 0) {
        processNode3D(nc, childNode, nestInTransform);
      }
    }
  }
}

function handleSceneMaterials() {
  var m = scene.materials;
  var ml = m.length;

  for (let i = 0; i < ml; i++) {
    let mat = collectionMaterials[m[i].name];
    if (!mat) {
      mat = collectionMaterials[m[i].name] = m[i];
      //change sorting for alpha textures
      if (m[i].albedoTexture) {
        if (m[i].albedoTexture.hasAlpha) {
          m[i].transparencyMode = 1;
        }
      }
    }
    let pendingMaterialUpdate = updateMaterialPending[mat.name];
    if (pendingMaterialUpdate) {
      updateMaterial(pendingMaterialUpdate)
      delete updateMaterialPending[mat.name];
    }
  }
}

function handleDuplicateMaterials(nodesArray) {
  for (let i = 0; i < nodesArray.length; i++) {
    var n = nodesArray[i];

    if (n.material) {
      var existingMaterial = collectionMaterials[n.material.name];

      if (existingMaterial) {
        if (n.material.uniqueId !== existingMaterial.uniqueId) {
          disposableMaterials.push(n.material);
          n.material = null;
          n.material = existingMaterial;
        }
      } else {
        collectionMaterials[n.material.name] = n.material;
      }
    }

    var nc = n.getChildren();
    if (nc) {
      if (nc.length > 0) {
        handleDuplicateMaterials(nc);
      }
    }
  }
}
function disposeMaterials() {
  for (var i = 0; i < disposableMaterials.length; i++) {
    disposableMaterials[i].dispose(true, true);
  }
  disposableMaterials = [];
}

function changeBackgroundColor(color) {
  scene.clearColor = Color3.FromHexString(color);
}

function dimEnvironment(state) {
  scene.environmentIntensity = state ? 1.48 : 1;
}
function onCameraCurrent() {
  currentCameraFunction();
  createScreenshot();
}
function onCameraReset() {
  camera.target = new Vector3(0, 2.35, 1.9);
  camera.alpha = 1.5708;
  camera.beta = 1.5708;
  camera.radius = 9;
  camera.upperRadiusLimit = null;
  camera.lowerRadiusLimit = null;
  camera.lowerAlphaLimit = (30 * Math.PI) / 180;
  camera.upperAlphaLimit = (150 * Math.PI) / 180;

  camera.lowerBetaLimit = (30 * Math.PI) / 180;
  camera.upperBetaLimit = (150 * Math.PI) / 180;
}

function onCameraReset2() {
  camera.target = new Vector3(0, 2.3, 2);
  camera.alpha = 1.5708;
  camera.beta = 1.5708;
  camera.radius = 9;
  camera.fov  = 0.5
  camera.upperRadiusLimit = 11;
  camera.lowerRadiusLimit = 0.5;
  camera.lowerAlphaLimit = (70 * Math.PI) / 180;
  camera.upperAlphaLimit = (110 * Math.PI) / 180;

  camera.lowerBetaLimit = (70 * Math.PI) / 180;
  camera.upperBetaLimit = (110 * Math.PI) / 180;
}

function onCameraReset3() {
  camera.target = new Vector3(0, 2.3, 2);
  camera.alpha = 1.5708;
  camera.beta = 1.5708;
  camera.radius = 9;
  camera.fov  = 0.5
  camera.upperRadiusLimit = 11;
  camera.lowerRadiusLimit = 0.5;
  camera.lowerAlphaLimit = (50 * Math.PI) / 180;
  camera.upperAlphaLimit = (130 * Math.PI) / 180;

  camera.lowerBetaLimit = (70 * Math.PI) / 180;
  camera.upperBetaLimit = (110 * Math.PI) / 180;
}

function setGlowLayer(state) {
  if (state === true) {
    glowLayer = new GlowLayer("glow", scene);
    glowLayer.intensity = 0.8;
  } else {
    if (glowLayer !== null && glowLayer !== undefined) {
      glowLayer.dispose();
    }
  }
}

function setdefaultRP(state) {
  if (state === true) {
    if (!defaultPLR) {
      defaultPLR = new DefaultRenderingPipeline("defaultPipeline", true, scene, [camera]);

      defaultPLR.imageProcessingEnabled = true;
      defaultPLR.samples = 16;
      defaultPLR.fxaaEnabled = true;
      defaultPLR.sharpenEnabled = false;
      defaultPLR.sharpen.edgeAmount = 0.32;
      defaultPLR.imageProcessing.vignetteEnabled = false;
      defaultPLR.imageProcessing.colorGradingEnabled = false;     
      defaultPLR.imageProcessing.contrast = 1.9;
      defaultPLR.imageProcessing.exposure = 1;
      defaultPLR.imageProcessing.toneMappingEnabled = true;
      defaultPLR.imageProcessing.toneMappingType = ImageProcessingConfiguration.TONEMAPPING_ACES;
      
      defaultPLR.imageProcessing.colorCurvesEnabled = true;
      var curve = new ColorCurves();
      curve.globalDensity = 0; // 0 by default
      curve.globalExposure = 0; // 0 by default
      curve.globalHue = 30; // 30 by default
      curve.globalSaturation = 20; // 0 by default
      curve.highlightsDensity = 0; // 0 by default
      curve.highlightsExposure = 0; // 0 by default
      curve.highlightsHue = 30; // 30 by default
      curve.highlightsSaturation = 0; // 0 by default
      curve.midtonesDensity = 0; // 0 by default
      curve.midtonesExposure = 0; // 0 by default
      curve.midtonesHue = 30; // 30 by default
      curve.midtonesSaturation = 0; // 0 by default
      curve.shadowsDensity = 0; // 0 by default
      curve.shadowsExposure = 0; // 0 by default
      curve.shadowsHue = 30; // 30 by default
      // curve.shadowsDensity = 80;
      curve.shadowsSaturation = 0; // 0 by default;
      defaultPLR.imageProcessing.colorCurves = curve;
    }
  } else {
    if (defaultPLR !== null && defaultPLR !== undefined) {
      defaultPLR.dispose();
      defaultPLR = null;
    }
  }
}

function setSSAO(state) {
  if (state === true) {
    if (SSAO2RenderingPipeline.IsSupported) {
      // Create SSAO and configure all properties (for the example)
      var ssaoRatio = {
        ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
        combineRatio: 1.0, // Ratio of the combine post-process (combines the SSAO and the scene)
      };
      SSAOPipeline = new SSAORenderingPipeline("ssao", scene, 0.75);
      // Attach camera to the SSAO render pipeline
      scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera);
      scene.postProcessRenderPipelineManager.enableEffectInPipeline(
        "ssao",
        SSAOPipeline.SSAOCombineRenderEffect,
        camera
      );

      //fix for ssao affecting transparent objects
      scene.enableGeometryBufferRenderer().renderTransparentMeshes = false;
    }
  } else {
    if (SSAOPipeline !== null && SSAOPipeline !== undefined) {
      SSAOPipeline.dispose();
    }
  }
}

function setSSAO2(state) {
  if (state === true) {
    if (SSAO2RenderingPipeline.IsSupported) {
      // Create SSAO and configure all properties (for the example)
      var ssaoRatio = {
        ssaoRatio: 0.5, 
        blurRatio: 1, 
        combineRatio: 1,
      };
      SSAOPipeline = new SSAO2RenderingPipeline("ssao", scene, ssaoRatio);
      SSAOPipeline.radius = 0.2;
      SSAOPipeline.totalStrength = 1;
      SSAOPipeline.base = 0;
       SSAOPipeline.expensiveBlur = true;
      SSAOPipeline.samples = 16;
      SSAOPipeline.maxZ = 100;
      SSAOPipeline.minZAspect = 0.2;
      // Attach camera to the SSAO render pipeline
      scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera);
      scene.postProcessRenderPipelineManager.enableEffectInPipeline(
        "ssao",
        SSAOPipeline.SSAOCombineRenderEffect,
        camera
      );

      //fix for ssao affecting transparent objects
      scene.enableGeometryBufferRenderer().renderTransparentMeshes = false;
    }
  } else {
    if (SSAOPipeline !== null && SSAOPipeline !== undefined) {
      SSAOPipeline.dispose();
    }
  }
}

function toggleDebug() {
  isDebugShowing = !isDebugShowing;
  if (isDebugShowing) {
    scene.debugLayer.show();
  } else {
    scene.debugLayer.hide();
  }
}

function changeRenderingLevel() {
  renderIndex++;
  if (renderIndex >= 2) {
    renderIndex = 0;
  }
  processRenderingLevel();
  theme.writeRenderIndex(renderIndex);
}

function processRenderingLevel() {
  if (renderIndex === 0) {
    scene.environmentIntensity = 1.48;       
    setdefaultRP(true);   
    setSSAO2(true); 
    light.setEnabled(true);
  } 

  if (renderIndex === 1) {
    scene.environmentIntensity = 1.48;   
    setSSAO2(false);
    setdefaultRP(true);
    if (defaultPLR) {
      if (defaultPLR.imageProcessing) {
        defaultPLR.imageProcessing.vignetteEnabled = false;
      }
      // defaultPLR.imageProcessingEnabled = false;
    }

    scene.shadowsEnabled = false;

    light.setEnabled(false);
  }
}
function createScreenshot(){
  
  CreateScreenshot(engine, camera, {width:canvasDom.width, height:canvasDom.height})

}
function create(canvasDomRef) {
  canvasDom = canvasDomRef;
  engine = new Engine(canvasDom, true, { preserveDrawingBuffer: true, stencil: true }); // Generate the BABYLON 3D engine
  engine.enableOfflineSupport = false;
  // what the heck is this and do i need to still have it in here ?? BABYLON.Database.IDBStorageEnabled = false; //disable the .manifest checking.
  scene = new Scene(engine); // Create the scene space

  Object.defineProperty(PBRBaseMaterial.prototype, "roughnessTexture", {
    configurable: true,
    set: function (newValue) {
      if (this[newValue.name]) {
        this.metallicTexture = this[newValue.name];
        return;
      }
      let texBuff;
      newValue.readPixels().then((val) => {
        texBuff = val;
        for (let i = 0; i < texBuff.length; i += 4) {
          texBuff[i] = 255;
          texBuff[i + 2] = 255;
          texBuff[i + 3] = 255;
        }
        let dimention = Math.sqrt(texBuff.length / 4);
        this[newValue.name] = new RawTexture(texBuff, dimention, dimention, 5, scene, true, true);
        this.roughness = 1;
        this.useRoughnessFromMetallicTextureAlpha = false;
        this.useRoughnessFromMetallicTextureGreen = true;
        this.metallicTexture = this[newValue.name];
      });
    },
  });

  scene.clearColor = new Color3(0.266, 0.266, 0.266).toLinearSpace();

  rootTransformNode = new TransformNode("root_transform_fix");
  rootTransformNode.rotation = new Vector3(-1.5708, 0, 0);
  rootTransformNode.scaling = new Vector3(-1, 1, 1);

  //Adding an Arc Rotate camera ..
  camera = new ArcRotateCamera("Camera", 1.5708, 1.5708, 3, new Vector3(0, 0, 0), scene);
  camera.attachControl(canvasDom, false);
  camera.wheelPrecision = 50;
  camera.panningSensibility = 250;
  camera.pinchPrecision = 700;

  camera.panningSensibility = 1000;
  camera.allowUpsideDown = false;
  camera.lowerRadiusLimit = 0.01;
  let p = window.location_url + envFile;
  hdrTexture = CubeTexture.CreateFromPrefilteredData(p, scene);
  hdrTexture.gammaSpace = false;
  hdrTexture.level = 1;
  scene.environmentTexture = hdrTexture;
  scene.environmentTexture.rotationY = 3.913;
  scene.environmentIntensity = 1.48;

  //var lightColor = Color3.FromHexString("#FDFFB5");
  var lightColor = Color3.FromHexString("#FFFFFF");

  light = pointLights["light_2"] = new PointLight("light_2", new Vector3(0, 4, 3), scene);
  light.shadowEnabled = false;
  //light.intensity = 45;
  light.intensity = 40;
  light.diffuse = lightColor;

  /*var lightColor = Color3.FromHexString("#FDFFB5");
  var light = (pointLights["light_1"] = new PointLight(
    "light_1",
    new Vector3(3, 4, 2),
    scene
  ));
  light.shadowEnabled = false;
  light.intensity = 8;
  light.diffuse = lightColor;

  light = pointLights["light_2"] = new PointLight(
    "light_2",
    new Vector3(0, 4, 2),
    scene
  );
  light.shadowEnabled = false;
  light.intensity = 8;
  light.diffuse = lightColor;

  light = pointLights["light_3"] = new PointLight(
    "light_3",
    new Vector3(-3, 4, 2),
    scene
  );
  light.shadowEnabled = false;
  light.intensity = 8;
  light.diffuse = lightColor;
*/
  processRenderingLevel();

  engine.runRenderLoop(renderListener);
  window.addEventListener("resize", resizeCompleteListener);
  window.addEventListener("keydown", keyListener);
  publishSubscribe.addEventListenerGlobal(EventNames.CONTENT_SIZING_COMPLETE, resizeCompleteListener);
  publishSubscribe.addEventListenerGlobal(EventNames.CONTENT_SIZING_BEGIN, resizeBeginListener);
  publishSubscribe.addEventListenerGlobal(EventNames.RESET_CAMERA, cameraResetListener);
  publishSubscribe.addEventListenerGlobal(EventNames.TOGGLE_HIGH_RENDERING, changeRenderIndexListener);
  onWindowResizeComplete();

  currentCameraFunction();
}
function onRenderLoop() {
  scene.render();
}
function onKeyListener(event) {
  if (event.ctrlKey && event.keyCode === 73) {
    toggleDebug();
  }
}
function onWindowResizeBegin() {
  //doing this as the site layout system uses flex that requires the element to have percentage widths
  //but this causes a visual stretch and squash of the canvas within , as the menu animates
  // so now on start of animation make canvas sizes zero
  canvasDom.width = 0;
  canvasDom.height = 0;
}
function onWindowResizeComplete() {
  canvasDom.width = 0;
  canvasDom.height = 0;
  if (engine) {
    engine.resize();
  }
  let cs = JSUtil.getComputedStyle(canvasDom.parentElement);
  //computed style has units on dimensions which need to be removed
  canvasDom.width = parseInt(cs.width.substring(0, cs.width.length - 2));
  canvasDom.height = parseInt(cs.height.substring(0, cs.height.length - 2));
  setTimeout(() => {
    let cs = JSUtil.getComputedStyle(canvasDom.parentElement);
    //computed style has units on dimensions which need to be removed
    canvasDom.width = parseInt(cs.width.substring(0, cs.width.length - 2));
    canvasDom.height = parseInt(cs.height.substring(0, cs.height.length - 2));
  }, 500);
}
</script>
