import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetDoorsInvert(payload, viewport) {
    
    const sceneEntities = useSceneEntities();
    let biPassBasco = sceneEntities.getUIData("biPassBasco");
    let biPassOriginal = sceneEntities.getUIData("biPassOriginal");
    let infinity_hinge = sceneEntities.getUIData("infinity_hinge");
    let showerenclosure_doors_rotolo_barn = sceneEntities.getUIData("showerenclosure_doors_rotolo_barn");
    let showerenclosure_doors_tuscon = sceneEntities.getUIData("showerenclosure_doors_tuscon");
  
    let shower_doors_invert = sceneEntities.getUIData("shower_doors_invert");
  
    if (
      (!biPassBasco.enabled || biPassBasco.disabled) &&
      (!biPassOriginal.enabled || biPassOriginal.disabled) &&
      (!infinity_hinge.enabled || infinity_hinge.disabled) &&
      (!showerenclosure_doors_rotolo_barn.enabled || showerenclosure_doors_rotolo_barn.disabled) &&
      (!showerenclosure_doors_tuscon.enabled || showerenclosure_doors_tuscon.disabled)
    ) {
      shower_doors_invert.enabled = false;
      shower_doors_invert.disabled = true;
      return;
    }
  
    if(biPassBasco.enabled && !biPassBasco.disabled){
      shower_doors_invert.enabled = false;
      shower_doors_invert.disabled = true;
    }
  
    if(biPassOriginal.enabled && !biPassOriginal.disabled){
      shower_doors_invert.enabled = false;
      shower_doors_invert.disabled = true;
    }
  
    if(showerenclosure_doors_rotolo_barn.enabled && !showerenclosure_doors_rotolo_barn.disabled){
      shower_doors_invert.enabled = false;
      shower_doors_invert.disabled = true;
    }
  
    if(infinity_hinge.enabled && !infinity_hinge.disabled){
      shower_doors_invert.enabled = false;
      shower_doors_invert.disabled = true;
    }
  
    if(showerenclosure_doors_tuscon.enabled && !showerenclosure_doors_tuscon.disabled){
      shower_doors_invert.disabled = false;
    }


    viewport.invalidateTransformForKey("shower_doors_container_inner","shower_doors_invert")
}