<template>
  <div class="home flex-column d-flex align-items-center justify-content-center">
    <b-img v-if="!window.isWhiteLabelBuild" class="mt-4 mb-1 d-block mx-auto" style="max-width: 300px" :src="getBrandingImage"></b-img>

    <div
    v-if="window.isWhiteLabelBuild"
      class="mb-1 text-center fs-3 fw-bold m-0"
      style="line-height: 0.8 !important"
      :class="getBrandTextColor"
    >
      bathroom
    </div>
    <div
      class="mb-4 text-center display-4 fw-bold m-0"
      style="line-height: 0.8 !important"
      :class="getBrandTextColor"
    >
      BUILDER
    </div>

    <button @click="toggleTheme" class="theme-button">
      <span
        class="mdi icon icon-color-2"
        :class="{
          'mdi-lightbulb-outline': theme.themeKey === 'light',
          'mdi-lightbulb-on': theme.themeKey === 'dark',
        }"
      ></span>
    </button>
    <div
      style="position: relative; height: fit-content"
      class="w-100 py-12 d-flex justify-content-center"
      :class="{
        'flex-row': !isSmallerScreens,
        'flex-column': isSmallerScreens,
        'align-content-center': !isSmallerScreens,
        'align-items-center': isSmallerScreens,
      }"
    >
      <div
        class="bg-image"
        :style="'background-image:url(' + (window.location_url + 'assets/images/bg5.jpg') + ');'"
      ></div>
      <div class="card-landing elevation-4 m-2 card" style="width: 18rem">
        <img :src="window.location_url + 'assets/images/room_1.jpg'" class="card-img-top" />
        <div class="card-body-landing card-body">
          <h5 class="card-title">Alcove Tub/Shower</h5>
          <p class="card-text"></p>
        </div>

        <div class="mt-2 mx-4 mb-6 mt-auto">
          <b-button class="fs-4 fw-bolder w-100 button-landing" @click="submit1">enter</b-button>
        </div>
      </div>

      <div class="card-landing elevation-4 m-2 card" style="width: 18rem">
        <img :src="window.location_url + 'assets/images/room_2.jpg'" class="card-img-top" />
        <div class="card-body-landing card-body">
          <h5 class="card-title">Corner / Neo Shower</h5>
          <p class="card-text">2 wall corner shower unit</p>
        </div>

        <div class="mt-2 mx-4 mb-6 mt-auto">
          <b-button class="fs-4 fw-bolder w-100 button-landing" @click="submit2">enter</b-button>
        </div>
      </div>
    </div>
    <div>
      <router-link to="/cacheutility" custom v-slot="{ navigate }">
        <button
          class="p-0 btn-no-focus-shadow button-minimal border-0 border-radius-0"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <div class="fs-6 fw-bolder text-color-4">cache all</div>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useTheme } from "@/stores/Theme";

import { useIsSmallerScreens } from "@/scripts/composables/IsSmallerScreens";
import { useRouter } from "vue-router";
import { ref, computed } from "vue";
import { useRequests } from "@/stores/Requests";
const requests = useRequests();
const theme = useTheme();
const is3DViewport = ref(true);
const router = useRouter();
function toggleTheme() {
  if (theme.themeKey === "light") {
    theme.writeTheme("dark");
    return;
  }
  if (theme.themeKey === "dark") {
    theme.writeTheme("light");
    return;
  }
}

const getBrandingImage = computed(() => {
  let path = null;
  if (theme.themeKey === "light") {
    //fivestar
    if (!window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_light.png";
    }
    //sentrel
    if (window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_sentrel_light.png";
    }
    //joyce
    if (window.isSentrelBuild && window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_joyce_light.png";
    }
    //dreamage
    if (window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_dreamage_light.png";
    }
  } else {
    //just asume dark as there are only two themes

    //fivestar
    if (!window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_dark.png";
    }
    //sentrel
    if (window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_sentrel_dark.png";
    }

    //joyce
    if (window.isSentrelBuild && window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_joyce_dark.png";
    }

    //dreamage
    if (window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_dreamage_dark.png";
    }
  }

  return path;
});

const isSmallerScreens = useIsSmallerScreens();

const getBrandTextColor = computed(() => {
  let classob = null;

    //fivestar
    if (!window.isWhiteLabelBuild && !window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      classob = { 'text-color-4': true }
    }
    //sentrel
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      classob = { 'text-color-8': true }
    }
    //joyce
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && window.isJoyceBuild && !window.isDreamAgeBuild) {
      classob = { 'text-color-11': true }
    }
    //dreamage
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
      classob = { 'text-color-12': true }
    }

      //joyce
      if (window.isWhiteLabelBuild) {
        classob = { 'text-color-11': true }
    }
  
 

  return classob;
});




function submit1() {
  router.push({
    path: "/application",
    query: { roomtype: "alcove", roomoption: 1, viewport: is3DViewport.value ? "3d" : "2d" },
  });
}
function submit2() {
  router.push({
    path: "/application",
    query: { roomtype: "corner", roomoption: 1, viewport: is3DViewport.value ? "3d" : "2d" },
  });
}
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--content-bg-5);
}

.card-landing {
  background-color: var(--content-bg-3);
}

.card-body-landing {
  color: var(--text-color-1);
}

.button-landing {
  background-color: var(--icon-color-7);
  border-color: var(--icon-color-7);
}

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.theme-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
}

.card {
  --bs-card-border-color: #ffffffe0 !important;
  --bs-card-border-width: 3px !important;
  --bs-card-inner-border-radius: calc(0.375rem - 3px) !important;
}
</style>
