import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetDoorsNeo(payload,viewport){
    const sceneEntities = useSceneEntities();

    let kneewall = sceneEntities.getUIData("kneewall");
    let doors_neo_1_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_neo_1_shower");
    let doors_neo_2_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_neo_2_shower");
  

    let doors_neo_1_shower_ui = sceneEntities.getUIData("doors_neo_1_shower");
    let doors_neo_2_shower_ui = sceneEntities.getUIData("doors_neo_2_shower");

    doors_neo_1_shower.enabled = doors_neo_1_shower_ui.enabled;
    doors_neo_2_shower.enabled = doors_neo_2_shower_ui.enabled;


    viewport.setEnabled(doors_neo_1_shower);
    viewport.setEnabled(doors_neo_2_shower);
   
    

}