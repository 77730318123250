import { useSceneEntities } from "@/stores/SceneEntities";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsValidate";
export function useAssetDoorsBipassValidate(payload, viewport) {
  
  const sceneEntities = useSceneEntities(); 

  let biPassBasco = sceneEntities.getUIData("biPassBasco");
  let biPassOriginal = sceneEntities.getUIData("biPassOriginal");

  if((!biPassBasco.enabled && !biPassBasco.disabled) && (!biPassOriginal.enabled&& !biPassOriginal.disabled)){
    biPassBasco.enabled = true;
    let previousSelection =  useUIRadioGroup(biPassBasco, viewport);   
    useAssetDoorsValidate(null,viewport);
  }
  

 
}
