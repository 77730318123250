import { useSceneEntities } from "@/stores/SceneEntities";
export function useMaterialWallPaint(payload, viewport) {
    const sceneEntities = useSceneEntities();
    if (!payload.enabled) {       
        return;
    }   
    sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({key:payload.key});
  

    //wall_exterior--------------------------------------------------------------------

    let materialUpdateObject = {};
    materialUpdateObject.name = "wall_exterior";
    materialUpdateObject.colors = {};
    materialUpdateObject.colors.albedoColor = payload.key.split("_")[1];
    viewport.updateMaterial(materialUpdateObject)

     

}