import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
export function useWalkin2653() {
  const sceneEntities = useSceneEntities();
  let target = useAccordion2();
  target.attributes.componentData.display.label = "Walk in 2653";
  target.attributes.componentData.key = "walkin2653";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;
  target.attributes.componentData.interaction.deselectSelf = false;
  target.attributes.componentData.interaction.actions["scene_update"] = true;

  //Divider Jet Options--------------------------------------------------
  let divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Jet Options";

  let jetOptionsRadioGroup = useButtonRadioGroup();

  //Dual Jetted--------------------------------------------------
  let dualJetted = useButton2();
  target.attributes.componentData.addChild(dualJetted)

  dualJetted.attributes.componentData.display.label = "Dual Jetted";
  dualJetted.attributes.componentData.key = "walkin2653dualjetted";
  sceneEntities.addItemToUIList(dualJetted.attributes.componentData);
  dualJetted.attributes.componentData.interaction.radioGroup = jetOptionsRadioGroup;
  dualJetted.attributes.componentData.interaction.actions["scene_update"] = true;
  dualJetted.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //Hydrotherapy--------------------------------------------------
  let hydrotherapy = useButton2();
  target.attributes.componentData.addChild(hydrotherapy)
  hydrotherapy.attributes.componentData.display.label = "Hydrotherapy";
  hydrotherapy.attributes.componentData.key = "walkin2653hydrotherapy"; 
  sceneEntities.addItemToUIList(hydrotherapy.attributes.componentData);
  hydrotherapy.attributes.componentData.interaction.radioGroup = jetOptionsRadioGroup;
  hydrotherapy.attributes.componentData.interaction.actions["scene_update"] = true;
  hydrotherapy.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //Whirlpool--------------------------------------------------
  let whirlpool = useButton2();
  target.attributes.componentData.addChild(whirlpool)
  whirlpool.attributes.componentData.display.label = "Whirlpool";
  whirlpool.attributes.componentData.key = "walkin2653whirlpool";
  sceneEntities.addItemToUIList(whirlpool.attributes.componentData);
  whirlpool.attributes.componentData.interaction.radioGroup = jetOptionsRadioGroup;
  whirlpool.attributes.componentData.interaction.actions["scene_update"] = true;
  whirlpool.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //Soaker--------------------------------------------------
  let soaker = useButton2();
  target.attributes.componentData.addChild(soaker)
  soaker.attributes.componentData.display.label = "Soaker";
  soaker.attributes.componentData.key = "walkin2653soaker"; 
  sceneEntities.addItemToUIList(soaker.attributes.componentData);
  soaker.attributes.componentData.interaction.radioGroup = jetOptionsRadioGroup;
  soaker.attributes.componentData.interaction.actions["scene_update"] = true;
  soaker.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //Divider Shower Options--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Shower Options";

  let showerOptionsRadioGroup = useButtonRadioGroup();

  //regular--------------------------------------------------
  let regular = useButton2();
  target.attributes.componentData.addChild(regular)
  regular.attributes.componentData.display.label = "Regular";
  regular.attributes.componentData.key = "elite_2653_shower_head_docked";
  sceneEntities.addItemToUIList(regular.attributes.componentData);
  regular.attributes.componentData.interaction.radioGroup = showerOptionsRadioGroup;
  regular.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  regular.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //Slide Bar--------------------------------------------------
  let slideBar = useButton2();
  target.attributes.componentData.addChild(slideBar)
  slideBar.attributes.componentData.display.label = "Slide Bar";
  slideBar.attributes.componentData.key = "elite_2653_sliderbar";
  sceneEntities.addItemToUIList(slideBar.attributes.componentData);
  slideBar.attributes.componentData.interaction.radioGroup = showerOptionsRadioGroup;
  slideBar.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  slideBar.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //Divider Door Options--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Door Options";

  //openclose--------------------------------------------------
  let openclose = useButton2();
  target.attributes.componentData.addChild(openclose)
  openclose.attributes.componentData.display.label = "Open/Close";
  
    openclose.attributes.componentData.key = "elite_2653_tub_door_open";
  sceneEntities.addItemToUIList(openclose.attributes.componentData);
  openclose.attributes.componentData.interaction.actions["scene_update"] = true;
  openclose.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  return target;
}
