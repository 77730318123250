import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetEnableSimple } from "@/scripts/rooms/actions/general/AssetEnableSimple";
import { useAssetCeilingPanelValidate } from "@/scripts/rooms/actions/alcove/AssetCeilingPanelValidate";
import { useAssetSoffitCoverValidate } from "@/scripts/rooms/actions/alcove/AssetSoffitCoverValidate";

export function useAssetSoffit(payload, viewport) {
  const sceneEntities = useSceneEntities();

  useAssetEnableSimple(payload, viewport);
  useAssetCeilingPanelValidate(null,viewport)
  useAssetSoffitCoverValidate(null,viewport)

}
