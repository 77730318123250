import { useSceneEntities } from "@/stores/SceneEntities";

export function useShowerWallMoldValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;

  let mold1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mould_left_full");
  let mold2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mould_right_full");
  let mold3 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mould_left_34");
  let mold4 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mould_right_34");

  let wall_inner_mold_jtrim_left_full = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "wall_inner_mold_jtrim_left_full"
  );
  let wall_inner_mold_jtrim_right_full = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "wall_inner_mold_jtrim_right_full"
  );
  let wall_inner_mold_jtrim_left_34 = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "wall_inner_mold_jtrim_left_34"
  );
  let wall_inner_mold_jtrim_right_34 = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "wall_inner_mold_jtrim_right_34"
  );

  let data = sceneEntities.configuratorView.viewportComponent.getDataCollectionMaterial("useSWDMaterialWalls");

  let permitMoldsStandard = true;
  if (data) {
    if (
      data.sentrelDesignKey === "acrylic3x8subway" ||
      data.sentrelDesignKey === "twotoneacrylic3x8black" ||
      data.sentrelDesignKey === "twotoneacrylic3x8gray" ||
      data.sentrelDesignKey === "twotoneacrylic10x20black" ||
      data.sentrelDesignKey === "twotoneacrylic10x20gray"
    ) {
      permitMoldsStandard = false;
    }
  }
  if (window.isSentrelBuild) {
    if (
      data.sentrelDesignKey === "acrylic3x8subway" ||
      data.sentrelDesignKey === "twotoneacrylic3x8black" ||
      data.sentrelDesignKey === "twotoneacrylic3x8gray" ||
      data.sentrelDesignKey === "twotoneacrylic10x20black" ||
      data.sentrelDesignKey === "twotoneacrylic10x20gray"
    ) {
      permitMoldsStandard = false;
    }
  }

  if (wallsfull) {
    mold1.enabled = permitMoldsStandard;
    viewport.setEnabled(mold1);
    mold2.enabled = permitMoldsStandard;
    viewport.setEnabled(mold2);

    mold3.enabled = false;
    viewport.setEnabled(mold3);
    mold4.enabled = false;
    viewport.setEnabled(mold4);

    if (window.isSentrelBuild) {
    
        //use jtrim

        wall_inner_mold_jtrim_left_full.enabled = !permitMoldsStandard;
        viewport.setEnabled(wall_inner_mold_jtrim_left_full);
        wall_inner_mold_jtrim_right_full.enabled = !permitMoldsStandard;
        viewport.setEnabled(wall_inner_mold_jtrim_right_full);
    
        wall_inner_mold_jtrim_left_34.enabled = false;
        viewport.setEnabled(wall_inner_mold_jtrim_left_34);
        wall_inner_mold_jtrim_right_34.enabled = false;
        viewport.setEnabled(wall_inner_mold_jtrim_right_34);

     
      
    }
  }
  if (walls34) {
    mold1.enabled = false;
    viewport.setEnabled(mold1);
    mold2.enabled = false;
    viewport.setEnabled(mold2);

    mold3.enabled = permitMoldsStandard;
    viewport.setEnabled(mold3);
    mold4.enabled = permitMoldsStandard;
    viewport.setEnabled(mold4);


    if (window.isSentrelBuild) {
      
        //use jtrim

        wall_inner_mold_jtrim_left_full.enabled = false;
        viewport.setEnabled(wall_inner_mold_jtrim_left_full);
        wall_inner_mold_jtrim_right_full.enabled = false;
        viewport.setEnabled(wall_inner_mold_jtrim_right_full);
    
        wall_inner_mold_jtrim_left_34.enabled = !permitMoldsStandard;
        viewport.setEnabled(wall_inner_mold_jtrim_left_34);
        wall_inner_mold_jtrim_right_34.enabled = !permitMoldsStandard;
        viewport.setEnabled(wall_inner_mold_jtrim_right_34);

     
      
    }

  }
}
