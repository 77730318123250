import { useSceneEntities } from "@/stores/SceneEntities";
import EventNames from "@/scripts/events/EventNames";
import PublishSubscribe from "@/scripts/events/PublishSubscribe";
export function usePresetApply(payload, viewport) {
  const publishSubscribe = new PublishSubscribe();
  const sceneEntities = useSceneEntities();
  let preset = sceneEntities.presetData.session.find((element) => element.key === payload.key);
  if (!preset) {
    preset = sceneEntities.presetData.persistant.find((element) => element.key === payload.key);
  }
  if (!preset) {
    preset = sceneEntities.presetData.default.find((element) => element.key === payload.key);
  }
  if (preset) {
   
    sceneEntities.configuratorView.loadPreset(preset.data);
    publishSubscribe.dispatchEventGlobal(EventNames.PRESET_APPLY);  
  }
}
