import { useWhiteStandard } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/WhiteStandard";
import { useAlaskanIvory } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/AlaskanIvory";
import { useAcrylic3by8 } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/Acrylic3by8";

import { use_two_tone_acrylic_3x8_black } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_3x8_black";
import { use_two_tone_acrylic_3x8_gray } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_3x8_gray";
import { use_two_tone_acrylic_10x20_black } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_10x20_black";
import { use_two_tone_acrylic_10x20_gray } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_10x20_gray";

import { useBardiglio } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/Bardiglio";
import { useBotticinoCream } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/BotticinoCream";
import { useBrecciaParadiso } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/BrecciaParadiso";
import { useCalabria } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/Calabria";
import { useCalacattaWhite } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/CalacattaWhite";
import { useCalcuttaGold } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/CalcuttaGold";
import { useCremaBordeaux } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/CremaBordeaux";
import { useCremeTravertine } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/CremeTravertine";
import { useEverest } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/Everest";
import { useFrost } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/Frost";
import { useGoldenBeaches } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/GoldenBeaches";
import { useGrayQuartz } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/GrayQuartz";
import { useMochaTravertine } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/MochaTravertine";
import { useLagosGray } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/LagosGray";
import { usePetrafini } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/Petrafini";
import { useTriton } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/Triton";
import { useVeincutGray } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/VeincutGray";
import { useVintageSpruce } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/VintageSpruce";
import { useMarmoGrigio } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/MarmoGrigio";
import { useMarmoMarrone } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/MarmoMarrone";
import { useWhitePearl } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/WhitePearl";
import { useAzzurraBay } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/AzzurraBay";
import { useTajMahal } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/TajMahal";
import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useSentrelWallDesigns(injectedRadioGroups) {
  const sceneEntities = useSceneEntities();

  sceneEntities.SWDRadioGroups = injectedRadioGroups;
  const target = useAccordion1();

  target.attributes.componentData.display.label = "Smart Choice Walls";
  if (window.isSentrelBuild) {
    target.attributes.componentData.display.label = "Sentrel Wall System";
  }
  if (window.isDreamAgeBuild || window.isWhiteLabelBuild) {
    target.attributes.componentData.display.label = "Wall System";
  }

  target.attributes.componentData.key = "swd";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

  //very hackish solution that is not scaling well obviously , but not cared to solve it here
  //becaseu configurator is been rebuilt anyway

  //for dream age
  if (window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
    //TajMahal / Arabia--------------------------------------------------
    //
    let tajMahal = useTajMahal(injectedRadioGroups);
    targetChildren.push(tajMahal);
    tajMahal.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //White Standard / Blanche--------------------------------------------------
    //
    let whiteStandard = useWhiteStandard(injectedRadioGroups);
    targetChildren.push(whiteStandard);
    whiteStandard.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;
    //

    //Calacatta White / Calacatta Bianco--------------------------------------------------
    //
    let calacattaWhite = useCalacattaWhite(injectedRadioGroups);
    targetChildren.push(calacattaWhite);
    calacattaWhite.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //veincutGray / Cirrus Gray--------------------------------------------------
    //
    let veincutGray = useVeincutGray(injectedRadioGroups);
    targetChildren.push(veincutGray);
    veincutGray.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //
    //Alaskan Ivory / 	Denali--------------------------------------------------
    //
    let alaskanIvory = useAlaskanIvory(injectedRadioGroups);
    targetChildren.push(alaskanIvory);
    alaskanIvory.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //
    //two_tone_acrylic_3x8_black--------------------------------------------------
    //
    let two_tone_acrylic_3x8_black = use_two_tone_acrylic_3x8_black(injectedRadioGroups);
    targetChildren.push(two_tone_acrylic_3x8_black);
    two_tone_acrylic_3x8_black.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //
    //two_tone_acrylic_3x8_gray--------------------------------------------------
    //
    let two_tone_acrylic_3x8_gray = use_two_tone_acrylic_3x8_gray(injectedRadioGroups);
    targetChildren.push(two_tone_acrylic_3x8_gray);
    two_tone_acrylic_3x8_gray.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //
    //two_tone_acrylic_10x20_black--------------------------------------------------
    //
    let two_tone_acrylic_10x20_black = use_two_tone_acrylic_10x20_black(injectedRadioGroups);
    targetChildren.push(two_tone_acrylic_10x20_black);
    two_tone_acrylic_10x20_black.attributes.componentData.interaction.radioGroup =
      injectedRadioGroups.sentrelRadioGroup;

    //
    //two_tone_acrylic_10x20_gray--------------------------------------------------
    //
    let two_tone_acrylic_10x20_gray = use_two_tone_acrylic_10x20_gray(injectedRadioGroups);
    targetChildren.push(two_tone_acrylic_10x20_gray);
    two_tone_acrylic_10x20_gray.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //Breccia Paradiso / Esperia Paradiso--------------------------------------------------
    //
    let brecciaParadiso = useBrecciaParadiso(injectedRadioGroups);
    targetChildren.push(brecciaParadiso);
    brecciaParadiso.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //
    //Botticino Cream / Florence--------------------------------------------------
    //
    let botticinoCream = useBotticinoCream(injectedRadioGroups);
    targetChildren.push(botticinoCream);
    botticinoCream.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //
    //AzzurraBay / Glacier--------------------------------------------------
    //
    let azzurraBay = useAzzurraBay(injectedRadioGroups);
    targetChildren.push(azzurraBay);
    azzurraBay.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //MarmoGrigio--------------------------------------------------
    //
    let marmoGrigio = useMarmoGrigio(injectedRadioGroups);
    targetChildren.push(marmoGrigio);
    marmoGrigio.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //MarmoMarrone--------------------------------------------------
    //
    let marmoMarrone = useMarmoMarrone(injectedRadioGroups);
    targetChildren.push(marmoMarrone);
    marmoMarrone.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //goldenBeaches / Mojave Shores--------------------------------------------------
    //
    let goldenBeaches = useGoldenBeaches(injectedRadioGroups);
    targetChildren.push(goldenBeaches);
    goldenBeaches.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //WhitePearl / Opaline White--------------------------------------------------
    //
    let whitePearl = useWhitePearl(injectedRadioGroups);
    targetChildren.push(whitePearl);
    whitePearl.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //triton / Poseidon--------------------------------------------------
    //
    let triton = useTriton(injectedRadioGroups);
    targetChildren.push(triton);
    triton.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    
  } else {
    //for sentrel , joyce and fivestar

    //
    //White Standard--------------------------------------------------
    //
    let whiteStandard = useWhiteStandard(injectedRadioGroups);
    targetChildren.push(whiteStandard);
    whiteStandard.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;
    //

    if (window.isSentrelBuild) {
      //
      //two_tone_acrylic_3x8_black--------------------------------------------------
      //
      let two_tone_acrylic_3x8_black = use_two_tone_acrylic_3x8_black(injectedRadioGroups);
      targetChildren.push(two_tone_acrylic_3x8_black);
      two_tone_acrylic_3x8_black.attributes.componentData.interaction.radioGroup =
        injectedRadioGroups.sentrelRadioGroup;

      //
      //two_tone_acrylic_3x8_gray--------------------------------------------------
      //
      let two_tone_acrylic_3x8_gray = use_two_tone_acrylic_3x8_gray(injectedRadioGroups);
      targetChildren.push(two_tone_acrylic_3x8_gray);
      two_tone_acrylic_3x8_gray.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

      //
      //two_tone_acrylic_10x20_black--------------------------------------------------
      //
      let two_tone_acrylic_10x20_black = use_two_tone_acrylic_10x20_black(injectedRadioGroups);
      targetChildren.push(two_tone_acrylic_10x20_black);
      two_tone_acrylic_10x20_black.attributes.componentData.interaction.radioGroup =
        injectedRadioGroups.sentrelRadioGroup;

      //
      //two_tone_acrylic_10x20_gray--------------------------------------------------
      //
      let two_tone_acrylic_10x20_gray = use_two_tone_acrylic_10x20_gray(injectedRadioGroups);
      targetChildren.push(two_tone_acrylic_10x20_gray);
      two_tone_acrylic_10x20_gray.attributes.componentData.interaction.radioGroup =
        injectedRadioGroups.sentrelRadioGroup;
    }

    if (!window.isSentrelBuild && !window.isDreamAgeBuild && !window.isJoyceBuild) {
 //
    //useAcrylic3by8--------------------------------------------------
    //
    let acrylic3by8 = useAcrylic3by8(injectedRadioGroups);
    targetChildren.push(acrylic3by8);
    acrylic3by8.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;
    }

    //
    //Alaskan Ivory--------------------------------------------------
    //
    let alaskanIvory = useAlaskanIvory(injectedRadioGroups);
    targetChildren.push(alaskanIvory);
    alaskanIvory.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;
    //
    //Athens--------------------------------------------------
    //
    /* let athens = useAthens(injectedRadioGroups);
    targetChildren.push(athens);
    athens.attributes.componentData.interaction.radioGroup =
    injectedRadioGroups.sentrelRadioGroup;*/
    //
    //AzzurraBay--------------------------------------------------
    //
    let azzurraBay = useAzzurraBay(injectedRadioGroups);
    targetChildren.push(azzurraBay);
    azzurraBay.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //
    //Bardiglio--------------------------------------------------
    //
    let bardiglio = useBardiglio(injectedRadioGroups);
    targetChildren.push(bardiglio);
    bardiglio.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;
    //
    //Botticino Cream--------------------------------------------------
    //
    let botticinoCream = useBotticinoCream(injectedRadioGroups);
    targetChildren.push(botticinoCream);
    botticinoCream.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //Breccia Paradiso--------------------------------------------------
    //
    let brecciaParadiso = useBrecciaParadiso(injectedRadioGroups);
    targetChildren.push(brecciaParadiso);
    brecciaParadiso.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //Calabria--------------------------------------------------
    //
    let Calabria = useCalabria(injectedRadioGroups);
    targetChildren.push(Calabria);
    Calabria.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //Calacatta White--------------------------------------------------
    //
    let calacattaWhite = useCalacattaWhite(injectedRadioGroups);
    targetChildren.push(calacattaWhite);
    calacattaWhite.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //Calcutta Gold--------------------------------------------------
    //
    let calcuttaGold = useCalcuttaGold(injectedRadioGroups);
    targetChildren.push(calcuttaGold);
    calcuttaGold.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //Crema Bordeaux--------------------------------------------------
    //
    let cremaBordeaux = useCremaBordeaux(injectedRadioGroups);
    targetChildren.push(cremaBordeaux);
    cremaBordeaux.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //cremeTravertine--------------------------------------------------
    //
    let cremeTravertine = useCremeTravertine(injectedRadioGroups);
    targetChildren.push(cremeTravertine);
    cremeTravertine.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //cremeTravertine--------------------------------------------------
    //
    let everest = useEverest(injectedRadioGroups);
    targetChildren.push(everest);
    everest.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //frost--------------------------------------------------
    //
    let frost = useFrost(injectedRadioGroups);
    targetChildren.push(frost);
    frost.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //goldenBeaches--------------------------------------------------
    //
    let goldenBeaches = useGoldenBeaches(injectedRadioGroups);
    targetChildren.push(goldenBeaches);
    goldenBeaches.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //GrayQuartz--------------------------------------------------
    //
    let GrayQuartz = useGrayQuartz(injectedRadioGroups);
    targetChildren.push(GrayQuartz);
    GrayQuartz.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //MochaTravertine--------------------------------------------------
    //
    let mochaTravertine = useMochaTravertine(injectedRadioGroups);
    targetChildren.push(mochaTravertine);
    mochaTravertine.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //LagosGray--------------------------------------------------
    //
    let lagosGray = useLagosGray(injectedRadioGroups);
    targetChildren.push(lagosGray);
    lagosGray.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //Petrafini--------------------------------------------------
    /*
  let petrafini = usePetrafini(injectedRadioGroups);
  targetChildren.push(petrafini);
  petrafini.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;
*/
    //TajMahal--------------------------------------------------
    //
    let tajMahal = useTajMahal(injectedRadioGroups);
    targetChildren.push(tajMahal);
    tajMahal.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //triton--------------------------------------------------
    //
    let triton = useTriton(injectedRadioGroups);
    targetChildren.push(triton);
    triton.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //veincutGray--------------------------------------------------
    //
    let veincutGray = useVeincutGray(injectedRadioGroups);
    targetChildren.push(veincutGray);
    veincutGray.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;

    //vintageSpruce--------------------------------------------------
    //
    /* let vintageSpruce = useVintageSpruce(injectedRadioGroups);
    targetChildren.push(vintageSpruce);
    vintageSpruce.attributes.componentData.interaction.radioGroup =
    injectedRadioGroups.sentrelRadioGroup;*/

    //WhitePearl--------------------------------------------------
    //
    let whitePearl = useWhitePearl(injectedRadioGroups);
    targetChildren.push(whitePearl);
    whitePearl.attributes.componentData.interaction.radioGroup = injectedRadioGroups.sentrelRadioGroup;
  }

  return target;
}
