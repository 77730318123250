import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetCornerShelvesFinish(payload, viewport) {
  //this function does not need to process when enabled is false
  if(!payload.enabled)return
  // need to hide or show shelves , need to know if they metal or sentrel
  const sceneEntities = useSceneEntities();
  let sentrelFinishData = sceneEntities.getUIData("corner_shelf_sentrel_material");
  let metalFinishData = sceneEntities.getUIData("corner_shelf_metal_material");

  let shelfLeftTop = sceneEntities.getUIData("accessories_shelflefttop");
  let shelfLeftMid = sceneEntities.getUIData("accessories_shelfleftmid");
  let shelfLeftBottom = sceneEntities.getUIData("accessories_shelfleftbottom");

  let shelfRightTop = sceneEntities.getUIData("accessories_shelfrighttop");
  let shelfRightMid = sceneEntities.getUIData("accessories_shelfrightmid");
  let shelfRightBottom = sceneEntities.getUIData("accessories_shelfrightbottom");

  //SENTREL FINISH ------------------------------------------

  if (sentrelFinishData.enabled) {

    //sentrel shelves

     //left
    let shelfLeftTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelflefttop");
    shelfLeftTopAsset.enabled = shelfLeftTop.enabled;
    viewport.setEnabled(shelfLeftTopAsset);

    let shelfLeftMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftmid");
    shelfLeftMidAsset.enabled = shelfLeftMid.enabled;
    viewport.setEnabled(shelfLeftMidAsset);

    let shelfLeftBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftbottom");
    shelfLeftBottomAsset.enabled = shelfLeftBottom.enabled;
    viewport.setEnabled(shelfLeftBottomAsset);

    //right
    let shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop");
    shelfRightTopAsset.enabled = shelfRightTop.enabled;
    viewport.setEnabled(shelfRightTopAsset);

    let shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid");
    shelfRightMidAsset.enabled = shelfRightMid.enabled;
    viewport.setEnabled(shelfRightMidAsset);

    let shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom");
    shelfRightBottomAsset.enabled = shelfRightBottom.enabled;
    viewport.setEnabled(shelfRightBottomAsset);

    //metal shelves

     //left
     shelfLeftTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelflefttop_metal");
     shelfLeftTopAsset.enabled = false;
     viewport.setEnabled(shelfLeftTopAsset);
 
     shelfLeftMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftmid_metal");
     shelfLeftMidAsset.enabled = false;
     viewport.setEnabled(shelfLeftMidAsset);
 
     shelfLeftBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftbottom_metal");
     shelfLeftBottomAsset.enabled = false;
     viewport.setEnabled(shelfLeftBottomAsset);
 
     //right
     shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop_metal");
     shelfRightTopAsset.enabled = false;
     viewport.setEnabled(shelfRightTopAsset);
 
     shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid_metal");
     shelfRightMidAsset.enabled = false;
     viewport.setEnabled(shelfRightMidAsset);
 
     shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom_metal");
     shelfRightBottomAsset.enabled = false;
     viewport.setEnabled(shelfRightBottomAsset);
  }

  //METAL FINISH ------------------------------------------

  if (metalFinishData.enabled) {

    //metal shelves

     //left
    let shelfLeftTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelflefttop_metal");
    shelfLeftTopAsset.enabled = shelfLeftTop.enabled;
    viewport.setEnabled(shelfLeftTopAsset);

    let shelfLeftMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftmid_metal");
    shelfLeftMidAsset.enabled = shelfLeftMid.enabled;
    viewport.setEnabled(shelfLeftMidAsset);

    let shelfLeftBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftbottom_metal");
    shelfLeftBottomAsset.enabled = shelfLeftBottom.enabled;
    viewport.setEnabled(shelfLeftBottomAsset);

    //right
    let shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop_metal");
    shelfRightTopAsset.enabled = shelfRightTop.enabled;
    viewport.setEnabled(shelfRightTopAsset);

    let shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid_metal");
    shelfRightMidAsset.enabled = shelfRightMid.enabled;
    viewport.setEnabled(shelfRightMidAsset);

    let shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom_metal");
    shelfRightBottomAsset.enabled = shelfRightBottom.enabled;
    viewport.setEnabled(shelfRightBottomAsset);

    //sentrel shelves

     //left
     shelfLeftTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelflefttop");
     shelfLeftTopAsset.enabled = false;
     viewport.setEnabled(shelfLeftTopAsset);
 
     shelfLeftMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftmid");
     shelfLeftMidAsset.enabled = false;
     viewport.setEnabled(shelfLeftMidAsset);
 
     shelfLeftBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftbottom");
     shelfLeftBottomAsset.enabled = false;
     viewport.setEnabled(shelfLeftBottomAsset);
 
     //right
     shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop");
     shelfRightTopAsset.enabled = false;
     viewport.setEnabled(shelfRightTopAsset);
 
     shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid");
     shelfRightMidAsset.enabled = false;
     viewport.setEnabled(shelfRightMidAsset);
 
     shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom");
     shelfRightBottomAsset.enabled = false;
     viewport.setEnabled(shelfRightBottomAsset);
  }

  
}
