
export function useButtonRadioGroup() {
   
    const data = {
       currentSelection:null,
      
     
          };

 
  return data;
}