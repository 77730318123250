import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetGrabbarsValidate } from "@/scripts/rooms/actions/alcove/AssetGrabbarsValidate";
import { useAssetAccentValidate1 } from "@/scripts/rooms/actions/alcove/AssetAccentValidate1";
export function useAssetRecessSteelShelf1(payload, viewport) {
  
  const sceneEntities = useSceneEntities();
  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;
  let asset = null;
  if (wallsfull) {
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_shelf_1");
  }
  if (walls34) {
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_shelf_1");
  }

  let asset2 =  sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_steelshelf1");

  if (asset) {
    asset.enabled = payload.enabled;
    viewport.setEnabled(asset);

    asset2.enabled = payload.enabled;
    viewport.setEnabled(asset2);
  }
  useAssetGrabbarsValidate(null,viewport)
  useAssetAccentValidate1(null,viewport)
}
