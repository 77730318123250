import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetSoffitValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let soffitUI = sceneEntities.getUIData("soffit");
  let soffitAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("soffit");

  soffitUI.disabled = wallsfull;
  soffitAsset.disabled = wallsfull;

  // new logic if disabled then set enabled to false
  if (soffitUI.enabled && soffitUI.disabled) {
    soffitUI.enabled = false;
  }
  soffitAsset.enabled = soffitUI.enabled;
  viewport.setEnabled(soffitAsset);
}
