import { useAssetPreloader } from "@/stores/AssetPreloader";
export default class BabylonTextureAsset {
  constructor(key) {
   this.key = key;  
   this.texture = null;
   this.loading = false;
   this.loaded = false;
   this.loadPercent = 0;
   this.enabled = false;
   this.assetPreloader = null;
   this.lengthComputable = false;
  
   

  }
  dispose(){    
    this.texture.dispose();  

  }
  load() {
    this.assetPreloader = useAssetPreloader();
    this.loading = true;
    this.assetPreloader.load(this);
  }
  loadProgress(event) {
    if (event) {
      this.lengthComputable = event.lengthComputable;
      if (event.lengthComputable) {       
        this.loadPercent = event.loaded / event.total;       
      }
      this.assetPreloader.progress();
    }
  }

  loadSuccess(event) {
    
    this.loading = false;
    this.loaded = true;
    this.assetPreloader.complete(this);
  
  }
 
}
