<template>
  <!--used to use align-items-center, changed it but keep an eye out for side effects?-->
  <div class="w-100 h-100 d-flex">
    <div class="ms-2 pe-1 d-flex me-auto align-content-center" style="flex:1 1 100%">
      <slot name="left"></slot>
    </div>
    <div class="me-2 ps-1 d-flex align-content-center flex-row-reverse ms-auto">
      <slot name="right"></slot>
    </div>
  </div>
</template>


