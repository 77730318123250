import { useSceneEntities } from "@/stores/SceneEntities";
import { useSWDMaterialWalls } from "@/scripts/rooms/actions/alcove/SWDMaterialWalls";
import { useSWDMaterialWainscotting } from "@/scripts/rooms/actions/general/SWDMaterialWainscotting";
import { useSWDMaterialShowerFloor } from "@/scripts/rooms/actions/general/SWDMaterialShowerFloor";
import { useSWDMaterialCeilingPanal } from "@/scripts/rooms/actions/general/SWDMaterialCeilingPanal";
import { useSWDMaterialTrigger1 } from "@/scripts/rooms/actions/general/SWDMaterialTrigger1";
import { useAssetWainscotting } from "@/scripts/rooms/actions/alcove/AssetWainscotting";

export function useSWDMaterials(payload, viewport) {
  const sceneEntities = useSceneEntities();
 
 

  // styles
  //assuming there are always three underscores with name in middle
  let sentrelDesignKey = payload.key.split("_")[1];

  let regular = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_regular");
  let sim2x2 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_sim2x2");
  let sim3x6 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_sim3x6");
  let sim10x20 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_sim10x20");
  //new mosaic
  let mosaic1 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_mosaic1");
  
  let applyWalls = false;
  let applyWainscotting = false;
  let applyShowerFloor = false;
  let applyCeilingPanal = false;

  if (payload.key === "swd_" + sentrelDesignKey + "_allsurfaces") {
    applyWalls = true;
    applyWainscotting = true;
    applyShowerFloor = true;
    applyCeilingPanal = true;
  }
  if (payload.key === "swd_" + sentrelDesignKey + "_interiorwalls") {
    applyWalls = true;
  }
  if (payload.key === "swd_" + sentrelDesignKey + "_wainscotting") {
    applyWainscotting = true;
  }
  if (payload.key === "swd_" + sentrelDesignKey + "_showerfloor") {
    applyShowerFloor = true;
  }
  if (payload.key === "swd_" + sentrelDesignKey + "_ceilingpanel") {
    applyCeilingPanal = true;
  }

  //---------------------------------------------------------------------------------------------------------
  let textureKeyData = {};
  textureKeyData.textureKey = null;
  textureKeyData.textureMoldKey = null;
  textureKeyData.sentrelDesignKey = sentrelDesignKey;

  if (regular?.enabled) {
    textureKeyData.textureKey = sentrelDesignKey + "_regular";
    textureKeyData.textureMoldKey = sentrelDesignKey + "_regular";
  }

  if (sim2x2?.enabled) {
    textureKeyData.textureKey = sentrelDesignKey + "_2x2";
    textureKeyData.textureMoldKey = sentrelDesignKey + "_regular";
  }

  if (sim3x6?.enabled) {
    textureKeyData.textureKey = sentrelDesignKey + "_3x6";
    textureKeyData.textureMoldKey = sentrelDesignKey + "_regular";
  }

  if (sim10x20?.enabled) {
    textureKeyData.textureKey = sentrelDesignKey + "_10x20";
    textureKeyData.textureMoldKey = sentrelDesignKey + "_regular";
  }

  if (mosaic1?.enabled) {
    textureKeyData.textureKey = sentrelDesignKey + "_mosaic1";
    textureKeyData.textureMoldKey = sentrelDesignKey + "_mosaic1_mold";
  }



  //---------------------------------------------------------------------------------------------------------

  if (applyWalls) {
    //trigger floor and wall color here ..
    useSWDMaterialTrigger1(sentrelDesignKey, viewport);

    useSWDMaterialWalls(textureKeyData, viewport);
  }

  //---------------------------------------------------------------------------------------------------------

  if (applyWainscotting) {
    //trigger wainscotting asset here and ui if not switched on
   /* let wainscotting = sceneEntities.getUIData("wainscotting");
    if (!wainscotting.enabled) {
      wainscotting.enabled = true;
      useAssetWainscotting(wainscotting, viewport);
    }*/

    useSWDMaterialWainscotting(textureKeyData, viewport);
  }

  //---------------------------------------------------------------------------------------------------------

  if (applyShowerFloor) {
    useSWDMaterialShowerFloor(textureKeyData, viewport);
  }

  //---------------------------------------------------------------------------------------------------------

  if (applyCeilingPanal) {
    //trigger ceilingpanel asset here and ui if not switched on
   /* let accessories_ceilingpanel = sceneEntities.getUIData("accessories_ceilingpanel");
    if (!accessories_ceilingpanel.enabled && !accessories_ceilingpanel.disabled) {
      accessories_ceilingpanel.enabled = true;
      let ceilingPanelAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_ceilingpanel");
      ceilingPanelAsset.enabled = true;
      viewport.setEnabled(ceilingPanelAsset);
    }*/

    useSWDMaterialCeilingPanal(textureKeyData, viewport);
  }
}
