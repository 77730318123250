import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetWainscotting(payload, viewport) {
  // need to hide or show asset
  const sceneEntities = useSceneEntities();
  let wainscoting_left = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_left");
  let wainscoting_left_2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_left_2");
  let wainscoting_right = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_right");
  let wainscoting_right_2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_right_2");

 
  wainscoting_left.enabled = payload.enabled;
  wainscoting_left_2.enabled = payload.enabled;
  wainscoting_right.enabled = payload.enabled;
  wainscoting_right_2.enabled = payload.enabled;
 
  viewport.setEnabled(wainscoting_left);
  viewport.setEnabled(wainscoting_left_2);
  viewport.setEnabled(wainscoting_right);
  viewport.setEnabled(wainscoting_right_2);

}
