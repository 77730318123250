
export function useDividerDisplayData() {
   
    const data = {
        label:null,
        useLeft:false,
        useRight:false,
       
       
          };

 
  return data;
}