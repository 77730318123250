import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
export function useSoaker() {

  const sceneEntities = useSceneEntities();
  let target = useAccordion2();
  target.attributes.componentData.display.label = "Soaker";
  target.attributes.componentData.key = "tub_soaker"; 
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;
  target.attributes.componentData.interaction.deselectSelf = false;
  target.attributes.componentData.interaction.actions["scene_update"] = true;

  //Divider skirts--------------------------------------------------
  let divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Skirts";

  let skirtsOptionsRadioGroup = useButtonRadioGroup();

  //Smooth--------------------------------------------------
  let smooth = useButton2();

  target.attributes.componentData.addChild(smooth)
  smooth.attributes.componentData.parentEnabledOverridesChildEnabled = true;
  //targetChildren.push(smooth);

  smooth.attributes.componentData.display.label = "Smooth";
  smooth.attributes.componentData.key = "tub_soaker_panal_2";
  sceneEntities.addItemToUIList(smooth.attributes.componentData);
  smooth.attributes.componentData.interaction.radioGroup = skirtsOptionsRadioGroup;
  smooth.attributes.componentData.interaction.deselectSelf = false;
  smooth.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  //Matching Sentrel--------------------------------------------------
  let matchingSentrel = useButton2();

  target.attributes.componentData.addChild(matchingSentrel)
  matchingSentrel.attributes.componentData.parentEnabledOverridesChildEnabled = true;
  //targetChildren.push(matchingSentrel);


  matchingSentrel.attributes.componentData.display.label = "Matching Sentrel";
  matchingSentrel.attributes.componentData.key = "tub_soaker_panal_1";
  sceneEntities.addItemToUIList(matchingSentrel.attributes.componentData);
  matchingSentrel.attributes.componentData.interaction.radioGroup = skirtsOptionsRadioGroup;
  matchingSentrel.attributes.componentData.interaction.deselectSelf = false;
  matchingSentrel.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  return target;
}
