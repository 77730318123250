import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetsShowerRestsValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let showers = sceneEntities.getUIData("showers").enabled;

  let mustDisable = !showers;

  let accessories_showerbench = sceneEntities.getUIData("accessories_showerbench");
  let accessories_showerchair = sceneEntities.getUIData("accessories_showerchair");
  let accessories_cornerseat = sceneEntities.getUIData("accessories_cornerseat");
  let accessories_shavestep = sceneEntities.getUIData("accessories_shavestep");
  let accessories_shavestep_metal = sceneEntities.getUIData("accessories_shavestep_metal");

  let divider_showerrests = sceneEntities.getUIData("divider_showerrests");

  accessories_showerbench.disabled = mustDisable;
  accessories_showerchair.disabled = mustDisable;
  accessories_cornerseat.disabled = mustDisable;
  accessories_shavestep.disabled = mustDisable;
  if(accessories_shavestep_metal){
  accessories_shavestep_metal.disabled = mustDisable;
  }
  divider_showerrests.disabled = mustDisable;

  // new logic if disabled then set enabled to false
  if (accessories_showerbench.enabled && accessories_showerbench.disabled) {
    accessories_showerbench.enabled = false;
  }

  if (accessories_showerchair.enabled && accessories_showerchair.disabled) {
    accessories_showerchair.enabled = false;
  }
  if (accessories_cornerseat.enabled && accessories_cornerseat.disabled) {
    accessories_cornerseat.enabled = false;
  }
  if (accessories_shavestep.enabled && accessories_shavestep.disabled) {
    accessories_shavestep.enabled = false;
  }
  if (accessories_shavestep_metal?.enabled && accessories_shavestep_metal?.disabled) {
    accessories_shavestep_metal.enabled = false;
  }
  if (divider_showerrests.enabled && divider_showerrests.disabled) {
    divider_showerrests.enabled = false;
  }
  let accessories_showerbench_asset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_showerbench");
  accessories_showerbench_asset.disabled = mustDisable;
  accessories_showerbench_asset.enabled = accessories_showerbench.enabled;
  viewport.setEnabled(accessories_showerbench_asset);

  let accessories_showerchair_asset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_showerchair");
  accessories_showerchair_asset.disabled = mustDisable;
  accessories_showerchair_asset.enabled = accessories_showerchair.enabled;
  viewport.setEnabled(accessories_showerchair_asset);

  let accessories_cornerseat_asset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_cornerseat");
  accessories_cornerseat_asset.disabled = mustDisable;
  accessories_cornerseat_asset.enabled = accessories_cornerseat.enabled;
  viewport.setEnabled(accessories_cornerseat_asset);

  let accessories_shavestep_asset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shavestep");
  accessories_shavestep_asset.disabled = mustDisable;
  accessories_shavestep_asset.enabled = accessories_shavestep.enabled;
  viewport.setEnabled(accessories_shavestep_asset);

  let accessories_shavestep_metal_asset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shavestep_metal");
  accessories_shavestep_metal_asset.disabled = mustDisable;
  accessories_shavestep_metal_asset.enabled = accessories_shavestep_metal?.enabled;
  viewport.setEnabled(accessories_shavestep_metal_asset);
}
