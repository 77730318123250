import { useAssetDoorsSquare } from "@/scripts/rooms/actions/corner/AssetDoorsSquare";
import { useAssetEnableSimple } from "@/scripts/rooms/actions/general/AssetEnableSimple";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetKneewall(payload,viewport){
    const sceneEntities = useSceneEntities();

    let x60 = sceneEntities.getUIData("x60");
    payload = payload|| sceneEntities.getUIData("kneewall");
    payload.enabled = payload.enabled && x60.enabled;

    if(!x60.enabled){
        payload.disabled = true;
    }else{
        payload.disabled = false;
    }

    useAssetEnableSimple(payload, viewport);
    useAssetDoorsSquare(null,viewport);

    let accessories_showerbench = sceneEntities.getUIData("accessories_showerbench");
    let accessories_showerchair = sceneEntities.getUIData("accessories_showerchair");
    let accessories_cornerseat = sceneEntities.getUIData("accessories_cornerseat");

    accessories_showerbench.disabled = !payload.enabled;
    accessories_showerchair.disabled = !payload.enabled;
    accessories_cornerseat.disabled = !payload.enabled;

    accessories_showerbench.enabled =  accessories_showerbench.enabled && !accessories_showerbench.disabled;
    accessories_showerchair.enabled =  accessories_showerchair.enabled && !accessories_showerchair.disabled;
    accessories_cornerseat.enabled =  accessories_cornerseat.enabled && !accessories_cornerseat.disabled;

    let asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_showerbench");
    asset.enabled = accessories_showerbench.enabled 
    viewport.setEnabled(asset);

    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_showerchair");
    asset.enabled = accessories_showerchair.enabled 
    viewport.setEnabled(asset);

    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_cornerseat");
    asset.enabled = accessories_cornerseat.enabled 
    viewport.setEnabled(asset);
    



}