import { useSceneEntities } from "@/stores/SceneEntities";


import { useMaterialMetalsFromFaucetUI } from "@/scripts/rooms/actions/general/MaterialMetalsFromFaucetUI";

export function useAssetFaucett17464(payload, viewport) {


    const sceneEntities = useSceneEntities();

    let showers = sceneEntities.getUIData("showers").enabled;

    //handle child assets first
    //to allow system to create viewport asset ahead of actual asset load

    let t17464_control = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464_control");
    t17464_control.disabled = payload.disabled;
    t17464_control.enabled = payload.enabled;
    viewport.setEnabled(t17464_control);
    viewport.invalidateTransformForKey("t17464_control","showers");

    let t17464_head = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464_head");
    t17464_head.disabled = payload.disabled;
    t17464_head.enabled = payload.enabled;
    viewport.setEnabled(t17464_head);

    let t17464_spout = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464_spout");
    //spout also validates if showers are selected
    t17464_spout.disabled = payload.disabled;
    t17464_spout.enabled = payload.enabled && !showers;
    viewport.setEnabled(t17464_spout);

    //now handle main asset
    
    let t17464 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464");
    t17464.disabled = payload.disabled;
    t17464.enabled = payload.enabled;
    viewport.setEnabled(t17464);


    useMaterialMetalsFromFaucetUI(null,viewport)


}