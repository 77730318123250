import { useSceneEntities } from "@/stores/SceneEntities";
export function useMaterialFloors(payload, viewport) {
    const sceneEntities = useSceneEntities();
    if (!payload.enabled) {       
        return;
    }   
    sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({key:payload.key});
  

    //floor_exterior--------------------------------------------------------------------

    let materialUpdateObject = {};
    materialUpdateObject.name = "floor_exterior";
    materialUpdateObject.textures = {};
    materialUpdateObject.textures.albedoTexture = {};
    materialUpdateObject.textures.albedoTexture.key = payload.key;
    materialUpdateObject.textures.albedoTexture.textureProperties = {};
    materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
    viewport.updateMaterial(materialUpdateObject)

     

}