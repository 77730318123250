<template>
  <div class="py-2 d-flex w-100 flex-wrap justify-content-center">
    <div class="h3 text-center mt-4 text-color-1">coming soon</div>
  </div>
</template>

<script setup>
import { useSceneEntities } from "@/stores/SceneEntities";
import ConsoleLogAdvanced from "@/scripts/utils/ConsoleLogAdvanced";

var dataCollectionMaterials = {};
var dataCollectionAssetsViewport = {};

defineExpose({
  invalidate,
  setEnabled,
  invalidateTransformForKey,
  updateMaterial,
  loadData,
  getViewportAsset,
  getColor,
  getTexture,
  storeMaterialUpdateForSerialization,
  removeMaterialUpdateForSerialization,
  serializeViewportAssets,
  serializeMaterials,
  dataCollectionMaterials
});

const sceneEntities = useSceneEntities();
const consoleLogAdvanced = new ConsoleLogAdvanced();
async function invalidate() {}
async function setEnabled(viewportAsset) {}

function invalidateTransformForKey(assetKey, transformKey) {}

function updateMaterial(materialData) {
  
}

async function loadAssetsData(url) {
  //
}

function parseAssetsData(data) {
  //
}

async function loadData() {
 //
}
function storeMaterialUpdateForSerialization(data) {
 
  //the key used here needs to map to a composable in the same way the ui did
  dataCollectionMaterials[data.key] = data.data;
}
function removeMaterialUpdateForSerialization(key) {
 
  delete dataCollectionMaterials[key];
}

function getViewportAsset(key) {
  let item = dataCollectionAssetsViewport[key];
  if (!item) {
  

    item = dataCollectionAssetsViewport[key] = {}
  }
  return item;
}

function getColor(key) {
 //
}
function getTexture(key) {
 //
}
function serializeViewportAssets() {
  let viewportassets = {};
  for (let prop in dataCollectionAssetsViewport) {
    let item = dataCollectionAssetsViewport[prop];
    let ob = (viewportassets[prop] = {});
        ob.enabled = item.enabled;
        ob.disabled = item.disabled;
  }
  return viewportassets;
}
function serializeMaterials() {
  let serializationMaterials = {};

for (let prop in dataCollectionMaterials) {
  let item = sceneEntities.dataCollectionUI[prop];

  //if keys in dataCollectionMaterials map to ui , then do ui checking for if to
  //serialize the data , if no ui map is valid , then just serialize it , since it maps to a action directly
  if (item) {
    //added this in because child UI need to possible check the state of their parent

    let enabled = item.isEnabled();
    if (enabled) {
      serializationMaterials[prop] = dataCollectionMaterials[prop];
    }
  } else {
    serializationMaterials[prop] = dataCollectionMaterials[prop];
  }
}
return serializationMaterials;
}
</script>
