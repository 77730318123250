import RoomAlcoveOption1 from "@/scripts/rooms/actions/alcove/RoomAlcoveOption1";
import RoomCornerOption1 from "@/scripts/rooms/actions/corner/RoomCornerOption1";
import RoomCaliforniaOption1 from "@/scripts/rooms/RoomCaliforniaOption1";
import { ref } from "vue";
export function useRoomFactory(sceneKey,optionKey) {
  optionKey = parseInt(optionKey);
  var roomController = null;
  const buildNumber = ref(process.env.VUE_APP_BUILD_NUMBER || 0);
 var dataPaths = {};
  switch(sceneKey){
    case "alcove" :
      switch(optionKey){
        case 1 :
          roomController = new RoomAlcoveOption1();
          dataPaths.assetsURL = 'assets/data/roomAlcoveOption1Viewport3DAssets.json'+ "?cache_uid=" + buildNumber.value;     
          dataPaths.serializedURL = 'assets/data/roomAlcoveOption1SerializedDefaults.json'+ "?cache_uid=" + buildNumber.value;
          dataPaths.colorsURL = 'assets/data/colors.json'+ "?cache_uid=" + buildNumber.value;
          dataPaths.texturesURL = 'assets/data/textures.json'+ "?cache_uid=" + buildNumber.value;
          dataPaths.presetsURL = 'assets/data/roomAlcoveOption1Preset1.json'+ "?cache_uid=" + buildNumber.value;
          roomController.dataPaths = dataPaths;
        break;
      }
       
        break;
        case "corner" :

        switch(optionKey){
          case 1 :
            roomController = new RoomCornerOption1();
            dataPaths.assetsURL = 'assets/data/roomCornerOption1Viewport3DAssets.json'+ "?cache_uid=" + buildNumber.value;     
            dataPaths.serializedURL = 'assets/data/roomCornerOption1SerializedDefaults.json'+ "?cache_uid=" + buildNumber.value;
            dataPaths.colorsURL = 'assets/data/colors.json'+ "?cache_uid=" + buildNumber.value;
            dataPaths.texturesURL = 'assets/data/textures.json'+ "?cache_uid=" + buildNumber.value;
            dataPaths.presetsURL = 'assets/data/roomCornerOption1Preset1.json'+ "?cache_uid=" + buildNumber.value;
            roomController.dataPaths = dataPaths;
          break;
        }

       
       
        break;
        case "california" :
          switch(optionKey){
            case 1 :
              roomController = new RoomCaliforniaOption1();
            break;
          }
       
        break;

  }

  return roomController;
}
