import { defineStore } from "pinia";
import ConsoleLogAdvanced from "@/scripts/utils/ConsoleLogAdvanced";
import { useRequests } from "@/stores/Requests";

export const useSceneEntities = defineStore("SceneEntities", {
  state: () => {
    return {
      configuratorView: null,
      config: {},
      presetData: { session: [], persistant: [], default: [] },
      uiBuildData: null,
      uiBuildDataTopNav: null,
      dataCollectionUI: {},
      serialization: {},
      consoleLogAdvanced: new ConsoleLogAdvanced(),
      SWDRadioGroups: null,
    };
  },

  actions: {
    dispose() {
    
      document.removeEventListener("visibilitychange", this.onDocumentVisibilityHidden);
      
      this.uiBuildData = {};
      this.uiBuildDataTopNav = {};
      this.dataCollectionUI = {};
      this.SWDRadioGroups = null;
      this.uiBuildDataTopNav = null;
      this.presetData = { session: [], persistant: [], default: [] };
     
      
    },
    uidGenerator() {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
    },

    addPreset(data) {
      //data ob could have properties
      //key
      //label
      //data
      //date
      //storageKey
      //softDelete
      //isDeleted
      //isDefault
      let key = data?.key || this.uidGenerator();
      let storageKey = data?.storageKey || "session"; //defaults to session if none is provided
      let preset = this.presetData[storageKey].find((element) => element.key === key);
      if (!preset) {
        preset = {};
        this.presetData[storageKey].push(preset);
      }
      let date = new Date();
      preset.key = key;
      preset.isDefault = data?.isDefault || false;
      preset.label = data?.label || date.toLocaleString();
      if(!data?.data){
      this.serializeScene();
      }
      preset.data = data?.data || { ...this.serialization };
      preset.date = date.toISOString();
      //this is used to know if the default presets were deleted and not to reload them from the app build each time
      preset.softDelete = data?.softDelete || false;
      preset.isDeleted = false;
      preset.storageKey = storageKey;
    },

    removePreset(data) {
      //no point in removing session based presets .. so assumes removing persistant presets
      //get preset
      let preset = this.presetData.persistant.find((element) => element.key === data.key);
      if (!preset) {
        preset = this.presetData.default.find((element) => element.key === data.key);
      }
      if (preset) {
        //check if softDelete is true
        if (preset.softDelete) {
          preset.isDeleted = true;
        } else {
          this.presetData.persistant = this.presetData.persistant.filter((preset) => preset.key !== data.key);
        }
      }
    },

    validatePresetsBuildID() {
      //session

      let prefix =
        this.configuratorView.roomtype + "_" + this.configuratorView.roomoption + "_";

      let presetString = localStorage.getItem(prefix + "presets_session_build_id");
      if (!presetString) {
        localStorage.setItem(prefix + "presets_session_build_id", process.env.VUE_APP_PRESET_BUILD_ID);
      } else {
        if (presetString !== process.env.VUE_APP_PRESET_BUILD_ID) {
          // need to disgard any stored data , just save blank to do this
          this.presetData.session = [];
          this.savePresetsSession();
          localStorage.setItem(prefix + "presets_session_build_id", process.env.VUE_APP_PRESET_BUILD_ID);
        }
      }

      //persistant
      presetString = localStorage.getItem(prefix + "presets_persistant_build_id");
      if (!presetString) {
        localStorage.setItem(prefix + "presets_persistant_build_id", process.env.VUE_APP_PRESET_BUILD_ID);
      } else {
        if (presetString !== process.env.VUE_APP_PRESET_BUILD_ID) {
          // need to disgard any stored data , just save blank to do this
          this.presetData.persistant = [];
          this.presetData.default = [];
          this.savePresetsPersistant();

          localStorage.setItem(prefix + "presets_persistant_build_id", process.env.VUE_APP_PRESET_BUILD_ID);
        }
      }
    },

    loadPresetsSession() {
      let prefix =
        this.configuratorView.roomtype + "_" + this.configuratorView.roomoption + "_";
       
       
      let presetString = localStorage.getItem(prefix + "presets_session");

      if (presetString) {
        this.presetData.session = JSON.parse(presetString);
       
        this.presetData.session.forEach((element) => {
          element.dateForSort = Date.parse(element.date);
        });
        this.presetData.session.sort((a, b) => a.dateForSort - b.dateForSort);
        this.presetData.session.reverse();
      }
    },
    async loadPresetsPersistant() {
      let prefix =
        this.configuratorView.roomtype + "_" + this.configuratorView.roomoption + "_";

      let presetString = localStorage.getItem(prefix + "presets_persistant");

      if (presetString) {
        let ob = JSON.parse(presetString);
        this.presetData.persistant = ob.filter((preset) => preset.softDelete !== true);
        this.presetData.default = ob.filter((preset) => preset.softDelete === true);
      }
    },
    savePresetsSession() {
     
      //sort date and strip length
      this.presetData.session.forEach((element) => {
       
        element.dateForSort = Date.parse(element.date);        
       
      });
      this.presetData.session.sort((a, b) => a.dateForSort - b.dateForSort);
      this.presetData.session.reverse();
      this.presetData.session.length = Math.min(this.presetData.session.length, 5);

     

      let prefix =
        this.configuratorView.roomtype + "_" + this.configuratorView.roomoption + "_";
       
      const replacer = (key, value) => (typeof value === "undefined" ? null : value);
      var textToSave = JSON.stringify(this.presetData.session, replacer, 2);
      localStorage.setItem(prefix + "presets_session", textToSave);
    },
    savePresetsPersistant() {
      //sort alpha

      let joinedArrays = [].concat(this.presetData.persistant, this.presetData.default);
      joinedArrays.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      let prefix =
        this.configuratorView.roomtype + "_" + this.configuratorView.roomoption + "_";
      const replacer = (key, value) => (typeof value === "undefined" ? null : value);
      var textToSave = JSON.stringify(joinedArrays, replacer, 2);
      localStorage.setItem(prefix + "presets_persistant", textToSave);
    },

    saveConfig() {
      const replacer = (key, value) => (typeof value === "undefined" ? null : value);
      var textToSave = JSON.stringify(this.config, null, 2);
      localStorage.setItem("config", textToSave);
    },
    validateConfigBuildID() {
      let configString = localStorage.getItem("config");
      if (configString) {
        this.config = JSON.parse(configString);
        //validate if the config number matches that stored in .env ,
        //if not this means to disgard this data and create again from new

        if (this.config.configBuildID !== process.env.VUE_APP_CONFIG_BUILD_ID) {
          this.createConfig();
          this.saveConfig();
        }
      } else {
        //if not found no config exists , so create it
        this.createConfig();
        this.saveConfig();
      }
    },
    loadConfig() {
      let configString = localStorage.getItem("config");
      if (configString) {
        this.config = JSON.parse(configString);
      }
    },
    createConfig() {
      this.config = {};
      this.config.configBuildID = process.env.VUE_APP_CONFIG_BUILD_ID;
      //0 is to load last session
       //1 is to load a preset set as default
      //2 is to load original minimal scene
     
      this.config.startUpPresetPreferred = 0;
    },
    async getPreferredStartupData(){
   
      let data = await this.loadSerializedData(this.configuratorView.roomController.dataPaths.serializedURL);
      if(this.config.startUpPresetPreferred === 0){
        if(this.presetData.session.length){
        
          data = this.presetData.session[0].data;
        }
      }
      let preset = null;
      if(this.config.startUpPresetPreferred === 1){
        if(this.presetData.persistant.length){
         
          preset = this.presetData.persistant.find(element=>element.isDefault === true)
          if(preset){
           
            data = preset.data;
          }
         
        }
        if(!preset && this.presetData.default.length){
         
          preset = this.presetData.default.find(element=>element.isDefault === true)
          if(preset){
           
            data = preset.data;
          }
         
        }
      }

      return data;
    },

    setPresetDefault(preset){
     
      this.presetData.persistant.forEach((element) => {
       element.isDefault = false;
       if(element.key === preset.key){
        element.isDefault = true;
       }
      });
      this.presetData.default.forEach((element) => {
        element.isDefault = false;
        if(element.key === preset.key){
         element.isDefault = true;
        }
       });
    },

    async handleDefaultBuilds() {
      this.validateConfigBuildID();
      this.loadConfig();
      this.validatePresetsBuildID();
      this.loadPresetsSession();
      this.loadPresetsPersistant();

      let presetData = await this.loadSerializedData(this.configuratorView.roomController.dataPaths.presetsURL);

      let prefix =
        this.configuratorView.roomtype + "_" + this.configuratorView.roomoption + "_";

      presetData.forEach((item, index) => {
        let defaultsKey = prefix + "default preset " + (index + 1);
        let preset = this.presetData.default.find((element) => element.key === defaultsKey);
        if (!preset) {
          //if no preset then create it.
          preset = {};
          if(index === 0){
            preset.isDefault = true;
          }
          preset.key = defaultsKey;
          preset.label = "default preset " + (index + 1);
          preset.data = item;
          preset.storageKey = "default";
          preset.softDelete = true;
          this.addPreset(preset);
        }
      });

      this.savePresetsPersistant();

      document.addEventListener("visibilitychange", this.onDocumentVisibilityHidden);


    },
    onDocumentVisibilityHidden(){
      if (document.visibilityState === "hidden") {
       this.onSessionEndSessionSave();
      } 
    },

    onSessionEndSessionSave(){
     
         this.addPreset();
         this.savePresetsSession();
      
    },
    async loadSerializedData(url) {
      const requests = useRequests();
      let result;
      try {
        result = await requests.get({ url });
      } catch (error) {
        console.log(error);
        return;
      }
      if (result) {
        return result.data;
      }
    },

    getUIData(key) {
      let item = this.dataCollectionUI[key];
      if (!item) {
        this.consoleLogAdvanced.error("getUIData failed for key: ", key);
      }
      return item;
    },

    

    addItemToUIList(item) {
      this.dataCollectionUI[item.key] = item;
    },

    parseSerializedUI(data) {
      //UI

      for (let prop in data.ui) {
        //try get asset
        let ui = this.dataCollectionUI[prop];
        if (ui) {
          let uiData = data.ui[prop];
          ui.enabled = uiData.enabled;
          ui.disabled = uiData.disabled;
          if (uiData.enabled) {
            if (ui.interaction?.radioGroup) {
              ui.interaction.radioGroup.currentSelection = ui;
            }
          }
        }
      }
    },

    serializeScene() {
      //this exports a json file which can be used to load te app into back into the exact state as when saved
      //ui
      this.serialization = {};
      this.serialization.ui = {};
      for (let prop in this.dataCollectionUI) {
        let item = this.dataCollectionUI[prop];
        if (item.skipSerialization) continue;
        //added this in because child UI need to possible check the state of their parent
        let enabled = item.isEnabled();
        if (enabled || (!item.enabled && item.disabled)) {
          let ob = (this.serialization.ui[prop] = {});
          ob.enabled = item.enabled;
          ob.disabled = item.disabled;
        }
      }
      //viewport assets
     
      this.serialization.viewportassets = this.configuratorView.viewportComponent.serializeViewportAssets();
      //materials
      this.serialization.materials = this.configuratorView.viewportComponent.serializeMaterials();
    },

    export() {
      this.serializeScene();

      //export json file
      const replacer = (key, value) => (typeof value === "undefined" ? null : value);
      var textToSave = JSON.stringify(this.serialization, replacer, 2);
      var textToSaveAsBlob = new Blob([textToSave], {
        type: "application/json",
        endings: "native",
      });
      var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
      var fileNameToSaveAs = "serialized_data";

      var downloadLink = document.createElement("a");
      downloadLink.download = fileNameToSaveAs;
      downloadLink.innerHTML = "Download File";
      downloadLink.href = textToSaveAsURL;
      downloadLink.onclick = (event) => document.body.removeChild(event.target);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
  },
});
