import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetCeilingTrim(payload, viewport) {
  // need to hide or show asset
  const sceneEntities = useSceneEntities();
  let asset1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mould_roof_center");
  let asset2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mould_roof_left");
  let asset3 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mould_roof_right");

  let walls34 = sceneEntities.getUIData("walls34").enabled;

  let bool = payload?.enabled ?? walls34;
  asset1.enabled = bool;
  viewport.setEnabled(asset1);
  asset2.enabled = bool;
  viewport.setEnabled(asset2);
  asset3.enabled = bool;
  viewport.setEnabled(asset3);
}
