import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetAccentValidate1(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let acccent_ui = ["swd_alaskanivory_accenttrim",
  "swd_alaskanivory_accenttrim", 
  "swd_bardiglio_accenttrim",
  "swd_botticinocream_accenttrim",
  "swd_brecciaparadiso_accenttrim",
  "swd_calabria_accenttrim",
  "swd_calacattawhite_accenttrim",
  "swd_calcuttagold_accenttrim",
  "swd_cremabordeaux_accenttrim",
  "swd_cremetravertine_accenttrim",
  "swd_everest_accenttrim",  
  "swd_goldenbeaches_accenttrim",
  "swd_mochatravertine_accenttrim", 
  "swd_triton_accenttrim",
  "swd_veincutgray_accenttrim",
  "swd_whitepearl_accenttrim",
  "swd_whitestandard_accenttrim"
];
let hasAccentSelection = false;

acccent_ui.every((element)=>{
    let ui = sceneEntities.getUIData(element);
    if(ui){
        if (ui.enabled) {
            hasAccentSelection = true;
            return false;
          } else {
            return true;
          }
    }else{
        return true;
    }
})


  //handle which accent geometry to show or hide..
  let wallsfull = sceneEntities.getUIData("wallsfull");
  let walls34 = sceneEntities.getUIData("walls34");

  if(wallsfull.enabled){
    //hide 34
    let asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_center");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_center_2");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_shelf_1");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_right");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_left");
    asset.enabled = false;
    viewport.setEnabled(asset);

    //show or hide relevant full
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_center");
    asset.enabled = hasAccentSelection;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_right");
    asset.enabled = hasAccentSelection;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_left");
    asset.enabled = hasAccentSelection;
    viewport.setEnabled(asset);    
    
  }

  if(walls34.enabled){
    //hide full
    let asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_center");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_right");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_left");
    asset.enabled = false;
    viewport.setEnabled(asset);    

    //hide 34 all center variatons at first then show relevant 
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_center");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_center_2");
    asset.enabled = false;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_shelf_1");
    asset.enabled = false;
    viewport.setEnabled(asset);
   

    let accessories_recess_window = sceneEntities.getUIData("accessories_recess_window");
    let accessories_recess_soap1window = sceneEntities.getUIData("accessories_recess_soap1window");
    let accessories_recess_soap2window = sceneEntities.getUIData("accessories_recess_soap2window");

    let accessories_recess_steelshelf1 = sceneEntities.getUIData("accessories_recess_steelshelf1");
    let accessories_recess_steelshelf2 = sceneEntities.getUIData("accessories_recess_steelshelf2");
    let accessories_recess_custom_soap_1 = sceneEntities.getUIData("accessories_recess_custom_soap_1");
    let accessories_recess_custom_soap_2 = sceneEntities.getUIData("accessories_recess_custom_soap_2");

   if(accessories_recess_steelshelf1?.enabled || accessories_recess_custom_soap_1?.enabled || accessories_recess_custom_soap_2?.enabled){
        asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_shelf_1");
        asset.enabled = hasAccentSelection;
        viewport.setEnabled(asset);
    }else if(!accessories_recess_window?.enabled && !accessories_recess_soap1window?.enabled && !accessories_recess_soap2window?.enabled){
        asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_center_2");
        asset.enabled = hasAccentSelection;
        viewport.setEnabled(asset);
    }

    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_center");
    asset.enabled = hasAccentSelection;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_right");
    asset.enabled = hasAccentSelection;
    viewport.setEnabled(asset);
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_accent_window_left");
    asset.enabled = hasAccentSelection;
    viewport.setEnabled(asset);

   
   
    
  }
}
