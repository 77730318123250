import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetRecessValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;

  let accessories_recess_regular = sceneEntities.getUIData("accessories_recess_regular");
  if (accessories_recess_regular?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_window = sceneEntities.getUIData("accessories_recess_window");
  if (accessories_recess_window?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_window");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_window");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_soap1 = sceneEntities.getUIData("accessories_recess_soap1");
  if (accessories_recess_soap1?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_soap_1");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_soap_1");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_soap2 = sceneEntities.getUIData("accessories_recess_soap2");
  if (accessories_recess_soap2?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_soap_2");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_soap_2");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_soap1window = sceneEntities.getUIData("accessories_recess_soap1window");
  if (accessories_recess_soap1window?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_soap_1_window");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_soap_1_window");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_soap2window = sceneEntities.getUIData("accessories_recess_soap2window");
  if (accessories_recess_soap2window?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_soap_2_window");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_soap_2_window");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_steelshelf1 = sceneEntities.getUIData("accessories_recess_steelshelf1");
  if (accessories_recess_steelshelf1?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_shelf_1");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_shelf_1");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_steelshelf2 = sceneEntities.getUIData("accessories_recess_steelshelf2");
  if (accessories_recess_steelshelf2?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_shelf_2");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_shelf_2");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_custom_soap_1 = sceneEntities.getUIData("accessories_recess_custom_soap_1");
  if (accessories_recess_custom_soap_1?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_shelf_1");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_shelf_1");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }

  //-------------------------------------

  let accessories_recess_custom_soap_2 = sceneEntities.getUIData("accessories_recess_custom_soap_2");
  if (accessories_recess_custom_soap_2?.enabled) {
    let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_shelf_1");
    let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_shelf_1");
    if (wallsfull) {
      wall1.enabled = true;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
    if (walls34) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = true;
      viewport.setEnabled(wall2);
    }
  }
}
