import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useButton1 } from "@/scripts/ui/buttons/Button1";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useShowerEnclosure() {
 
  let enclosureRadioGroup1 = useButtonRadioGroup();
  let enclosureRadioGroup2 = useButtonRadioGroup();
  let finishOptionRadioGroup = useButtonRadioGroup();
  const sceneEntities = useSceneEntities();

  let target = useAccordion1();
  target.attributes.componentData.display.label = "Shower Enclosure";
  target.attributes.componentData.key = "showerenclosure";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;


  

  let regular = useButton1();
  targetChildren.push(regular);
  regular.attributes.componentData.display.label = "90 degree custom";
  regular.attributes.componentData.interaction.radioGroup = enclosureRadioGroup1;  
  regular.attributes.componentData.key = "doors_square_1_shower";  
  regular.attributes.componentData.sharedKey = "doors_square_shower";  
  sceneEntities.addItemToUIList(regular.attributes.componentData);
  regular.attributes.componentData.interaction.actions["scene_update"] = true;
 

  regular = useButton1();
  targetChildren.push(regular);
  regular.attributes.componentData.display.label = "Door 2";
  regular.attributes.componentData.interaction.radioGroup = enclosureRadioGroup1; 
  regular.attributes.componentData.key = "doors_square_2_shower";
  regular.attributes.componentData.sharedKey = "doors_square_shower";  
  sceneEntities.addItemToUIList(regular.attributes.componentData);
  regular.attributes.componentData.interaction.actions["scene_update"] = true;


  regular = useButton1();
  targetChildren.push(regular);
  regular.attributes.componentData.display.label = "Door 1 Neo";
  regular.attributes.componentData.interaction.radioGroup = enclosureRadioGroup2; 
  regular.attributes.componentData.key = "doors_neo_1_shower";
  sceneEntities.addItemToUIList(regular.attributes.componentData);
  regular.attributes.componentData.interaction.actions["viewport_asset_update"] = true;


  regular = useButton1();
  targetChildren.push(regular);
  regular.attributes.componentData.display.label = "Door 2 Neo";
  regular.attributes.componentData.interaction.radioGroup = enclosureRadioGroup2; 
  regular.attributes.componentData.key = "doors_neo_2_shower";
  sceneEntities.addItemToUIList(regular.attributes.componentData);
  regular.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  

  




   //Finish Options--------------------------------------------------
   let divider = useDivider1();
   targetChildren.push(divider);
   divider.attributes.componentData.display.useLeft = true;
   divider.attributes.componentData.display.useRight = true;
   divider.attributes.componentData.display.label = "Finish Options";
   divider.attributes.componentData.key = "divider_shower_finish";
   sceneEntities.addItemToUIList(divider.attributes.componentData);

  //Flex Container--------------------------------------------------
  let flexContainer1 = useFlexContainer1();
  targetChildren.push(flexContainer1);
  let flexContainer1Children = flexContainer1.attributes.componentData.children;

  let option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Chrome";
  option.attributes.componentData.display.color = "#b5bbbd";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_chrome";
  option.attributes.componentData.colorKey = "chrome";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Brushed Nickle";
  option.attributes.componentData.display.color = "#aaa9a6";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_brushed_nickel";
  option.attributes.componentData.colorKey = "brushed_nickel";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Oil Bronze";
  option.attributes.componentData.display.color = "#554943";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_oil_bronze";
  option.attributes.componentData.colorKey = "oil_bronze";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Matt Black";
  option.attributes.componentData.display.color = "#282828";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_black";
  option.attributes.componentData.colorKey = "black";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;


 

 

  return target;
}
