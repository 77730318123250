import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetShowerX60 } from "@/scripts/rooms/actions/alcove/AssetShowerX60";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetsShowerRestsValidate } from "@/scripts/rooms/actions/general/AssetsShowerRestsValidate";

import { useAssetTubStandard } from "@/scripts/rooms/actions/alcove/AssetTubStandard";
import { useAssetTubSoaker } from "@/scripts/rooms/actions/alcove/AssetTubSoaker";
import { useAssetTub2653 } from "@/scripts/rooms/actions/alcove/AssetTub2653";
import { useAssetTub3053 } from "@/scripts/rooms/actions/alcove/AssetTub3053";
import { useAssetTub2852 } from "@/scripts/rooms/actions/alcove/AssetTub2852";




export function useAssetShowers(payload, viewport) {
  //dont handle the false call , since this handles true and false for either state
  if (!payload.enabled) return;

  const sceneEntities = useSceneEntities();
  let x60 = sceneEntities.getUIData("x60").enabled;
  let x48 = sceneEntities.getUIData("x48").enabled;
  let x36 = sceneEntities.getUIData("x36").enabled;


  if (!x60 && !x48 && !x36 ) {
    let data = sceneEntities.getUIData("x60");
    data.enabled = true;
    let previousSelection = useUIRadioGroup(data, viewport);

    let interactionFunctions = {};
    
   interactionFunctions.tub_standard = useAssetTubStandard;
   interactionFunctions.tub_soaker = useAssetTubSoaker;
   interactionFunctions.walkin2653 = useAssetTub2653;
   interactionFunctions.walkin3053 = useAssetTub3053;
   interactionFunctions.walkin2852 = useAssetTub2852;

   if (previousSelection) {
    let f = interactionFunctions[previousSelection.key];
    if (f) {
      f(previousSelection, viewport);
    }
  }
    useAssetShowerX60(data, viewport);
  }

  useAssetsShowerRestsValidate(null, viewport);
}
