
export function useSceneInvertOrientation(payload, viewport) {
   


  viewport.invalidateTransformForKey("faucet_collection","invertorientation")
  viewport.invalidateTransformForKey("tub_standard","invertorientation")
  viewport.invalidateTransformForKey("tub_soaker","invertorientation")
  viewport.invalidateTransformForKey("walkin2653","invertorientation")
  viewport.invalidateTransformForKey("walkin2852","invertorientation")
  viewport.invalidateTransformForKey("walkin3053","invertorientation")
  viewport.invalidateTransformForKey("shower_collection","invertorientation")
  viewport.invalidateTransformForKey("accessoriesgrabbarcenter45","invertorientation")
  viewport.invalidateTransformForKey("shower_doors_container","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_bath_x60_curved_long","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_bath_x60_curved_short","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_bath_x60_straight_long","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_bath_x60_straight_short","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_shower_x48_curved","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_shower_x48_straight","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_shower_x60_curved","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_shower_x60_straight","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_bath_x60_straight_walkin_tub","invertorientation")
  viewport.invalidateTransformForKey("curtain_bar_bath_x60_curved_walkin_tub","invertorientation")
  
  

 


  
  
}
