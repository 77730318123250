<template>
  <ItemContainerLeftRightSlotsComponent>
    <template #left>
      <button class="button-minimal" @click="handleInteraction" style="display: block; width: 100%">
        <div :class="getClassText">{{ componentData.display.label }}</div>
      </button>
    </template>
    <template #right>
      <button class="button-minimal" @click="handleInteraction2">
        <span v-if="getHasIcon" :class="getClassIcon"></span>
      </button>
    </template>
  </ItemContainerLeftRightSlotsComponent>
  <div v-if="expanded">
    <b-form-group label="label:" :label-for="componentData.key" class="fs-9 text-align-left text-color-1">
      <b-form-input class="btn-no-focus-shadow" :id="componentData.key" v-model="label" trim></b-form-input>
    </b-form-group>
    <div style="display: flex">
      <div @click="deletePreset" style="cursor:pointer;background-color: #333; color: #fff; flex: 1 1 0" class="ma-1">delete</div>
      <div @click="savePreset" style="cursor:pointer;background-color: #333; color: #fff; flex: 1 1 0" class="ma-1">save</div>
    </div>

    <button class="button-minimal pr-2" @click="setAsDefault">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-9 p-0 mr-1">set as default</div>
            <span class="mdi icon icon-color-8" :class="getIsDefault"></span>
          </div>
        </button>

    
  </div>
</template>

<script setup>
import ItemContainerLeftRightSlotsComponent from "@/components/ItemContainerLeftRightSlotsComponent";

import { ref, reactive, watch, computed } from "vue";
const emit = defineEmits(["select"]);
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return null;
    },
  },
  styling: {
    type: Object,
    default: () => {
      return null;
    },
  },
});
const componentDataLocal = reactive({ value: props.componentData });
watch(
  () => props.componentData,
  () => {
    componentDataLocal.value = { ...props.componentData };
  }
);

let expanded = computed(() => {
  return componentDataLocal.value.enabled;
});

const label = computed({
  // getter
  get() {
    return componentDataLocal.value.display.label || null;
  },
  // setter
  set(newValue) {
    componentDataLocal.value.display.label = newValue;
  },
});

let getClassText = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringTextSelected ?? props.componentData.styling.classStringTextSelected
    : props.styling?.classStringTextDefault ?? props.componentData.styling.classStringTextDefault;
});

    let getClassIcon = computed(() => {
  return props.componentData.enabled
    ? props.styling?.classStringIconSelected ?? props.componentData.styling.classStringIconSelected
    : props.styling?.classStringIconDefault ?? props.componentData.styling.classStringIconDefault;
});

let getHasIcon = computed(() => {
  return props.styling?.classStringIconDefault ??  props.componentData.styling.classStringIconDefault
});

let getIsDefault = computed(() => {
  
  return props.componentData.isDefault
    ? "mdi-checkbox-marked-outline"
    : "mdi-checkbox-blank-outline";
});

function handleInteraction($event) {
  emit("select", componentDataLocal.value, $event);
}

function savePreset($event){
  let ob = {};
  ob.key = componentDataLocal.value.key;
  ob.interaction = {}
  ob.interaction.actions = {}
  ob.interaction.actions.scene_update = true
  ob.sharedKey = "preset_save"
  emit("select", ob, $event);
  
}
function deletePreset($event){
  let ob = {};
  ob.key = componentDataLocal.value.key;
  ob.interaction = {}
  ob.interaction.actions = {}
  ob.interaction.actions.scene_update = true
  ob.sharedKey = "preset_delete"
  emit("select", ob, $event);
  
}

function setAsDefault($event){
  let ob = {};
  ob.key = componentDataLocal.value.key;
  ob.interaction = {}
  ob.interaction.actions = {}
  ob.interaction.actions.scene_update = true
  ob.sharedKey = "preset_set_default"
  emit("select", ob, $event);
}

function handleInteraction2($event) {
  componentDataLocal.value.enabled = !componentDataLocal.value.enabled;
}
</script>
