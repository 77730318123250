import { useSceneEntities } from "@/stores/SceneEntities";
import { useMaterialFloors } from "@/scripts/rooms/actions/general/MaterialFloors";
import { useMaterialWallPaint } from "@/scripts/rooms/actions/general/MaterialWallPaint";
import { useMaterialWoodColor1 } from "@/scripts/rooms/actions/general/MaterialWoodColor1";

import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
export function useSWDMaterialTrigger1(payload, viewport) {
    const sceneEntities = useSceneEntities();
    let sentrelDesignToFloorAndWall = {};
  sentrelDesignToFloorAndWall.alaskanivory = {};
  sentrelDesignToFloorAndWall.alaskanivory.wallColorKey = "paintcolor_cfbea9";
  sentrelDesignToFloorAndWall.alaskanivory.floorKey = "wood_delacy";
  sentrelDesignToFloorAndWall.alaskanivory.tint1 = '766F6A'


  sentrelDesignToFloorAndWall.athens = {};
  sentrelDesignToFloorAndWall.athens.wallColorKey = "paintcolor_b5a597";
  sentrelDesignToFloorAndWall.athens.floorKey = "wood_delacy";
  sentrelDesignToFloorAndWall.athens.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.bardiglio = {};
  sentrelDesignToFloorAndWall.bardiglio.wallColorKey = "paintcolor_656d73";
  sentrelDesignToFloorAndWall.bardiglio.floorKey = "wood_silver_spurr";
  sentrelDesignToFloorAndWall.bardiglio.tint1 = '535353'

  sentrelDesignToFloorAndWall.botticinocream = {};
  sentrelDesignToFloorAndWall.botticinocream.wallColorKey = "paintcolor_d3c8bd";
  sentrelDesignToFloorAndWall.botticinocream.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.botticinocream.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.brecciaparadiso = {};
  sentrelDesignToFloorAndWall.brecciaparadiso.wallColorKey = "paintcolor_907865";
  sentrelDesignToFloorAndWall.brecciaparadiso.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.brecciaparadiso.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.calabria = {};
  sentrelDesignToFloorAndWall.calabria.wallColorKey = "paintcolor_b2ac9c";
  sentrelDesignToFloorAndWall.calabria.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.calabria.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.calacattawhite = {};
  sentrelDesignToFloorAndWall.calacattawhite.wallColorKey = "paintcolor_dcdedc";
  sentrelDesignToFloorAndWall.calacattawhite.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.calacattawhite.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.calcuttagold = {};
  sentrelDesignToFloorAndWall.calcuttagold.wallColorKey = "paintcolor_e6dfd3";
  sentrelDesignToFloorAndWall.calcuttagold.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.calcuttagold.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.cremabordeaux = {};
  sentrelDesignToFloorAndWall.cremabordeaux.wallColorKey = "paintcolor_7a6455";
  sentrelDesignToFloorAndWall.cremabordeaux.floorKey = "wood_silver_spurr";
  sentrelDesignToFloorAndWall.cremabordeaux.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.cremetravertine = {};
  sentrelDesignToFloorAndWall.cremetravertine.wallColorKey = "paintcolor_b1a290";
  sentrelDesignToFloorAndWall.cremetravertine.floorKey = "wood_delacy";
  sentrelDesignToFloorAndWall.cremetravertine.tint1 ='A5A4A1'

  sentrelDesignToFloorAndWall.everest = {};
  sentrelDesignToFloorAndWall.everest.wallColorKey = "paintcolor_bec1c3";
  sentrelDesignToFloorAndWall.everest.floorKey = "wood_silver_spurr";
  sentrelDesignToFloorAndWall.everest.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.frost = {};
  sentrelDesignToFloorAndWall.frost.wallColorKey = "paintcolor_b0b5b5";
  sentrelDesignToFloorAndWall.frost.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.frost.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.goldenbeaches = {};
  sentrelDesignToFloorAndWall.goldenbeaches.wallColorKey = "paintcolor_af987f";
  sentrelDesignToFloorAndWall.goldenbeaches.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.goldenbeaches.tint1 ='5B5B5B'
  
  sentrelDesignToFloorAndWall.grayquartz = {};
  sentrelDesignToFloorAndWall.grayquartz.wallColorKey = "paintcolor_949194";
  sentrelDesignToFloorAndWall.grayquartz.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.grayquartz.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.lagosgray = {};
  sentrelDesignToFloorAndWall.lagosgray.wallColorKey = "paintcolor_949194";
  sentrelDesignToFloorAndWall.lagosgray.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.lagosgray.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.mochatravertine = {};
  sentrelDesignToFloorAndWall.mochatravertine.wallColorKey = "paintcolor_817a6e";
  sentrelDesignToFloorAndWall.mochatravertine.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.mochatravertine.tint1 ='5B5853'

  sentrelDesignToFloorAndWall.petrafini = {};
  sentrelDesignToFloorAndWall.petrafini.wallColorKey = "paintcolor_c0ad96";
  sentrelDesignToFloorAndWall.petrafini.floorKey = "wood_delacy";
  sentrelDesignToFloorAndWall.petrafini.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.triton = {};
  sentrelDesignToFloorAndWall.triton.wallColorKey = "paintcolor_9bafb2";
  sentrelDesignToFloorAndWall.triton.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.triton.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.veincutgray = {};
  sentrelDesignToFloorAndWall.veincutgray.wallColorKey = "paintcolor_dee0de";
  sentrelDesignToFloorAndWall.veincutgray.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.veincutgray.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.vintagespruce = {};
  sentrelDesignToFloorAndWall.vintagespruce.wallColorKey = "paintcolor_cfbea9";
  sentrelDesignToFloorAndWall.vintagespruce.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.vintagespruce.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.whitepearl = {};
  sentrelDesignToFloorAndWall.whitepearl.wallColorKey = "paintcolor_dedfdc";
  sentrelDesignToFloorAndWall.whitepearl.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.whitepearl.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.whitestandard = {};
  sentrelDesignToFloorAndWall.whitestandard.wallColorKey = "paintcolor_c9cccd";
  sentrelDesignToFloorAndWall.whitestandard.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.whitestandard.tint1 = 'a9a9a9'

  sentrelDesignToFloorAndWall.azzurrabay = {};
  sentrelDesignToFloorAndWall.azzurrabay.wallColorKey = "paintcolor_d3d5d3";
  sentrelDesignToFloorAndWall.azzurrabay.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.azzurrabay.tint1 = '4C4D4E'

  sentrelDesignToFloorAndWall.tajmahal = {};
  sentrelDesignToFloorAndWall.tajmahal.wallColorKey = "paintcolor_c2c0ba";
  sentrelDesignToFloorAndWall.tajmahal.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.tajmahal.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.acrylic3x8subway = {};
  sentrelDesignToFloorAndWall.acrylic3x8subway.wallColorKey = "paintcolor_b8bebe";
  sentrelDesignToFloorAndWall.acrylic3x8subway.floorKey = "wood_silver_spurr";
  sentrelDesignToFloorAndWall.acrylic3x8subway.tint1 = '4C4D4E'

  
  sentrelDesignToFloorAndWall.twotoneacrylic3x8black = {};
  sentrelDesignToFloorAndWall.twotoneacrylic3x8black.wallColorKey = "paintcolor_596368";
  sentrelDesignToFloorAndWall.twotoneacrylic3x8black.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.twotoneacrylic3x8black.tint1 = '3B3B3B'
  

  sentrelDesignToFloorAndWall.twotoneacrylic10x20black = {};
  sentrelDesignToFloorAndWall.twotoneacrylic10x20black.wallColorKey = "paintcolor_585858";
  sentrelDesignToFloorAndWall.twotoneacrylic10x20black.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.twotoneacrylic10x20black.tint1 = '777470'


  sentrelDesignToFloorAndWall.twotoneacrylic3x8gray = {};
  sentrelDesignToFloorAndWall.twotoneacrylic3x8gray.wallColorKey = "paintcolor_616669";
  sentrelDesignToFloorAndWall.twotoneacrylic3x8gray.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.twotoneacrylic3x8gray.tint1 = '4C4D4E'

  sentrelDesignToFloorAndWall.twotoneacrylic10x20gray = {};
  sentrelDesignToFloorAndWall.twotoneacrylic10x20gray.wallColorKey = "paintcolor_7a848d";
  sentrelDesignToFloorAndWall.twotoneacrylic10x20gray.floorKey = "wood_logden";
  sentrelDesignToFloorAndWall.twotoneacrylic10x20gray.tint1 = '4C4D4E'

  sentrelDesignToFloorAndWall.marmogrigio = {};
  sentrelDesignToFloorAndWall.marmogrigio.wallColorKey = "paintcolor_cfcac7";
  sentrelDesignToFloorAndWall.marmogrigio.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.marmogrigio.tint1 ='5B5B5B'

  sentrelDesignToFloorAndWall.marmomarrone = {};
  sentrelDesignToFloorAndWall.marmomarrone.wallColorKey = "paintcolor_aca79e";
  sentrelDesignToFloorAndWall.marmomarrone.floorKey = "wood_windsong_oak";
  sentrelDesignToFloorAndWall.marmomarrone.tint1 ='5B5B5B'


  let floorAndWall = sentrelDesignToFloorAndWall[payload];


  let wallColorUI = sceneEntities.getUIData(floorAndWall.wallColorKey);
  let floorUI = sceneEntities.getUIData(floorAndWall.floorKey);

  wallColorUI.enabled = true;
  floorUI.enabled = true;
  useUIRadioGroup(wallColorUI, viewport);
  useUIRadioGroup(floorUI, viewport);

  useMaterialWallPaint(wallColorUI, viewport);
  useMaterialWoodColor1({key:'wood_color_1',data:{color:floorAndWall.tint1}}, viewport);
  useMaterialFloors(floorUI, viewport);

  

}