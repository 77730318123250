import { useSceneEntities } from "@/stores/SceneEntities";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useMaterialMetalsFromFaucetUI } from "@/scripts/rooms/actions/general/MaterialMetalsFromFaucetUI";
import { useAssetFaucett17494iCradleOnly } from "@/scripts/rooms/actions/general/AssetFaucett17494iCradleOnly";
import { useAssetFaucett17494iSideLeft } from "@/scripts/rooms/actions/general/AssetFaucett17494iSideLeft";
import { useAssetFaucett17494iSideRight } from "@/scripts/rooms/actions/general/AssetFaucett17494iSideRight";

export function useAssetFaucett17494i(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let showers = sceneEntities.getUIData("showers").enabled;

  //handle child assets first
  //to allow system to create viewport asset ahead of actual asset load

  let t17494i_control = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17494i_control");
  t17494i_control.disabled = payload.disabled;
  t17494i_control.enabled = payload.enabled;
  viewport.setEnabled(t17494i_control);
  viewport.invalidateTransformForKey("t17494i_control", "showers");

  let t17494i_head = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17494i_head");
  t17494i_head.disabled = payload.disabled;
  t17494i_head.enabled = payload.enabled;
  viewport.setEnabled(t17494i_head);

  let t17494i_spout = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17494i_spout");
  //spout also validates if showers are selected
  t17494i_spout.disabled = payload.disabled;
  t17494i_spout.enabled = payload.enabled && !showers;
  viewport.setEnabled(t17494i_spout);

  //now handle main asset

  let t17494i = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17494i");
  t17494i.disabled = payload.disabled;
  t17494i.enabled = payload.enabled;
  viewport.setEnabled(t17494i);

 

  //object options
  let t17494i_cradleonly = sceneEntities.getUIData("t17494i_cradleonly");
  let t17494i_sidebarleft = sceneEntities.getUIData("t17494i_sidebarleft");
  let t17494i_sidebarright = sceneEntities.getUIData("t17494i_sidebarright");

  if (!t17494i_cradleonly.enabled && !t17494i_sidebarleft.enabled && !t17494i_sidebarright.enabled) {
    t17494i_cradleonly.disabled = payload.disabled;
    t17494i_cradleonly.enabled = true;
    useUIRadioGroup(t17494i_cradleonly, viewport);
    useAssetFaucett17494iCradleOnly(t17494i_cradleonly, viewport);
  }

  //when asset enabled is false , deselect any of these object options
  if (!payload.enabled) {
    t17494i_cradleonly.enabled = false;
    t17494i_sidebarleft.enabled = false;
    t17494i_sidebarright.enabled = false;
  }

  t17494i_cradleonly.disabled = payload.disabled;
  useAssetFaucett17494iCradleOnly(t17494i_cradleonly, viewport);

  t17494i_sidebarleft.disabled = payload.disabled;
  useAssetFaucett17494iSideLeft(t17494i_sidebarleft, viewport);

  t17494i_sidebarright.disabled = payload.disabled;
  useAssetFaucett17494iSideRight(t17494i_sidebarright, viewport);

  useMaterialMetalsFromFaucetUI(null,viewport)
}
