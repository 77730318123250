import { useSceneEntities } from "@/stores/SceneEntities";
import { useAccessoriesCeilingNavValidate } from "@/scripts/rooms/actions/alcove/AccessoriesCeilingNavValidate";
export function useAssetCeilingPanelValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let walls34 = sceneEntities.getUIData("walls34").enabled;
  let soffitUI = sceneEntities.getUIData("soffit");
  let ceilingPanelUI = sceneEntities.getUIData("accessories_ceilingpanel");
  let ceilingPanelAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_ceilingpanel");
  let disabled = walls34 && !(soffitUI.enabled && !soffitUI.disabled);
  ceilingPanelUI.disabled = disabled;
  ceilingPanelAsset.disabled = disabled;
  //ceilingPanelAsset.enabled = ceilingPanelUI.enabled;

  //changed logic .. if disabled , then set enabled to false
  if (ceilingPanelUI.enabled && disabled) {
    ceilingPanelUI.enabled = false;
    ceilingPanelAsset.enabled = false;
  }
  viewport.setEnabled(ceilingPanelAsset);
  viewport.invalidateTransformForKey("accessories_ceilingpanel", "walls34");

  useAccessoriesCeilingNavValidate(null,viewport);
}
