import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetGrabbarsValidate } from "@/scripts/rooms/actions/alcove/AssetGrabbarsValidate";
import { useAssetAccentValidate1 } from "@/scripts/rooms/actions/alcove/AssetAccentValidate1";
export function useAssetRecessRegular(payload, viewport) {
  
  const sceneEntities = useSceneEntities();
  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;
  let asset = null;
  if (wallsfull) {
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full");
  }
  if (walls34) {
    asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34");
  }

  if (asset) {
    asset.enabled = payload.enabled;
    viewport.setEnabled(asset);
  }

  useAssetGrabbarsValidate(null,viewport)
  useAssetAccentValidate1(null,viewport)
}
