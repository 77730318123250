import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetEnableSimple(payload, viewport) {
  
  // need to hide or show asset 
  const sceneEntities = useSceneEntities();  
  let asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(payload.key);
  
    asset.enabled = payload.enabled;
    viewport.setEnabled(asset);
  
}
