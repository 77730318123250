import { useSceneEntities } from "@/stores/SceneEntities";
import { useAccessoriesCeilingNavValidate } from "@/scripts/rooms/actions/alcove/AccessoriesCeilingNavValidate";
export function useAssetSoffitCoverValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let soffitUI = sceneEntities.getUIData("soffit");

  //just handle soffit cover validation here
  let soffitCoverUI = sceneEntities.getUIData("soffit_cover");
  let soffitCoverAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("soffit_cover");
  let disabled = !(soffitUI.enabled && !soffitUI.disabled);
  soffitCoverUI.disabled = disabled;
  soffitCoverAsset.disabled = disabled;
  // new logic if disabled then set enabled to false
  if (soffitCoverUI.enabled && soffitCoverUI.disabled) {
    soffitCoverUI.enabled = false;
    soffitCoverAsset.enabled = false;
  }

  viewport.setEnabled(soffitCoverAsset);

  useAccessoriesCeilingNavValidate(null,viewport);
}
