import { useSceneEntities } from "@/stores/SceneEntities";
import { useRecessWallValidate } from "@/scripts/rooms/actions/corner/RecessWallValidate";
import { useAssetCeilingPanal } from "@/scripts/rooms/actions/corner/AssetCeilingPanal";
import { useAssetCeilingTrim } from "@/scripts/rooms/actions/corner/AssetCeilingTrim";

export function useSceneWallHeight(payload, viewport) {
  //dont handle the false call , since this handles true and false for either state
  if (!payload.enabled) return;  

  useRecessWallValidate(null, viewport);
  useAssetCeilingPanal(null, viewport);
  useAssetCeilingTrim(null,viewport);

  
}
