import { useSceneEntities } from "@/stores/SceneEntities";
import { usePresetsBuild } from "@/scripts/rooms/actions/general/PresetsBuild";
import { useButtonsContainer1 } from "@/scripts/ui/buttons/ButtonsContainer1";
export function usePresetAdd(){
    const sceneEntities = useSceneEntities();
   

// need to also do top nav presets

    let presets_persistant = sceneEntities.getUIData("presets_persistant")
    presets_persistant.children = [];

    let presets_persistant_topnav = sceneEntities.getUIData("presets_persistant_topnav");
    presets_persistant_topnav.children = [];
   
    let label = "preset";
    let uniqueKey = sceneEntities.uidGenerator();

    let objectOption = useButtonsContainer1();

    presets_persistant.children.push(objectOption);
    presets_persistant_topnav.children.push(objectOption);

    objectOption.attributes.componentData.display.label = label;
    objectOption.attributes.componentData.key = uniqueKey;
    objectOption.attributes.componentData.enabled = true;
    objectOption.attributes.componentData.sharedKey = "preset_selection";   
    sceneEntities.addItemToUIList(objectOption.attributes.componentData); 
   

    
   

   

}