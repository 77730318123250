export function useDividerStyling1() {
   
    const data = {
      classStringComponentDefault:"width-90 mx-auto my-6",  
        classStringLineDefault:"dividerSolid",       
        classStringTextDefault:"mx-3 text-type-3 text-color-7 text-shadow-1",       
        classStringIconDefault:"",       
       
          };

 
  return data;
}