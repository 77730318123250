import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetCornerShelvesFinish(payload, viewport) {
  //this function does not need to process when enabled is false
  if(!payload.enabled)return
  // need to hide or show shelves , need to know if they metal or sentrel
  const sceneEntities = useSceneEntities();
  let sentrelFinishData = sceneEntities.getUIData("corner_shelf_sentrel_material");
  let metalFinishData = sceneEntities.getUIData("corner_shelf_metal_material");

 

  let shelfRightTop = sceneEntities.getUIData("accessories_shelfrighttop");
  let shelfRightMid = sceneEntities.getUIData("accessories_shelfrightmid");
  let shelfRightBottom = sceneEntities.getUIData("accessories_shelfrightbottom");

  //SENTREL FINISH ------------------------------------------

  if (sentrelFinishData.enabled) {

    //sentrel shelves

   

    //right
    let shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop");
    shelfRightTopAsset.enabled = shelfRightTop.enabled;
    viewport.setEnabled(shelfRightTopAsset);

    let shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid");
    shelfRightMidAsset.enabled = shelfRightMid.enabled;
    viewport.setEnabled(shelfRightMidAsset);

    let shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom");
    shelfRightBottomAsset.enabled = shelfRightBottom.enabled;
    viewport.setEnabled(shelfRightBottomAsset);

    //metal shelves

    
 
     //right
     shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop_metal");
     shelfRightTopAsset.enabled = false;
     viewport.setEnabled(shelfRightTopAsset);
 
     shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid_metal");
     shelfRightMidAsset.enabled = false;
     viewport.setEnabled(shelfRightMidAsset);
 
     shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom_metal");
     shelfRightBottomAsset.enabled = false;
     viewport.setEnabled(shelfRightBottomAsset);
  }

  //METAL FINISH ------------------------------------------

  if (metalFinishData.enabled) {

    //metal shelves

    
    //right
    let shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop_metal");
    shelfRightTopAsset.enabled = shelfRightTop.enabled;
    viewport.setEnabled(shelfRightTopAsset);

    let shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid_metal");
    shelfRightMidAsset.enabled = shelfRightMid.enabled;
    viewport.setEnabled(shelfRightMidAsset);

    let shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom_metal");
    shelfRightBottomAsset.enabled = shelfRightBottom.enabled;
    viewport.setEnabled(shelfRightBottomAsset);

    //sentrel shelves

    
 
     //right
     shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop");
     shelfRightTopAsset.enabled = false;
     viewport.setEnabled(shelfRightTopAsset);
 
     shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid");
     shelfRightMidAsset.enabled = false;
     viewport.setEnabled(shelfRightMidAsset);
 
     shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom");
     shelfRightBottomAsset.enabled = false;
     viewport.setEnabled(shelfRightBottomAsset);
  }

  
}
