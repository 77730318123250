import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetCeilingTrim(payload, viewport) {
 
  const sceneEntities = useSceneEntities();

  let walls34 = sceneEntities.getUIData("walls34");


  let x60 = sceneEntities.getUIData("x60");
  let x48 = sceneEntities.getUIData("x48");
  //shower_roof_x60

  //since this can be called from the ceiling panel ui or for validation , payload is not gauranteed so get it here
  payload = payload || sceneEntities.getUIData("accessories_ceilingtrim");

  let enabledOverride = payload.enabled;
  if(walls34.enabled){    
    payload.disabled = true;
    enabledOverride = true;
  }else{
    payload.disabled = false;
  }

  let wall_inner_mold_roof_center_x60 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mold_roof_center_x60"); 
  let wall_inner_mold_roof_right_x60 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mold_roof_right_x60");

  let wall_inner_mold_roof_center_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mold_roof_center_neo"); 
  let wall_inner_mold_roof_right_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_inner_mold_roof_right_neo");

  wall_inner_mold_roof_center_x60.enabled = enabledOverride && x60.enabled;
  wall_inner_mold_roof_right_x60.enabled = enabledOverride && x60.enabled;

  wall_inner_mold_roof_center_neo.enabled = enabledOverride && x48.enabled;
  wall_inner_mold_roof_right_neo.enabled = enabledOverride && x48.enabled;

  viewport.setEnabled(wall_inner_mold_roof_center_x60);
  viewport.setEnabled(wall_inner_mold_roof_right_x60);
  viewport.setEnabled(wall_inner_mold_roof_center_neo);
  viewport.setEnabled(wall_inner_mold_roof_right_neo);

  viewport.invalidateTransformForKey("wall_inner_mold_roof_center_x60", "walls34");
  viewport.invalidateTransformForKey("wall_inner_mold_roof_right_x60", "walls34");
  viewport.invalidateTransformForKey("wall_inner_mold_roof_center_neo", "walls34");
  viewport.invalidateTransformForKey("wall_inner_mold_roof_right_neo", "walls34");

 

  
}
