import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetCeilingPanal(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let x60 = sceneEntities.getUIData("x60");
  let x48 = sceneEntities.getUIData("x48");
  //shower_roof_x60

  //since this can be called from the ceiling panel ui or for validation , payload is not gauranteed so get it here
  payload = payload || sceneEntities.getUIData("accessories_ceilingpanel");

   //ceiling can only be on full walls
   let wallsfull = sceneEntities.getUIData("wallsfull");
   let enabledOverride = payload.enabled;
   if(!wallsfull.enabled){
    enabledOverride = false;
    payload.disabled = true;
   }else{
    payload.disabled = false;
   }

  let shower_roof_x60 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_roof_x60");
  let shower_roof_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_roof_neo");

 

  shower_roof_x60.enabled = enabledOverride && x60.enabled;
  shower_roof_neo.enabled = enabledOverride && x48.enabled;

  viewport.setEnabled(shower_roof_x60);
  viewport.setEnabled(shower_roof_neo);
}
