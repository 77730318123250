import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useShowers(baseChildRadioGroup) {
  const sceneEntities = useSceneEntities();

  let target = useAccordion2();
  target.attributes.componentData.display.label = "Showers";
  target.attributes.componentData.key = "showers";
  target.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(target.attributes.componentData);

  target.attributes.componentData.interaction.deselectSelf = false;

  //x60--------------------------------------------------
  let x60 = useButton2();

  target.attributes.componentData.addChild(x60);
  x60.attributes.componentData.display.label = "x60";
  x60.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  x60.attributes.componentData.key = "x60";
  sceneEntities.addItemToUIList(x60.attributes.componentData);
  x60.attributes.componentData.interaction.deselectSelf = false;
  x60.attributes.componentData.interaction.actions["scene_update"] = true;
  x60.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //x48--------------------------------------------------
  let x48 = useButton2();
  target.attributes.componentData.addChild(x48);
  x48.attributes.componentData.display.label = "x48";
  x48.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  x48.attributes.componentData.key = "x48";
  sceneEntities.addItemToUIList(x48.attributes.componentData);
  x48.attributes.componentData.interaction.deselectSelf = false;
  x48.attributes.componentData.interaction.actions["scene_update"] = true;
  x48.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //x36--------------------------------------------------
  let x36 = useButton2();
  target.attributes.componentData.addChild(x36);
  x36.attributes.componentData.display.label = "x36";
  x36.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  x36.attributes.componentData.key = "x36";
  sceneEntities.addItemToUIList(x36.attributes.componentData);
  x36.attributes.componentData.interaction.deselectSelf = false;
  x36.attributes.componentData.interaction.actions["scene_update"] = true;
  x36.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //threshold--------------------------------------------------
  let shower_threshold = useButton2();

  target.attributes.componentData.addChild(shower_threshold);

  

  shower_threshold.attributes.componentData.display.label = "low profile Roll in"; 

  if (window.isSentrelBuild) {
    shower_threshold.attributes.componentData.display.label = "Zero-Threshold"; 
  }

  shower_threshold.attributes.componentData.key = "shower_threshold";
  //shower_threshold.attributes.componentData.enabled = true;
  sceneEntities.addItemToUIList(shower_threshold.attributes.componentData); 
  shower_threshold.attributes.componentData.interaction.actions["scene_update"] = true;
  shower_threshold.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  return target;
}
