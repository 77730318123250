import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetTub2852Whirlpool(payload, viewport) {
  const sceneEntities = useSceneEntities();
 
  let assets = ["elite_2852_button_2","elite_2852_jets_whirlpool"];

  assets.forEach((value) => {
    let asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(value);
    asset.enabled = payload.enabled;
    viewport.setEnabled(asset);
  });

 
}
