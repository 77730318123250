
export function useComponentStylingType5() {
   
    const data = {
        classStringContainerDefault:"container-type-4 m-2 p-0",
        classStringContainerSelected:"container-type-4-selected m-2 p-0",
        classStringTextDefault:"text-type-4 px-1 py-2",
        classStringTextSelected:"text-type-4 px-1 py-2",
        classStringThumbDefault:"thumb-2",
        classStringThumbSelected:"thumb-2",
      
     
          };

 
  return data;
}