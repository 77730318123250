import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetEnableSimple } from "@/scripts/rooms/actions/general/AssetEnableSimple";
import { useAssetTub3053DualJetted } from "@/scripts/rooms/actions/alcove/AssetTub3053DualJetted";
import { useAssetRecessValidate2 } from "@/scripts/rooms/actions/alcove/AssetRecessValidate2";
import { useAssetCornerShelvesValidate } from "@/scripts/rooms/actions/alcove/AssetCornerShelvesValidate";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetFaucetsValidate1 } from "@/scripts/rooms/actions/alcove/AssetFaucetsValidate1";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsValidate";
import { useSWDValidate1 } from "@/scripts/rooms/actions/general/SWDValidate1";
export function useAssetTub3053(payload, viewport) {

  const sceneEntities = useSceneEntities();

  //is false then no need to validate default child ui selections..
  if (!payload.enabled) {
    useAssetEnableSimple(payload, viewport);

    return;
  }

  useAssetRecessValidate2(null, viewport);
  useAssetCornerShelvesValidate(null, viewport);

  //calling to get the asset before it is loaded will cause the viewport
  //asset to be created , which has the enabled property set to false

  //then when the main asset is loaded and child assets are looped
  //if it finds the viewport asset already exists
  //it will not set enabled to true, this effectively hides the children
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_3053_jets_hydro");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_3053_jets_whirlpool");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_3053_shower_head_docked");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_3053_sliderbar");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_3053_button_1");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_3053_button_2");
  

  let walkin3053dualjetted = sceneEntities.getUIData("walkin3053dualjetted").enabled;
  let walkin3053hydrotherapy = sceneEntities.getUIData("walkin3053hydrotherapy").enabled;
  let walkin3053whirlpool = sceneEntities.getUIData("walkin3053whirlpool").enabled;
  let walkin3053soaker = sceneEntities.getUIData("walkin3053soaker").enabled;

  if (!walkin3053dualjetted && !walkin3053hydrotherapy && !walkin3053whirlpool && !walkin3053soaker) {
 
    let data = sceneEntities.getUIData("walkin3053dualjetted");
    data.enabled = true;
    useUIRadioGroup(data, viewport);
    useAssetTub3053DualJetted(data, viewport);
  }

  let elite_3053_shower_head_docked = sceneEntities.getUIData("elite_3053_shower_head_docked").enabled;
  let elite_3053_sliderbar = sceneEntities.getUIData("elite_3053_sliderbar").enabled;

  if (!elite_3053_shower_head_docked && !elite_3053_sliderbar) {
    let data = sceneEntities.getUIData("elite_3053_shower_head_docked");
    data.enabled = true;
    useUIRadioGroup(data, viewport);
    useAssetEnableSimple(data, viewport);
  }

  useAssetEnableSimple(payload, viewport);
  useAssetFaucetsValidate1(null, viewport);
  useAssetDoorsValidate(null, viewport);
  useSWDValidate1(null, viewport);

}
