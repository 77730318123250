import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useButton5 } from "@/scripts/ui/buttons/Button5";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useContainer } from "@/scripts/ui/containers/Container";
export function usePresets() {
  let target = useAccordion1();
  let presetRadioGroup = useButtonRadioGroup();
  const sceneEntities = useSceneEntities();
  target.attributes.componentData.display.label = "Presets";
  target.attributes.componentData.key = "presets";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;
  target.attributes.componentData.interaction.actions["scene_update"] = true;

  let persistant = useAccordion2();  
  targetChildren.push(persistant);
  persistant.attributes.componentData.display.label = "Persistant";
  persistant.attributes.componentData.key = "presets_persistant";  
  sceneEntities.addItemToUIList(persistant.attributes.componentData);
  let persistantChildren = persistant.attributes.componentData.children;
  persistant.attributes.componentData.skipSerialization = true;

  //addPreset--------------------------------------------------
  let addPreset = useButton5();
  persistantChildren.push(addPreset);
  addPreset.attributes.componentData.display.label = "Add Preset";
  addPreset.attributes.componentData.skipSerialization = true;
  addPreset.attributes.componentData.key = "preset_add";  
  sceneEntities.addItemToUIList(addPreset.attributes.componentData); 
  addPreset.attributes.componentData.interaction.actions["scene_update"] = true;


  let session = useAccordion2();  
  targetChildren.push(session);
  session.attributes.componentData.display.label = "Session";
  session.attributes.componentData.key = "presets_session";  
  sceneEntities.addItemToUIList(session.attributes.componentData); 
  let sessionChildren = persistant.attributes.componentData.children;
  session.attributes.componentData.skipSerialization = true;

  //addPreset--------------------------------------------------
  let saveSession = useButton5();
  sessionChildren.push(saveSession);
  saveSession.attributes.componentData.display.label = "Save Session";
  saveSession.attributes.componentData.skipSerialization = true;
  saveSession.attributes.componentData.key = "save_session";
  saveSession.attributes.componentData.sharedKey = "session_save_shared";
  sceneEntities.addItemToUIList(saveSession.attributes.componentData);
  saveSession.attributes.componentData.interaction.actions["scene_update"] = true;


  let uiData = [];
uiData.presetsPersistant = [];
uiData.presetsSession = [];

let presetPersistant = useContainer();
presetPersistant.attributes.componentData.key = "presets_persistant_topnav";
presetPersistant.attributes.componentData.skipSerialization = true;
presetPersistant.attributes.componentData.enabled = true;
sceneEntities.addItemToUIList(presetPersistant.attributes.componentData);
uiData.presetsPersistant.push(presetPersistant);

let presetSession = useContainer();
presetSession.attributes.componentData.key = "presets_session_topnav";
presetSession.attributes.componentData.skipSerialization = true;
presetSession.attributes.componentData.enabled = true;
sceneEntities.addItemToUIList(presetSession.attributes.componentData);
uiData.presetsSession.push(presetSession);

sceneEntities.uiBuildDataTopNav = uiData;

  

  return target;
}
