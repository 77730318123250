import { useSceneEntities } from "@/stores/SceneEntities";

export function useShowerWallValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;

  //side walls
  let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_left_full");
  let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_right_full");

  let wall3 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_left_34");
  let wall4 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_right_34");

  if (wallsfull) {
    wall1.enabled = true;
    viewport.setEnabled(wall1);
    wall2.enabled = true;
    viewport.setEnabled(wall2);

    wall3.enabled = false;
    viewport.setEnabled(wall3);
    wall4.enabled = false;
    viewport.setEnabled(wall4);
  }
  if (walls34) {
    wall1.enabled = false;
    viewport.setEnabled(wall1);
    wall2.enabled = false;
    viewport.setEnabled(wall2);

    wall3.enabled = true;
    viewport.setEnabled(wall3);
    wall4.enabled = true;
    viewport.setEnabled(wall4);
  }
}
