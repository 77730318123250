import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useTubs } from "@/scripts/ui/configurator_scene_navigation/alcove/base/tubs/Tubs";
import { useShowers } from "@/scripts/ui/configurator_scene_navigation/alcove/base/showers/Showers";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useBase() {
  const sceneEntities = useSceneEntities();
  let baseRadioGroup = useButtonRadioGroup();
  let baseChildRadioGroup = useButtonRadioGroup();

  const target = useAccordion1();
  target.attributes.componentData.display.label = "Base";
  target.attributes.componentData.key = "base";

  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

  if (!window.isSentrelBuild) {
    //Tubs--------------------------------------------------
    let tubs = useTubs(baseChildRadioGroup);
    targetChildren.push(tubs);
    tubs.attributes.componentData.interaction.radioGroup = baseRadioGroup;

    //Showers--------------------------------------------------
    let showers = useShowers(baseChildRadioGroup);
    targetChildren.push(showers);
    showers.attributes.componentData.interaction.radioGroup = baseRadioGroup;
  } else {
    // for sentrel shower and tubs are reversed

    //Showers--------------------------------------------------
    let showers = useShowers(baseChildRadioGroup);
    targetChildren.push(showers);
    showers.attributes.componentData.interaction.radioGroup = baseRadioGroup;

    //Tubs--------------------------------------------------
    let tubs = useTubs(baseChildRadioGroup);
    targetChildren.push(tubs);
    tubs.attributes.componentData.interaction.radioGroup = baseRadioGroup;
  }

  return target;
}
