import { useSceneEntities } from "@/stores/SceneEntities";

import { useMaterialMetalsFromFaucetUI } from "@/scripts/rooms/actions/general/MaterialMetalsFromFaucetUI";

export function useAssetFaucett17453(payload, viewport) {


    const sceneEntities = useSceneEntities();

    let showers = sceneEntities.getUIData("showers").enabled;

    //handle child assets first
    //to allow system to create viewport asset ahead of actual asset load

    let t17453_control = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17453_control");
    t17453_control.disabled = payload.disabled;
    t17453_control.enabled = payload.enabled;
    viewport.setEnabled(t17453_control);
    viewport.invalidateTransformForKey("t17453_control","showers");

    let t17453_head = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17453_head");
    t17453_head.disabled = payload.disabled;
    t17453_head.enabled = payload.enabled;
    viewport.setEnabled(t17453_head);

    let t17453_spout = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17453_spout");
    //spout also validates if showers are selected
    t17453_spout.disabled = payload.disabled;
    t17453_spout.enabled = payload.enabled && !showers;
    viewport.setEnabled(t17453_spout);

    //now handle main asset
    
    let t17453 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17453");
    t17453.disabled = payload.disabled;
    t17453.enabled = payload.enabled;
    viewport.setEnabled(t17453);


    useMaterialMetalsFromFaucetUI(null,viewport)




}