import { useSceneEntities } from "@/stores/SceneEntities";
import { useAlcoveValidate1 } from "@/scripts/rooms/actions/alcove/AlcoveValidate1";
import { useAssetDoorsInvert } from "@/scripts/rooms/actions/alcove/AssetDoorsInvert";
export function useAssetCurtainCurved(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let tub_standard = sceneEntities.getUIData("tub_standard");
  let tub_soaker = sceneEntities.getUIData("tub_soaker");
  let x60 = sceneEntities.getUIData("x60");
  let x48 = sceneEntities.getUIData("x48");
  let x36 = sceneEntities.getUIData("x36");

  let tub2852 = sceneEntities.getUIData("walkin2852");
  let tub2653 = sceneEntities.getUIData("walkin2653");
  let tub3053 = sceneEntities.getUIData("walkin3053");
  let isWalkin = false;
  if (tub2852.enabled || tub2653.enabled || tub3053.enabled) {
    isWalkin = true;
  }

  let curtain_bar_bath_x60_curved_long = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "curtain_bar_bath_x60_curved_long"
  );
  let curtain_bar_bath_x60_curved_short = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "curtain_bar_bath_x60_curved_short"
  );
  let curtain_bar_shower_x48_curved = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "curtain_bar_shower_x48_curved"
  );
  let curtain_bar_shower_x60_curved = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "curtain_bar_shower_x60_curved"
  );
  let curtain_bar_bath_x60_curved_walkin_tub = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "curtain_bar_bath_x60_curved_walkin_tub"
  );

  //just disable all at first
  curtain_bar_bath_x60_curved_long.enabled = false;
  viewport.setEnabled(curtain_bar_bath_x60_curved_long);

  curtain_bar_bath_x60_curved_short.enabled = false;
  viewport.setEnabled(curtain_bar_bath_x60_curved_short);

  curtain_bar_shower_x48_curved.enabled = false;
  viewport.setEnabled(curtain_bar_shower_x48_curved);

  curtain_bar_shower_x60_curved.enabled = false;
  viewport.setEnabled(curtain_bar_shower_x60_curved);

  curtain_bar_bath_x60_curved_walkin_tub.enabled = false;
  viewport.setEnabled(curtain_bar_bath_x60_curved_walkin_tub);

  //since this can be used from direct ui selections of the bi pass original ui
  //or or for validation from other selections like showers or tubs , payload could be null
  // so retrieve the payload manually..

  let ui = sceneEntities.getUIData("showerenclosure_curved");
  let ui2 = sceneEntities.getUIData("showerenclosure_straight");
  if (x36.enabled) {
    ui.disabled = true;
  } else {
    ui.disabled = false;
  }

   // new logic if disabled then set enabled to false
  if (ui.enabled && ui.disabled) {
    ui.enabled = false;
  }

  if (ui.enabled && !ui.disabled) {
    if (tub_standard.enabled) {
      curtain_bar_bath_x60_curved_long.enabled = true;
      viewport.setEnabled(curtain_bar_bath_x60_curved_long);
    }
    if (tub_soaker.enabled) {
      curtain_bar_bath_x60_curved_short.enabled = true;
      viewport.setEnabled(curtain_bar_bath_x60_curved_short);
    }
    if (x60.enabled) {
      curtain_bar_shower_x60_curved.enabled = true;
      viewport.setEnabled(curtain_bar_shower_x60_curved);
    }
    if (x48.enabled) {
      curtain_bar_shower_x48_curved.enabled = true;
      viewport.setEnabled(curtain_bar_shower_x48_curved);
    }
    if (isWalkin) {
      curtain_bar_bath_x60_curved_walkin_tub.enabled = true;
      viewport.setEnabled(curtain_bar_bath_x60_curved_walkin_tub);
    }
  }

 
  useAssetDoorsInvert(null, viewport);
  useAlcoveValidate1(null, viewport);

  let showerenclosure_curtains = sceneEntities.getUIData("showerenclosure_curtains");
  showerenclosure_curtains.disabled = false;
  if (ui.disabled && ui2.disabled) {
    showerenclosure_curtains.disabled = true;
  }
}
