import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetWainscotting(payload, viewport) {
    const sceneEntities = useSceneEntities();
     ///since this can be called for validation and not only from wainscotting ui itself , get the ui payload here
  let wainscotting = sceneEntities.getUIData("wainscotting");
  

  let wainscoting_left = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_left_1");
  let wainscoting_left_2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_left_2");
  let wainscoting_right = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_right_1");
  let wainscoting_right_2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wainscoting_right_2");

  let x60 = sceneEntities.getUIData("x60");
  let x48 = sceneEntities.getUIData("x48");


 

 
  wainscoting_left.enabled = wainscotting.enabled && x60.enabled;
  wainscoting_left_2.enabled = wainscotting.enabled && x48.enabled;
  wainscoting_right.enabled = wainscotting.enabled && x60.enabled;
  wainscoting_right_2.enabled = wainscotting.enabled && x48.enabled;
 
  viewport.setEnabled(wainscoting_left);
  viewport.setEnabled(wainscoting_left_2);
  viewport.setEnabled(wainscoting_right);
  viewport.setEnabled(wainscoting_right_2);

}
