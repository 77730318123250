import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetDoorsBipass2Towel } from "@/scripts/rooms/actions/alcove/AssetDoorsBipass2Towel";
import { useAssetDoorsBipass1Towel } from "@/scripts/rooms/actions/alcove/AssetDoorsBipass1Towel";
import { useAssetDoorsHinge1panel } from "@/scripts/rooms/actions/alcove/AssetDoorsHinge1panel";
import { useAssetDoorsInfinityHinge } from "@/scripts/rooms/actions/alcove/AssetDoorsInfinityHinge";
import { useAssetDoorsBarnStyle } from "@/scripts/rooms/actions/alcove/AssetDoorsBarnStyle";
import { useAssetCurtainCurved } from "@/scripts/rooms/actions/alcove/AssetCurtainCurved";
import { useAssetCurtainStraight } from "@/scripts/rooms/actions/alcove/AssetCurtainStraight";



export function useAssetDoorsValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  useAssetDoorsBipass2Towel(null, viewport);
  useAssetDoorsBipass1Towel(null, viewport);
  useAssetDoorsHinge1panel(null, viewport);
  useAssetDoorsInfinityHinge(null, viewport);
  useAssetDoorsBarnStyle(null, viewport);
  useAssetCurtainCurved(null, viewport);
  useAssetCurtainStraight(null, viewport);  
 

 
}
