import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetDoorsOpenClose(payload, viewport) {
  const sceneEntities = useSceneEntities();  

  viewport.invalidateTransformForKey("doors_x60_rotolo_barn_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x48_rotolo_barn_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_rotolo_barn_tub_standard_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_rotolo_barn_tub_soaker_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_cantour_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x48_cantour_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x36_cantour_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_tuscon_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x48_tuscon_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_bipass_original_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_bipass_basco_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x48_bipass_original_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x48_bipass_basco_shower_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_bipass_original_tub_standard_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_bipass_basco_tub_standard_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_bipass_original_tub_soaker_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x60_bipass_basco_tub_soaker_panel_1","shower_doors_open");
  viewport.invalidateTransformForKey("doors_x36_infinity_hinge_panel_1","shower_doors_open");
  
}
