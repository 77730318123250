import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useShowers } from "@/scripts/ui/configurator_scene_navigation/corner/base/showers/Showers";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useBase() {

  const sceneEntities = useSceneEntities();
 
  let baseChildRadioGroup = useButtonRadioGroup();

  const target = useAccordion1();
  target.attributes.componentData.display.label = "Base";
  target.attributes.componentData.key = "base"; 
 

  
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

 

  //Showers--------------------------------------------------
  let showers = useShowers(baseChildRadioGroup);
  targetChildren.push(showers); 


  return target;
}
