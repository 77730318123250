
export function useComponentStylingType3() {
   
    const data = {
        classStringContainerDefault:"container-type-1 py-2 my-1",
        classStringContainerSelected:"container-type-2 py-2 my-1",
        classStringTextDefault:"text-type-2 text-color-6",
        classStringTextSelected:"text-type-2 text-color-10",
        classStringIconDefault:"mdi icon icon-color-6 mdi-chevron-down",
        classStringIconSelected:"mdi icon text-color-7 mdi-chevron-up",
       
          };

 
  return data;
}