import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useShowers(baseChildRadioGroup) {
  const sceneEntities = useSceneEntities();
 
  let target = useAccordion2();
  target.attributes.componentData.display.label = "Showers";
  target.attributes.componentData.key = "showers";
  target.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(target.attributes.componentData);

  target.attributes.componentData.interaction.deselectSelf = false;

   //x60--------------------------------------------------
   let x60 = useButton2();

   target.attributes.componentData.addChild(x60);
   x60.attributes.componentData.display.label = "2 Wall Square";
   x60.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
   x60.attributes.componentData.key = "x60";
   sceneEntities.addItemToUIList(x60.attributes.componentData);
   x60.attributes.componentData.interaction.deselectSelf = false;
   x60.attributes.componentData.interaction.actions["scene_update"] = true;
   x60.attributes.componentData.parentEnabledOverridesChildEnabled = true;

    //x48--------------------------------------------------
  let x48 = useButton2();
  target.attributes.componentData.addChild(x48);
  x48.attributes.componentData.display.label = "Neo";
  x48.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  x48.attributes.componentData.key = "x48";
  sceneEntities.addItemToUIList(x48.attributes.componentData);
  x48.attributes.componentData.interaction.deselectSelf = false;
  x48.attributes.componentData.interaction.actions["scene_update"] = true;
  x48.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  return target;
}
