import { useSceneEntities } from "@/stores/SceneEntities";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
export function useSWDValidate1(payload, viewport) {
  const sceneEntities = useSceneEntities();

  /* since I now had to change logic to disable 2x2 and mosaic and floor apply when on tubs,
  i want to call this from tubs , just checking which design is expanded , then I know 
  what payload to derive, since there can only be one expanded at a time, 
  and also, because clicking any of them after this performs this validation
  i only have to fix the one that is displayed*/
  if (!payload) {
    let designNames = [
      "alaskanivory",
      "athens",
      "bardiglio",
      "botticinocream",
      "brecciaparadiso",
      "calabria",
      "calacattawhite",
      "calcuttagold",
      "cremabordeaux",
      "cremetravertine",
      "everest",
      "frost",
      "goldenbeaches",
      "grayquartz",
      "lagosgray",
      "mochatravertine",
      "petrafini",
      "triton",
      "veincutgray",
      "vintagespruce",
      "whitepearl",
      "whitestandard",
      "azzurrabay",
      "tajmahal",
      "acrylic3x8subway",
      "twotoneacrylic3x8black",
      "twotoneacrylic3x8gray",
      "twotoneacrylic10x20black",
      "twotoneacrylic10x20gray",
      "marmogrigio",
      "marmomarrone",
    ];
    let currentUI = null;
    designNames.every(element=>{
      let ui = sceneEntities.getUIData("swd_"+element);
      if(ui){
        if(ui.enabled){
          currentUI = ui;
         
          return false;
        }else{
          return true;
        }

      }else{
        return true;
      }
    })
    payload = currentUI;  
   
  }

  if (!payload.enabled) {
    return;
  }

  let tubs = sceneEntities.getUIData("tubs");
  let isShower = true;
  if (tubs) {
    if (tubs.enabled) {
      isShower = false;
    }
  }

  // styles
  //sometimes key has two under scores , sometimes 1 , eitherway , the name will be at index 1
  let sentrelDesignKey = payload.key.split("_")[1];

  let regular = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_regular");
  let sim2x2 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_sim2x2");
  let sim3x6 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_sim3x6");
  let sim10x20 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_sim10x20");
  //new mosaic
  let mosaic1 = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_mosaic1");

  if (sim2x2) {
    sim2x2.disabled = !isShower;
    if (sim2x2.enabled && sim2x2.disabled) {
      sim2x2.enabled = false;
    }
  }

  if (mosaic1) {
    mosaic1.disabled = !isShower;
    if (mosaic1.enabled && mosaic1.disabled) {
      mosaic1.enabled = false;
    }
  }

  if (!regular.enabled && !sim2x2?.enabled && !sim3x6?.enabled && !sim10x20?.enabled && !mosaic1?.enabled) {
    regular.enabled = true;
    useUIRadioGroup(regular, viewport);
  }

  // apply to
  let allsurfaces = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_allsurfaces");
  let interiorwalls = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_interiorwalls");
  let wainscotting = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_wainscotting");
  let showerfloor = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_showerfloor");
  let ceilingpanel = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_ceilingpanel");

  allsurfaces.disabled = sim2x2?.enabled || sim3x6?.enabled || sim10x20?.enabled || mosaic1?.enabled;
  interiorwalls.disabled = sim2x2?.enabled || mosaic1?.enabled;
  wainscotting.disabled = sim2x2?.enabled || mosaic1?.enabled;
  ceilingpanel.disabled = sim2x2?.enabled || sim3x6?.enabled || sim10x20?.enabled || mosaic1?.enabled;
  showerfloor.disabled = sim3x6?.enabled || sim10x20?.enabled || !isShower;

  let accenttrim = sceneEntities.getUIData("swd_" + sentrelDesignKey + "_accenttrim");

  let data =  sceneEntities.configuratorView.viewportComponent.getDataCollectionMaterial("useSWDMaterialWalls")
  if (data) {
    if (data.sentrelDesignKey !== sentrelDesignKey) {
      if (accenttrim) {
        accenttrim.disabled = true;
      }
    }
  }
}
