export function useUIRadioGroup(payload) {
    
    let group = payload?.interaction?.radioGroup;
    let previousSelection = null;
    if (group) {
      if (group.currentSelection) {
        if (group.currentSelection.key != payload.key) {
          group.currentSelection.enabled = false;
         
        }
      }
     
      previousSelection = group.currentSelection;
      group.currentSelection = payload;
      
    }
 return previousSelection;
 
}