import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetAccentValidate1 } from "@/scripts/rooms/actions/alcove/AssetAccentValidate1";
import { useShowerWallMoldValidate } from "@/scripts/rooms/actions/alcove/ShowerWallMoldValidate";

export function useSWDMaterialWalls(payload, viewport) {

  const sceneEntities = useSceneEntities();

  //since accents belong to specific wall designs , there might be an accent visible from a previous wall design selection
  //check the stored data for this wall and see if the data matches , if not , the accent needs to be hidden

  //accent ui for this selection
  let accenttrim = sceneEntities.getUIData("swd_" + payload.sentrelDesignKey + "_accenttrim");

  let data =  sceneEntities.configuratorView.viewportComponent.getDataCollectionMaterial("useSWDMaterialWalls")
  
  if (data) {
    if (data.sentrelDesignKey !== payload.sentrelDesignKey) {
      //walls dont match so if accents are visible they must be hidden ,
      let currentSelection = sceneEntities.SWDRadioGroups.swdAccentEnableRadioGroup.currentSelection; 
     
      if (currentSelection) {        
        currentSelection.enabled = false;
        useAssetAccentValidate1(null, viewport);
      }
    }
  }

  //enable accent ui for this design again
  if (accenttrim) {
    accenttrim.disabled = false;
  }

  sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({ key: "useSWDMaterialWalls", data: payload });
 

  //wall_interior
  let materialUpdateObject = {};
  materialUpdateObject.name = "wall_interior";
  materialUpdateObject.colors = {};
  materialUpdateObject.colors.albedoColor = 'd6d6d6'
  materialUpdateObject.textures = {};
  materialUpdateObject.textures.albedoTexture = {};
  let textureKeyPreferred = payload.textureKey + "_walls";
  let texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureKeyPreferred);
  if (!texture) {
    //no preferred key so fall back to default implementation
    textureKeyPreferred = payload.textureKey;
  }
  materialUpdateObject.textures.albedoTexture.key = textureKeyPreferred;
  materialUpdateObject.textures.albedoTexture.textureProperties = {};
  materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
  viewport.updateMaterial(materialUpdateObject);

  //wall_interior_mould
  materialUpdateObject = {};
  materialUpdateObject.name = "wall_interior_mould";
  materialUpdateObject.colors = {};
  materialUpdateObject.colors.albedoColor = 'd6d6d6'
  materialUpdateObject.textures = {};
  materialUpdateObject.textures.albedoTexture = {};
  let textureMoldKeyPreferred = payload.textureMoldKey + "_walls_mold";
  texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureMoldKeyPreferred);
  if (!texture) {
    //no preferred key so fall back to default implementation
    textureMoldKeyPreferred = payload.textureMoldKey;
  }
  materialUpdateObject.textures.albedoTexture.key = textureMoldKeyPreferred;
  materialUpdateObject.textures.albedoTexture.textureProperties = {};
  materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
  viewport.updateMaterial(materialUpdateObject);
  
  useShowerWallMoldValidate(null,viewport);
 
}
