import { useSceneEntities } from "@/stores/SceneEntities";
export function useSWDMaterialWainscotting(payload, viewport) {
  const sceneEntities = useSceneEntities();

  sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({key:"useSWDMaterialWainscotting",data:payload});

   //wainscotting
   let materialUpdateObject = {};
   materialUpdateObject.name = "wainscotting";
   materialUpdateObject.colors = {};
   materialUpdateObject.colors.albedoColor = 'd6d6d6'
   materialUpdateObject.textures = {};
   materialUpdateObject.textures.albedoTexture = {};
   let textureKeyPreferred = payload.textureKey + "_wainscotting";
   
   let texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureKeyPreferred);
   if (!texture) {
     //no preferred key so fall back to default implementation
    
     textureKeyPreferred = payload.textureKey;
   
   }
   materialUpdateObject.textures.albedoTexture.key = textureKeyPreferred;
   materialUpdateObject.textures.albedoTexture.textureProperties = {};
   materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
   viewport.updateMaterial(materialUpdateObject);

   //wainscotting_mold
   materialUpdateObject = {};
   materialUpdateObject.name = "wainscotting_mold";
   materialUpdateObject.colors = {};
   materialUpdateObject.colors.albedoColor = 'd6d6d6'
   materialUpdateObject.textures = {};
   materialUpdateObject.textures.albedoTexture = {};
   let textureMoldKeyPreferred = payload.textureMoldKey + "_wainscotting_mold";
   texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureMoldKeyPreferred);
   if (!texture) {
     //no preferred key so fall back to default implementation
     textureMoldKeyPreferred = payload.textureMoldKey;
   }
   materialUpdateObject.textures.albedoTexture.key = textureMoldKeyPreferred;
   materialUpdateObject.textures.albedoTexture.textureProperties = {};
   materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
   viewport.updateMaterial(materialUpdateObject);

}