import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetRecessRegular } from "@/scripts/rooms/actions/corner/AssetRecessRegular";
import { useAssetRecessSoap1 } from "@/scripts/rooms/actions/corner/AssetRecessSoap1";
import { useAssetRecessSoap2 } from "@/scripts/rooms/actions/corner/AssetRecessSoap2";
import { useAssetRecessSteelShelf1 } from "@/scripts/rooms/actions/corner/AssetRecessSteelShelf1";
import { useAssetRecessSteelShelf2 } from "@/scripts/rooms/actions/corner/AssetRecessSteelShelf2";
import { useAssetRecessCustomSoap1 } from "@/scripts/rooms/actions/corner/AssetRecessCustomSoap1";
import { useAssetRecessCustomSoap2 } from "@/scripts/rooms/actions/corner/AssetRecessCustomSoap2";

export function useRecessWallValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  //used when changing walls from full to 3/4 
  //and when changing from x60 to neo

  //get all recess buttons
  let accessories_recess_regular = sceneEntities.getUIData("accessories_recess_regular"); 
  let accessories_recess_soap1 = sceneEntities.getUIData("accessories_recess_soap1");
  let accessories_recess_soap2 = sceneEntities.getUIData("accessories_recess_soap2");  
  let accessories_recess_steelshelf1 = sceneEntities.getUIData("accessories_recess_steelshelf1");
  let accessories_recess_steelshelf2 = sceneEntities.getUIData("accessories_recess_steelshelf2");
  let accessories_recess_custom_soap_1 = sceneEntities.getUIData("accessories_recess_custom_soap_1");
  let accessories_recess_custom_soap_2 = sceneEntities.getUIData("accessories_recess_custom_soap_2");

  //!! not all buttons are valid due to sentrel builds excluding some of them

  

  if (accessories_recess_regular?.enabled) {
    useAssetRecessRegular(accessories_recess_regular,viewport);
  }

  //-------------------------------------

  if (accessories_recess_soap1?.enabled) {
    useAssetRecessSoap1(accessories_recess_soap1,viewport);
  }

  //-------------------------------------

  if (accessories_recess_soap2?.enabled) {
    useAssetRecessSoap2(accessories_recess_soap2,viewport);
  }

  //-------------------------------------

  if (accessories_recess_steelshelf1?.enabled) {
    useAssetRecessSteelShelf1(accessories_recess_steelshelf1,viewport);
  }

  //-------------------------------------

  if (accessories_recess_steelshelf2?.enabled) {
    useAssetRecessSteelShelf2(accessories_recess_steelshelf2,viewport);
  }

  //-------------------------------------

  if (accessories_recess_custom_soap_1?.enabled) {
    useAssetRecessCustomSoap1(accessories_recess_custom_soap_1,viewport);
  }

  //-------------------------------------

  if (accessories_recess_custom_soap_2?.enabled) {
    useAssetRecessCustomSoap2(accessories_recess_custom_soap_2,viewport);
  }

  //handle side walls and molds


  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;
  let x60 = sceneEntities.getUIData("x60").enabled;
  let x48 = sceneEntities.getUIData("x48").enabled;

  //side walls
  let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_right_full_x60");
  let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_right_34_x60");

  let wall1_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_right_full_neo");
  let wall2_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_right_34_neo");

  let mold1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("walls_mold_x60_vertical_full");
  let mold2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("walls_mold_x60_vertical_34");

  let mold3 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("walls_mold_neo_vertical_full");
  let mold4 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("walls_mold_neo_vertical_34");


  let wall_mold_square_full = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_mold_square_full");
  let wall_mold_square_34 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_mold_square_34");

  let wall_mold_neo_full = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_mold_neo_full");
  let wall_mold_neo_34 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_mold_neo_34");

  //set all to false
  wall1.enabled = false;
  viewport.setEnabled(wall1);
  wall2.enabled = false;
  viewport.setEnabled(wall2);
  wall1_neo.enabled = false;
  viewport.setEnabled(wall1_neo);
  wall2_neo.enabled = false;
  viewport.setEnabled(wall2_neo);
  mold1.enabled = false;
  viewport.setEnabled(mold1);
  mold2.enabled = false;
  viewport.setEnabled(mold2);
  mold3.enabled = false;
  viewport.setEnabled(mold3);
  mold4.enabled = false;
  viewport.setEnabled(mold4);

  wall_mold_square_full.enabled = false;
  viewport.setEnabled(wall_mold_square_full);
  wall_mold_square_34.enabled = false;
  viewport.setEnabled(wall_mold_square_34);
  wall_mold_neo_full.enabled = false;
  viewport.setEnabled(wall_mold_neo_full);
  wall_mold_neo_34.enabled = false;
  viewport.setEnabled(wall_mold_neo_34);

  let data = sceneEntities.configuratorView.viewportComponent.getDataCollectionMaterial("useSWDMaterialWalls"); 
  console.log(data)
  let permitMoldsStandard = true;
  if (data) {    
    if (
      data.sentrelDesignKey === "acrylic3x8subway" ||
      data.sentrelDesignKey === "twotoneacrylic3x8black" ||
      data.sentrelDesignKey === "twotoneacrylic3x8gray" ||
      data.sentrelDesignKey === "twotoneacrylic10x20black" ||
      data.sentrelDesignKey === "twotoneacrylic10x20gray"
    ) {
      permitMoldsStandard = false;
    }
  }

  if (wallsfull) {
    

  

    if (x60) {
      wall1.enabled = true;
    viewport.setEnabled(wall1);

        mold1.enabled = permitMoldsStandard;
        viewport.setEnabled(mold1);   
        if (window.isSentrelBuild) {
          if(!permitMoldsStandard){
            wall_mold_square_full.enabled = true;
            viewport.setEnabled(wall_mold_square_full);  
          }
        }
       
    }
    if (x48) {
      wall1_neo.enabled = true;
      viewport.setEnabled(wall1_neo);

        mold3.enabled = permitMoldsStandard;
        viewport.setEnabled(mold3);

        if (window.isSentrelBuild) {
          if(!permitMoldsStandard){
            wall_mold_neo_full.enabled = true;
            viewport.setEnabled(wall_mold_neo_full);  
          }
        }
    }
  
    
  }
  if (walls34) {   

  

    if (x60) {
      wall2.enabled = true;
      viewport.setEnabled(wall2);

        mold2.enabled =   permitMoldsStandard;
        viewport.setEnabled(mold2);

        if (window.isSentrelBuild) {
          if(!permitMoldsStandard){
            wall_mold_square_34.enabled = true;
            viewport.setEnabled(wall_mold_square_34);  
          }
        }
    }
    if (x48) {
      wall2_neo.enabled = true;
      viewport.setEnabled(wall2_neo);

        mold4.enabled =  permitMoldsStandard;
        viewport.setEnabled(mold4);

        if (window.isSentrelBuild) {
          if(!permitMoldsStandard){
            wall_mold_neo_34.enabled = true;
            viewport.setEnabled(wall_mold_neo_34);  
          }
        }
    }
   
  }

}
