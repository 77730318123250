export function useMaterialSceneLightmaps(payload, viewport) {

   

    //shower_floor--------------------------------------------------------------------

    let materialUpdateObject = {};
    materialUpdateObject.name = "shower_floor";
    materialUpdateObject.textures = {};

    materialUpdateObject.textures.lightmapTexture = {};
    materialUpdateObject.textures.lightmapTexture.key ="sentrel_ao";
    materialUpdateObject.textures.lightmapTexture.textureProperties = {};
    materialUpdateObject.textures.lightmapTexture.textureProperties.vScale = -1;
    materialUpdateObject.textures.lightmapTexture.textureProperties.coordinatesIndex = 1;
    materialUpdateObject.textures.lightmapTexture.textureProperties.level = 1;   

    materialUpdateObject.props = {};   
    materialUpdateObject.props.useLightmapAsShadowmap = true;

    viewport.updateMaterial(materialUpdateObject)

    //shower_floor_threshold--------------------------------------------------------------------

    materialUpdateObject = {};
    materialUpdateObject.name = "shower_floor_threshold";
    materialUpdateObject.textures = {};

    materialUpdateObject.textures.lightmapTexture = {};
    materialUpdateObject.textures.lightmapTexture.key ="sentrel_ao";
    materialUpdateObject.textures.lightmapTexture.textureProperties = {};
    materialUpdateObject.textures.lightmapTexture.textureProperties.vScale = -1;
    materialUpdateObject.textures.lightmapTexture.textureProperties.coordinatesIndex = 1;
    materialUpdateObject.textures.lightmapTexture.textureProperties.level = 1;   

    materialUpdateObject.props = {};   
    materialUpdateObject.props.useLightmapAsShadowmap = true;

    viewport.updateMaterial(materialUpdateObject)

    //wall_interior--------------------------------------------------------------------

    materialUpdateObject = {};
    materialUpdateObject.name = "wall_interior";
    materialUpdateObject.textures = {};

    materialUpdateObject.textures.lightmapTexture = {};
    materialUpdateObject.textures.lightmapTexture.key ="sentrel_ao";
    materialUpdateObject.textures.lightmapTexture.textureProperties = {};
    materialUpdateObject.textures.lightmapTexture.textureProperties.vScale = -1;
    materialUpdateObject.textures.lightmapTexture.textureProperties.coordinatesIndex = 1;
    materialUpdateObject.textures.lightmapTexture.textureProperties.level = 1;   

    materialUpdateObject.props = {};   
    materialUpdateObject.props.useLightmapAsShadowmap = true;

    viewport.updateMaterial(materialUpdateObject)

     //wall_interior_mould--------------------------------------------------------------------

     materialUpdateObject = {};
     materialUpdateObject.name = "wall_interior_mould";
     materialUpdateObject.textures = {};
 
     materialUpdateObject.textures.lightmapTexture = {};
     materialUpdateObject.textures.lightmapTexture.key ="sentrel_ao";
     materialUpdateObject.textures.lightmapTexture.textureProperties = {};
     materialUpdateObject.textures.lightmapTexture.textureProperties.vScale = -1;
     materialUpdateObject.textures.lightmapTexture.textureProperties.coordinatesIndex = 1;
     materialUpdateObject.textures.lightmapTexture.textureProperties.level = 1;   
 
     materialUpdateObject.props = {};   
     materialUpdateObject.props.useLightmapAsShadowmap = true;
 
     viewport.updateMaterial(materialUpdateObject)

      //wall_exterior--------------------------------------------------------------------

      materialUpdateObject = {};
      materialUpdateObject.name = "wall_exterior";
      materialUpdateObject.textures = {};
  
      materialUpdateObject.textures.lightmapTexture = {};
      materialUpdateObject.textures.lightmapTexture.key ="wall_exterior_ao";
      materialUpdateObject.textures.lightmapTexture.textureProperties = {};
      materialUpdateObject.textures.lightmapTexture.textureProperties.vScale = -1;
      //materialUpdateObject.textures.lightmapTexture.textureProperties.coordinatesIndex = 1;
     // materialUpdateObject.textures.lightmapTexture.textureProperties.level = 1;   
  
      materialUpdateObject.props = {};   
      materialUpdateObject.props.useLightmapAsShadowmap = true;
  
      viewport.updateMaterial(materialUpdateObject)

}