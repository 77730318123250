import { useButtonInteraction } from "@/scripts/ui/ButtonInteraction";
import { useComponentDisplayData } from "@/scripts/ui/ComponentDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
import { useComponentStylingType1 } from "@/scripts/ui/ComponentStylingType1";
export function useButton1() {
  const data = {};
  data.component = "CofigurableButtonComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  attr.componentData.content = "CofigurableButtonContent1Component";
  attr.componentData.styling = useComponentStylingType1();
  attr.componentData.interaction = useButtonInteraction();
  attr.componentData.display = useComponentDisplayData();
  

  return data;
}
