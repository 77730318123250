class EventNames {
  static MENU_INTERACTION = "MENU_INTERACTION";  
  static CONTENT_SIZING_COMPLETE = "CONTENT_SIZING_COMPLETE";  
  static RESET_CAMERA = "RESET_CAMERA";  
  static TOGGLE_HIGH_RENDERING = "TOGGLE_HIGH_RENDERING";
  static CONTENT_SIZING_BEGIN = "CONTENT_SIZING_BEGIN";
  static PRESET_APPLY = "PRESET_APPLY";
  
}
module.exports =  EventNames;


