import { useSceneEntities } from "@/stores/SceneEntities";

export function useMaterialGlassRain(payload, viewport) {
  const sceneEntities = useSceneEntities();  
  if (!payload.enabled) {
    sceneEntities.configuratorView.viewportComponent.removeMaterialUpdateForSerialization(payload.key);
    return;
}   
sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({key:payload.key});
  //glass_1--------------------------------------------------------------------

 let materialUpdateObject = {};
 materialUpdateObject.name = "glass_1";

 materialUpdateObject.props = {};   
 materialUpdateObject.props.alpha = 0.4;
 materialUpdateObject.colors = {};
 materialUpdateObject.colors.albedoColor ="glass_color_1";

 materialUpdateObject.textures = {};

 materialUpdateObject.textures.metallicTexture = {};
 materialUpdateObject.textures.metallicTexture.key ="glass_rain_rough";
 materialUpdateObject.textures.metallicTexture.textureProperties = {};
 materialUpdateObject.textures.metallicTexture.textureProperties.vScale = -1;

 materialUpdateObject.textures.bumpTexture = {};
 materialUpdateObject.textures.bumpTexture.key ="glass_rain_normal";
 materialUpdateObject.textures.bumpTexture.textureProperties = {};
 materialUpdateObject.textures.bumpTexture.textureProperties.vScale = -1;

 viewport.updateMaterial(materialUpdateObject)
 
}
