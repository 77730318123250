import { defineStore } from "pinia";
export const useAssetPreloader = defineStore("AssetPreloader", {
  state: () => {
    return {
      isLoading: false,
      percent: "",
      label: "",
      labelComplete: "loading complete",
      labelPercentSymbol: "% loading...",
      labelPercentUnknown: "loading...",
      labelPercentComplete: "100%",
      items: new Map(),
      itemsIterator: null,
      intervalID: null,
      timeoutID: null,
    };
  },

  actions: {
    load(item) {    
      this.items.set(item, item);
      if(!this.isLoading){
        this.itemsIterator = this.items.entries();
        this.percent = this.labelPercentUnknown;
      }
      this.isLoading = true;


      //if timeoutID , then it means the preloader is in the hide timing out state , so clear it
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
        this.timeoutID = null;
      }
      //labels must update at a slower rate than percentages for good ux
      if (!this.intervalID) {
        this.label = "";
       
        this.intervalID = setInterval(() => {
          let iterated = this.itemsIterator.next();
          if (iterated.done) {
            //recreate the iterator since there is no way to reset the index
            // also other items were added to the map , not sure if this automatically updates the iterator
            //anyway , i will just catch new items on the next round if not
            this.itemsIterator = this.items.entries();
          } else {
            this.label = iterated.value[1].key;          
          }
        }, 350);
      }
    },

    progress() {     

      this.percent = 0;
      let divisor = 0;
      this.items.forEach((value) => {
        if (value.lengthComputable) {
          this.percent += value.loadPercent;
          divisor++;
        }
      });
      if (divisor > 1) {
        this.percent /= divisor;
        this.percent = (this.percent*100)|0;
        this.percent += this.labelPercentSymbol;
      } else {
        this.percent = this.labelPercentUnknown;
      }
    },
    complete(item) {
     
      //remove item loading progress
      this.items.delete(item);
      //reset the iterator because the map was altered
      this.itemsIterator = this.items.entries();
      if (this.items.size === 0) {
        this.label = this.labelComplete;
        this.percent = this.labelPercentComplete;
        if (this.intervalID) {
          clearInterval(this.intervalID);
          this.intervalID = null;
        }

        if (this.timeoutID) {
          clearTimeout(this.timeoutID);
          this.timeoutID = null;
        }

        this.timeoutID = setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    },
  },
});
