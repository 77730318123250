<template>
  <div class="w-100 d-flex nav-bar-custom navbar-bg-1">
    <ItemContainerLeftRightSlotsComponent>
      <template #left>
        <button class="button-minimal pr-3" @click="toggleMenu">
          <span class="mdi icon nav-color-1 mdi-menu"></span>
        </button>
        <router-link to="/applicationlanding/roomselect" custom v-slot="{ navigate }">
          <button class="button-minimal pr-3" @click="navigate" @keypress.enter="navigate">
            <span class="mdi icon nav-color-1 mdi-home"></span>
          </button>
        </router-link>
      </template>
      <template #right>
        <button class="button-minimal pl-3" @click="showSettings">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">settings</div>
            <span class="mdi icon icon-color-8 mdi-cog-outline"></span>
          </div>
        </button>
        <button class="button-minimal pl-3" @click="showSaveSettings">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">saves</div>
            <span class="mdi icon icon-color-8 mdi-content-save-cog-outline"></span>
          </div>
        </button>
      </template>
    </ItemContainerLeftRightSlotsComponent>
  </div>
 
</template>

<script setup>
import ItemContainerLeftRightSlotsComponent from "@/components/ItemContainerLeftRightSlotsComponent";
const emit = defineEmits(["togglemenu","showsettings","showSaveSettings"]);
function toggleMenu() { 
  emit("togglemenu");
}
function showSettings() {
  emit("showsettings"); 
}
function showSaveSettings() {
  emit("showSaveSettings");
}
</script>

<style scoped>
.nav-bar-custom {
  align-content: center;
  justify-content: flex-start;
  min-height: 48px;
  border-bottom:var(--border-bottom-3)
  /*height: 48px;*/
}
</style>
