import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetDoorsSquare(payload,viewport){
    const sceneEntities = useSceneEntities();

    let kneewall = sceneEntities.getUIData("kneewall");
    let doors_square_1_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_square_1_shower");
    let doors_square_2_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_square_2_shower");
    let doors_square_1_shower_knee = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_square_1_shower_knee");
    let doors_square_2_shower_knee = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_square_2_shower_knee");

    let doors_square_1_shower_ui = sceneEntities.getUIData("doors_square_1_shower");
    let doors_square_2_shower_ui = sceneEntities.getUIData("doors_square_2_shower");

    doors_square_1_shower.enabled = doors_square_1_shower_ui.enabled && !kneewall.enabled;
    doors_square_2_shower.enabled = doors_square_2_shower_ui.enabled && !kneewall.enabled;

    doors_square_1_shower_knee.enabled = doors_square_1_shower_ui.enabled && kneewall.enabled;
    doors_square_2_shower_knee.enabled = doors_square_2_shower_ui.enabled && kneewall.enabled;

    viewport.setEnabled(doors_square_1_shower);
    viewport.setEnabled(doors_square_2_shower);
    viewport.setEnabled(doors_square_1_shower_knee);
    viewport.setEnabled(doors_square_2_shower_knee);

    

}