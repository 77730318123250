import { useSceneEntities } from "@/stores/SceneEntities";
export default class RoomCaliforniaOption1 {
  constructor() {
   
    this.sceneEntities = useSceneEntities();
    this.build();
  }
  build() {
   
   
    let uiBuildData = [];   
    

    this.sceneEntities.uiBuildData = uiBuildData;
  }
  
 
  
}
