import { useRecessWallValidate } from "@/scripts/rooms/actions/corner/RecessWallValidate";
import { useAssetWainscotting } from "@/scripts/rooms/actions/corner/AssetWainscotting";
import { useAssetCeilingPanal } from "@/scripts/rooms/actions/corner/AssetCeilingPanal";
import { useAssetShowerFloor } from "@/scripts/rooms/actions/corner/AssetShowerFloor";
import { useAssetCeilingTrim } from "@/scripts/rooms/actions/corner/AssetCeilingTrim";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/corner/AssetDoorsValidate";
import { useAssetKneewall } from "@/scripts/rooms/actions/corner/AssetKneewall";

export function useAssetShowerX48(payload,viewport){
    useRecessWallValidate(null,viewport);
    useAssetWainscotting(null,viewport);
    useAssetCeilingPanal(null,viewport);
    useAssetShowerFloor(null,viewport);
    useAssetCeilingTrim(null,viewport);
    useAssetDoorsValidate(null,viewport);
    useAssetKneewall(null,viewport);

}