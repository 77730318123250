import { useAssetCornerShelvesValidate } from "@/scripts/rooms/actions/alcove/AssetCornerShelvesValidate";
import { useAssetRecessValidate2 } from "@/scripts/rooms/actions/alcove/AssetRecessValidate2";
import { useAssetFaucetsValidate1 } from "@/scripts/rooms/actions/alcove/AssetFaucetsValidate1";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetThresholdValidate } from "@/scripts/rooms/actions/alcove/AssetThresholdValidate";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsValidate";
import { useSWDValidate1 } from "@/scripts/rooms/actions/general/SWDValidate1";
export function useAssetShowerX48(payload, viewport) {
  const sceneEntities = useSceneEntities();

  useAssetCornerShelvesValidate(null, viewport);
  useAssetRecessValidate2(null, viewport);
  useAssetFaucetsValidate1(null, viewport);
  useSWDValidate1(null, viewport);

  let transformkey = "x48";

  viewport.invalidateTransformForKey("room_collection_left", transformkey);
  viewport.invalidateTransformForKey("room_collection_right", transformkey);
  viewport.invalidateTransformForKey("t17438", transformkey);
  viewport.invalidateTransformForKey("t17464", transformkey);
  viewport.invalidateTransformForKey("t17464i", transformkey);
  viewport.invalidateTransformForKey("t17494i", transformkey);
  viewport.invalidateTransformForKey("t17453", transformkey);
  viewport.invalidateTransformForKey("t27959", transformkey);
  viewport.invalidateTransformForKey("faucet_extras", transformkey);
  viewport.invalidateTransformForKey("accessories_cornerseat", transformkey);
  viewport.invalidateTransformForKey("accessories_showerchair", transformkey);
  viewport.invalidateTransformForKey("accessories_shavestep", transformkey);
  viewport.invalidateTransformForKey("accessories_shavestep_metal", transformkey);
  viewport.invalidateTransformForKey("accessories_showerbench", transformkey);

  let shower_floor_x48 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_floor_x60");
  shower_floor_x48.enabled = payload.enabled;
  viewport.setEnabled(shower_floor_x48);

  let shower_threshold = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_threshold");
  shower_threshold.enabled = payload.enabled;
  viewport.setEnabled(shower_threshold);
  if (payload.enabled) {
    useAssetThresholdValidate(null, viewport);
    useAssetDoorsValidate(null, viewport);
  }

 
}
