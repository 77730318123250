import { useSceneEntities } from "@/stores/SceneEntities";
export function useSWDMaterialCeilingPanal(payload, viewport) {
  
  const sceneEntities = useSceneEntities();

  sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({key:"useSWDMaterialCeilingPanal",data:payload});

  //shower_roof
  let materialUpdateObject = {};
  materialUpdateObject.name = "shower_roof";
  materialUpdateObject.colors = {};
  materialUpdateObject.colors.albedoColor = 'd6d6d6'
  materialUpdateObject.textures = {};
  materialUpdateObject.textures.albedoTexture = {};
  let textureKeyPreferred = payload.textureKey + "_ceilingpanal";
  let texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureKeyPreferred);
  if (!texture) {
    //no preferred key so fall back to default implementation
    textureKeyPreferred = payload.textureKey;
  }
  materialUpdateObject.textures.albedoTexture.key = textureKeyPreferred;
  materialUpdateObject.textures.albedoTexture.textureProperties = {};
  materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
  viewport.updateMaterial(materialUpdateObject);

  //shower_roof_mould
  materialUpdateObject = {};
  materialUpdateObject.name = "shower_roof_mould";
  materialUpdateObject.colors = {};
  materialUpdateObject.colors.albedoColor = 'd6d6d6'
  materialUpdateObject.textures = {};
  materialUpdateObject.textures.albedoTexture = {};
  let textureMoldKeyPreferred = payload.textureMoldKey + "_ceilingpanal_mold";
   texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureMoldKeyPreferred);
   if (!texture) {
     //no preferred key so fall back to default implementation
     textureMoldKeyPreferred = payload.textureMoldKey;
   }
   materialUpdateObject.textures.albedoTexture.key = textureMoldKeyPreferred;
  materialUpdateObject.textures.albedoTexture.textureProperties = {};
  materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
  viewport.updateMaterial(materialUpdateObject);

}