

import { use_two_tone_acrylic_3x8_black } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_3x8_black";
import { use_two_tone_acrylic_3x8_gray } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_3x8_gray";
import { use_two_tone_acrylic_10x20_black } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_10x20_black";
import { use_two_tone_acrylic_10x20_gray } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/two_tone_acrylic_10x20_gray";

import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useAcrylicWallDesigns(injectedRadioGroups) {
  const sceneEntities = useSceneEntities();
 
  const target = useAccordion1();

  target.attributes.componentData.display.label = "Acrylic Walls";
  

  
  
  target.attributes.componentData.key = "awd";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

  


   

  //
  //two_tone_acrylic_3x8_black--------------------------------------------------
  //
  let two_tone_acrylic_3x8_black = use_two_tone_acrylic_3x8_black(injectedRadioGroups);
  targetChildren.push(two_tone_acrylic_3x8_black);
  two_tone_acrylic_3x8_black.attributes.componentData.interaction.radioGroup =
    injectedRadioGroups.sentrelRadioGroup;  
  
  //
  //two_tone_acrylic_3x8_gray--------------------------------------------------
  //
  let two_tone_acrylic_3x8_gray = use_two_tone_acrylic_3x8_gray(injectedRadioGroups);
  targetChildren.push(two_tone_acrylic_3x8_gray);
  two_tone_acrylic_3x8_gray.attributes.componentData.interaction.radioGroup =
    injectedRadioGroups.sentrelRadioGroup;  
    
  //
  //two_tone_acrylic_10x20_black--------------------------------------------------
  //
  let two_tone_acrylic_10x20_black = use_two_tone_acrylic_10x20_black(injectedRadioGroups);
  targetChildren.push(two_tone_acrylic_10x20_black);
  two_tone_acrylic_10x20_black.attributes.componentData.interaction.radioGroup =
    injectedRadioGroups.sentrelRadioGroup;  
    
  //
  //two_tone_acrylic_10x20_gray--------------------------------------------------
  //
  let two_tone_acrylic_10x20_gray = use_two_tone_acrylic_10x20_gray(injectedRadioGroups);
  targetChildren.push(two_tone_acrylic_10x20_gray);
  two_tone_acrylic_10x20_gray.attributes.componentData.interaction.radioGroup =
    injectedRadioGroups.sentrelRadioGroup;   
    
  

  return target;
}
