import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetRecessValidate } from "@/scripts/rooms/actions/alcove/AssetRecessValidate";
import { useAssetCeilingTrim } from "@/scripts/rooms/actions/alcove/AssetCeilingTrim";
import { useAssetCeilingTrimValidate } from "@/scripts/rooms/actions/alcove/AssetCeilingTrimValidate";
import { useAssetSoffitValidate } from "@/scripts/rooms/actions/alcove/AssetSoffitValidate";
import { useAssetCeilingPanelValidate } from "@/scripts/rooms/actions/alcove/AssetCeilingPanelValidate";
import { useAssetSoffitCoverValidate } from "@/scripts/rooms/actions/alcove/AssetSoffitCoverValidate";
import { useAssetAccentValidate1 } from "@/scripts/rooms/actions/alcove/AssetAccentValidate1";
import { useShowerWallValidate } from "@/scripts/rooms/actions/alcove/ShowerWallValidate";
import { useShowerWallMoldValidate } from "@/scripts/rooms/actions/alcove/ShowerWallMoldValidate";

export function useSceneWallHeight(payload, viewport) {
  //dont handle the false call , since this handles true and false for either state
  if (!payload.enabled) return;

  const sceneEntities = useSceneEntities();

  //shower wall validate
  useShowerWallValidate(null, viewport);

  //shower wall mold validate
  useShowerWallMoldValidate(null, viewport);

  //ceiling trim validate
  useAssetCeilingTrimValidate(null, viewport);
  //ceiling trim
  useAssetCeilingTrim(null, viewport);
  //recess validate
  useAssetRecessValidate(null, viewport);

  //soffit
  useAssetSoffitValidate(null, viewport);

  //ceiling panel
  useAssetCeilingPanelValidate(null, viewport);

  ////soffit cover
  useAssetSoffitCoverValidate(null, viewport);

  //accents
  useAssetAccentValidate1(null, viewport);
}
