import { useAssetEnableSimpleInverted } from "@/scripts/rooms/actions/general/AssetEnableSimpleInverted";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsValidate";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetThresholdValidate(payload, viewport) {
    const sceneEntities = useSceneEntities();

    let shower_threshold = sceneEntities.getUIData("shower_threshold");
    useAssetEnableSimpleInverted(shower_threshold, viewport);
    useAssetDoorsValidate(null, viewport);
    
      
   
}