<template>






<div style="display: flex; flex-direction:row-reverse; gap: 10px; flex-wrap: wrap">
  
   
    <div class="card card-settings-1" >
      <div
        class="fs-2 fw-bold text-color-6 p-0 m-0 mb-2 pb-2"
       
        style="white-space: nowrap; border-bottom: 1px solid rgb(76, 76, 76)"
      >
        Application
      </div>
      <div class="d-flex flex-column align-items-end">
        <button class="button-minimal pr-2 mb-2" @click="changeRenderIndex">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">quality</div>
            <span
              class="mdi icon icon-color-8"
              :class="{
                'mdi-lightbulb-outline': theme.renderIndex === 0,
                'mdi-lightbulb-on': theme.renderIndex === 1,
              }"
            ></span>
          </div>
        </button>

        <button class="button-minimal pr-2 mb-2" @click="resetCamera">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">screenshot</div>
            <span class="mdi icon icon-color-8 mdi-camera"></span>
          </div>
        </button>

       

        

        <button class="button-minimal pr-2 mb-2" @click="toggleTheme">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">theme</div>
            <span
              class="mdi icon icon-color-8"
              :class="{
                'mdi-brightness-4': theme.themeKey === 'light',
                'mdi-brightness-7': theme.themeKey === 'dark',
              }"
            ></span>
          </div>
        </button>

       

        <button class="button-minimal pr-2 mb-2" @click="exportScene">
          <div class="d-flex align-items-center">
            <div class="fs-10 fw-bold text-color-6 p-0 mr-1">export preset</div>
            <span class="mdi icon icon-color-8 mdi-export"></span>
          </div>
        </button>
      </div>
    </div>

   
   
  </div>
















     <div class="d-flex flex-column align-items-end">
       
      </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
import { useTheme } from "@/stores/Theme";
import { useSceneEntities } from "@/stores/SceneEntities";
import {useSaveJSONFlatFile} from "@/scripts/utils/SaveJSONFlatFile"
const emit = defineEmits(["resetcamera", "changerenderindex"]);

const route = useRoute();
const is3DViewport = ref(route.query.viewport === "3d" ? true : false);
const router = useRouter();
const theme = useTheme();
const sceneEntities = useSceneEntities();
function on3DCheckbox() {
  router.push({
    path: "/application",
    query: {
      roomoption: route.query.roomoption,
      roomtype: route.query.roomtype,
      viewport: is3DViewport.value ? "3d" : "2d",
    },
  });
}
function exportScene() {
  sceneEntities.serializeScene();
  useSaveJSONFlatFile("preset",sceneEntities.serialization)
}
function resetCamera() {
  emit("resetcamera");
}

function changeRenderIndex() {
  emit("changerenderindex");
}

function toggleTheme() {
  if (theme.themeKey === "light") {
    theme.writeTheme("dark");
    return;
  }
  if (theme.themeKey === "dark") {
    theme.writeTheme("light");
    return;
  }
}
</script>