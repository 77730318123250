function JSUtil() {}

JSUtil.prototype = {
  isObject(value) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  },
  getComputedStyle( elementNode ) {
    var style;
    var returns = {};
    // FireFox and Chrome way
    if(window.getComputedStyle){
        style = window.getComputedStyle(elementNode, null);
        for(let i = 0, l = style.length; i < l; i++){
            var prop = style[i];
            var val = style.getPropertyValue(prop);
            returns[prop] = val;
        }
        return returns;
    }
    // IE and Opera way
    if(elementNode.currentStyle){
        style = elementNode.currentStyle;
        for(let prop in style){
            returns[prop] = style[prop];
        }
        return returns;
    }
    // Style from style attribute
    if(style == elementNode.style){
        for(let prop in style){
            if(typeof style[prop] != 'function'){
                returns[prop] = style[prop];
            }
        }
        return returns;
    }
    return returns;
},

};
var j = new JSUtil();
export default j;
