import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetTubStandard } from "@/scripts/rooms/actions/alcove/AssetTubStandard";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetsShowerRestsValidate } from "@/scripts/rooms/actions/general/AssetsShowerRestsValidate";

import { useAssetShowerX60 } from "@/scripts/rooms/actions/alcove/AssetShowerX60";
import { useAssetShowerX48 } from "@/scripts/rooms/actions/alcove/AssetShowerX48";
import { useAssetShowerX36 } from "@/scripts/rooms/actions/alcove/AssetShowerX36";


export function useAssetTubs(payload, viewport) {
  //dont handle the false call , since this handles true and false for either state
  if (!payload.enabled) return;

  const sceneEntities = useSceneEntities();
  let tub_standard = sceneEntities.getUIData("tub_standard").enabled;
  let tub_soaker = sceneEntities.getUIData("tub_soaker").enabled;
  let walkin2653 = sceneEntities.getUIData("walkin2653").enabled;
  let walkin2852 = sceneEntities.getUIData("walkin2852").enabled;
  let walkin3053 = sceneEntities.getUIData("walkin3053").enabled;

  if (!tub_standard && !tub_soaker && !walkin2653 && !walkin2852 && !walkin3053) {
    let data = sceneEntities.getUIData("tub_standard");
    data.enabled = true;
    let previousSelection = useUIRadioGroup(data, viewport);

    let interactionFunctions = {};
    
    interactionFunctions.x60 = useAssetShowerX60;
    interactionFunctions.x48 = useAssetShowerX48;
    interactionFunctions.x36 = useAssetShowerX36;

    if (previousSelection) {
      let f = interactionFunctions[previousSelection.key];
      if (f) {
        f(previousSelection, viewport);
      }
    }



    useAssetTubStandard(data, viewport);
  }

  useAssetsShowerRestsValidate(null, viewport);
}
