import ConsoleLogAdvanced from "@/scripts/utils/ConsoleLogAdvanced";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSentrelWallDesigns } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/SentrelWallDesigns";
import { useAcrylicWallDesigns } from "@/scripts/ui/configurator_scene_navigation/general/sentrel_wall_designs/AcrylicWallDesigns";

import { useRoom } from "@/scripts/ui/configurator_scene_navigation/alcove/room/Room.js";
import { useBase } from "@/scripts/ui/configurator_scene_navigation/alcove/base/Base.js";
import { useFaucets } from "@/scripts/ui/configurator_scene_navigation/general/faucets/Faucets.js";
import { useAccessories } from "@/scripts/ui/configurator_scene_navigation/alcove/accessories/Accessories.js";
import { useShowerEnclosure } from "@/scripts/ui/configurator_scene_navigation/alcove/shower_enclosure/ShowerEnclosure.js";
import { usePaintColor } from "@/scripts/ui/configurator_scene_navigation/general/paint_color/PaintColor.js";
import { useFloors } from "@/scripts/ui/configurator_scene_navigation/general/floors/Floors.js";
import { useSceneEntities } from "@/stores/SceneEntities";
import { usePresets } from "@/scripts/ui/configurator_scene_navigation/general/presets/Presets.js";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetCornerShelves } from "@/scripts/rooms/actions/general/AssetCornerShelves";
import { useAssetCornerShelvesFinish } from "@/scripts/rooms/actions/alcove/AssetCornerShelvesFinish";
import { useAssetEnableSimple } from "@/scripts/rooms/actions/general/AssetEnableSimple";
import { useAssetCeilingTrim } from "@/scripts/rooms/actions/alcove/AssetCeilingTrim";
import { useAssetRecessRegular } from "@/scripts/rooms/actions/alcove/AssetRecessRegular";
import { useAssetRecessWindow } from "@/scripts/rooms/actions/alcove/AssetRecessWindow";
import { useAssetRecessSoap1 } from "@/scripts/rooms/actions/alcove/AssetRecessSoap1";
import { useAssetRecessSoap2 } from "@/scripts/rooms/actions/alcove/AssetRecessSoap2";
import { useAssetRecessSoap1Window } from "@/scripts/rooms/actions/alcove/AssetRecessSoap1Window";
import { useAssetRecessSoap2Window } from "@/scripts/rooms/actions/alcove/AssetRecessSoap2Window";
import { useAssetRecessSteelShelf1 } from "@/scripts/rooms/actions/alcove/AssetRecessSteelShelf1";
import { useAssetRecessSteelShelf2 } from "@/scripts/rooms/actions/alcove/AssetRecessSteelShelf2";
import { useAssetRecessCustomSoap1 } from "@/scripts/rooms/actions/alcove/AssetRecessCustomSoap1";
import { useAssetRecessCustomSoap2 } from "@/scripts/rooms/actions/alcove/AssetRecessCustomSoap2";
import { useSceneWallHeight } from "@/scripts/rooms/actions/alcove/SceneWallHeight";
import { useAssetTubStandard } from "@/scripts/rooms/actions/alcove/AssetTubStandard";
import { useAssetTubs } from "@/scripts/rooms/actions/alcove/AssetTubs";
import { useAssetShowers } from "@/scripts/rooms/actions/alcove/AssetShowers";

import { useAssetTubSoaker } from "@/scripts/rooms/actions/alcove/AssetTubSoaker";

import { useAssetTub2653 } from "@/scripts/rooms/actions/alcove/AssetTub2653";

import { useAssetTub2653DualJetted } from "@/scripts/rooms/actions/alcove/AssetTub2653DualJetted";
import { useAssetTub2653Hydrotherapy } from "@/scripts/rooms/actions/alcove/AssetTub2653Hydrotherapy";
import { useAssetTub2653Whirlpool } from "@/scripts/rooms/actions/alcove/AssetTub2653Whirlpool";
import { useAssetTub2653Soaker } from "@/scripts/rooms/actions/alcove/AssetTub2653Soaker";

import { useAssetTub2653Door } from "@/scripts/rooms/actions/alcove/AssetTub2653Door";

import { useAssetTub3053 } from "@/scripts/rooms/actions/alcove/AssetTub3053";

import { useAssetTub3053DualJetted } from "@/scripts/rooms/actions/alcove/AssetTub3053DualJetted";
import { useAssetTub3053Hydrotherapy } from "@/scripts/rooms/actions/alcove/AssetTub3053Hydrotherapy";
import { useAssetTub3053Whirlpool } from "@/scripts/rooms/actions/alcove/AssetTub3053Whirlpool";
import { useAssetTub3053Soaker } from "@/scripts/rooms/actions/alcove/AssetTub3053Soaker";

import { useAssetTub3053Door } from "@/scripts/rooms/actions/alcove/AssetTub3053Door";

import { useAssetTub2852 } from "@/scripts/rooms/actions/alcove/AssetTub2852";

import { useAssetTub2852DualJetted } from "@/scripts/rooms/actions/alcove/AssetTub2852DualJetted";
import { useAssetTub2852Hydrotherapy } from "@/scripts/rooms/actions/alcove/AssetTub2852Hydrotherapy";
import { useAssetTub2852Whirlpool } from "@/scripts/rooms/actions/alcove/AssetTub2852Whirlpool";
import { useAssetTub2852Soaker } from "@/scripts/rooms/actions/alcove/AssetTub2852Soaker";

import { useAssetTub2852Door } from "@/scripts/rooms/actions/alcove/AssetTub2852Door";

import { useAssetWainscotting } from "@/scripts/rooms/actions/alcove/AssetWainscotting";
import { useSceneInvertOrientation } from "@/scripts/rooms/actions/alcove/SceneInvertOrientation";

import { useAssetSoffit } from "@/scripts/rooms/actions/alcove/AssetSoffit";

import { useAssetShowerX60 } from "@/scripts/rooms/actions/alcove/AssetShowerX60";
import { useAssetShowerX48 } from "@/scripts/rooms/actions/alcove/AssetShowerX48";
import { useAssetShowerX36 } from "@/scripts/rooms/actions/alcove/AssetShowerX36";

import { useAssetFaucets } from "@/scripts/rooms/actions/general/AssetFaucets";

import { useAssetFaucett17438 } from "@/scripts/rooms/actions/general/AssetFaucett17438";


import { useAssetFaucett17453 } from "@/scripts/rooms/actions/general/AssetFaucett17453";


import { useAssetFaucett17464 } from "@/scripts/rooms/actions/general/AssetFaucett17464";


import { useAssetFaucett17464i } from "@/scripts/rooms/actions/general/AssetFaucett17464i";


import { useAssetFaucett17464iCradleOnly } from "@/scripts/rooms/actions/general/AssetFaucett17464iCradleOnly";
import { useAssetFaucett17464iSideLeft } from "@/scripts/rooms/actions/general/AssetFaucett17464iSideLeft";
import { useAssetFaucett17464iSideRight } from "@/scripts/rooms/actions/general/AssetFaucett17464iSideRight";

import { useAssetFaucett17494i } from "@/scripts/rooms/actions/general/AssetFaucett17494i";


import { useAssetFaucett17494iCradleOnly } from "@/scripts/rooms/actions/general/AssetFaucett17494iCradleOnly";
import { useAssetFaucett17494iSideLeft } from "@/scripts/rooms/actions/general/AssetFaucett17494iSideLeft";
import { useAssetFaucett17494iSideRight } from "@/scripts/rooms/actions/general/AssetFaucett17494iSideRight";

import { useAssetFaucett27959 } from "@/scripts/rooms/actions/general/AssetFaucett27959";


import { useMaterialFloors } from "@/scripts/rooms/actions/general/MaterialFloors";
import { useMaterialWallPaint } from "@/scripts/rooms/actions/general/MaterialWallPaint";

import { useSWDValidate1 } from "@/scripts/rooms/actions/general/SWDValidate1";

import { useAssetAccents } from "@/scripts/rooms/actions/alcove/AssetAccents";
import { useMaterialAccents } from "@/scripts/rooms/actions/general/MaterialAccents";

import { useSWDMaterials } from "@/scripts/rooms/actions/alcove/SWDMaterials";

import { useSWDMaterialWalls } from "@/scripts/rooms/actions/alcove/SWDMaterialWalls";
import { useSWDMaterialWainscotting } from "@/scripts/rooms/actions/general/SWDMaterialWainscotting";
import { useSWDMaterialShowerFloor } from "@/scripts/rooms/actions/general/SWDMaterialShowerFloor";
import { useSWDMaterialCeilingPanal } from "@/scripts/rooms/actions/general/SWDMaterialCeilingPanal";

import { useAssetDoorsBipassValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsBipassValidate";
import { useAssetDoorsBipass2Towel } from "@/scripts/rooms/actions/alcove/AssetDoorsBipass2Towel";
import { useAssetDoorsBipass1Towel } from "@/scripts/rooms/actions/alcove/AssetDoorsBipass1Towel";
import { useAssetDoorsHinge1panel } from "@/scripts/rooms/actions/alcove/AssetDoorsHinge1panel";
import { useAssetDoorsInfinityHinge } from "@/scripts/rooms/actions/alcove/AssetDoorsInfinityHinge";
import { useAssetDoorsBarnStyle } from "@/scripts/rooms/actions/alcove/AssetDoorsBarnStyle";

import { useMaterialGlassClear } from "@/scripts/rooms/actions/general/MaterialGlassClear";
import { useMaterialGlassObscure } from "@/scripts/rooms/actions/general/MaterialGlassObscure";
import { useMaterialGlassRain } from "@/scripts/rooms/actions/general/MaterialGlassRain";



import { useAssetCurtainStraight } from "@/scripts/rooms/actions/alcove/AssetCurtainStraight";
import { useAssetCurtainCurved } from "@/scripts/rooms/actions/alcove/AssetCurtainCurved";

import { useAssetDoorsInvert } from "@/scripts/rooms/actions/alcove/AssetDoorsInvert";

import { useAssetDoorsOpenClose } from "@/scripts/rooms/actions/general/AssetDoorsOpenClose";

import { usePresetAdd } from "@/scripts/rooms/actions/general/PresetAdd";

import { usePresetApply } from "@/scripts/rooms/actions/general/PresetApply";

import { usePresetDelete } from "@/scripts/rooms/actions/general/PresetDelete";
import { usePresetSetDefault } from "@/scripts/rooms/actions/general/PresetSetDefault";
import { usePresetSave } from "@/scripts/rooms/actions/general/PresetSave";
import { useSessionSave } from "@/scripts/rooms/actions/general/SessionSave";

import { useMaterialMetals } from "@/scripts/rooms/actions/general/MaterialMetals";


import { useMaterialWoodColor1 } from "@/scripts/rooms/actions/general/MaterialWoodColor1";


import { useAssetThresholdValidate } from "@/scripts/rooms/actions/alcove/AssetThresholdValidate";

import { useMaterialSceneLightmaps } from "@/scripts/rooms/actions/alcove/MaterialSceneLightmaps";

export default class RoomAlcoveOption1 {
  constructor() {
    this.sceneEntities = useSceneEntities();
    this.dataPaths = {};
    this.interactionFunctions = {};
    this.interactionFunctions.viewportAssetUpdates = {};
    this.interactionFunctions.materialUpdates = {};
    this.interactionFunctions.sceneUpdates = {};

    //shelves corner enabling

    this.interactionFunctions.sceneUpdates.accessories_shelflefttop = useAssetCornerShelves;
    this.interactionFunctions.sceneUpdates.accessories_shelfleftmid = useAssetCornerShelves;
    this.interactionFunctions.sceneUpdates.accessories_shelfleftbottom = useAssetCornerShelves;

    this.interactionFunctions.sceneUpdates.accessories_shelfrighttop = useAssetCornerShelves;
    this.interactionFunctions.sceneUpdates.accessories_shelfrightmid = useAssetCornerShelves;
    this.interactionFunctions.sceneUpdates.accessories_shelfrightbottom = useAssetCornerShelves;

    //shelves corner finish
    this.interactionFunctions.sceneUpdates.corner_shelf_sentrel_material = useAssetCornerShelvesFinish;
    this.interactionFunctions.sceneUpdates.corner_shelf_metal_material = useAssetCornerShelvesFinish;

    //generic enabling
    this.interactionFunctions.viewportAssetUpdates.enableSimple = useAssetEnableSimple;

    //ceiling trim enabling
    this.interactionFunctions.sceneUpdates.accessories_ceilingtrim = useAssetCeilingTrim;

    //recess regular
    this.interactionFunctions.sceneUpdates.accessories_recess_regular = useAssetRecessRegular;

    //recess window
    this.interactionFunctions.sceneUpdates.accessories_recess_window = useAssetRecessWindow;

    //recess soap 1
    this.interactionFunctions.sceneUpdates.accessories_recess_soap1 = useAssetRecessSoap1;

    //recess soap 2
    this.interactionFunctions.sceneUpdates.accessories_recess_soap2 = useAssetRecessSoap2;

    //recess soap 1 window
    this.interactionFunctions.sceneUpdates.accessories_recess_soap1window = useAssetRecessSoap1Window;

    //recess soap 2 window
    this.interactionFunctions.sceneUpdates.accessories_recess_soap2window = useAssetRecessSoap2Window;

    //recess steel shelf 1
    this.interactionFunctions.sceneUpdates.accessories_recess_steelshelf1 = useAssetRecessSteelShelf1;

    //recess steel shelf 2
    this.interactionFunctions.sceneUpdates.accessories_recess_steelshelf2 = useAssetRecessSteelShelf2;

    //recess custom soap 1
    this.interactionFunctions.sceneUpdates.accessories_recess_custom_soap_1 = useAssetRecessCustomSoap1;

    //recess custom soap 2
    this.interactionFunctions.sceneUpdates.accessories_recess_custom_soap_2 = useAssetRecessCustomSoap2;

    //scene wall height
    this.interactionFunctions.sceneUpdates.wallsfull = useSceneWallHeight;
    this.interactionFunctions.sceneUpdates.walls34 = useSceneWallHeight;

    //asset tubs
    this.interactionFunctions.sceneUpdates.tubs = useAssetTubs;
    //asset tubs
    this.interactionFunctions.sceneUpdates.showers = useAssetShowers;

    //asset tub standard
    this.interactionFunctions.sceneUpdates.tub_standard = useAssetTubStandard;

    //asset tub soaker
    this.interactionFunctions.sceneUpdates.tub_soaker = useAssetTubSoaker;

    //walkin2653
    this.interactionFunctions.sceneUpdates.walkin2653 = useAssetTub2653;

    this.interactionFunctions.sceneUpdates.walkin2653dualjetted = useAssetTub2653DualJetted;
    this.interactionFunctions.sceneUpdates.walkin2653hydrotherapy = useAssetTub2653Hydrotherapy;
    this.interactionFunctions.sceneUpdates.walkin2653whirlpool = useAssetTub2653Whirlpool;
    this.interactionFunctions.sceneUpdates.walkin2653soaker = useAssetTub2653Soaker;

    this.interactionFunctions.sceneUpdates.elite_2653_tub_door_open = useAssetTub2653Door;

    //walkin3053
    this.interactionFunctions.sceneUpdates.walkin3053 = useAssetTub3053;

    this.interactionFunctions.sceneUpdates.walkin3053dualjetted = useAssetTub3053DualJetted;
    this.interactionFunctions.sceneUpdates.walkin3053hydrotherapy = useAssetTub3053Hydrotherapy;
    this.interactionFunctions.sceneUpdates.walkin3053whirlpool = useAssetTub3053Whirlpool;
    this.interactionFunctions.sceneUpdates.walkin3053soaker = useAssetTub3053Soaker;

    this.interactionFunctions.sceneUpdates.elite_3053_tub_door_open = useAssetTub3053Door;

    //walkin2852
    this.interactionFunctions.sceneUpdates.walkin2852 = useAssetTub2852;

    this.interactionFunctions.sceneUpdates.walkin2852dualjetted = useAssetTub2852DualJetted;
    this.interactionFunctions.sceneUpdates.walkin2852hydrotherapy = useAssetTub2852Hydrotherapy;
    this.interactionFunctions.sceneUpdates.walkin2852whirlpool = useAssetTub2852Whirlpool;
    this.interactionFunctions.sceneUpdates.walkin2852soaker = useAssetTub2852Soaker;

    this.interactionFunctions.sceneUpdates.elite_2852_tub_door_open = useAssetTub2852Door;

    //wainscotting
    this.interactionFunctions.sceneUpdates.wainscotting = useAssetWainscotting;

    //invertorientation
    this.interactionFunctions.sceneUpdates.invertorientation = useSceneInvertOrientation;

    //soffit
    this.interactionFunctions.sceneUpdates.soffit = useAssetSoffit;

    //soffit
    this.interactionFunctions.sceneUpdates.x60 = useAssetShowerX60;
    this.interactionFunctions.sceneUpdates.x48 = useAssetShowerX48;
    this.interactionFunctions.sceneUpdates.x36 = useAssetShowerX36;

    //faucets
    this.interactionFunctions.sceneUpdates.faucets = useAssetFaucets;
    this.interactionFunctions.sceneUpdates.allmetals = useMaterialMetals;
    //faucet t17438
    this.interactionFunctions.sceneUpdates.t17438 = useAssetFaucett17438;
    //faucet t17453
    this.interactionFunctions.sceneUpdates.t17453 = useAssetFaucett17453;
    //faucet t17464
    this.interactionFunctions.sceneUpdates.t17464 = useAssetFaucett17464;
    //faucet t17464i
    this.interactionFunctions.sceneUpdates.t17464i = useAssetFaucett17464i;
    //faucet t17464i objects
    this.interactionFunctions.sceneUpdates.t17464i_cradleonly = useAssetFaucett17464iCradleOnly;
    this.interactionFunctions.sceneUpdates.t17464i_sidebarleft = useAssetFaucett17464iSideLeft;
    this.interactionFunctions.sceneUpdates.t17464i_sidebarright = useAssetFaucett17464iSideRight;
    //faucet t17494i
    this.interactionFunctions.sceneUpdates.t17494i = useAssetFaucett17494i;
    //faucet t17494i objects
    this.interactionFunctions.sceneUpdates.t17494i_cradleonly = useAssetFaucett17494iCradleOnly;
    this.interactionFunctions.sceneUpdates.t17494i_sidebarleft = useAssetFaucett17494iSideLeft;
    this.interactionFunctions.sceneUpdates.t17494i_sidebarright = useAssetFaucett17494iSideRight;
    //faucet t27959
    this.interactionFunctions.sceneUpdates.t27959 = useAssetFaucett27959;

   

    //floors

    this.interactionFunctions.sceneUpdates.wood_delacy = useMaterialFloors;
    this.interactionFunctions.sceneUpdates.wood_logden = useMaterialFloors;
    this.interactionFunctions.sceneUpdates.wood_silver_spurr = useMaterialFloors;
    this.interactionFunctions.sceneUpdates.wood_windsong_oak = useMaterialFloors;

    //wall paint
    this.interactionFunctions.sceneUpdates.paint_colors = useMaterialWallPaint;

    //swd_design
    this.interactionFunctions.sceneUpdates.swd_design = useSWDValidate1;


     //wood_color_1
     this.interactionFunctions.sceneUpdates.wood_color_1 = useMaterialWoodColor1;
    

    //accents_assets
    this.interactionFunctions.sceneUpdates.accents_assets = useAssetAccents;

    //accents_materials
    this.interactionFunctions.sceneUpdates.accents_materials = useMaterialAccents;

    //swd_materials
    this.interactionFunctions.sceneUpdates.swd_materials = useSWDMaterials;

    //swd_material apply to ..
    //useSWDMaterials will use these internally at runtime
    //but on loading serialized data , processSerializedMaterialUpdates function will map that data to them here
    this.interactionFunctions.sceneUpdates.useSWDMaterialWalls = useSWDMaterialWalls;
    this.interactionFunctions.sceneUpdates.useSWDMaterialWainscotting = useSWDMaterialWainscotting;
    this.interactionFunctions.sceneUpdates.useSWDMaterialShowerFloor = useSWDMaterialShowerFloor;
    this.interactionFunctions.sceneUpdates.useSWDMaterialCeilingPanal = useSWDMaterialCeilingPanal;

    this.interactionFunctions.sceneUpdates.doors_bipass = useAssetDoorsBipassValidate;
    this.interactionFunctions.sceneUpdates.biPassBasco = useAssetDoorsBipass2Towel;
    this.interactionFunctions.sceneUpdates.biPassOriginal = useAssetDoorsBipass1Towel;
    this.interactionFunctions.sceneUpdates.showerenclosure_doors_tuscon = useAssetDoorsHinge1panel;
    this.interactionFunctions.sceneUpdates.infinity_hinge = useAssetDoorsInfinityHinge;
    this.interactionFunctions.sceneUpdates.showerenclosure_doors_rotolo_barn = useAssetDoorsBarnStyle;

    this.interactionFunctions.sceneUpdates.showerenclosure_glass_clear = useMaterialGlassClear;
    this.interactionFunctions.sceneUpdates.showerenclosure_glass_obscure = useMaterialGlassObscure;
    this.interactionFunctions.sceneUpdates.showerenclosure_glass_rain = useMaterialGlassRain;    

    this.interactionFunctions.sceneUpdates.showerenclosure_straight = useAssetCurtainStraight;
    this.interactionFunctions.sceneUpdates.showerenclosure_curved = useAssetCurtainCurved;

    this.interactionFunctions.sceneUpdates.shower_doors_invert = useAssetDoorsInvert;

    this.interactionFunctions.sceneUpdates.shower_doors_open = useAssetDoorsOpenClose;

    this.interactionFunctions.sceneUpdates.preset_add_shared = usePresetAdd;

    this.interactionFunctions.sceneUpdates.preset_selection = usePresetApply;

    this.interactionFunctions.sceneUpdates.preset_delete = usePresetDelete;
    this.interactionFunctions.sceneUpdates.preset_set_default = usePresetSetDefault;
    this.interactionFunctions.sceneUpdates.preset_save = usePresetSave;


    this.interactionFunctions.sceneUpdates.session_save_shared = useSessionSave;

    this.interactionFunctions.sceneUpdates.shower_threshold = useAssetThresholdValidate;

    

    

    this.consoleLogAdvanced = new ConsoleLogAdvanced();
  }
  processSerializedMaterialUpdates() {
 // console.log("processSerializedMaterialUpdates")
//console.log(this.sceneEntities.configuratorView.viewportComponent.dataCollectionMaterials)
    for (let prop in this.sceneEntities.configuratorView.viewportComponent.dataCollectionMaterials) {
      // try map key to a ui item , if not found then try map to a scene update function directly
      let payload = this.sceneEntities.getUIData(prop);
      if (payload) {
        this.processInteraction(payload);
      } else {
        let f = this.interactionFunctions.sceneUpdates[prop];
        if (f) {
         
          payload = this.sceneEntities.configuratorView.viewportComponent.dataCollectionMaterials[prop];
          f(payload, this.sceneEntities.configuratorView.viewportComponent);
        }
      }
    }
  }
  processInteraction(payload) {
  
    ///
    let previousSelection = useUIRadioGroup(payload);
    //if previousSelection is valid then first handle it and then the new selection afterwards
    if (previousSelection) {
     
      if (previousSelection.key !== payload.key) {
        this.processActions(previousSelection);
      }
    }
    this.processActions(payload);
  }
  processActions(payload) {
   
   
   
    if (payload.interaction.actions.viewport_asset_update) {
     
      this.interactionFunctions.viewportAssetUpdates.enableSimple(
        payload,
        this.sceneEntities.configuratorView.viewportComponent
      );
    }

    if (payload.interaction.actions.scene_update) {
      let f = this.interactionFunctions.sceneUpdates[payload.key];
      if (f) {
      
        f(payload, this.sceneEntities.configuratorView.viewportComponent);
        return;
      }
      
      //now check if function is stored via shared key
      f = this.interactionFunctions.sceneUpdates[payload.sharedKey];
      if (f) {
      
        f(payload, this.sceneEntities.configuratorView.viewportComponent);
        return;
      }

    
    }
  }
  async build() {
    let uiBuildData = [];
    let rootRadioGroup = useButtonRadioGroup();



    let injectedRadioGroups = {};
    injectedRadioGroups.swdApplyAllSurfacesRadioGroup = useButtonRadioGroup();
    injectedRadioGroups.swdApplyInteriorWallsRadioGroup = useButtonRadioGroup();
    injectedRadioGroups.swdApplyWainscottingRadioGroup = useButtonRadioGroup();
    injectedRadioGroups.swdApplyShowerFloorRadioGroup = useButtonRadioGroup();
    injectedRadioGroups.swdApplyCeilingPanelRadioGroup = useButtonRadioGroup();
    injectedRadioGroups.swdAccentEnableRadioGroup = useButtonRadioGroup();
    injectedRadioGroups.swdAccentSelectRadioGroup = useButtonRadioGroup();
    injectedRadioGroups.sentrelRadioGroup = useButtonRadioGroup();

    //Room--------------------------------------------------
    let room = useRoom();
    uiBuildData.push(room);
    room.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    //Base--------------------------------------------------
    let base = useBase();
    uiBuildData.push(base);
    base.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    //Sentrel Wall Designs--------------------------------------------------

    let swd = useSentrelWallDesigns(injectedRadioGroups);
    uiBuildData.push(swd);
    swd.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    //Faucets--------------------------------------------------
    let faucets = useFaucets();
    uiBuildData.push(faucets);
    faucets.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    //Accessories--------------------------------------------------
    let accessories = useAccessories();
    uiBuildData.push(accessories);
    accessories.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    //Shower Enclosure--------------------------------------------------
    let showerEnclosure = useShowerEnclosure();
    uiBuildData.push(showerEnclosure);
    showerEnclosure.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    //Paint Color--------------------------------------------------
    let paintColor = usePaintColor();
    uiBuildData.push(paintColor);
    paintColor.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    if (!window.isSentrelBuild) {
     //Acrylic Wall Designs--------------------------------------------------

     let awd = useAcrylicWallDesigns(injectedRadioGroups);
     uiBuildData.push(awd);
     awd.attributes.componentData.interaction.radioGroup = rootRadioGroup;
    }

    //Floors--------------------------------------------------
    let floors = useFloors();
    uiBuildData.push(floors);
    floors.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    //Presets--------------------------------------------------
    let presets = usePresets();
    uiBuildData.push(presets);
    presets.attributes.componentData.interaction.radioGroup = rootRadioGroup;

    this.sceneEntities.uiBuildData = uiBuildData;

    useMaterialSceneLightmaps(null, this.sceneEntities.configuratorView.viewportComponent);



   
  }
 
}
