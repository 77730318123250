import { useSceneEntities } from "@/stores/SceneEntities";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";

import { useAssetFaucett17464iCradleOnly } from "@/scripts/rooms/actions/general/AssetFaucett17464iCradleOnly";
import { useAssetFaucett17464iSideLeft } from "@/scripts/rooms/actions/general/AssetFaucett17464iSideLeft";
import { useAssetFaucett17464iSideRight } from "@/scripts/rooms/actions/general/AssetFaucett17464iSideRight";
import { useMaterialMetalsFromFaucetUI } from "@/scripts/rooms/actions/general/MaterialMetalsFromFaucetUI";
export function useAssetFaucett17464i(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let showers = sceneEntities.getUIData("showers").enabled;

  //handle child assets first
  //to allow system to create viewport asset ahead of actual asset load

  let t17464i_control = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464i_control");
  t17464i_control.disabled = payload.disabled;
  t17464i_control.enabled = payload.enabled;
  viewport.setEnabled(t17464i_control);
  viewport.invalidateTransformForKey("t17464i_control", "showers");

  let t17464i_head = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464i_head");
  t17464i_head.disabled = payload.disabled;
  t17464i_head.enabled = payload.enabled;
  viewport.setEnabled(t17464i_head);

  let t17464i_spout = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464i_spout");
  //spout also validates if showers are selected
  t17464i_spout.disabled = payload.disabled;
  t17464i_spout.enabled = payload.enabled && !showers;
  viewport.setEnabled(t17464i_spout);

  //now handle main asset

  let t17464i = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464i");
  t17464i.disabled = payload.disabled;
  t17464i.enabled = payload.enabled;
  viewport.setEnabled(t17464i);

 

  //object options
  let t17464i_cradleonly = sceneEntities.getUIData("t17464i_cradleonly");
  let t17464i_sidebarleft = sceneEntities.getUIData("t17464i_sidebarleft");
  let t17464i_sidebarright = sceneEntities.getUIData("t17464i_sidebarright");

  if (!t17464i_cradleonly.enabled && !t17464i_sidebarleft.enabled && !t17464i_sidebarright.enabled) {
    t17464i_cradleonly.disabled = payload.disabled;
    t17464i_cradleonly.enabled = true;
    useUIRadioGroup(t17464i_cradleonly, viewport);
    useAssetFaucett17464iCradleOnly(t17464i_cradleonly, viewport);
  }

  //when asset enabled is false , deselect any of these object options
  if (!payload.enabled) {
    t17464i_cradleonly.enabled = false;
    t17464i_sidebarleft.enabled = false;
    t17464i_sidebarright.enabled = false;
  }

  t17464i_cradleonly.disabled = payload.disabled;
  useAssetFaucett17464iCradleOnly(t17464i_cradleonly, viewport);

  t17464i_sidebarleft.disabled = payload.disabled;
  useAssetFaucett17464iSideLeft(t17464i_sidebarleft, viewport);

  t17464i_sidebarright.disabled = payload.disabled;
  useAssetFaucett17464iSideRight(t17464i_sidebarright, viewport);


  useMaterialMetalsFromFaucetUI(null,viewport)
}
