import { useButtonInteraction } from "@/scripts/ui/ButtonInteraction";
import { useComponentDisplayData } from "@/scripts/ui/ComponentDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
import { useComponentStylingType2 } from "@/scripts/ui/ComponentStylingType2";
export function useButton2() {
  const data = {};
  data.component = "CofigurableButtonComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  attr.componentData.content = "CofigurableButtonContent1Component";
  attr.componentData.styling = useComponentStylingType2();
  attr.componentData.interaction = useButtonInteraction();
  attr.componentData.display = useComponentDisplayData();
  

  return data;
}
