<template>  
   <ConfiguratorView ref="thisComponent"></ConfiguratorView>  
</template>

<script setup>
import { useSceneEntities } from "@/stores/SceneEntities";
import ConfiguratorView from '@/views/ConfiguratorView.vue'
import {  onMounted, ref } from "vue";
let thisComponent = ref(null);
const sceneEntities = useSceneEntities();
onMounted(async () => {
   
    sceneEntities.configuratorView = thisComponent.value;
   
});

</script>