import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetGrabbarsValidate } from "@/scripts/rooms/actions/corner/AssetGrabbarsValidate";
import { useAssetAccentValidate1 } from "@/scripts/rooms/actions/corner/AssetAccentValidate1";
export function useAssetRecessSteelShelf2(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;
  let x48 = sceneEntities.getUIData("x48").enabled;
  let asset = null;

  let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full_shelf_2");
  let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34_shelf_2");
  let wall1_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "wall_interior_center_full_shelf_2_neo"
  );
  let wall2_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "wall_interior_center_34_shelf_2_neo"
  );

  if (wallsfull) {
    if (!x48) {
      wall1.enabled = payload.enabled;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);

      //remove neo
      wall1_neo.enabled = false;
      viewport.setEnabled(wall1_neo);

      wall2_neo.enabled = false;
      viewport.setEnabled(wall2_neo);
    } else {
      wall1_neo.enabled = payload.enabled;
      viewport.setEnabled(wall1_neo);

      wall2_neo.enabled = false;
      viewport.setEnabled(wall2_neo);

      //remove full
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
  }
  if (walls34) {
    if (!x48) {
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = payload.enabled;
      viewport.setEnabled(wall2);

      //remove neo
      wall1_neo.enabled = false;
      viewport.setEnabled(wall1_neo);

      wall2_neo.enabled = false;
      viewport.setEnabled(wall2_neo);
    } else {
      wall1_neo.enabled = false;
      viewport.setEnabled(wall1_neo);

      wall2_neo.enabled = payload.enabled;
      viewport.setEnabled(wall2_neo);

      //remove full
      wall1.enabled = false;
      viewport.setEnabled(wall1);

      wall2.enabled = false;
      viewport.setEnabled(wall2);
    }
  }

  let asset2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_steelshelf2");

  asset2.enabled = payload.enabled;
  viewport.setEnabled(asset2);
  viewport.invalidateTransformForKey("accessories_steelshelf2", "x48");

  useAssetGrabbarsValidate(null, viewport);
  useAssetAccentValidate1(null, viewport);
}
