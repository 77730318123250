import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetDoorsGlassValidate } from "@/scripts/rooms/actions/general/AssetDoorsGlassValidate";
import { useAlcoveValidate1 } from "@/scripts/rooms/actions/alcove/AlcoveValidate1";
import { useAssetDoorsInvert } from "@/scripts/rooms/actions/alcove/AssetDoorsInvert";
export function useAssetDoorsHinge1panel(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let x60 = sceneEntities.getUIData("x60");
  let x48 = sceneEntities.getUIData("x48");

  let shower_threshold = sceneEntities.getUIData("shower_threshold");

  // let doors_x60_rotolo_barn_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_x60_rotolo_barn_shower");

  let doors_x60_tuscon_shower =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_x60_tuscon_shower");
  let doors_x48_tuscon_shower =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_x48_tuscon_shower");

  //just disable all at first
  doors_x60_tuscon_shower.enabled = false;
  viewport.setEnabled(doors_x60_tuscon_shower);

  doors_x48_tuscon_shower.enabled = false;
  viewport.setEnabled(doors_x48_tuscon_shower);

  //since this can be used from direct ui selections of the bi pass original ui
  //or or for validation from other selections like showers or tubs , payload could be null
  // so retrieve the payload manually..

  let uiItem = sceneEntities.getUIData("showerenclosure_doors_tuscon");
  if ((x60.enabled || x48.enabled)  && !shower_threshold.enabled) {
    uiItem.disabled = false;
  } else {
    uiItem.disabled = true;
  }

  // new logic if disabled then set enabled to false
  if (uiItem.enabled && uiItem.disabled) {
    uiItem.enabled = false;
  }

  if (uiItem.enabled && !uiItem.disabled) {
    if (x60.enabled) {
      doors_x60_tuscon_shower.enabled = true;
      viewport.setEnabled(doors_x60_tuscon_shower);
    }
    if (x48.enabled) {
      doors_x48_tuscon_shower.enabled = true;
      viewport.setEnabled(doors_x48_tuscon_shower);
    }
  }

  useAssetDoorsGlassValidate(null, viewport);
  useAssetDoorsInvert(null, viewport);
  useAlcoveValidate1(null, viewport);
}
