import { useSceneEntities } from "@/stores/SceneEntities";
export function useAccessoriesCeilingNavValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let soffitCoverUI = sceneEntities.getUIData("soffit_cover");
  let ceilingPanelUI = sceneEntities.getUIData("accessories_ceilingpanel");
  let ceilingTrimUI = sceneEntities.getUIData("accessories_ceilingtrim");

  let divider_ceiling = sceneEntities.getUIData("divider_ceiling");
  divider_ceiling.disabled = false;
  if (soffitCoverUI.disabled && ceilingPanelUI.disabled && ceilingTrimUI.disabled) {
    divider_ceiling.disabled = true;
  }
}
