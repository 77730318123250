import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetDoorsSquare } from "@/scripts/rooms/actions/corner/AssetDoorsSquare";
import { useAssetDoorsNeo } from "@/scripts/rooms/actions/corner/AssetDoorsNeo";

export function useAssetDoorsValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let x60 = sceneEntities.getUIData("x60");
  let x48 = sceneEntities.getUIData("x48");

  let doors_square_1_shower_ui = sceneEntities.getUIData("doors_square_1_shower");
  let doors_square_2_shower_ui = sceneEntities.getUIData("doors_square_2_shower");

  let doors_neo_1_shower_ui = sceneEntities.getUIData("doors_neo_1_shower");
  let doors_neo_2_shower_ui = sceneEntities.getUIData("doors_neo_2_shower");

  if (!x60.enabled) {
    doors_square_1_shower_ui.enabled = false;
    doors_square_2_shower_ui.enabled = false;

    doors_square_1_shower_ui.disabled = true;
    doors_square_2_shower_ui.disabled = true;
  } else {
    doors_square_1_shower_ui.disabled = false;
    doors_square_2_shower_ui.disabled = false;
  }

  useAssetDoorsSquare(null, viewport);

  if (!x48.enabled) {
    doors_neo_1_shower_ui.enabled = false;
    doors_neo_2_shower_ui.enabled = false;

    doors_neo_1_shower_ui.disabled = true;
    doors_neo_2_shower_ui.disabled = true;
  } else {
    doors_neo_1_shower_ui.disabled = false;
    doors_neo_2_shower_ui.disabled = false;
  }

  useAssetDoorsNeo(null, viewport);
}
