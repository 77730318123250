export function useSaveJSONFlatFile(fileName, data) {
  const replacer = (key, value) => (typeof value === "undefined" ? null : value);
  var textToSave = JSON.stringify(data, replacer, 2);
  var textToSaveAsBlob = new Blob([textToSave], {
    type: "application/json",
    endings: "native",
  });
  var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
  var fileNameToSaveAs = fileName;

  var downloadLink = document.createElement("a");
  downloadLink.download = fileNameToSaveAs;
  downloadLink.innerHTML = "Download File";
  downloadLink.href = textToSaveAsURL;
  downloadLink.onclick = (event) => document.body.removeChild(event.target);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
}
