import { defineStore } from "pinia";
import { ref } from "vue";
export const useToasts = defineStore("Toasts", {
  state (){return {
   
        message: "test error message",
    type: "success",
    display:false,
    timeOutID:0
   
    }
  },
 
  actions: {
    dismiss(){
clearTimeout(this.timeOutID);
this.display = false;
    },

    setErrorMessage( payload) {
        this.type = "error";
        this.message = payload;
        this.display = true;
        this.timeOutID = setTimeout(()=>{this.display = false;},20000)
      
      },
      setSuccessMessage( payload) {
        this.type = "success";
        this.message = payload;
        this.display = true;
        this.timeOutID =setTimeout(()=>{this.display = false;},5000)
       
      },
  
  },
});
