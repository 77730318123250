import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetShowerFloor(payload,viewport){

    const sceneEntities = useSceneEntities();

    let shower_floor_corner_x60 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_floor_corner_x60");
    let shower_floor_corner_neo = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_floor_corner_neo");
   
    let x60 = sceneEntities.getUIData("x60");
    let x48 = sceneEntities.getUIData("x48");

    shower_floor_corner_x60.enabled = x60.enabled;
    shower_floor_corner_neo.enabled = x48.enabled;
   
    viewport.setEnabled(shower_floor_corner_x60);
    viewport.setEnabled(shower_floor_corner_neo);
  
}