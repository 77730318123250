import { useSceneEntities } from "@/stores/SceneEntities";
import { usePresetsBuild } from "@/scripts/rooms/actions/general/PresetsBuild";

export function useSessionSave(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let presetCurrentUI = sceneEntities.getUIData(payload.key);

  sceneEntities.addPreset();
  sceneEntities.savePresetsSession();
  usePresetsBuild();
}
