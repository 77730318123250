import { useAssetCornerShelvesValidate } from "@/scripts/rooms/actions/alcove/AssetCornerShelvesValidate";
import { useAssetRecessValidate2 } from "@/scripts/rooms/actions/alcove/AssetRecessValidate2";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetFaucetsValidate1 } from "@/scripts/rooms/actions/alcove/AssetFaucetsValidate1";
import { useAssetThresholdValidate } from "@/scripts/rooms/actions/alcove/AssetThresholdValidate";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsValidate";
import { useSWDValidate1 } from "@/scripts/rooms/actions/general/SWDValidate1";
export function useAssetShowerX60(payload, viewport) {
  const sceneEntities = useSceneEntities();

  useAssetCornerShelvesValidate(null, viewport);
  useAssetRecessValidate2(null, viewport);
  useAssetFaucetsValidate1(null, viewport);
  useSWDValidate1(null, viewport);

  let shower_floor_x60 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_floor_x60");
  shower_floor_x60.enabled = payload.enabled;
  viewport.setEnabled(shower_floor_x60);

  let shower_threshold = sceneEntities.configuratorView.viewportComponent.getViewportAsset("shower_threshold");
  shower_threshold.enabled = payload.enabled;
  viewport.setEnabled(shower_threshold);

  if (payload.enabled) {
    useAssetThresholdValidate(null, viewport);
    useAssetDoorsValidate(null, viewport);
  }
}
