import { useSceneEntities } from "@/stores/SceneEntities";
export function useMaterialMetals(payload, viewport) {
  const sceneEntities = useSceneEntities();

 
  let colorKey = payload.colorKey;

  sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({
    key: "allmetals",
    data: { colorKey:payload.colorKey },
  });

  //sync metal ui
  let uiNames = [];
  //these get disabled
  uiNames.push("metal_shared_1_chrome");
  uiNames.push("metal_shared_1_brushed_nickel");
  uiNames.push("metal_shared_1_oil_bronze");
  uiNames.push("metal_shared_1_black");

  uiNames.push("faucet_chrome");
  uiNames.push("faucet_brushed_nickel");
  uiNames.push("faucet_oil_bronze");
  uiNames.push("faucet_black");

  uiNames.forEach((element) => {
    let ui = sceneEntities.getUIData(element);
    ui.enabled = false;
  });

  let uiNames2 = [];
  //these get enabled
  uiNames2.push("metal_shared_1_" + colorKey);
  uiNames2.push("faucet_" + colorKey);

  uiNames2.forEach((element) => {
    let ui = sceneEntities.getUIData(element);
    ui.enabled = true;
  });

  //just disable all to start , then enable the valid ones

  //faucet ui
  let t17438 = sceneEntities.getUIData("t17438");
  let t17453 = sceneEntities.getUIData("t17453");
  let t17464 = sceneEntities.getUIData("t17464");
  let t17464i = sceneEntities.getUIData("t17464i");
  let t17494i = sceneEntities.getUIData("t17494i");
  let t27959 = sceneEntities.getUIData("t27959");

  let roughnessSuffix = colorKey === "black" ? "roughness_2" : "roughness_1";
  let roughnessValue = colorKey === "black" ? 0.7 : 0.2;

  //find out which faucet is selected..

  let faucetString = null;
  if (t17438.enabled) {
    faucetString = "t17438";
  }
  if (t17453.enabled) {
    faucetString = "t17453";
  }
  if (t17464.enabled) {
    faucetString = "t17464";
  }

  if (t17464i.enabled) {
    faucetString = "t17464i";
  }

  if (t17494i.enabled) {
    faucetString = "t17494i";
  }

  if (t27959.enabled) {
    //must update t17438 and t17464i as well as this faucet uses three different faucet materials
    faucetString = ["t57014", "t17438", "t17464i"];
  }

  //update faucet textures ..
  function updateFaucetMaterials(key) {
    //faucet_cable--------------------------------------------------------------------

    let materialUpdateObject = {};
    materialUpdateObject.name = "faucet_cable";
    materialUpdateObject.props = {};
    materialUpdateObject.props.roughness = roughnessValue;
    materialUpdateObject.colors = {};
    materialUpdateObject.colors.albedoColor = colorKey;

    viewport.updateMaterial(materialUpdateObject);

    //faucet_untextured--------------------------------------------------------------------

    materialUpdateObject = {};
    materialUpdateObject.name = "faucet_untextured";
    materialUpdateObject.props = {};
    materialUpdateObject.props.roughness = roughnessValue;
    materialUpdateObject.colors = {};
    materialUpdateObject.colors.albedoColor = colorKey;

    viewport.updateMaterial(materialUpdateObject);

    //faucet_[key]_textured--------------------------------------------------------------------

    materialUpdateObject = {};
    materialUpdateObject.name = "faucet_" + key + "_textured";
    materialUpdateObject.textures = {};

    materialUpdateObject.textures.albedoTexture = {};
    materialUpdateObject.textures.albedoTexture.key = "faucet_" + key + "_" + colorKey + "_diffuse";
    materialUpdateObject.textures.albedoTexture.textureProperties = {};
    materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;

    materialUpdateObject.textures.bumpTexture = {};
    materialUpdateObject.textures.bumpTexture.key = "faucet_" + key + "_normal";
    materialUpdateObject.textures.bumpTexture.textureProperties = {};
    materialUpdateObject.textures.bumpTexture.textureProperties.vScale = -1;

    materialUpdateObject.textures.ambientTexture = {};
    materialUpdateObject.textures.ambientTexture.key = "faucet_" + key + "_ao";
    materialUpdateObject.textures.ambientTexture.textureProperties = {};
    materialUpdateObject.textures.ambientTexture.textureProperties.vScale = -1;

    materialUpdateObject.textures.roughnessTexture = {};
    materialUpdateObject.textures.roughnessTexture.key = "faucet_" + key + "_" + roughnessSuffix;
    materialUpdateObject.textures.roughnessTexture.textureProperties = {};
    materialUpdateObject.textures.roughnessTexture.textureProperties.vScale = -1;

    viewport.updateMaterial(materialUpdateObject);
  }

  if (Array.isArray(faucetString)) {
    faucetString.forEach((element) => updateFaucetMaterials(element));
  } else {
    updateFaucetMaterials(faucetString);
  }

  //metal_shared_1--------------------------------------------------------------------
  let materialUpdateObject = {};
  materialUpdateObject.name = "metal_shared_1";
  materialUpdateObject.props = {};
  materialUpdateObject.props.roughness = roughnessValue;
  materialUpdateObject.colors = {};
  materialUpdateObject.colors.albedoColor = colorKey;
  viewport.updateMaterial(materialUpdateObject);
}
