import { computed } from 'vue'
import { useGrid } from "vue-screen";

export function useIsSmallerScreens() {
    const grid = useGrid("bootstrap");
    const isSmallerScreens = computed(() => {
        return (
         grid.breakpoint === 'xs' || grid.breakpoint === 'sm' || grid.breakpoint === 'md'
        );
      });

 
  return isSmallerScreens
}