import { useSceneEntities } from "@/stores/SceneEntities";

export function useAlcoveValidate1(payload, viewport) {
  //when a color option ui is selected payload is valid
  //when this is called for validation purposes , payload will be null
  if (payload) {
    if (!payload.enabled) {
      return;
    }
  }
  const sceneEntities = useSceneEntities(); 

  let showerenclosure_doors_rotolo_barn = sceneEntities.getUIData("showerenclosure_doors_rotolo_barn");
  let showerenclosure_doors_tuscon = sceneEntities.getUIData("showerenclosure_doors_tuscon");
  let biPassBasco = sceneEntities.getUIData("biPassBasco");
  let biPassOriginal = sceneEntities.getUIData("biPassOriginal");
  let infinity_hinge = sceneEntities.getUIData("infinity_hinge");

  let showerenclosure_curved = sceneEntities.getUIData("showerenclosure_curved");
  let showerenclosure_straight = sceneEntities.getUIData("showerenclosure_straight");

  let shower_doors_open = sceneEntities.getUIData("shower_doors_open");
  let shower_doors_invert = sceneEntities.getUIData("shower_doors_invert");

  if (
    (!biPassBasco.enabled || biPassBasco.disabled) &&
    (!biPassOriginal.enabled || biPassOriginal.disabled) &&
    (!infinity_hinge.enabled || infinity_hinge.disabled) &&
    (!showerenclosure_doors_rotolo_barn.enabled || showerenclosure_doors_rotolo_barn.disabled) &&
    (!showerenclosure_doors_tuscon.enabled || showerenclosure_doors_tuscon.disabled) 
  ) {
    shower_doors_open.disabled = true;
    // new logic if disabled then set enabled to false
    shower_doors_open.enabled = false;

    let divider_alcove_door_options = sceneEntities.getUIData("divider_alcove_door_options");
    divider_alcove_door_options.disabled = false;
    if (shower_doors_open.disabled && shower_doors_invert.disabled) {
      divider_alcove_door_options.disabled = true;
    }
  }
  if (
    (!showerenclosure_curved.enabled || showerenclosure_curved.disabled) &&
    (!showerenclosure_straight.enabled || showerenclosure_straight.disabled)
  ) {
    shower_doors_open.disabled = false;
  }
  
  let divider_alcove_door_options = sceneEntities.getUIData("divider_alcove_door_options");
  divider_alcove_door_options.disabled = false;
  if (shower_doors_open.disabled && shower_doors_invert.disabled) {
    divider_alcove_door_options.disabled = true;
  }
}
