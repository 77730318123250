import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetEnableSimple } from "@/scripts/rooms/actions/general/AssetEnableSimple";
import { useAssetCornerShelvesValidate } from "@/scripts/rooms/actions/alcove/AssetCornerShelvesValidate";
import { useAssetRecessValidate2 } from "@/scripts/rooms/actions/alcove/AssetRecessValidate2";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetFaucetsValidate1 } from "@/scripts/rooms/actions/alcove/AssetFaucetsValidate1";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsValidate";
import { useSWDValidate1 } from "@/scripts/rooms/actions/general/SWDValidate1";
export function useAssetTubSoaker(payload, viewport) {
  
 

  //is false then no need to validate default child ui selections..
  if (!payload.enabled){
    useAssetEnableSimple(payload, viewport);
    return;
  } 

  useAssetCornerShelvesValidate(null, viewport);
  useAssetRecessValidate2(null, viewport);

  const sceneEntities = useSceneEntities();


//calling to get the asset before it is loaded will cause the viewport
  //asset to be created , which has the enabled property set to false

  //then when the main asset is loaded and child assets are looped
  //if it finds the viewport asset already exists
  //it will not set enabled to true, this effectively hides the children
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("tub_soaker_panal_1");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("tub_soaker_panal_2");



  let tub_soaker_panal_1 = sceneEntities.getUIData("tub_soaker_panal_1").enabled;
  let tub_soaker_panal_2 = sceneEntities.getUIData("tub_soaker_panal_2").enabled;

  if (!tub_soaker_panal_1 && !tub_soaker_panal_2) {
    let data = sceneEntities.getUIData("tub_soaker_panal_1");
    data.enabled = true;
    useUIRadioGroup(data,viewport)
    useAssetEnableSimple(data, viewport);
  }

  useAssetEnableSimple(payload, viewport);
  useAssetFaucetsValidate1(null, viewport);
  useAssetDoorsValidate(null, viewport);
  useSWDValidate1(null, viewport);
 
}
