import { useSceneEntities } from "@/stores/SceneEntities";
import { useAccessoriesCeilingNavValidate } from "@/scripts/rooms/actions/alcove/AccessoriesCeilingNavValidate";

export function useAssetCeilingTrimValidate(payload, viewport) {
   

  const sceneEntities = useSceneEntities();
  let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
  let walls34 = sceneEntities.getUIData("walls34").enabled;
  let ceilingTrimUI = sceneEntities.getUIData("accessories_ceilingtrim");

  if (wallsfull) {    
    ceilingTrimUI.disabled = false;
    viewport.invalidateTransformForKey("wall_inner_mould_roof_center","wallsfull")
    viewport.invalidateTransformForKey("wall_inner_mould_roof_left","wallsfull")
    viewport.invalidateTransformForKey("wall_inner_mould_roof_right","wallsfull")
  }
  if (walls34) {
    ceilingTrimUI.disabled = true;
    viewport.invalidateTransformForKey("wall_inner_mould_roof_center","walls34")
    viewport.invalidateTransformForKey("wall_inner_mould_roof_left","walls34")
    viewport.invalidateTransformForKey("wall_inner_mould_roof_right","walls34")
  }

  useAccessoriesCeilingNavValidate(null,viewport);

 


 
  
}
