<template>
    <div v-if="assetPreloader.isLoading" style="background-color:#000;position:absolute;top:0;right:0;width:fit-content;" class="p-2 my-1">
    <ItemContainerLeftRightSlotsComponent>
      <template #left>
        <div class="text-type-8 text-color-5">{{ assetPreloader.label }}</div>
      </template>
      <template #right>       
        <div class="text-type-8 text-color-5">{{ assetPreloader.percent }}</div>
      </template>
    </ItemContainerLeftRightSlotsComponent>
</div>
</template>
<script setup>
    import ItemContainerLeftRightSlotsComponent from "@/components/ItemContainerLeftRightSlotsComponent";
    import {useAssetPreloader} from "@/stores/AssetPreloader";
    const assetPreloader = useAssetPreloader();

</script>
