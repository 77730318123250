
export function useComponentDisplayData() {
   
    const data = {
        label:null,
        thumb:null,
       
          };

 
  return data;
}