import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetEnableSimple } from "@/scripts/rooms/actions/general/AssetEnableSimple";
import { useAssetTub2653DualJetted } from "@/scripts/rooms/actions/alcove/AssetTub2653DualJetted";
import { useAssetRecessValidate2 } from "@/scripts/rooms/actions/alcove/AssetRecessValidate2";
import { useAssetCornerShelvesValidate } from "@/scripts/rooms/actions/alcove/AssetCornerShelvesValidate";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetFaucetsValidate1 } from "@/scripts/rooms/actions/alcove/AssetFaucetsValidate1";
import { useAssetDoorsValidate } from "@/scripts/rooms/actions/alcove/AssetDoorsValidate";
import { useSWDValidate1 } from "@/scripts/rooms/actions/general/SWDValidate1";
export function useAssetTub2653(payload, viewport) {
 
  const sceneEntities = useSceneEntities();

  //is false then no need to validate default child ui selections..
  if (!payload.enabled) {
    useAssetEnableSimple(payload, viewport);

    return;
  }

  useAssetRecessValidate2(null, viewport);
  useAssetCornerShelvesValidate(null, viewport);

  //calling to get the asset before it is loaded will cause the viewport
  //asset to be created , which has the enabled property set to false

  //then when the main asset is loaded and child assets are looped
  //if it finds the viewport asset already exists
  //it will not set enabled to true, this effectively hides the children
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_2653_jets_hydro");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_2653_jets_whirlpool");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_2653_shower_head_docked");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_2653_sliderbar");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_2653_button_1");
  sceneEntities.configuratorView.viewportComponent.getViewportAsset("elite_2653_button_2");
  

  let walkin2653dualjetted = sceneEntities.getUIData("walkin2653dualjetted").enabled;
  let walkin2653hydrotherapy = sceneEntities.getUIData("walkin2653hydrotherapy").enabled;
  let walkin2653whirlpool = sceneEntities.getUIData("walkin2653whirlpool").enabled;
  let walkin2653soaker = sceneEntities.getUIData("walkin2653soaker").enabled;

  if (!walkin2653dualjetted && !walkin2653hydrotherapy && !walkin2653whirlpool && !walkin2653soaker) {
    
    let data = sceneEntities.getUIData("walkin2653dualjetted");
    data.enabled = true;
    useUIRadioGroup(data, viewport);
    useAssetTub2653DualJetted(data, viewport);
  }

  let elite_2653_shower_head_docked = sceneEntities.getUIData("elite_2653_shower_head_docked").enabled;
  let elite_2653_sliderbar = sceneEntities.getUIData("elite_2653_sliderbar").enabled;

  if (!elite_2653_shower_head_docked && !elite_2653_sliderbar) {
    let data = sceneEntities.getUIData("elite_2653_shower_head_docked");
    data.enabled = true;
    useUIRadioGroup(data, viewport);
    useAssetEnableSimple(data, viewport);
  }

  useAssetEnableSimple(payload, viewport);
  useAssetFaucetsValidate1(null, viewport);
  useAssetDoorsValidate(null, viewport);
  useSWDValidate1(null, viewport);
  
}
