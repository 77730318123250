import { useSceneEntities } from "@/stores/SceneEntities";
import { useMaterialMetalsFromFaucetUI } from "@/scripts/rooms/actions/general/MaterialMetalsFromFaucetUI";


export function useAssetFaucett17438(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let showers = sceneEntities.getUIData("showers").enabled;

  //handle child assets first
  //to allow system to create viewport asset ahead of actual asset load

  let t17438_control = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17438_control");
  t17438_control.disabled = payload.disabled;
  t17438_control.enabled = payload.enabled;
  viewport.setEnabled(t17438_control);
  viewport.invalidateTransformForKey("t17438_control", "showers");

  let t17438_head = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17438_head");
  t17438_head.disabled = payload.disabled;
  t17438_head.enabled = payload.enabled;
  viewport.setEnabled(t17438_head);

  let t17438_spout = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17438_spout");
  t17438_spout.disabled = payload.disabled;
  //spout also validates if showers are selected
  t17438_spout.enabled = payload.enabled && !showers;
  viewport.setEnabled(t17438_spout);

  //now handle main asset

  let t17438 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17438");
  t17438.disabled = payload.disabled;
  t17438.enabled = payload.enabled;
  viewport.setEnabled(t17438);

  useMaterialMetalsFromFaucetUI(null,viewport)
 

 
}
