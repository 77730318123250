import { useSceneEntities } from "@/stores/SceneEntities";
export function useSWDMaterialShowerFloor(payload, viewport) {
  const sceneEntities = useSceneEntities();

  sceneEntities.configuratorView.viewportComponent.storeMaterialUpdateForSerialization({key:"useSWDMaterialShowerFloor",data:payload});


    //shower_floor
    let materialUpdateObject = {};
    materialUpdateObject.name = "shower_floor";
    materialUpdateObject.colors = {};
    materialUpdateObject.colors.albedoColor = 'd6d6d6'
    materialUpdateObject.textures = {};
    materialUpdateObject.textures.albedoTexture = {};
    let textureKeyPreferred = payload.textureKey + "_showerfloor";
  let texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureKeyPreferred);
  if (!texture) {
    //no preferred key so fall back to default implementation
    textureKeyPreferred = payload.textureKey;
  }
  materialUpdateObject.textures.albedoTexture.key = textureKeyPreferred;
    materialUpdateObject.textures.albedoTexture.textureProperties = {};
    materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
    viewport.updateMaterial(materialUpdateObject);

    //shower_floor_threshold
    materialUpdateObject = {};
    materialUpdateObject.name = "shower_floor_threshold";
    materialUpdateObject.colors = {};
    materialUpdateObject.colors.albedoColor = 'd6d6d6'
    materialUpdateObject.textures = {};
    materialUpdateObject.textures.albedoTexture = {};
    let textureMoldKeyPreferred = payload.textureMoldKey + "_showerfloor_mold";
    texture = sceneEntities.configuratorView.viewportComponent.getTexture(textureMoldKeyPreferred);
    if (!texture) {
      //no preferred key so fall back to default implementation
      textureMoldKeyPreferred = payload.textureMoldKey;
    }
    materialUpdateObject.textures.albedoTexture.key = textureMoldKeyPreferred;
    materialUpdateObject.textures.albedoTexture.textureProperties = {};
    materialUpdateObject.textures.albedoTexture.textureProperties.vScale = -1;
    viewport.updateMaterial(materialUpdateObject);

}