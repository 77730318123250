import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useStandard } from "@/scripts/ui/configurator_scene_navigation/alcove/base/tubs/Standard";
import { useSoaker } from "@/scripts/ui/configurator_scene_navigation/alcove/base/tubs/Soaker";
import { useWalkin2852 } from "@/scripts/ui/configurator_scene_navigation/alcove/base/tubs/Walkin2852";
import { useWalkin2653 } from "@/scripts/ui/configurator_scene_navigation/alcove/base/tubs/Walkin2653";
import { useWalkin3053 } from "@/scripts/ui/configurator_scene_navigation/alcove/base/tubs/Walkin3053";
import { useSceneEntities } from "@/stores/SceneEntities";

export function useTubs(baseChildRadioGroup) {
  const sceneEntities = useSceneEntities();
 
  const target = useAccordion2();
  target.attributes.componentData.display.label = "Tubs";
  target.attributes.componentData.key = "tubs";  
  target.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(target.attributes.componentData);
 

  let tubStandard = useStandard();
  target.attributes.componentData.addChild(tubStandard);
  tubStandard.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  tubStandard.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  let tubSoaker = useSoaker();
  target.attributes.componentData.addChild(tubSoaker);
  tubSoaker.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  tubSoaker.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  let walkIn2852 = useWalkin2852();
  target.attributes.componentData.addChild(walkIn2852);
  walkIn2852.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  walkIn2852.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  let walkin2653 = useWalkin2653();
  target.attributes.componentData.addChild(walkin2653);
  walkin2653.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  walkin2653.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  let walkin3053 = useWalkin3053();
  target.attributes.componentData.addChild(walkin3053);
  walkin3053.attributes.componentData.interaction.radioGroup = baseChildRadioGroup;
  walkin3053.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  return target;
}
