<template>
  <div class="d-flex flex-wrap justify-content-center"
  :class="componentData.styling.classStringContainerDefault"
  >

<component
      v-for="(item, index) in componentData.children"
      :key="keyref+ '_child_' + index"
      :keyref="keyref+ '_child_' + index"
      :is="item.component"
      @select="onChildSelect"
      v-bind="item.attributes"
    >
    </component>

  </div>
</template>

<script setup>
const emit = defineEmits(["select"]);
const props = defineProps({
  componentData: {
    type: Object,
    default: () => {
      return;
    },
  },
  keyref:{
    type:String,
    default:""
  }
});

function onChildSelect(payload,event) {
  emit("select", payload,event);
 
}

</script>

<style>

</style>