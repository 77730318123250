import { useSceneEntities } from "@/stores/SceneEntities";
import { useMaterialMetals } from "@/scripts/rooms/actions/general/MaterialMetals";
export function useMaterialMetalsFromFaucetUI(payload,viewport){
    const sceneEntities = useSceneEntities();

    let faucet_chrome = sceneEntities.getUIData("faucet_chrome");
    let faucet_brushed_nickel = sceneEntities.getUIData("faucet_brushed_nickel");
    let faucet_oil_bronze = sceneEntities.getUIData("faucet_oil_bronze");
    let faucet_black = sceneEntities.getUIData("faucet_black");

    if(faucet_chrome.enabled){
        useMaterialMetals({colorKey:"chrome"},viewport)
    }
    if(faucet_brushed_nickel.enabled){
        useMaterialMetals({colorKey:"brushed_nickel"},viewport)
    }
    if(faucet_oil_bronze.enabled){
        useMaterialMetals({colorKey:"oil_bronze"},viewport)
    }
    if(faucet_black.enabled){
        useMaterialMetals({colorKey:"black"},viewport)
    }
}