export function useViewportFactory(viewportKey) {
  var viewport = null;
  switch(viewportKey){
    case "2d" :
        viewport = "Viewport2D"
        break;
        case "3d" :
            viewport = "Viewport3D"
            break;

  }

  return viewport;
}
