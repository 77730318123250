import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetTub2852Soaker(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let assets = ["elite_2852_button_1", "elite_2852_button_2", "elite_2852_jets_whirlpool", "elite_2852_jets_hydro"];

  assets.forEach((value) => {
    let asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(value);
    asset.enabled = false;
    viewport.setEnabled(asset);
  });
}
