import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetCornerShelves(payload, viewport) {
  // need to hide or show shelves , need to know if they metal or sentrel
  const sceneEntities = useSceneEntities();
  let useSentrelShelves = sceneEntities.getUIData("corner_shelf_sentrel_material").enabled;
  let key = payload.key;
  if (!useSentrelShelves) {
    key += "_metal";
  }
  let asset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(key);
  if (asset) {
    asset.enabled = payload.enabled;
    viewport.setEnabled(asset);
  }
}
