import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetDoorsGlassValidate } from "@/scripts/rooms/actions/general/AssetDoorsGlassValidate";
import { useAlcoveValidate1 } from "@/scripts/rooms/actions/alcove/AlcoveValidate1";
import { useAssetDoorsInvert } from "@/scripts/rooms/actions/alcove/AssetDoorsInvert";
export function useAssetDoorsBipass1Towel(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let tubs = sceneEntities.getUIData("tubs");
  let tub_standard = sceneEntities.getUIData("tub_standard");
  let tub_soaker = sceneEntities.getUIData("tub_soaker");
  let x60 = sceneEntities.getUIData("x60");
  let x48 = sceneEntities.getUIData("x48");

  let shower_threshold = sceneEntities.getUIData("shower_threshold");

  // let doors_x60_bipass_original_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset("doors_x60_bipass_original_shower");

  let doors_x60_bipass_original_tub_standard = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "doors_x60_bipass_original_tub_standard"
  );
  let doors_x60_bipass_original_tub_soaker = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "doors_x60_bipass_original_tub_soaker"
  );
  let doors_x60_bipass_original_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "doors_x60_bipass_original_shower"
  );
  let doors_x48_bipass_original_shower = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "doors_x48_bipass_original_shower"
  );

  //just disable all at first
  doors_x60_bipass_original_tub_standard.enabled = false;
  viewport.setEnabled(doors_x60_bipass_original_tub_standard);

  doors_x60_bipass_original_tub_soaker.enabled = false;
  viewport.setEnabled(doors_x60_bipass_original_tub_soaker);

  doors_x60_bipass_original_shower.enabled = false;
  viewport.setEnabled(doors_x60_bipass_original_shower);

  doors_x48_bipass_original_shower.enabled = false;
  viewport.setEnabled(doors_x48_bipass_original_shower);

  //since this can be used from direct ui selections of the bi pass original ui
  //or or for validation from other selections like showers or tubs , payload could be null
  // so retrieve the payload manually..
  let doors_bipass = sceneEntities.getUIData("doors_bipass");
  let biPassBasco = sceneEntities.getUIData("biPassBasco");
  let biPassOriginal = sceneEntities.getUIData("biPassOriginal");

  if (tubs.enabled) {
    if (tub_standard.enabled || tub_soaker.enabled) {
      biPassOriginal.disabled = false;
    } else {
      biPassOriginal.disabled = true;
    }
  } else {
    if ((x60.enabled || x48.enabled) && !shower_threshold.enabled) {
      biPassOriginal.disabled = false;
    } else {
      biPassOriginal.disabled = true;
    }
  }

  doors_bipass.disabled = false;
  if (biPassBasco.disabled && biPassOriginal.disabled) {
    doors_bipass.disabled = true;
  }

  // new logic if disabled then set enabled to false
  if (biPassOriginal.enabled && biPassOriginal.disabled) {
    biPassOriginal.enabled = false;
  }

  if (biPassOriginal.enabled && !biPassOriginal.disabled) {
    if (tub_standard.enabled) {
      doors_x60_bipass_original_tub_standard.enabled = true;
      viewport.setEnabled(doors_x60_bipass_original_tub_standard);
    }
    if (tub_soaker.enabled) {
      doors_x60_bipass_original_tub_soaker.enabled = true;
      viewport.setEnabled(doors_x60_bipass_original_tub_soaker);
    }
    if (x60.enabled) {
      doors_x60_bipass_original_shower.enabled = true;
      viewport.setEnabled(doors_x60_bipass_original_shower);
    }
    if (x48.enabled) {
      doors_x48_bipass_original_shower.enabled = true;
      viewport.setEnabled(doors_x48_bipass_original_shower);
    }
  }

  useAssetDoorsGlassValidate(null, viewport);
  useAssetDoorsInvert(null, viewport);
  useAlcoveValidate1(null, viewport);
}
