<template>
  <div class="toast-container-custom" >
    <div
      class="shadow rounded toast-content p-3 pt-1"
      :class="{
        'bg-error': toastType === 'error',
        'bg-success': toastType === 'success',
      }"
    >
      <div class="fw-bolder fs-1" style="line-height: normal">
        {{ toastType }}
      </div>
      <div class="fs-6">
        {{ toastMessage }}
      </div>
       <b-button variant="outline-light" class="dismiss button-minimal p-2 px-4 mt-4 text-uppercase fs-4 fw-bolder text-white" @click="toasts.dismiss" >dismiss</b-button>
    </div>
  </div>
</template>

<script setup>
import { computed, watch ,onMounted} from "vue";
import { useToasts } from "@/stores/Toasts";
import { gsap } from "gsap";
const toasts = useToasts();



const toastMessage = computed(() => {
  return toasts.message;
});

const toastType = computed(() => {
  return toasts.type;
});

const display = computed(() => {
  return toasts.display;
});


onMounted(()=>{
gsap.set(".toast-container-custom",{autoAlpha:0});
})



watch(display, (newDisplay, prevDisplay) => {
 if(newDisplay){
gsap.to(".toast-container-custom",{autoAlpha:1})
 } else{
gsap.to(".toast-container-custom",{autoAlpha:0})
 }
 
});
</script>

<style scoped>

.dismiss:hover {
  background-color: #ffffff2d;
  color: white;
}
.toast-content {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  border:4px solid #ffffff;
}
.toast-container-custom {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 20px;
  z-index: 10;
  color: #fff; 
}
.bg-error {
  background-color: brown;
}
.bg-success {
  background-color: darkgreen;
}
.shadow {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 40%) !important;
}
.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa38 !important;
}
</style>
