import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useSceneEntities } from "@/stores/SceneEntities";
export function usePaintColor() {
  let colorRadioGroup = useButtonRadioGroup();
  let colorGroupRadioGroup = useButtonRadioGroup();
  let colorGroupSetRadioGroup = useButtonRadioGroup();
  const sceneEntities = useSceneEntities();
  let target = useAccordion1();
  target.attributes.componentData.display.label = "Paint Color";
  target.attributes.componentData.key = "paintcolor";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

  let group;
  let groupChildren;
  let groupSet1;
  let groupSet2;
  let groupSet3;
  let groupSet1Children;
  let groupSet2Children;
  let groupSet3Children;
  let flexContainer1;
  let flexContainer1Children;
  let option;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "red";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_red";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "red high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_red_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6561 Teaberry";
  option.attributes.componentData.display.color = "#ebd1db";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ebd1db";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6568 Lighthearted Pink";
  option.attributes.componentData.display.color = "#edd5dd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edd5dd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6575 Priscilla";
  option.attributes.componentData.display.color = "#f1d3da";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f1d3da";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6582 Impatiens Petal";
  option.attributes.componentData.display.color = "#f1d2d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f1d2d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6589 Alyssum";
  option.attributes.componentData.display.color = "#f2d5d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2d5d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6596 Bella Pink";
  option.attributes.componentData.display.color = "#f1c6c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f1c6c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6603 Oleander";
  option.attributes.componentData.display.color = "#f2ccc5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2ccc5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6562 Irresistible";
  option.attributes.componentData.display.color = "#e3c0cf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3c0cf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6569 Childlike";
  option.attributes.componentData.display.color = "#e8c0cf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8c0cf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6576 Azalea Flower";
  option.attributes.componentData.display.color = "#efc0cb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_efc0cb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6583 In the Pink";
  option.attributes.componentData.display.color = "#f0bcc9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0bcc9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6590 Loveable";
  option.attributes.componentData.display.color = "#f0c1c6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0c1c6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6597 Hopeful";
  option.attributes.componentData.display.color = "#f0b3b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0b3b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6604 Youthful Coral";
  option.attributes.componentData.display.color = "#f0afa8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0afa8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6563 Rosebay";
  option.attributes.componentData.display.color = "#cb9aad";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cb9aad";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6570 Haute Pink";
  option.attributes.componentData.display.color = "#d899b1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d899b1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6577 Jaipur Pink";
  option.attributes.componentData.display.color = "#e392a1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e392a1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6584 Cheery";
  option.attributes.componentData.display.color = "#eb92a3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eb92a3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6591 Amaryllis";
  option.attributes.componentData.display.color = "#ed939d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ed939d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6598 Dishy Coral";
  option.attributes.componentData.display.color = "#ed9190";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ed9190";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6605 Charisma";
  option.attributes.componentData.display.color = "#ee9489";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ee9489";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6564 Red Clover";
  option.attributes.componentData.display.color = "#b87e93";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b87e93";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6571 Cyclamen";
  option.attributes.componentData.display.color = "#c47b95";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c47b95";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6578 Tuberose";
  option.attributes.componentData.display.color = "#d47c8c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d47c8c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6585 Coming up Roses";
  option.attributes.componentData.display.color = "#dd7788";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dd7788";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6592 Grenadine";
  option.attributes.componentData.display.color = "#d66972";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d66972";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6599 Begonia";
  option.attributes.componentData.display.color = "#d76c6e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d76c6e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6606 Coral Reef";
  option.attributes.componentData.display.color = "#d9766c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d9766c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6565 Grandeur Plum";
  option.attributes.componentData.display.color = "#92576f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_92576f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6572 Ruby Shade";
  option.attributes.componentData.display.color = "#a2566f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a2566f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6579 Gala Pink";
  option.attributes.componentData.display.color = "#b04b63";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b04b63";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6860 Eros Pink";
  option.attributes.componentData.display.color = "#c84f68";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c84f68";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6593 Coral Bells";
  option.attributes.componentData.display.color = "#bb4b51";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bb4b51";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6600 Enticing Red";
  option.attributes.componentData.display.color = "#b74e4f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b74e4f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6607 Red Tomato";
  option.attributes.componentData.display.color = "#b24743";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b24743";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6841 Dynamo";
  option.attributes.componentData.display.color = "#953d68";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_953d68";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6573 Juneberry";
  option.attributes.componentData.display.color = "#854158";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_854158";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6843 Hot";
  option.attributes.componentData.display.color = "#ac4362";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ac4362";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6586 Heartfelt";
  option.attributes.componentData.display.color = "#bd4c5f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bd4c5f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7588 Show Stopper";
  option.attributes.componentData.display.color = "#a42e37";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a42e37";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6601 Tanager";
  option.attributes.componentData.display.color = "#a43834";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a43834";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6608 Rave Red";
  option.attributes.componentData.display.color = "#a13b34";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a13b34";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6842 Forward Fuchsia";
  option.attributes.componentData.display.color = "#92345b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_92345b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6566 Framboise";
  option.attributes.componentData.display.color = "#7c3655";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7c3655";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6580 Cerise";
  option.attributes.componentData.display.color = "#99324e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_99324e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6587 Valentine";
  option.attributes.componentData.display.color = "#a53a4e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a53a4e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6594 Poinsettia";
  option.attributes.componentData.display.color = "#9d373c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9d373c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7587 Antique Red";
  option.attributes.componentData.display.color = "#9f4442";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9f4442";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7600 Bolero";
  option.attributes.componentData.display.color = "#903934";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_903934";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "red medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_red_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6288 Rosebud";
  option.attributes.componentData.display.color = "#e0cdd1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0cdd1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6295 Demure";
  option.attributes.componentData.display.color = "#e8d4d5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8d4d5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6302 Innocence";
  option.attributes.componentData.display.color = "#ebd1cf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ebd1cf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6309 Charming Pink";
  option.attributes.componentData.display.color = "#edd3d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edd3d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6316 Rosy Outlook";
  option.attributes.componentData.display.color = "#ebcecb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ebcecb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6323 Romance";
  option.attributes.componentData.display.color = "#ebcfc3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ebcfc3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6330 Quaint Peche";
  option.attributes.componentData.display.color = "#eacdc1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eacdc1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6289 Delightful";
  option.attributes.componentData.display.color = "#d2b6be";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d2b6be";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6296 Fading Rose";
  option.attributes.componentData.display.color = "#dabdc1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dabdc1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6303 Rose Colored";
  option.attributes.componentData.display.color = "#dcb6b5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcb6b5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6310 Lotus Flower";
  option.attributes.componentData.display.color = "#e6bdbd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6bdbd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6317 Gracious Rose";
  option.attributes.componentData.display.color = "#e3b7b1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3b7b1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6324 Mellow Coral";
  option.attributes.componentData.display.color = "#e3b5a8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3b5a8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6331 Smoky Salmon";
  option.attributes.componentData.display.color = "#e2b6a7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2b6a7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6290 Rosé";
  option.attributes.componentData.display.color = "#b995a1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b995a1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6297 Rose Embroidery";
  option.attributes.componentData.display.color = "#c79ea2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c79ea2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6304 Pressed Flower";
  option.attributes.componentData.display.color = "#c39393";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c39393";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6311 Memorable Rose";
  option.attributes.componentData.display.color = "#cf8a8d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cf8a8d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6318 Resounding Rose";
  option.attributes.componentData.display.color = "#cd8e89";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cd8e89";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6325 Constant Coral";
  option.attributes.componentData.display.color = "#cd8e7f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cd8e7f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6332 Coral Island";
  option.attributes.componentData.display.color = "#ce9382";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ce9382";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6291 Moss Rose";
  option.attributes.componentData.display.color = "#9e6d79";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e6d79";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9001 Audrey's Blush";
  option.attributes.componentData.display.color = "#ae8087";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ae8087";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9002 Carley's Rose";
  option.attributes.componentData.display.color = "#a87376";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a87376";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9003 Rita's Rouge";
  option.attributes.componentData.display.color = "#ba7176";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ba7176";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9004 Coral Rose";
  option.attributes.componentData.display.color = "#c37f7a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c37f7a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9005 Coral Clay";
  option.attributes.componentData.display.color = "#bf796e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bf796e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9006 Rojo Dust";
  option.attributes.componentData.display.color = "#b57466";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b57466";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6292 Berry Bush";
  option.attributes.componentData.display.color = "#8d5869";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8d5869";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6298 Concerto";
  option.attributes.componentData.display.color = "#9e6b75";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e6b75";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6305 Rambling Rose";
  option.attributes.componentData.display.color = "#995d62";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_995d62";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6312 Redbud";
  option.attributes.componentData.display.color = "#ad5e65";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ad5e65";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6319 Reddish";
  option.attributes.componentData.display.color = "#b56966";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b56966";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6326 Henna Shade";
  option.attributes.componentData.display.color = "#b3675d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b3675d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6333 Foxy";
  option.attributes.componentData.display.color = "#a85e53";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a85e53";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6293 Fabulous Grape";
  option.attributes.componentData.display.color = "#6d344f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6d344f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6299 Aged Wine";
  option.attributes.componentData.display.color = "#895460";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_895460";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6306 Cordial";
  option.attributes.componentData.display.color = "#864c52";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_864c52";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6313 Kirsch Red";
  option.attributes.componentData.display.color = "#974953";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_974953";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6320 Bravado Red";
  option.attributes.componentData.display.color = "#a0524e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a0524e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6327 Bold Brick";
  option.attributes.componentData.display.color = "#a0584f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a0584f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6334 Flower Pot";
  option.attributes.componentData.display.color = "#8f4438";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8f4438";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7577 Blackberry";
  option.attributes.componentData.display.color = "#533640";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_533640";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6300 Burgundy";
  option.attributes.componentData.display.color = "#63333e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_63333e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6307 Fine Wine";
  option.attributes.componentData.display.color = "#723941";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_723941";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6314 Luxurious Red";
  option.attributes.componentData.display.color = "#863a42";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_863a42";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6321 Red Bay";
  option.attributes.componentData.display.color = "#8e3738";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8e3738";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6328 Fireweed";
  option.attributes.componentData.display.color = "#7b3730";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7b3730";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6335 Fired Brick";
  option.attributes.componentData.display.color = "#83382a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_83382a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "red low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_red_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6022 Breathless";
  option.attributes.componentData.display.color = "#d6c2be";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6c2be";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6029 White Truffle";
  option.attributes.componentData.display.color = "#d7c8c2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7c8c2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6043 Unfussy Beige";
  option.attributes.componentData.display.color = "#d6c8c0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6c8c0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6050 Abalone Shell";
  option.attributes.componentData.display.color = "#dbc7bd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbc7bd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6057 Malted Milk";
  option.attributes.componentData.display.color = "#decabd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_decabd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6064 Reticence";
  option.attributes.componentData.display.color = "#d9cdc3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d9cdc3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6085 Simplify Beige";
  option.attributes.componentData.display.color = "#d6c7b9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6c7b9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6023 Insightful Rose";
  option.attributes.componentData.display.color = "#c9b0ab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c9b0ab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6030 Artistic Taupe";
  option.attributes.componentData.display.color = "#c3b1ac";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c3b1ac";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6044 Doeskin";
  option.attributes.componentData.display.color = "#c6b3a9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c6b3a9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6051 Sashay Sand";
  option.attributes.componentData.display.color = "#cfb4a8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfb4a8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6058 Likeable Sand";
  option.attributes.componentData.display.color = "#d1b7a8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1b7a8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6065 Bona Fide Beige";
  option.attributes.componentData.display.color = "#cbb9ab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbb9ab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6086 Sand Dune";
  option.attributes.componentData.display.color = "#c5b1a2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5b1a2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6024 Dressy Rose";
  option.attributes.componentData.display.color = "#b89d9a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b89d9a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6031 Glamour";
  option.attributes.componentData.display.color = "#b6a09a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b6a09a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6045 Emerging Taupe";
  option.attributes.componentData.display.color = "#b8a196";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b8a196";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6052 Sandbank";
  option.attributes.componentData.display.color = "#c3a497";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c3a497";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6059 Interface Tan";
  option.attributes.componentData.display.color = "#c1a392";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1a392";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6066 Sand Trap";
  option.attributes.componentData.display.color = "#bba595";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bba595";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6087 Trusty Tan";
  option.attributes.componentData.display.color = "#b59f8f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b59f8f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9077 Rosaline Pearl";
  option.attributes.componentData.display.color = "#a38887";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a38887";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9078 Cocoa Berry";
  option.attributes.componentData.display.color = "#a08882";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a08882";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9079 Velvety Chestnut";
  option.attributes.componentData.display.color = "#a2877d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a2877d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9080 Hushed Auburn";
  option.attributes.componentData.display.color = "#a8857a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a8857a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9081 Redend Point";
  option.attributes.componentData.display.color = "#ae8e7e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ae8e7e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9082 Chocolate Powder";
  option.attributes.componentData.display.color = "#a58c7b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a58c7b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9083 Dusted Truffle";
  option.attributes.componentData.display.color = "#9c8373";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9c8373";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6025 Socialite";
  option.attributes.componentData.display.color = "#907676";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_907676";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6032 Dutch Cocoa";
  option.attributes.componentData.display.color = "#8c706a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8c706a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6046 Swing Brown";
  option.attributes.componentData.display.color = "#947569";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_947569";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6053 Reddened Earth";
  option.attributes.componentData.display.color = "#9c6e63";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9c6e63";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6060 Moroccan Spice";
  option.attributes.componentData.display.color = "#9d7868";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9d7868";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6067 Mocha";
  option.attributes.componentData.display.color = "#967a6a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_967a6a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6088 Nuthatch";
  option.attributes.componentData.display.color = "#8e725f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8e725f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6026 River Rouge";
  option.attributes.componentData.display.color = "#76595d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_76595d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6033 Bateau Brown";
  option.attributes.componentData.display.color = "#7a5f5a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a5f5a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6047 Hot Cocoa";
  option.attributes.componentData.display.color = "#806257";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_806257";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6054 Canyon Clay";
  option.attributes.componentData.display.color = "#85594f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_85594f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6061 Tanbark";
  option.attributes.componentData.display.color = "#896656";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_896656";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6068 Brevity Brown";
  option.attributes.componentData.display.color = "#715243";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_715243";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6089 Grounded";
  option.attributes.componentData.display.color = "#785b47";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_785b47";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6027 Cordovan";
  option.attributes.componentData.display.color = "#5f3d3f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5f3d3f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6034 Dark Auburn";
  option.attributes.componentData.display.color = "#5a3532";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5a3532";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6048 Terra Brun";
  option.attributes.componentData.display.color = "#5a382d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5a382d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6055 Fiery Brown";
  option.attributes.componentData.display.color = "#5d3831";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5d3831";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6062 Vintage Leather";
  option.attributes.componentData.display.color = "#694336";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_694336";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6069 French Roast";
  option.attributes.componentData.display.color = "#4f3426";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4f3426";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6090 Java";
  option.attributes.componentData.display.color = "#634533";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_634533";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "orange";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_orange";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "orange high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_orange_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6610 Koral Kicks";
  option.attributes.componentData.display.color = "#f2d1c3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2d1c3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6617 Blushing";
  option.attributes.componentData.display.color = "#f0d1c3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0d1c3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6624 Peach Blossom";
  option.attributes.componentData.display.color = "#f3d0bd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3d0bd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6631 Naive Peach";
  option.attributes.componentData.display.color = "#f3d3bf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3d3bf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6638 Flattering Peach";
  option.attributes.componentData.display.color = "#f4d3b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4d3b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6652 Flan";
  option.attributes.componentData.display.color = "#f4d4af";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4d4af";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6659 Captivating Cream";
  option.attributes.componentData.display.color = "#f4d9b1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4d9b1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6611 Jovial";
  option.attributes.componentData.display.color = "#f2b8a7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2b8a7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6618 Cosmetic Peach";
  option.attributes.componentData.display.color = "#f3c1ab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3c1ab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6625 Certain Peach";
  option.attributes.componentData.display.color = "#f2bda2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2bda2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6632 Neighborly Peach";
  option.attributes.componentData.display.color = "#f3c1a3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3c1a3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6639 Avid Apricot";
  option.attributes.componentData.display.color = "#f4c69f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4c69f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6653 Delicious Melon";
  option.attributes.componentData.display.color = "#f5c894";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5c894";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6660 Honey Blush";
  option.attributes.componentData.display.color = "#f5cf9b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5cf9b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6612 Ravishing Coral";
  option.attributes.componentData.display.color = "#e79580";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e79580";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6619 Sockeye";
  option.attributes.componentData.display.color = "#e49780";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e49780";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6626 Sunset";
  option.attributes.componentData.display.color = "#e2946f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2946f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6633 Inventive Orange";
  option.attributes.componentData.display.color = "#e89d6f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e89d6f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9007 Melón Meloso";
  option.attributes.componentData.display.color = "#f2b88c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2b88c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6654 Surprise Amber";
  option.attributes.componentData.display.color = "#efb57a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_efb57a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9008 Bellini Fizz";
  option.attributes.componentData.display.color = "#f5c78e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5c78e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6613 Lei Flower";
  option.attributes.componentData.display.color = "#d87b6a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d87b6a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6620 Rejuvenate";
  option.attributes.componentData.display.color = "#dd7861";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dd7861";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6627 Emberglow";
  option.attributes.componentData.display.color = "#d67c56";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d67c56";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6634 Copper Harbor";
  option.attributes.componentData.display.color = "#d57e52";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d57e52";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6640 Tangerine";
  option.attributes.componentData.display.color = "#f2ac78";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2ac78";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6655 Adventure Orange";
  option.attributes.componentData.display.color = "#e69f5f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e69f5f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6661 Papaya";
  option.attributes.componentData.display.color = "#efb97b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_efb97b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6614 Quite Coral";
  option.attributes.componentData.display.color = "#c76356";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c76356";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6621 Emotional";
  option.attributes.componentData.display.color = "#c65f47";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c65f47";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6628 Robust Orange";
  option.attributes.componentData.display.color = "#c4633e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c4633e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6635 Determined Orange";
  option.attributes.componentData.display.color = "#c56639";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c56639";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6641 Outgoing Orange";
  option.attributes.componentData.display.color = "#e6955f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6955f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6656 Serape";
  option.attributes.componentData.display.color = "#d88b4d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d88b4d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6662 Summer Day";
  option.attributes.componentData.display.color = "#eaaa62";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eaaa62";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6615 Peppery";
  option.attributes.componentData.display.color = "#b85444";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b85444";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6622 Hearty Orange";
  option.attributes.componentData.display.color = "#b44b34";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b44b34";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6629 Jalapeño";
  option.attributes.componentData.display.color = "#b1533c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b1533c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6636 Husky Orange";
  option.attributes.componentData.display.color = "#bb613e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bb613e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6642 Rhumba Orange";
  option.attributes.componentData.display.color = "#cb7841";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cb7841";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6657 Amber Wave";
  option.attributes.componentData.display.color = "#d28240";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d28240";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6663 Saffron Thread";
  option.attributes.componentData.display.color = "#df984e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_df984e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7589 Habanero Chile";
  option.attributes.componentData.display.color = "#b8473d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b8473d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6881 Cayenne";
  option.attributes.componentData.display.color = "#c04d35";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c04d35";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7599 Brick Paver";
  option.attributes.componentData.display.color = "#93402f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_93402f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6883 Raucous Orange";
  option.attributes.componentData.display.color = "#c35530";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c35530";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6643 Yam";
  option.attributes.componentData.display.color = "#c36f3e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c36f3e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7703 Earthen Jug";
  option.attributes.componentData.display.color = "#a85e39";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a85e39";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6664 Marigold";
  option.attributes.componentData.display.color = "#d28233";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d28233";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "orange medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_orange_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6337 Spun Sugar";
  option.attributes.componentData.display.color = "#edd2c0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edd2c0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6344 Peach Fuzz";
  option.attributes.componentData.display.color = "#eccfbb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eccfbb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6351 Sweet Orange";
  option.attributes.componentData.display.color = "#ebccb3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ebccb3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6358 Creamery";
  option.attributes.componentData.display.color = "#edd0b6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edd0b6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6365 Cachet Cream";
  option.attributes.componentData.display.color = "#f3d9ba";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3d9ba";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6372 Inviting Ivory";
  option.attributes.componentData.display.color = "#f2d5b0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2d5b0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6379 Jersey Cream";
  option.attributes.componentData.display.color = "#f5debb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5debb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6338 Warming Peach";
  option.attributes.componentData.display.color = "#e4b9a2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4b9a2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6345 Sumptuous Peach";
  option.attributes.componentData.display.color = "#e5b99b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5b99b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6352 Soft Apricot";
  option.attributes.componentData.display.color = "#e0b392";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0b392";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6359 Sociable";
  option.attributes.componentData.display.color = "#e8be9b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8be9b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6366 Ambitious Amber";
  option.attributes.componentData.display.color = "#f0cb97";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0cb97";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6373 Harvester";
  option.attributes.componentData.display.color = "#edc38e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edc38e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6380 Humble Gold";
  option.attributes.componentData.display.color = "#edc796";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edc796";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6339 Persimmon";
  option.attributes.componentData.display.color = "#d9987c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d9987c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6346 Fame Orange";
  option.attributes.componentData.display.color = "#db9c7b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_db9c7b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9010 Windswept Canyon";
  option.attributes.componentData.display.color = "#dba480";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dba480";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9011 Sun Bleached Ochre";
  option.attributes.componentData.display.color = "#e3ab7b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3ab7b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6367 Viva Gold";
  option.attributes.componentData.display.color = "#e3ac72";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3ac72";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9012 Polvo de Oro";
  option.attributes.componentData.display.color = "#e8b87f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8b87f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7682 Bee's Wax";
  option.attributes.componentData.display.color = "#eabf86";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eabf86";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9009 Subdued Sienna";
  option.attributes.componentData.display.color = "#cc896c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cc896c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6347 Chrysanthemum";
  option.attributes.componentData.display.color = "#c47b5b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c47b5b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6353 Chivalry Copper";
  option.attributes.componentData.display.color = "#d4966e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d4966e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6360 Folksy Gold";
  option.attributes.componentData.display.color = "#d69969";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d69969";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6368 Bakelite Gold";
  option.attributes.componentData.display.color = "#d7995d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7995d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6374 Torchlight";
  option.attributes.componentData.display.color = "#e5ae6b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5ae6b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6381 Anjou Pear";
  option.attributes.componentData.display.color = "#ddac6d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ddac6d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6340 Baked Clay";
  option.attributes.componentData.display.color = "#c1785c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1785c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6348 Reynard";
  option.attributes.componentData.display.color = "#b46848";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b46848";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6354 Armagnac";
  option.attributes.componentData.display.color = "#c38058";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c38058";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6361 Autumnal";
  option.attributes.componentData.display.color = "#cd8c5d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cd8c5d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6369 Tassel";
  option.attributes.componentData.display.color = "#c6884a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c6884a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6375 Honeycomb";
  option.attributes.componentData.display.color = "#d59858";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d59858";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6382 Ceremonial Gold";
  option.attributes.componentData.display.color = "#d69e59";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d69e59";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6341 Red Cent";
  option.attributes.componentData.display.color = "#ad654c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ad654c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6349 Pennywise";
  option.attributes.componentData.display.color = "#a2583a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a2583a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6355 Truepenny";
  option.attributes.componentData.display.color = "#b46c42";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b46c42";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6362 Tigereye";
  option.attributes.componentData.display.color = "#bb7748";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bb7748";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6370 Saucy Gold";
  option.attributes.componentData.display.color = "#b6743b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b6743b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6376 Gold Coast";
  option.attributes.componentData.display.color = "#c78538";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c78538";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6383 Golden Rule";
  option.attributes.componentData.display.color = "#cc9249";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cc9249";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6342 Spicy Hue";
  option.attributes.componentData.display.color = "#994b35";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_994b35";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7598 Sierra Redwood";
  option.attributes.componentData.display.color = "#924e3c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_924e3c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6356 Copper Mountain";
  option.attributes.componentData.display.color = "#a6613c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a6613c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6363 Gingery";
  option.attributes.componentData.display.color = "#b06c3e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b06c3e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7709 Copper Pot";
  option.attributes.componentData.display.color = "#b16a37";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b16a37";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6377 Butterscotch";
  option.attributes.componentData.display.color = "#b67d3c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b67d3c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6384 Cut the Mustard";
  option.attributes.componentData.display.color = "#ba7f38";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ba7f38";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "orange low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_orange_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6078 Realist Beige";
  option.attributes.componentData.display.color = "#d3c8bd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d3c8bd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9085 Touch of Sand";
  option.attributes.componentData.display.color = "#d5c7ba";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d5c7ba";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6092 Lightweight Beige";
  option.attributes.componentData.display.color = "#dac8b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dac8b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6099 Sand Dollar";
  option.attributes.componentData.display.color = "#d7c5b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7c5b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9094 Playa Arenosa";
  option.attributes.componentData.display.color = "#dcc7b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcc7b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9101 Tres Naturale";
  option.attributes.componentData.display.color = "#dcc7ad";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcc7ad";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6106 Kilim Beige";
  option.attributes.componentData.display.color = "#d7c5ae";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7c5ae";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6079 Diverse Beige";
  option.attributes.componentData.display.color = "#c2b4a7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2b4a7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9086 Cool Beige";
  option.attributes.componentData.display.color = "#c6b5a7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c6b5a7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6093 Familiar Beige";
  option.attributes.componentData.display.color = "#cab3a0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cab3a0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6100 Practical Beige";
  option.attributes.componentData.display.color = "#c9b29c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c9b29c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9095 Ginger Root";
  option.attributes.componentData.display.color = "#d2b79e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d2b79e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9102 Quinoa";
  option.attributes.componentData.display.color = "#cfb597";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfb597";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6107 Nomadic Desert";
  option.attributes.componentData.display.color = "#c7b198";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c7b198";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6080 Utterly Beige";
  option.attributes.componentData.display.color = "#b5a597";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b5a597";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9087 Smoky Beige";
  option.attributes.componentData.display.color = "#b9a796";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b9a796";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6094 Sensational Sand";
  option.attributes.componentData.display.color = "#bfa38d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bfa38d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6101 Sands of Time";
  option.attributes.componentData.display.color = "#bca38b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bca38b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9096 Beige Intenso";
  option.attributes.componentData.display.color = "#c5a88d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5a88d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9103 Farro";
  option.attributes.componentData.display.color = "#c1a485";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1a485";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6108 Latte";
  option.attributes.componentData.display.color = "#baa185";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_baa185";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9084 Cocoa Whip";
  option.attributes.componentData.display.color = "#a08e7e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a08e7e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9088 Utaupeia";
  option.attributes.componentData.display.color = "#a58f7b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a58f7b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9092 Iced Mocha";
  option.attributes.componentData.display.color = "#a3846c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a3846c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9093 Nearly Brown";
  option.attributes.componentData.display.color = "#a88e76";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a88e76";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9097 Soft Fawn";
  option.attributes.componentData.display.color = "#b59778";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b59778";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9104 Woven Wicker";
  option.attributes.componentData.display.color = "#b99974";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b99974";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9108 Double Latte";
  option.attributes.componentData.display.color = "#a78c71";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a78c71";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6081 Down Home";
  option.attributes.componentData.display.color = "#907865";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_907865";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9089 Llama Wool";
  option.attributes.componentData.display.color = "#917864";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_917864";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6095 Toasty";
  option.attributes.componentData.display.color = "#957258";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_957258";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6102 Portabello";
  option.attributes.componentData.display.color = "#947a62";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_947a62";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9098 Baked Cookie";
  option.attributes.componentData.display.color = "#89674a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_89674a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9105 Almond Roca";
  option.attributes.componentData.display.color = "#a78361";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a78361";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6109 Hopsack";
  option.attributes.componentData.display.color = "#9e8163";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e8163";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6082 Cobble Brown";
  option.attributes.componentData.display.color = "#7a6455";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a6455";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9090 Caraïbe";
  option.attributes.componentData.display.color = "#785f4c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_785f4c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6096 Jute Brown";
  option.attributes.componentData.display.color = "#815d40";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_815d40";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6103 Tea Chest";
  option.attributes.componentData.display.color = "#7d644d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7d644d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9099 Saddle Up";
  option.attributes.componentData.display.color = "#725237";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_725237";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9106 El Caramelo";
  option.attributes.componentData.display.color = "#946e48";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_946e48";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6110 Steady Brown";
  option.attributes.componentData.display.color = "#8a6b4d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8a6b4d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6083 Sable";
  option.attributes.componentData.display.color = "#5f4b3f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5f4b3f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9091 Half-Caff";
  option.attributes.componentData.display.color = "#604c3d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_604c3d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6097 Sturdy Brown";
  option.attributes.componentData.display.color = "#69482c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_69482c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6104 Kaffee";
  option.attributes.componentData.display.color = "#65503d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_65503d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9100 Umber Rust";
  option.attributes.componentData.display.color = "#765138";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_765138";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9107 Über Umber";
  option.attributes.componentData.display.color = "#7b5838";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7b5838";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6111 Coconut Husk";
  option.attributes.componentData.display.color = "#70573f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_70573f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "yellow";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_yellow";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "yellow high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_yellow_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6666 Enjoyable Yellow";
  option.attributes.componentData.display.color = "#f5d6a9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5d6a9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6673 Banana Cream";
  option.attributes.componentData.display.color = "#f5deaf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5deaf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9015 They call it Mellow";
  option.attributes.componentData.display.color = "#fbe4b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fbe4b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6680 Friendly Yellow";
  option.attributes.componentData.display.color = "#f5e0b1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5e0b1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6687 Lantern Light";
  option.attributes.componentData.display.color = "#f4e1ae";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4e1ae";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6694 Glad Yellow";
  option.attributes.componentData.display.color = "#f5e1ac";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5e1ac";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 1668 Pineapple Cream";
  option.attributes.componentData.display.color = "#f2eac3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2eac3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6667 Afterglow";
  option.attributes.componentData.display.color = "#f6cd8e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f6cd8e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6674 Jonquil";
  option.attributes.componentData.display.color = "#f7d391";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f7d391";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9016 La Luna Amarilla";
  option.attributes.componentData.display.color = "#fddfa0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fddfa0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6681 Butter Up";
  option.attributes.componentData.display.color = "#f6dda3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f6dda3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6688 Solaria";
  option.attributes.componentData.display.color = "#f5d68f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f5d68f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6695 Midday";
  option.attributes.componentData.display.color = "#f7d78a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f7d78a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 1667 Icy Lemonade";
  option.attributes.componentData.display.color = "#f4e8b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4e8b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6668 Sunrise";
  option.attributes.componentData.display.color = "#f4bf77";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4bf77";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9014 Pollen Powder";
  option.attributes.componentData.display.color = "#fbd187";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fbd187";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9017 Sunny Veranda";
  option.attributes.componentData.display.color = "#fedf94";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fedf94";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9021 Naples Yellow";
  option.attributes.componentData.display.color = "#f6d58f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f6d58f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9022 Yellow Bird";
  option.attributes.componentData.display.color = "#f1cd7b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f1cd7b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6696 Quilt Gold";
  option.attributes.componentData.display.color = "#eac365";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eac365";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 1666 Venetian Yellow";
  option.attributes.componentData.display.color = "#f6e3a1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f6e3a1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9013 Olden Amber";
  option.attributes.componentData.display.color = "#eeb76b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eeb76b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6675 Afternoon";
  option.attributes.componentData.display.color = "#fbcb78";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fbcb78";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9018 Honey Bees";
  option.attributes.componentData.display.color = "#fbd682";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fbd682";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6682 June Day";
  option.attributes.componentData.display.color = "#f6c973";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f6c973";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6689 Overjoy";
  option.attributes.componentData.display.color = "#eec25f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eec25f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6697 Nugget";
  option.attributes.componentData.display.color = "#dbb04a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbb04a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6908 Fun Yellow";
  option.attributes.componentData.display.color = "#f7e594";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f7e594";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6669 Yarrow";
  option.attributes.componentData.display.color = "#ebad5e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ebad5e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6676 Butterfield";
  option.attributes.componentData.display.color = "#f7be5b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f7be5b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9019 Golden Plumeria";
  option.attributes.componentData.display.color = "#fbd073";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fbd073";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6683 Bee";
  option.attributes.componentData.display.color = "#f1ba55";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f1ba55";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6690 Gambol Gold";
  option.attributes.componentData.display.color = "#e1b047";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e1b047";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6698 Kingdom Gold";
  option.attributes.componentData.display.color = "#d1a436";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1a436";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6913 Funky Yellow";
  option.attributes.componentData.display.color = "#edd26f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edd26f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6670 Gold Crest";
  option.attributes.componentData.display.color = "#df9938";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_df9938";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6677 Goldenrod";
  option.attributes.componentData.display.color = "#f2af46";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2af46";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9020 Rayo de Sol";
  option.attributes.componentData.display.color = "#f4c454";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4c454";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6684 Brittlebush";
  option.attributes.componentData.display.color = "#eaae47";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eaae47";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6691 Glitzy Gold";
  option.attributes.componentData.display.color = "#d6a02b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6a02b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6699 Crispy Gold";
  option.attributes.componentData.display.color = "#c49832";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c49832";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6914 Eye Catching";
  option.attributes.componentData.display.color = "#ddb835";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ddb835";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6671 Curry";
  option.attributes.componentData.display.color = "#d88f32";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d88f32";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6678 Sunflower";
  option.attributes.componentData.display.color = "#e39a33";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e39a33";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6903 Cheerful";
  option.attributes.componentData.display.color = "#ffc723";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ffc723";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6685 Trinket";
  option.attributes.componentData.display.color = "#d69835";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d69835";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6692 Auric";
  option.attributes.componentData.display.color = "#c48919";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c48919";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7700 Olde World Gold";
  option.attributes.componentData.display.color = "#8f6c3e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8f6c3e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6915 Citronella";
  option.attributes.componentData.display.color = "#cba901";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cba901";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "yellow medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_yellow_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6386 Napery";
  option.attributes.componentData.display.color = "#efddc1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_efddc1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6127 Ivoire";
  option.attributes.componentData.display.color = "#e4ceac";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4ceac";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6120 Believable Buff";
  option.attributes.componentData.display.color = "#dbc7a8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbc7a8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6393 Convivial Yellow";
  option.attributes.componentData.display.color = "#e9d6b0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9d6b0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6400 Lucent Yellow";
  option.attributes.componentData.display.color = "#e4d0a5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4d0a5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6407 Ancestral Gold";
  option.attributes.componentData.display.color = "#ddcda6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ddcda6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6414 Rice Paddy";
  option.attributes.componentData.display.color = "#dfd4b0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dfd4b0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6387 Compatible Cream";
  option.attributes.componentData.display.color = "#e8c89e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8c89e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6128 Blonde";
  option.attributes.componentData.display.color = "#dcbd92";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcbd92";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6121 Whole Wheat";
  option.attributes.componentData.display.color = "#cdb592";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cdb592";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6394 Sequin";
  option.attributes.componentData.display.color = "#e1c28d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e1c28d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9027 Pale Moss";
  option.attributes.componentData.display.color = "#dcc797";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcc797";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6408 Wheat Grass";
  option.attributes.componentData.display.color = "#cbb584";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbb584";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6415 Hearts of Palm";
  option.attributes.componentData.display.color = "#cfc291";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfc291";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9023 Dakota Wheat";
  option.attributes.componentData.display.color = "#e1bd8e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e1bd8e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6129 Restrained Gold";
  option.attributes.componentData.display.color = "#d2b084";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d2b084";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6122 Camelback";
  option.attributes.componentData.display.color = "#c5aa85";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5aa85";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9026 Tarnished Trumpet";
  option.attributes.componentData.display.color = "#d5b176";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d5b176";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6401 Independent Gold";
  option.attributes.componentData.display.color = "#d2ba83";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d2ba83";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9028 Dusted Olive";
  option.attributes.componentData.display.color = "#bea775";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bea775";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9029 Cool Avocado";
  option.attributes.componentData.display.color = "#c4b47d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c4b47d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6388 Golden Fleece";
  option.attributes.componentData.display.color = "#d6ad78";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6ad78";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9024 Vintage Gold";
  option.attributes.componentData.display.color = "#cba576";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cba576";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9025 Coriander Powder";
  option.attributes.componentData.display.color = "#ba9c75";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ba9c75";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6395 Alchemy";
  option.attributes.componentData.display.color = "#c99e53";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c99e53";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6402 Antiquity";
  option.attributes.componentData.display.color = "#c2a462";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2a462";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6409 Edgy Gold";
  option.attributes.componentData.display.color = "#b1975f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b1975f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6416 Sassy Green";
  option.attributes.componentData.display.color = "#bba86a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bba86a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6389 Butternut";
  option.attributes.componentData.display.color = "#cc9b5c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cc9b5c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6130 Mannered Gold";
  option.attributes.componentData.display.color = "#c19763";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c19763";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6123 Baguette";
  option.attributes.componentData.display.color = "#b39167";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b39167";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6396 Different Gold";
  option.attributes.componentData.display.color = "#bc934d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bc934d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6403 Escapade Gold";
  option.attributes.componentData.display.color = "#b89b59";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b89b59";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6410 Brassy";
  option.attributes.componentData.display.color = "#9d8344";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9d8344";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6417 Tupelo Tree";
  option.attributes.componentData.display.color = "#9c9152";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9c9152";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6390 Bosc Pear";
  option.attributes.componentData.display.color = "#c09056";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c09056";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6131 Chamois";
  option.attributes.componentData.display.color = "#ad8451";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ad8451";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6124 Cardboard";
  option.attributes.componentData.display.color = "#9c7a56";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9c7a56";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6397 Nankeen";
  option.attributes.componentData.display.color = "#aa803a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aa803a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6404 Grandiose";
  option.attributes.componentData.display.color = "#9c7f41";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9c7f41";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6411 Bengal Grass";
  option.attributes.componentData.display.color = "#8e773f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8e773f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6418 Rural Green";
  option.attributes.componentData.display.color = "#8d844d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8d844d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6391 Gallant Gold";
  option.attributes.componentData.display.color = "#a4763c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a4763c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6132 Relic Bronze";
  option.attributes.componentData.display.color = "#906a3a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_906a3a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6125 Craft Paper";
  option.attributes.componentData.display.color = "#8a6645";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8a6645";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6398 Sconce Gold";
  option.attributes.componentData.display.color = "#996f32";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_996f32";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6405 Fervent Brass";
  option.attributes.componentData.display.color = "#95793d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_95793d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6412 Eminent Bronze";
  option.attributes.componentData.display.color = "#7a6841";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a6841";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6419 Saguaro";
  option.attributes.componentData.display.color = "#655f2d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_655f2d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "yellow low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_yellow_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9109 Natural Linen";
  option.attributes.componentData.display.color = "#dfd3c3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dfd3c3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6141 Softer Tan";
  option.attributes.componentData.display.color = "#dacab2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dacab2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6148 Wool Skein";
  option.attributes.componentData.display.color = "#d9cfba";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d9cfba";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6134 Netsuke";
  option.attributes.componentData.display.color = "#e0cfb0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0cfb0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9119 Dirty Martini";
  option.attributes.componentData.display.color = "#ddd0b6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ddd0b6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6155 Rice Grain";
  option.attributes.componentData.display.color = "#dbd0b9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbd0b9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6169 Sedate Gray";
  option.attributes.componentData.display.color = "#d1cdbf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1cdbf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9110 Malabar";
  option.attributes.componentData.display.color = "#cfbea9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfbea9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6142 Macadamia";
  option.attributes.componentData.display.color = "#ccb79b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ccb79b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6149 Relaxed Khaki";
  option.attributes.componentData.display.color = "#c8bba3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c8bba3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6135 Ecru";
  option.attributes.componentData.display.color = "#d0ba94";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d0ba94";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9120 Tumblin' Tumbleweed";
  option.attributes.componentData.display.color = "#cdbb9c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cdbb9c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6156 Ramie";
  option.attributes.componentData.display.color = "#cdbda2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cdbda2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6170 Techno Gray";
  option.attributes.componentData.display.color = "#bfb9aa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bfb9aa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9111 Antler Velvet";
  option.attributes.componentData.display.color = "#c0ad96";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c0ad96";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6143 Basket Beige";
  option.attributes.componentData.display.color = "#c0a98b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c0a98b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6150 Universal Khaki";
  option.attributes.componentData.display.color = "#b8a992";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b8a992";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6136 Harmonic Tan";
  option.attributes.componentData.display.color = "#c6b08a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c6b08a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9121 Sawgrass Basket";
  option.attributes.componentData.display.color = "#c3b090";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c3b090";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6157 Favorite Tan";
  option.attributes.componentData.display.color = "#c1ae91";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1ae91";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6171 Chatroom";
  option.attributes.componentData.display.color = "#b0ab9c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b0ab9c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9112 Song Thrush";
  option.attributes.componentData.display.color = "#af987f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_af987f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9116 Serengeti Grass";
  option.attributes.componentData.display.color = "#ab9579";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ab9579";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9117 Urban Jungle";
  option.attributes.componentData.display.color = "#a4947e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a4947e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9118 Tarnished Treasure";
  option.attributes.componentData.display.color = "#b9a47e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b9a47e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9122 Dried Edamame";
  option.attributes.componentData.display.color = "#b19f80";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b19f80";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7544 Fenland";
  option.attributes.componentData.display.color = "#ac9d83";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ac9d83";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9126 Honed Soapstone";
  option.attributes.componentData.display.color = "#9d9887";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9d9887";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9113 Mudslide";
  option.attributes.componentData.display.color = "#a08568";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a08568";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6144 Dapper Tan";
  option.attributes.componentData.display.color = "#947f65";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_947f65";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6151 Quiver Tan";
  option.attributes.componentData.display.color = "#8e7f6a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8e7f6a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6137 Burlap";
  option.attributes.componentData.display.color = "#ac9571";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ac9571";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9123 Barro Verde";
  option.attributes.componentData.display.color = "#9f8e71";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9f8e71";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6158 Sawdust";
  option.attributes.componentData.display.color = "#998970";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_998970";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6172 Hardware";
  option.attributes.componentData.display.color = "#8b8372";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8b8372";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9114 Fallen Leaves";
  option.attributes.componentData.display.color = "#8f7659";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8f7659";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6145 Thatch Brown";
  option.attributes.componentData.display.color = "#867057";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_867057";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6152 Superior Bronze";
  option.attributes.componentData.display.color = "#786957";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_786957";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6138 Artifact";
  option.attributes.componentData.display.color = "#9a815e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9a815e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9124 Verde Marrón";
  option.attributes.componentData.display.color = "#877459";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_877459";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6159 High Tea";
  option.attributes.componentData.display.color = "#7e6f59";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7e6f59";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6173 Cocoon";
  option.attributes.componentData.display.color = "#726b5b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_726b5b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9115 Cowboy Boots";
  option.attributes.componentData.display.color = "#695239";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_695239";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6146 Umber";
  option.attributes.componentData.display.color = "#6e543c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6e543c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6153 Protégé Bronze";
  option.attributes.componentData.display.color = "#66543e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_66543e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6139 Mossy Gold";
  option.attributes.componentData.display.color = "#7f6743";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7f6743";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9125 Oliva Oscuro";
  option.attributes.componentData.display.color = "#665439";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_665439";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6160 Best Bronze";
  option.attributes.componentData.display.color = "#5d513e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5d513e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7745 Muddled Basil";
  option.attributes.componentData.display.color = "#5a5243";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5a5243";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "green";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_green";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "green high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_green_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6701 Moonraker";
  option.attributes.componentData.display.color = "#eee3b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eee3b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6708 Springtime";
  option.attributes.componentData.display.color = "#e9e5b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9e5b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6715 Lime Granita";
  option.attributes.componentData.display.color = "#dce1b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dce1b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6729 Lacewing";
  option.attributes.componentData.display.color = "#d7e3ca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7e3ca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6722 Cucumber";
  option.attributes.componentData.display.color = "#d3dfc3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d3dfc3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6736 Jocular Green";
  option.attributes.componentData.display.color = "#cce2ca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cce2ca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6743 Mint Condition";
  option.attributes.componentData.display.color = "#d1e3d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1e3d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6702 Lively Yellow";
  option.attributes.componentData.display.color = "#e6d88e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6d88e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6709 Gleeful";
  option.attributes.componentData.display.color = "#dad790";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dad790";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6716 Dancing Green";
  option.attributes.componentData.display.color = "#c5cd8f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5cd8f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6730 Romaine";
  option.attributes.componentData.display.color = "#c0d2ad";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c0d2ad";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6723 Jardin";
  option.attributes.componentData.display.color = "#bdd0ab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bdd0ab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6737 Kiwi";
  option.attributes.componentData.display.color = "#aed2b0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aed2b0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6744 Reclining Green";
  option.attributes.componentData.display.color = "#b7d7bf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b7d7bf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6703 Frolic";
  option.attributes.componentData.display.color = "#d9c661";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d9c661";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9031 Primavera";
  option.attributes.componentData.display.color = "#d2d083";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d2d083";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6717 Lime Rickey";
  option.attributes.componentData.display.color = "#afb96a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_afb96a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9033 Oh Pistachio";
  option.attributes.componentData.display.color = "#abca99";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_abca99";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9034 Seawashed Glass";
  option.attributes.componentData.display.color = "#a9c095";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a9c095";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6738 Vegan";
  option.attributes.componentData.display.color = "#8ec298";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8ec298";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9036 Retro Mint";
  option.attributes.componentData.display.color = "#9fcdb1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9fcdb1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9030 Limón Fresco";
  option.attributes.componentData.display.color = "#cebc55";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cebc55";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6710 Mélange Green";
  option.attributes.componentData.display.color = "#c4c476";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c4c476";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9032 Stay in Lime";
  option.attributes.componentData.display.color = "#9fac5c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9fac5c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6731 Picnic";
  option.attributes.componentData.display.color = "#99c285";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_99c285";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6724 Mesclun Green";
  option.attributes.componentData.display.color = "#9db682";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9db682";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9035 Frosted Emerald";
  option.attributes.componentData.display.color = "#78b185";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_78b185";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6745 Lark Green";
  option.attributes.componentData.display.color = "#8ac1a1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8ac1a1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6704 Hep Green";
  option.attributes.componentData.display.color = "#c4b146";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c4b146";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6711 Parakeet";
  option.attributes.componentData.display.color = "#b4b05a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b4b05a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6718 Overt Green";
  option.attributes.componentData.display.color = "#97a554";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_97a554";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6732 Organic Green";
  option.attributes.componentData.display.color = "#7fac6e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7fac6e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6725 Pickle";
  option.attributes.componentData.display.color = "#85a16a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_85a16a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6739 Eco Green";
  option.attributes.componentData.display.color = "#68a678";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_68a678";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6746 Julep";
  option.attributes.componentData.display.color = "#57aa80";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_57aa80";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6705 High Strung";
  option.attributes.componentData.display.color = "#ac9825";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ac9825";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6712 Luau Green";
  option.attributes.componentData.display.color = "#989746";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_989746";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6719 Gecko";
  option.attributes.componentData.display.color = "#7a8833";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a8833";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6733 Grasshopper";
  option.attributes.componentData.display.color = "#4f854a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4f854a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6726 Talipot Palm";
  option.attributes.componentData.display.color = "#648149";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_648149";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6740 Kilkenny";
  option.attributes.componentData.display.color = "#498555";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_498555";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6747 Argyle";
  option.attributes.componentData.display.color = "#348a5d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_348a5d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6706 Offbeat Green";
  option.attributes.componentData.display.color = "#9c8b1f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9c8b1f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6713 Verdant";
  option.attributes.componentData.display.color = "#847e35";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_847e35";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6720 Paradise";
  option.attributes.componentData.display.color = "#6c7b30";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6c7b30";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6734 Espalier";
  option.attributes.componentData.display.color = "#2f5f3a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2f5f3a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6727 Houseplant";
  option.attributes.componentData.display.color = "#58713f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_58713f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6741 Derbyshire";
  option.attributes.componentData.display.color = "#245e36";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_245e36";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6748 Greens";
  option.attributes.componentData.display.color = "#016844";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_016844";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "green medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_green_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6421 Celery";
  option.attributes.componentData.display.color = "#e0ddbd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0ddbd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6428 Honeydew";
  option.attributes.componentData.display.color = "#dbddbd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbddbd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6435 Gratifying Green";
  option.attributes.componentData.display.color = "#dae2cd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dae2cd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6442 Supreme Green";
  option.attributes.componentData.display.color = "#cfddc7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfddc7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6449 Topiary Tint";
  option.attributes.componentData.display.color = "#c8d8c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c8d8c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6456 Slow Green";
  option.attributes.componentData.display.color = "#c6d5c9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c6d5c9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6463 Breaktime";
  option.attributes.componentData.display.color = "#c4d9ce";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c4d9ce";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6422 Shagreen";
  option.attributes.componentData.display.color = "#cbc99d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbc99d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6429 Baize Green";
  option.attributes.componentData.display.color = "#c7cda8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c7cda8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6436 Bonsai Tint";
  option.attributes.componentData.display.color = "#c5d1b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5d1b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6443 Relish";
  option.attributes.componentData.display.color = "#b3cbaa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b3cbaa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6450 Easy Green";
  option.attributes.componentData.display.color = "#acc2a8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_acc2a8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6457 Kind Green";
  option.attributes.componentData.display.color = "#aac2b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aac2b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6464 Aloe";
  option.attributes.componentData.display.color = "#accabc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_accabc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9037 Baby Bok Choy";
  option.attributes.componentData.display.color = "#bbb98a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bbb98a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6430 Great Green";
  option.attributes.componentData.display.color = "#abb486";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_abb486";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6437 Haven";
  option.attributes.componentData.display.color = "#a3b48c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a3b48c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6444 Lounge Green";
  option.attributes.componentData.display.color = "#8ba97f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8ba97f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6451 Nurture Green";
  option.attributes.componentData.display.color = "#98b092";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_98b092";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6458 Restful";
  option.attributes.componentData.display.color = "#91af9d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_91af9d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6465 Spearmint";
  option.attributes.componentData.display.color = "#94b5a6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_94b5a6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6423 Ryegrass";
  option.attributes.componentData.display.color = "#aeac7a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aeac7a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9038 Cucuzza Verde";
  option.attributes.componentData.display.color = "#9ba373";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9ba373";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9039 Broccoflower";
  option.attributes.componentData.display.color = "#8fa277";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8fa277";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9040 Reseda Green";
  option.attributes.componentData.display.color = "#75946b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_75946b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7742 Agate Green";
  option.attributes.componentData.display.color = "#8ea486";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8ea486";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9041 Parisian Patina";
  option.attributes.componentData.display.color = "#7d9b89";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7d9b89";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9042 Verdigreen";
  option.attributes.componentData.display.color = "#81a595";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_81a595";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6424 Tansy Green";
  option.attributes.componentData.display.color = "#95945c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_95945c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6431 Leapfrog";
  option.attributes.componentData.display.color = "#88915d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_88915d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6438 Dill";
  option.attributes.componentData.display.color = "#788d60";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_788d60";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6445 Garden Grove";
  option.attributes.componentData.display.color = "#5e7f57";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5e7f57";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6452 Inland";
  option.attributes.componentData.display.color = "#6c8867";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6c8867";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6459 Jadite";
  option.attributes.componentData.display.color = "#61826c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_61826c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6466 Grandview";
  option.attributes.componentData.display.color = "#6b927f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6b927f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6425 Relentless Olive";
  option.attributes.componentData.display.color = "#71713e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_71713e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6432 Garden Spot";
  option.attributes.componentData.display.color = "#6d7645";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6d7645";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6439 Greenfield";
  option.attributes.componentData.display.color = "#60724f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_60724f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6446 Arugula";
  option.attributes.componentData.display.color = "#42603c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_42603c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6453 Cilantro";
  option.attributes.componentData.display.color = "#537150";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_537150";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6460 Kale Green";
  option.attributes.componentData.display.color = "#4f6a56";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4f6a56";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6467 Kendal Green";
  option.attributes.componentData.display.color = "#547867";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_547867";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6426 Basque Green";
  option.attributes.componentData.display.color = "#5f6033";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5f6033";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6433 Inverness";
  option.attributes.componentData.display.color = "#576238";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_576238";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6440 Courtyard";
  option.attributes.componentData.display.color = "#475842";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_475842";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6447 Evergreens";
  option.attributes.componentData.display.color = "#405840";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_405840";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6454 Shamrock";
  option.attributes.componentData.display.color = "#205134";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_205134";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6461 Isle of Pines";
  option.attributes.componentData.display.color = "#3d5541";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3d5541";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6468 Hunt Club";
  option.attributes.componentData.display.color = "#2a4f43";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2a4f43";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "green low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_green_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6162 Ancient Marble";
  option.attributes.componentData.display.color = "#d1ccb9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1ccb9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6176 Liveable Green";
  option.attributes.componentData.display.color = "#cecebd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cecebd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6190 Filmy Green";
  option.attributes.componentData.display.color = "#d1d3c7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1d3c7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6183 Conservative Gray";
  option.attributes.componentData.display.color = "#d1d0c6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1d0c6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6197 Aloof Gray";
  option.attributes.componentData.display.color = "#c9c9c0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c9c9c0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6204 Sea Salt";
  option.attributes.componentData.display.color = "#cdd2ca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cdd2ca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6211 Rainwashed";
  option.attributes.componentData.display.color = "#c2cdc5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2cdc5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6163 Grassland";
  option.attributes.componentData.display.color = "#c1bca7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1bca7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6177 Softened Green";
  option.attributes.componentData.display.color = "#bbbca7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bbbca7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6191 Contented";
  option.attributes.componentData.display.color = "#bdc0b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bdc0b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6184 Austere Gray";
  option.attributes.componentData.display.color = "#bebfb2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bebfb2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6198 Sensible Hue";
  option.attributes.componentData.display.color = "#b6b5ab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b6b5ab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6205 Comfort Gray";
  option.attributes.componentData.display.color = "#bec3bb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bec3bb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6212 Quietude";
  option.attributes.componentData.display.color = "#adbbb2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_adbbb2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6164 Svelte Sage";
  option.attributes.componentData.display.color = "#b2ac96";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b2ac96";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6178 Clary Sage";
  option.attributes.componentData.display.color = "#acad97";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_acad97";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6192 Coastal Plain";
  option.attributes.componentData.display.color = "#9fa694";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9fa694";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6185 Escape Gray";
  option.attributes.componentData.display.color = "#abac9f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_abac9f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6199 Rare Gray";
  option.attributes.componentData.display.color = "#a6a69b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a6a69b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6206 Oyster Bay";
  option.attributes.componentData.display.color = "#aeb3a9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aeb3a9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6213 Halcyon Green";
  option.attributes.componentData.display.color = "#9baaa2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9baaa2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9127 At Ease Soldier";
  option.attributes.componentData.display.color = "#9e9985";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e9985";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9128 Green Onyx";
  option.attributes.componentData.display.color = "#989a82";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_989a82";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9129 Jade Dragon";
  option.attributes.componentData.display.color = "#909886";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_909886";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9130 Evergreen Fog";
  option.attributes.componentData.display.color = "#95978a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_95978a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9131 Cornwall Slate";
  option.attributes.componentData.display.color = "#949488";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_949488";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9132 Acacia Haze";
  option.attributes.componentData.display.color = "#969c92";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_969c92";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9133 Jasper Stone";
  option.attributes.componentData.display.color = "#8d9e97";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8d9e97";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6165 Connected Gray";
  option.attributes.componentData.display.color = "#898473";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_898473";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6179 Artichoke";
  option.attributes.componentData.display.color = "#7f8266";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7f8266";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6193 Privilege Green";
  option.attributes.componentData.display.color = "#7a8775";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a8775";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6186 Dried Thyme";
  option.attributes.componentData.display.color = "#7b8070";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7b8070";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6200 Link Gray";
  option.attributes.componentData.display.color = "#7f7e72";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7f7e72";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6207 Retreat";
  option.attributes.componentData.display.color = "#7a8076";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a8076";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6214 Underseas";
  option.attributes.componentData.display.color = "#7c8e87";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7c8e87";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6166 Eclipse";
  option.attributes.componentData.display.color = "#6b6757";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6b6757";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6180 Oakmoss";
  option.attributes.componentData.display.color = "#65684c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_65684c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6194 Basil";
  option.attributes.componentData.display.color = "#626e60";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_626e60";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6187 Rosemary";
  option.attributes.componentData.display.color = "#64695c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_64695c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6201 Thunderous";
  option.attributes.componentData.display.color = "#6d6c62";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6d6c62";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6208 Pewter Green";
  option.attributes.componentData.display.color = "#5e6259";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5e6259";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6215 Rocky River";
  option.attributes.componentData.display.color = "#5e706a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5e706a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6167 Garden Gate";
  option.attributes.componentData.display.color = "#5e5949";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5e5949";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6181 Secret Garden";
  option.attributes.componentData.display.color = "#4f523a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4f523a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6195 Rock Garden";
  option.attributes.componentData.display.color = "#465448";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_465448";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6188 Shade-Grown";
  option.attributes.componentData.display.color = "#4e5147";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4e5147";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6202 Cast Iron";
  option.attributes.componentData.display.color = "#64645a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_64645a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6209 Ripe Olive";
  option.attributes.componentData.display.color = "#44483d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_44483d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6216 Jasper";
  option.attributes.componentData.display.color = "#343b36";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_343b36";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "blue";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_blue";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "blue high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_blue_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6750 Waterfall";
  option.attributes.componentData.display.color = "#c0e3da";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c0e3da";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6757 Tame Teal";
  option.attributes.componentData.display.color = "#c1e6df";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1e6df";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6764 Swimming";
  option.attributes.componentData.display.color = "#c2e5e5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2e5e5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6771 Bathe Blue";
  option.attributes.componentData.display.color = "#c2e0e3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2e0e3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6778 Aviary Blue";
  option.attributes.componentData.display.color = "#c6e3e8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c6e3e8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6785 Quench Blue";
  option.attributes.componentData.display.color = "#b4e0e7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b4e0e7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6792 Minor Blue";
  option.attributes.componentData.display.color = "#b7dfe8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b7dfe8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6751 Refresh";
  option.attributes.componentData.display.color = "#a1d4c8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a1d4c8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6758 Aqueduct";
  option.attributes.componentData.display.color = "#a1d5cb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a1d5cb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6765 Spa";
  option.attributes.componentData.display.color = "#a7dcdc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a7dcdc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6772 Cay";
  option.attributes.componentData.display.color = "#a6d0d6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a6d0d6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6779 Liquid Blue";
  option.attributes.componentData.display.color = "#a6d4de";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a6d4de";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6786 Cloudless";
  option.attributes.componentData.display.color = "#8fd0dd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8fd0dd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6793 Bluebell";
  option.attributes.componentData.display.color = "#a2d5e7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a2d5e7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9043 Aquastone";
  option.attributes.componentData.display.color = "#89c6b7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_89c6b7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9044 Little Blue Box";
  option.attributes.componentData.display.color = "#8ac5ba";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8ac5ba";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9045 Bora Bora Shore";
  option.attributes.componentData.display.color = "#92d0d0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_92d0d0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9046 Gentle Aquamarine";
  option.attributes.componentData.display.color = "#97cbd2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_97cbd2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9047 After the Rain";
  option.attributes.componentData.display.color = "#8bc4d1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8bc4d1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9048 Surfin'";
  option.attributes.componentData.display.color = "#73c0d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_73c0d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9049 Sky Fall";
  option.attributes.componentData.display.color = "#89c6df";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_89c6df";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6752 Larchmere";
  option.attributes.componentData.display.color = "#70baa7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_70baa7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6759 Cooled Blue";
  option.attributes.componentData.display.color = "#75b9ae";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_75b9ae";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6766 Mariner";
  option.attributes.componentData.display.color = "#6ec2c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6ec2c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6773 Rapture Blue";
  option.attributes.componentData.display.color = "#7dc1cb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7dc1cb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6780 Nautilus";
  option.attributes.componentData.display.color = "#71b8c7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_71b8c7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6787 Fountain";
  option.attributes.componentData.display.color = "#56b5ca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_56b5ca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6794 Flyway";
  option.attributes.componentData.display.color = "#5db3d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5db3d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6753 Jargon Jade";
  option.attributes.componentData.display.color = "#53a38f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_53a38f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6760 Rivulet";
  option.attributes.componentData.display.color = "#61a89d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_61a89d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6767 Aquarium";
  option.attributes.componentData.display.color = "#3aa9ae";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3aa9ae";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6774 Freshwater";
  option.attributes.componentData.display.color = "#4da6b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4da6b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6781 Jamaica Bay";
  option.attributes.componentData.display.color = "#34a3b6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_34a3b6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6788 Capri";
  option.attributes.componentData.display.color = "#01a0b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_01a0b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6795 Major Blue";
  option.attributes.componentData.display.color = "#289ec4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_289ec4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6754 Ionian";
  option.attributes.componentData.display.color = "#368976";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_368976";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6761 Thermal Spring";
  option.attributes.componentData.display.color = "#3b8c80";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3b8c80";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6768 Gulfstream";
  option.attributes.componentData.display.color = "#01858b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_01858b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6775 Briny";
  option.attributes.componentData.display.color = "#08808e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_08808e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6782 Cruising";
  option.attributes.componentData.display.color = "#018498";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_018498";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6789 Blue Mosque";
  option.attributes.componentData.display.color = "#01819e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_01819e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6796 Blue Plate";
  option.attributes.componentData.display.color = "#017ca7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_017ca7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6755 Starboard";
  option.attributes.componentData.display.color = "#016c4f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_016c4f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6762 Poseidon";
  option.attributes.componentData.display.color = "#016d60";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_016d60";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6769 Maxi Teal";
  option.attributes.componentData.display.color = "#017478";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_017478";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6776 Blue Nile";
  option.attributes.componentData.display.color = "#01717e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_01717e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6783 Amalfi";
  option.attributes.componentData.display.color = "#016e85";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_016e85";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6790 Adriatic Sea";
  option.attributes.componentData.display.color = "#016081";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_016081";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6797 Jay Blue";
  option.attributes.componentData.display.color = "#015d87";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_015d87";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "blue medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_blue_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6470 Waterscape";
  option.attributes.componentData.display.color = "#bfd2c9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bfd2c9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6477 Tidewater";
  option.attributes.componentData.display.color = "#c3d7d3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c3d7d3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6484 Meander Blue";
  option.attributes.componentData.display.color = "#bedbd8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bedbd8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6491 Open Air";
  option.attributes.componentData.display.color = "#c7dfe0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c7dfe0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9054 Little Boy Blu";
  option.attributes.componentData.display.color = "#c7d8db";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c7d8db";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6498 Byte Blue";
  option.attributes.componentData.display.color = "#c5dce0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5dce0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6505 Atmospheric";
  option.attributes.componentData.display.color = "#c2dae0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2dae0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6471 Hazel";
  option.attributes.componentData.display.color = "#a8c1b7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a8c1b7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6478 Watery";
  option.attributes.componentData.display.color = "#b4ccc9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b4ccc9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6485 Raindrop";
  option.attributes.componentData.display.color = "#9ec6c6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9ec6c6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6492 Jetstream";
  option.attributes.componentData.display.color = "#b0d2d6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b0d2d6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9055 Billowy Breeze";
  option.attributes.componentData.display.color = "#afc7cd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_afc7cd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6499 Stream";
  option.attributes.componentData.display.color = "#adccd3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_adccd3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6506 Vast Sky";
  option.attributes.componentData.display.color = "#a9c9d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a9c9d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9050 Vintage Vessel";
  option.attributes.componentData.display.color = "#94b2a6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_94b2a6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9051 Aquaverde";
  option.attributes.componentData.display.color = "#a3c0bd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a3c0bd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9052 Blithe Blue";
  option.attributes.componentData.display.color = "#90bdbd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_90bdbd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9053 Agua Fría";
  option.attributes.componentData.display.color = "#9fc5cc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9fc5cc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9056 French Moire";
  option.attributes.componentData.display.color = "#9fbbc3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9fbbc3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9061 Rest Assured";
  option.attributes.componentData.display.color = "#9bbfc9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9bbfc9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6507 Resolute Blue";
  option.attributes.componentData.display.color = "#85b0c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_85b0c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6472 Composed";
  option.attributes.componentData.display.color = "#7ea298";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7ea298";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6479 Drizzle";
  option.attributes.componentData.display.color = "#8caeab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8caeab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6486 Reflecting Pool";
  option.attributes.componentData.display.color = "#7bb1b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7bb1b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6493 Ebbtide";
  option.attributes.componentData.display.color = "#84b4be";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_84b4be";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9057 Aquitaine";
  option.attributes.componentData.display.color = "#88abb4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_88abb4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6500 Open Seas";
  option.attributes.componentData.display.color = "#83afbc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_83afbc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9062 Bluebird Feather";
  option.attributes.componentData.display.color = "#6f9db3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6f9db3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6473 Surf Green";
  option.attributes.componentData.display.color = "#5f887d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5f887d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6480 Lagoon";
  option.attributes.componentData.display.color = "#518682";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_518682";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6487 Cloudburst";
  option.attributes.componentData.display.color = "#5c9598";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5c9598";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6494 Lakeshore";
  option.attributes.componentData.display.color = "#5b96a2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5b96a2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9058 Secret Cove";
  option.attributes.componentData.display.color = "#68909d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_68909d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6501 Manitou Blue";
  option.attributes.componentData.display.color = "#5b92a2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5b92a2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6508 Secure Blue";
  option.attributes.componentData.display.color = "#5389a1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5389a1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6474 Raging Sea";
  option.attributes.componentData.display.color = "#476f65";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_476f65";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6481 Green Bay";
  option.attributes.componentData.display.color = "#2e6864";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2e6864";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6488 Grand Canal";
  option.attributes.componentData.display.color = "#3c797d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3c797d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6495 Great Falls";
  option.attributes.componentData.display.color = "#217786";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_217786";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9059 Silken Peacock";
  option.attributes.componentData.display.color = "#427584";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_427584";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6502 Loch Blue";
  option.attributes.componentData.display.color = "#2f778b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2f778b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6509 Georgian Bay";
  option.attributes.componentData.display.color = "#22657f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_22657f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6475 Country Squire";
  option.attributes.componentData.display.color = "#124a42";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_124a42";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6482 Cape Verde";
  option.attributes.componentData.display.color = "#01554f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_01554f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6489 Really Teal";
  option.attributes.componentData.display.color = "#016367";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_016367";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6496 Oceanside";
  option.attributes.componentData.display.color = "#015a6b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_015a6b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9060 Connor's Lakefront";
  option.attributes.componentData.display.color = "#175a6c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_175a6c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6503 Bosporus";
  option.attributes.componentData.display.color = "#015d75";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_015d75";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6510 Loyal Blue";
  option.attributes.componentData.display.color = "#01455e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_01455e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "blue low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_blue_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6218 Tradewind";
  option.attributes.componentData.display.color = "#c2cfcf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2cfcf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6225 Sleepy Blue";
  option.attributes.componentData.display.color = "#bccbce";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bccbce";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9136 Lullaby";
  option.attributes.componentData.display.color = "#cbd4d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbd4d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6232 Misty";
  option.attributes.componentData.display.color = "#cdd2d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cdd2d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9144 Moonmist";
  option.attributes.componentData.display.color = "#c9d9e0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c9d9e0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6239 Upward";
  option.attributes.componentData.display.color = "#bfc9d0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bfc9d0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6246 North Star";
  option.attributes.componentData.display.color = "#cad0d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cad0d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6219 Rain";
  option.attributes.componentData.display.color = "#abbebf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_abbebf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6226 Languid Blue";
  option.attributes.componentData.display.color = "#a4b7bd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a4b7bd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9137 Niebla Azul";
  option.attributes.componentData.display.color = "#b6c3c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b6c3c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6233 Samovar Silver";
  option.attributes.componentData.display.color = "#b8bebe";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b8bebe";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9145 Sleepy Hollow";
  option.attributes.componentData.display.color = "#b7c9d1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b7c9d1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6240 Windy Blue";
  option.attributes.componentData.display.color = "#aabac6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aabac6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6247 Krypton";
  option.attributes.componentData.display.color = "#b8c0c3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b8c0c3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6220 Interesting Aqua";
  option.attributes.componentData.display.color = "#9bafb2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9bafb2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6227 Meditative";
  option.attributes.componentData.display.color = "#96aab0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_96aab0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9138 Stardew";
  option.attributes.componentData.display.color = "#a6b2b5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a6b2b5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6234 Uncertain Gray";
  option.attributes.componentData.display.color = "#a9b0b1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a9b0b1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9146 Faded Flaxflower";
  option.attributes.componentData.display.color = "#9eb4c0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9eb4c0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6241 Aleutian";
  option.attributes.componentData.display.color = "#98a9b7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_98a9b7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6248 Jubilee";
  option.attributes.componentData.display.color = "#adb5b9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_adb5b9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9134 Delft";
  option.attributes.componentData.display.color = "#8b9fa0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8b9fa0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9135 Whirlpool";
  option.attributes.componentData.display.color = "#80969d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_80969d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9139 Debonair";
  option.attributes.componentData.display.color = "#90a0a6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_90a0a6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9143 Cadet";
  option.attributes.componentData.display.color = "#91999c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_91999c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9147 Favorite Jeans";
  option.attributes.componentData.display.color = "#8aa3b1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8aa3b1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9151 Daphne";
  option.attributes.componentData.display.color = "#899caa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_899caa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9152 Let it Rain";
  option.attributes.componentData.display.color = "#979fa5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_979fa5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6221 Moody Blue";
  option.attributes.componentData.display.color = "#7a9192";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a9192";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6228 Refuge";
  option.attributes.componentData.display.color = "#607d84";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_607d84";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9140 Blustery Sky";
  option.attributes.componentData.display.color = "#6f848c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6f848c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6235 Foggy Day";
  option.attributes.componentData.display.color = "#727c7f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_727c7f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9148 Smoky Azurite";
  option.attributes.componentData.display.color = "#708d9e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_708d9e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6242 Bracing Blue";
  option.attributes.componentData.display.color = "#768b9a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_768b9a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6249 Storm Cloud";
  option.attributes.componentData.display.color = "#7a848d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a848d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6222 Riverway";
  option.attributes.componentData.display.color = "#5d7274";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5d7274";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6229 Tempe Star";
  option.attributes.componentData.display.color = "#47626a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_47626a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9141 Waterloo";
  option.attributes.componentData.display.color = "#536872";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_536872";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6236 Grays Harbor";
  option.attributes.componentData.display.color = "#596368";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_596368";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9149 Inky Blue";
  option.attributes.componentData.display.color = "#4e7287";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4e7287";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6243 Distance";
  option.attributes.componentData.display.color = "#5d6f7f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5d6f7f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6250 Granite Peak";
  option.attributes.componentData.display.color = "#606b75";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_606b75";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6223 Still Water";
  option.attributes.componentData.display.color = "#4a5d5f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4a5d5f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6230 Rainstorm";
  option.attributes.componentData.display.color = "#244653";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_244653";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9142 Moscow Midnight";
  option.attributes.componentData.display.color = "#204652";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_204652";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6237 Dark Night";
  option.attributes.componentData.display.color = "#23383f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_23383f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9150 Endless Sea";
  option.attributes.componentData.display.color = "#32586e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_32586e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7602 Indigo Batik";
  option.attributes.componentData.display.color = "#3e5063";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3e5063";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6251 Outerspace";
  option.attributes.componentData.display.color = "#586168";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_586168";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "purple";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_purple";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "purple high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_purple_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6799 Soar";
  option.attributes.componentData.display.color = "#c3dfe8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c3dfe8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6526 Icelandic";
  option.attributes.componentData.display.color = "#cbd8e1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbd8e1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6806 Rhythmic Blue";
  option.attributes.componentData.display.color = "#ccdbe5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ccdbe5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6813 Wishful Blue";
  option.attributes.componentData.display.color = "#d8dde6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d8dde6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6820 Inspired Lilac";
  option.attributes.componentData.display.color = "#dfd9e4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dfd9e4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6827 Elation";
  option.attributes.componentData.display.color = "#dfdce5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dfdce5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6834 Spangle";
  option.attributes.componentData.display.color = "#e5dbe5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5dbe5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6800 Something Blue";
  option.attributes.componentData.display.color = "#b0d6e6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b0d6e6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6527 Blissful Blue";
  option.attributes.componentData.display.color = "#b2c8d8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b2c8d8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6807 Wondrous Blue";
  option.attributes.componentData.display.color = "#b8cddd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b8cddd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6814 Breathtaking";
  option.attributes.componentData.display.color = "#c7d1e2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c7d1e2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6821 Potentially Purple";
  option.attributes.componentData.display.color = "#d1cbdf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1cbdf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6828 Rhapsody Lilac";
  option.attributes.componentData.display.color = "#d2c8dd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d2c8dd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6835 Euphoric Lilac";
  option.attributes.componentData.display.color = "#dac7da";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dac7da";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9063 Porch Ceiling";
  option.attributes.componentData.display.color = "#9bc8de";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9bc8de";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6528 Cosmos";
  option.attributes.componentData.display.color = "#8ea9c2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8ea9c2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6808 Celestial";
  option.attributes.componentData.display.color = "#97b3d0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_97b3d0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9066 Agapanthus";
  option.attributes.componentData.display.color = "#bbc5de";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bbc5de";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6822 Wisteria";
  option.attributes.componentData.display.color = "#bdb4d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bdb4d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6829 Magical";
  option.attributes.componentData.display.color = "#c0afd0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c0afd0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6836 Novel Lilac";
  option.attributes.componentData.display.color = "#c2a4c2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2a4c2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6801 Regale Blue";
  option.attributes.componentData.display.color = "#7db5d3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7db5d3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9064 Bluesy Note";
  option.attributes.componentData.display.color = "#7c9ab5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7c9ab5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6809 Lobelia";
  option.attributes.componentData.display.color = "#7498be";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7498be";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6815 Awesome Violet";
  option.attributes.componentData.display.color = "#a7b2d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a7b2d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9067 Forever Lilac";
  option.attributes.componentData.display.color = "#afa5c7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_afa5c7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9068 Berry Frappé";
  option.attributes.componentData.display.color = "#b3a1c6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b3a1c6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6837 Baroness";
  option.attributes.componentData.display.color = "#a785a7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a785a7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6802 Jacaranda";
  option.attributes.componentData.display.color = "#5a9ec0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5a9ec0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6529 Scanda";
  option.attributes.componentData.display.color = "#6b8ca9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6b8ca9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9065 Perfect Periwinkle";
  option.attributes.componentData.display.color = "#6487b0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6487b0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6816 Dahlia";
  option.attributes.componentData.display.color = "#8b98c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8b98c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6823 Brave Purple";
  option.attributes.componentData.display.color = "#968db8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_968db8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6830 Kismet";
  option.attributes.componentData.display.color = "#a18ab7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a18ab7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9069 Veri Berri";
  option.attributes.componentData.display.color = "#937496";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_937496";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6803 Danube";
  option.attributes.componentData.display.color = "#2377a2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2377a2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6530 Revel Blue";
  option.attributes.componentData.display.color = "#4c6b8a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4c6b8a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6810 Lupine";
  option.attributes.componentData.display.color = "#4e739f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4e739f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6817 Gentian";
  option.attributes.componentData.display.color = "#6572a5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6572a5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6824 Forget-Me-Not";
  option.attributes.componentData.display.color = "#716998";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_716998";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6831 Clematis";
  option.attributes.componentData.display.color = "#7e6596";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7e6596";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6838 Vigorous Violet";
  option.attributes.componentData.display.color = "#7c5a7e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7c5a7e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6804 Dignity Blue";
  option.attributes.componentData.display.color = "#094c73";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_094c73";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6531 Indigo";
  option.attributes.componentData.display.color = "#284a70";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_284a70";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6811 Honorable Blue";
  option.attributes.componentData.display.color = "#164576";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_164576";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6818 Valiant Violet";
  option.attributes.componentData.display.color = "#3e4371";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3e4371";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6825 Izmir Purple";
  option.attributes.componentData.display.color = "#4d426e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4d426e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6832 Impulsive Purple";
  option.attributes.componentData.display.color = "#624977";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_624977";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6839 Kimono Violet";
  option.attributes.componentData.display.color = "#5d395f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5d395f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "purple medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_purple_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6512 Balmy";
  option.attributes.componentData.display.color = "#c5d8de";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5d8de";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6519 Hinting Blue";
  option.attributes.componentData.display.color = "#ced9dd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ced9dd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6533 Mild Blue";
  option.attributes.componentData.display.color = "#cbd5db";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbd5db";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6540 Starry Night";
  option.attributes.componentData.display.color = "#d6d9de";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6d9de";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6547 Silver Peony";
  option.attributes.componentData.display.color = "#dad6db";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dad6db";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6554 Lite Lavender";
  option.attributes.componentData.display.color = "#e0dadf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0dadf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6281 Wallflower";
  option.attributes.componentData.display.color = "#dbcfd4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbcfd4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6513 Take Five";
  option.attributes.componentData.display.color = "#b3c9d3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b3c9d3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6520 Honest Blue";
  option.attributes.componentData.display.color = "#b2c7d3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b2c7d3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6534 Icy";
  option.attributes.componentData.display.color = "#bbc7d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bbc7d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6541 Daydream";
  option.attributes.componentData.display.color = "#bdc3cd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bdc3cd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6548 Grape Mist";
  option.attributes.componentData.display.color = "#c5c0c9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5c0c9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6555 Enchant";
  option.attributes.componentData.display.color = "#d1c6d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1c6d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6282 Mauve Finery";
  option.attributes.componentData.display.color = "#cbb8c0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbb8c0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6514 Respite";
  option.attributes.componentData.display.color = "#97b4c3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_97b4c3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6521 Notable Hue";
  option.attributes.componentData.display.color = "#8ba7bb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8ba7bb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6535 Solitude";
  option.attributes.componentData.display.color = "#99a7b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_99a7b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6542 Vesper Violet";
  option.attributes.componentData.display.color = "#99a0b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_99a0b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6549 Ash Violet";
  option.attributes.componentData.display.color = "#a29baa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a29baa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6556 Obi Lilac";
  option.attributes.componentData.display.color = "#b0a3b6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b0a3b6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6283 Thistle";
  option.attributes.componentData.display.color = "#aa8e9a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aa8e9a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9070 Baby Blue Eyes";
  option.attributes.componentData.display.color = "#83a2b4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_83a2b4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9071 Dyer's Woad";
  option.attributes.componentData.display.color = "#7b99b0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7b99b0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9072 Dried Lavender";
  option.attributes.componentData.display.color = "#8595aa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8595aa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9073 Dusty Heather";
  option.attributes.componentData.display.color = "#8990a3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8990a3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9074 Gentle Grape";
  option.attributes.componentData.display.color = "#908a9b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_908a9b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9075 Berry Cream";
  option.attributes.componentData.display.color = "#9a8ca2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9a8ca2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9076 Ruby Violet";
  option.attributes.componentData.display.color = "#9b7e8b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9b7e8b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6515 Leisure Blue";
  option.attributes.componentData.display.color = "#6a8ea1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6a8ea1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6522 Sporty Blue";
  option.attributes.componentData.display.color = "#6a8aa4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6a8aa4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6536 Searching Blue";
  option.attributes.componentData.display.color = "#6c7f9a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6c7f9a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6543 Soulful Blue";
  option.attributes.componentData.display.color = "#757c91";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_757c91";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6550 Mythical";
  option.attributes.componentData.display.color = "#7e778e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7e778e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6557 Wood Violet";
  option.attributes.componentData.display.color = "#7a6b85";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7a6b85";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6284 Plum Dandy";
  option.attributes.componentData.display.color = "#8b6878";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8b6878";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6516 Down Pour";
  option.attributes.componentData.display.color = "#43718b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_43718b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6523 Denim";
  option.attributes.componentData.display.color = "#506b84";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_506b84";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6537 Luxe Blue";
  option.attributes.componentData.display.color = "#516582";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_516582";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6544 Mesmerize";
  option.attributes.componentData.display.color = "#5d657b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5d657b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6551 Purple Passage";
  option.attributes.componentData.display.color = "#645e77";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_645e77";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6558 Plummy";
  option.attributes.componentData.display.color = "#675a75";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_675a75";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6285 Grape Harvest";
  option.attributes.componentData.display.color = "#7e5a6d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7e5a6d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6517 Regatta";
  option.attributes.componentData.display.color = "#215772";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_215772";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6524 Commodore";
  option.attributes.componentData.display.color = "#25476a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_25476a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6538 Dignified";
  option.attributes.componentData.display.color = "#3b496d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3b496d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6545 Majestic Purple";
  option.attributes.componentData.display.color = "#3b3c5a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3b3c5a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6552 Dewberry";
  option.attributes.componentData.display.color = "#3e385a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3e385a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6559 Concord Grape";
  option.attributes.componentData.display.color = "#443757";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_443757";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6286 Mature Grape";
  option.attributes.componentData.display.color = "#5f3f54";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5f3f54";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "purple low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_purple_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6274 Destiny";
  option.attributes.componentData.display.color = "#cfc9c8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfc9c8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7078 Minute Mauve";
  option.attributes.componentData.display.color = "#cfc9c8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfc9c8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6260 Unique Gray";
  option.attributes.componentData.display.color = "#cbc9c9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbc9c9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6267 Sensitive Tint";
  option.attributes.componentData.display.color = "#cec9cc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cec9cc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6015 Vaguely Mauve";
  option.attributes.componentData.display.color = "#d1c5c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1c5c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6008 Individual White";
  option.attributes.componentData.display.color = "#d4cdca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d4cdca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6036 Angora";
  option.attributes.componentData.display.color = "#d1c5be";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1c5be";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6275 Fashionable Gray";
  option.attributes.componentData.display.color = "#bdb8b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bdb8b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7079 Ponder";
  option.attributes.componentData.display.color = "#bcb6b6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bcb6b6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6261 Swanky Gray";
  option.attributes.componentData.display.color = "#b5b1b5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b5b1b5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6268 Veiled Violet";
  option.attributes.componentData.display.color = "#bdb5b9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bdb5b9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6016 Chaise Mauve";
  option.attributes.componentData.display.color = "#c1b2b3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1b2b3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6009 Imagine";
  option.attributes.componentData.display.color = "#c2b6b6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2b6b6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6037 Temperate Taupe";
  option.attributes.componentData.display.color = "#bfb1aa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bfb1aa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6276 Mystical Shade";
  option.attributes.componentData.display.color = "#aea9aa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aea9aa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7080 Quest Gray";
  option.attributes.componentData.display.color = "#ada5a5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ada5a5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6262 Mysterious Mauve";
  option.attributes.componentData.display.color = "#a6a3a9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a6a3a9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6269 Beguiling Mauve";
  option.attributes.componentData.display.color = "#afa7ac";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_afa7ac";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6017 Intuitive";
  option.attributes.componentData.display.color = "#b3a3a5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b3a3a5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6010 Flexible Gray";
  option.attributes.componentData.display.color = "#b1a3a1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b1a3a1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6038 Truly Taupe";
  option.attributes.componentData.display.color = "#ac9e97";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ac9e97";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9153 Moonlit Orchid";
  option.attributes.componentData.display.color = "#949194";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_949194";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9155 Slate Violet";
  option.attributes.componentData.display.color = "#989192";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_989192";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9156 Gris Morado";
  option.attributes.componentData.display.color = "#8f8a91";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8f8a91";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9157 Autumn Orchid";
  option.attributes.componentData.display.color = "#9d9093";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9d9093";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9158 Coquina";
  option.attributes.componentData.display.color = "#9d8d8e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9d8d8e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9159 Auger Shell";
  option.attributes.componentData.display.color = "#9f9291";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9f9291";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9160 Armadillo";
  option.attributes.componentData.display.color = "#9e9089";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e9089";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6277 Special Gray";
  option.attributes.componentData.display.color = "#7b787d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7b787d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7081 Sensuous Gray";
  option.attributes.componentData.display.color = "#837d7f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_837d7f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6263 Exclusive Plum";
  option.attributes.componentData.display.color = "#736f78";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_736f78";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6270 Soulmate";
  option.attributes.componentData.display.color = "#85777b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_85777b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6018 Enigma";
  option.attributes.componentData.display.color = "#8b7c7e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8b7c7e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6011 Chinchilla";
  option.attributes.componentData.display.color = "#867875";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_867875";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6039 Poised Taupe";
  option.attributes.componentData.display.color = "#8c7e78";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8c7e78";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6278 Cloak Gray";
  option.attributes.componentData.display.color = "#605e63";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_605e63";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7082 Stunning Shade";
  option.attributes.componentData.display.color = "#676064";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_676064";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6264 Midnight";
  option.attributes.componentData.display.color = "#5d5962";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5d5962";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6271 Expressive Plum";
  option.attributes.componentData.display.color = "#695c62";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_695c62";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6019 Poetry Plum";
  option.attributes.componentData.display.color = "#6f5c5f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6f5c5f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6012 Browse Brown";
  option.attributes.componentData.display.color = "#6e615f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6e615f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6040 Nutshell";
  option.attributes.componentData.display.color = "#756761";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_756761";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9154 Perle Noir";
  option.attributes.componentData.display.color = "#4f4d51";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4f4d51";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7083 Darkroom";
  option.attributes.componentData.display.color = "#443e40";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_443e40";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6265 Quixotic Plum";
  option.attributes.componentData.display.color = "#4a4653";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4a4653";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6272 Plum Brown";
  option.attributes.componentData.display.color = "#4e4247";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4e4247";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6020 Marooned";
  option.attributes.componentData.display.color = "#4e3132";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4e3132";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6013 Bitter Chocolate";
  option.attributes.componentData.display.color = "#4d3c3c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4d3c3c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6041 Otter";
  option.attributes.componentData.display.color = "#56433b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_56433b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "neutral";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_neutral";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "neutral high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_neutral_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7661 Reflection";
  option.attributes.componentData.display.color = "#d3d5d3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d3d5d3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6253 Olympus White";
  option.attributes.componentData.display.color = "#d4d8d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d4d8d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7071 Gray Screen";
  option.attributes.componentData.display.color = "#c6caca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c6caca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7064 Passive";
  option.attributes.componentData.display.color = "#cbccc9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cbccc9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7057 Silver Strand";
  option.attributes.componentData.display.color = "#c8cbc4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c8cbc4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9165 Gossamer Veil";
  option.attributes.componentData.display.color = "#d3cec4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d3cec4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7653 Silverpointe";
  option.attributes.componentData.display.color = "#d1d2cb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1d2cb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7662 Evening Shadow";
  option.attributes.componentData.display.color = "#c9cccd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c9cccd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6254 Lazy Gray";
  option.attributes.componentData.display.color = "#bec1c3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bec1c3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7072 Online";
  option.attributes.componentData.display.color = "#b0b5b5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b0b5b5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7065 Argos";
  option.attributes.componentData.display.color = "#bdbdb7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bdbdb7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7058 Magnetic Gray";
  option.attributes.componentData.display.color = "#b2b5af";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b2b5af";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9166 Drift of Mist";
  option.attributes.componentData.display.color = "#dcd8d0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcd8d0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7654 Lattice";
  option.attributes.componentData.display.color = "#cecec6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cecec6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7663 Monorail Silver";
  option.attributes.componentData.display.color = "#b8bcbb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b8bcbb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6255 Morning Fog";
  option.attributes.componentData.display.color = "#a8aeb1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a8aeb1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7073 Network Gray";
  option.attributes.componentData.display.color = "#a0a5a7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a0a5a7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7066 Gray Matters";
  option.attributes.componentData.display.color = "#a7a8a2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a7a8a2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7059 Unusual Gray";
  option.attributes.componentData.display.color = "#a3a7a0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a3a7a0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7658 Gray Clouds";
  option.attributes.componentData.display.color = "#b7b7b2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b7b7b2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7651 Front Porch";
  option.attributes.componentData.display.color = "#ccccc5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ccccc5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7664 Steely Gray";
  option.attributes.componentData.display.color = "#90979b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_90979b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9161 Dustblu";
  option.attributes.componentData.display.color = "#959ba0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_959ba0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9162 African Gray";
  option.attributes.componentData.display.color = "#939899";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_939899";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9163 Tin Lizzie";
  option.attributes.componentData.display.color = "#939591";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_939591";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9164 Illusive Green";
  option.attributes.componentData.display.color = "#92948d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_92948d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7652 Mineral Deposit";
  option.attributes.componentData.display.color = "#abb0ac";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_abb0ac";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7657 Tinsmith";
  option.attributes.componentData.display.color = "#c5c8c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5c8c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7665 Wall Street";
  option.attributes.componentData.display.color = "#656d73";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_656d73";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6256 Serious Gray";
  option.attributes.componentData.display.color = "#7d848b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7d848b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7074 Software";
  option.attributes.componentData.display.color = "#7f8486";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7f8486";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7067 Cityscape";
  option.attributes.componentData.display.color = "#7f817e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7f817e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7060 Attitude Gray";
  option.attributes.componentData.display.color = "#7c7d75";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7c7d75";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7659 Gris";
  option.attributes.componentData.display.color = "#a5a9a8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a5a9a8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7649 Silverplate";
  option.attributes.componentData.display.color = "#c2c0ba";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2c0ba";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7624 Slate Tile";
  option.attributes.componentData.display.color = "#606e74";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_606e74";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6257 Gibraltar";
  option.attributes.componentData.display.color = "#626970";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_626970";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7075 Web Gray";
  option.attributes.componentData.display.color = "#616669";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_616669";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7068 Grizzle Gray";
  option.attributes.componentData.display.color = "#636562";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_636562";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7061 Night Owl";
  option.attributes.componentData.display.color = "#63655f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_63655f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7660 Earl Grey";
  option.attributes.componentData.display.color = "#969a96";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_969a96";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7650 Ellie Gray";
  option.attributes.componentData.display.color = "#aaa9a4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aaa9a4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7604 Smoky Blue";
  option.attributes.componentData.display.color = "#596e79";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_596e79";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7615 Sea Serpent";
  option.attributes.componentData.display.color = "#3e4b54";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3e4b54";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7076 Cyberspace";
  option.attributes.componentData.display.color = "#44484d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_44484d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7674 Peppercorn";
  option.attributes.componentData.display.color = "#585858";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_585858";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7749 Laurel Woods";
  option.attributes.componentData.display.color = "#44493d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_44493d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7622 Homburg Gray";
  option.attributes.componentData.display.color = "#666d69";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_666d69";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7655 Stamped Concrete";
  option.attributes.componentData.display.color = "#a0a09a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a0a09a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "neutral medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_neutral_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6001 Grayish";
  option.attributes.componentData.display.color = "#cfcac7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfcac7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7022 Alpaca";
  option.attributes.componentData.display.color = "#ccc5bd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ccc5bd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6071 Popular Gray";
  option.attributes.componentData.display.color = "#d4ccc3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d4ccc3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7029 Agreeable Gray";
  option.attributes.componentData.display.color = "#d1cbc1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1cbc1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7015 Repose Gray";
  option.attributes.componentData.display.color = "#ccc9c0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ccc9c0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7043 Worldly Gray";
  option.attributes.componentData.display.color = "#cec6bb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cec6bb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7050 Useful Gray";
  option.attributes.componentData.display.color = "#cfcabd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cfcabd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6002 Essential Gray";
  option.attributes.componentData.display.color = "#bcb8b6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bcb8b6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7023 Requisite Gray";
  option.attributes.componentData.display.color = "#b9b2a9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b9b2a9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6072 Versatile Gray";
  option.attributes.componentData.display.color = "#c1b6ab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c1b6ab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7030 Anew Gray";
  option.attributes.componentData.display.color = "#bfb6aa";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bfb6aa";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7016 Mindful Gray";
  option.attributes.componentData.display.color = "#bcb7ad";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bcb7ad";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7044 Amazing Gray";
  option.attributes.componentData.display.color = "#beb5a9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_beb5a9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7051 Analytical Gray";
  option.attributes.componentData.display.color = "#bfb6a7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_bfb6a7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6003 Proper Gray";
  option.attributes.componentData.display.color = "#ada8a5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ada8a5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7024 Functional Gray";
  option.attributes.componentData.display.color = "#aba39a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aba39a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6073 Perfect Greige";
  option.attributes.componentData.display.color = "#b7ab9f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b7ab9f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7031 Mega Greige";
  option.attributes.componentData.display.color = "#ada295";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ada295";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7017 Dorian Gray";
  option.attributes.componentData.display.color = "#aca79e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aca79e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7045 Intellectual Gray";
  option.attributes.componentData.display.color = "#a8a093";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a8a093";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7052 Gray Area";
  option.attributes.componentData.display.color = "#afa696";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_afa696";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9167 Polished Concrete";
  option.attributes.componentData.display.color = "#9e9793";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e9793";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9168 Elephant Ear";
  option.attributes.componentData.display.color = "#988f85";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_988f85";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9169 Chatura Gray";
  option.attributes.componentData.display.color = "#a09287";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a09287";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7504 Keystone Gray";
  option.attributes.componentData.display.color = "#9e9284";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e9284";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9170 Acier";
  option.attributes.componentData.display.color = "#9e9991";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9e9991";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9171 Felted Wool";
  option.attributes.componentData.display.color = "#979083";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_979083";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9172 Studio Clay";
  option.attributes.componentData.display.color = "#958d7f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_958d7f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6004 Mink";
  option.attributes.componentData.display.color = "#847b77";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_847b77";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7025 Backdrop";
  option.attributes.componentData.display.color = "#867a6f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_867a6f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6074 Spalding Gray";
  option.attributes.componentData.display.color = "#8d7f75";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8d7f75";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7032 Warm Stone";
  option.attributes.componentData.display.color = "#887b6c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_887b6c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7018 Dovetail";
  option.attributes.componentData.display.color = "#908a83";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_908a83";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7046 Anonymous";
  option.attributes.componentData.display.color = "#817a6e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_817a6e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7053 Adaptive Shade";
  option.attributes.componentData.display.color = "#867e70";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_867e70";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6005 Folkstone";
  option.attributes.componentData.display.color = "#6d6562";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6d6562";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7026 Griffin";
  option.attributes.componentData.display.color = "#6f6459";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6f6459";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6075 Garret Gray";
  option.attributes.componentData.display.color = "#756861";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_756861";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7033 Brainstorm Bronze";
  option.attributes.componentData.display.color = "#74685a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_74685a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7019 Gauntlet Gray";
  option.attributes.componentData.display.color = "#78736e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_78736e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7047 Porpoise";
  option.attributes.componentData.display.color = "#6b645b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_6b645b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7054 Oak Leaf Brown";
  option.attributes.componentData.display.color = "#645a4b";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_645a4b";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7505 Manor House";
  option.attributes.componentData.display.color = "#665d57";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_665d57";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7027 Hickory Smoke";
  option.attributes.componentData.display.color = "#564537";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_564537";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7510 Chateau Brown";
  option.attributes.componentData.display.color = "#5b4b44";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5b4b44";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7034 Status Bronze";
  option.attributes.componentData.display.color = "#5c4d3c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_5c4d3c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7020 Black Fox";
  option.attributes.componentData.display.color = "#4f4842";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_4f4842";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7048 Urbane Bronze";
  option.attributes.componentData.display.color = "#54504a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_54504a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7055 Enduring Bronze";
  option.attributes.componentData.display.color = "#554c3e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_554c3e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "neutral low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_neutral_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7541 Grecian Ivory";
  option.attributes.componentData.display.color = "#d8cfbe";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d8cfbe";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9173 Shiitake";
  option.attributes.componentData.display.color = "#c8bcab";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c8bcab";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7036 Accessible Beige";
  option.attributes.componentData.display.color = "#d1c7b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d1c7b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7517 Rivers Edge";
  option.attributes.componentData.display.color = "#dbcebd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbcebd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6258 Tricorn Black";
  option.attributes.componentData.display.color = "#2f2f30";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2f2f30";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 2735 Rockweed";
  option.attributes.componentData.display.color = "#443735";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_443735";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9176 Dress Blues";
  option.attributes.componentData.display.color = "#2b4360";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2b4360";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7638 Jogging Path";
  option.attributes.componentData.display.color = "#c0b9a9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c0b9a9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7506 Loggia";
  option.attributes.componentData.display.color = "#c4b7a5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c4b7a5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7037 Balanced Beige";
  option.attributes.componentData.display.color = "#c0b2a2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c0b2a2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7511 Bungalow Beige";
  option.attributes.componentData.display.color = "#cdbfb0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cdbfb0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6990 Caviar";
  option.attributes.componentData.display.color = "#313031";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_313031";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6006 Black Bean";
  option.attributes.componentData.display.color = "#403330";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_403330";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9177 Salty Dog";
  option.attributes.componentData.display.color = "#234058";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_234058";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7644 Gateway Gray";
  option.attributes.componentData.display.color = "#b2ac9c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b2ac9c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7507 Stone Lion";
  option.attributes.componentData.display.color = "#b3a491";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b3a491";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7038 Tony Taupe";
  option.attributes.componentData.display.color = "#b1a290";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_b1a290";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7524 Dhurrie Beige";
  option.attributes.componentData.display.color = "#cabaa8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cabaa8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6991 Black Magic";
  option.attributes.componentData.display.color = "#323132";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_323132";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7630 Raisin";
  option.attributes.componentData.display.color = "#392b2d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_392b2d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9178 In the Navy";
  option.attributes.componentData.display.color = "#283849";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_283849";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7639 Ethereal Mood";
  option.attributes.componentData.display.color = "#aea594";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_aea594";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7633 Taupe Tone";
  option.attributes.componentData.display.color = "#ada090";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ada090";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9174 Moth Wing";
  option.attributes.componentData.display.color = "#a0907f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a0907f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7512 Pavilion Beige";
  option.attributes.componentData.display.color = "#c5b6a4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c5b6a4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6992 Inkwell";
  option.attributes.componentData.display.color = "#31363a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_31363a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9175 Deep Forest Brown";
  option.attributes.componentData.display.color = "#393437";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_393437";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 2739 Charcoal Blue";
  option.attributes.componentData.display.color = "#3d4450";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3d4450";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7640 Fawn Brindle";
  option.attributes.componentData.display.color = "#a7a094";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a7a094";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7513 Sanderling";
  option.attributes.componentData.display.color = "#a79582";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a79582";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7039 Virtual Taupe";
  option.attributes.componentData.display.color = "#8a7a6a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_8a7a6a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7501 Threshold Taupe";
  option.attributes.componentData.display.color = "#ac9a8a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ac9a8a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6993 Black of Night";
  option.attributes.componentData.display.color = "#323639";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_323639";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6989 Domino";
  option.attributes.componentData.display.color = "#353337";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_353337";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 2740 Mineral Gray";
  option.attributes.componentData.display.color = "#515763";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_515763";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7746 Rushing River";
  option.attributes.componentData.display.color = "#a19c8f";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a19c8f";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7508 Tavern Taupe";
  option.attributes.componentData.display.color = "#9c8a79";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_9c8a79";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7040 Smokehouse";
  option.attributes.componentData.display.color = "#716354";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_716354";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7502 Dry Dock";
  option.attributes.componentData.display.color = "#a18d7d";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_a18d7d";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6994 Greenblack";
  option.attributes.componentData.display.color = "#373a3a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_373a3a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6988 Bohemian Black";
  option.attributes.componentData.display.color = "#3b373c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3b373c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6244 Naval";
  option.attributes.componentData.display.color = "#2f3d4c";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2f3d4c";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7743 Mountain Road";
  option.attributes.componentData.display.color = "#868578";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_868578";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7514 Foothills";
  option.attributes.componentData.display.color = "#827466";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_827466";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7041 Van Dyke Brown";
  option.attributes.componentData.display.color = "#564536";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_564536";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7509 Tiki Hut";
  option.attributes.componentData.display.color = "#826f5e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_826f5e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7069 Iron Ore";
  option.attributes.componentData.display.color = "#434341";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_434341";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6279 Black Swan";
  option.attributes.componentData.display.color = "#3a373e";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_3a373e";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9179 Anchors Aweigh";
  option.attributes.componentData.display.color = "#2b3441";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_2b3441";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  group = useAccordion2();
  targetChildren.push(group);
  group.attributes.componentData.display.label = "pastel";
  groupChildren = group.attributes.componentData.children;
  group.attributes.componentData.key = "color_group_pastel";
  group.attributes.componentData.interaction.radioGroup = colorGroupRadioGroup;
  sceneEntities.addItemToUIList(group.attributes.componentData);
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "pastel high";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_pastel_0";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7551 Greek Villa";
  option.attributes.componentData.display.color = "#f0ece2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0ece2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7005 Pure White";
  option.attributes.componentData.display.color = "#edece6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edece6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7757 High Reflective White";
  option.attributes.componentData.display.color = "#f7f7f1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f7f7f1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7006 Extra White";
  option.attributes.componentData.display.color = "#eeefea";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eeefea";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6203 Spare White";
  option.attributes.componentData.display.color = "#e4e4dd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4e4dd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6070 Heron Plume";
  option.attributes.componentData.display.color = "#e5e1d8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5e1d8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7100 Arcade White";
  option.attributes.componentData.display.color = "#f3eee7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3eee7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7009 Pearly White";
  option.attributes.componentData.display.color = "#e8e3d9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8e3d9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7008 Alabaster";
  option.attributes.componentData.display.color = "#edeae0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edeae0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7004 Snowbound";
  option.attributes.componentData.display.color = "#edeae5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edeae5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7007 Ceiling Bright White";
  option.attributes.componentData.display.color = "#e9ebe7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9ebe7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7056 Reserved White";
  option.attributes.componentData.display.color = "#e0e0d9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0e0d9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7626 Zurich White";
  option.attributes.componentData.display.color = "#e6e1d9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6e1d9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7000 Ibis White";
  option.attributes.componentData.display.color = "#f2ece6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2ece6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7013 Ivory Lace";
  option.attributes.componentData.display.color = "#ece5d8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ece5d8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7566 Westhighland White";
  option.attributes.componentData.display.color = "#f3eee3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3eee3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7003 Toque White";
  option.attributes.componentData.display.color = "#e7e2da";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7e2da";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6252 Ice Cube";
  option.attributes.componentData.display.color = "#e3e4e1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3e4e1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7666 Fleur de Sel";
  option.attributes.componentData.display.color = "#dcddd8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcddd8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7636 Origami White";
  option.attributes.componentData.display.color = "#e5e2da";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5e2da";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6028 Cultured Pearl";
  option.attributes.componentData.display.color = "#e5dcd6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5dcd6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7042 Shoji White";
  option.attributes.componentData.display.color = "#e6dfd3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6dfd3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7570 Egret White";
  option.attributes.componentData.display.color = "#dfd9cf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dfd9cf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7028 Incredible White";
  option.attributes.componentData.display.color = "#e3ded7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3ded7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7656 Rhinestone";
  option.attributes.componentData.display.color = "#dee0de";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dee0de";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6196 Frosty White";
  option.attributes.componentData.display.color = "#ddddd6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ddddd6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7035 Aesthetic White";
  option.attributes.componentData.display.color = "#e3ddd3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3ddd3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6063 Nice White";
  option.attributes.componentData.display.color = "#e6ddd5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6ddd5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7010 White Duck";
  option.attributes.componentData.display.color = "#e5dfd2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5dfd2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7634 Pediment";
  option.attributes.componentData.display.color = "#d3ccc4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d3ccc4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7014 Eider White";
  option.attributes.componentData.display.color = "#e2ded8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2ded8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7063 Nebulous White";
  option.attributes.componentData.display.color = "#dedfdc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dedfdc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6182 Ethereal White";
  option.attributes.componentData.display.color = "#e3e2d9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3e2d9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7627 White Heron";
  option.attributes.componentData.display.color = "#e7e1d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7e1d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6077 Everyday White";
  option.attributes.componentData.display.color = "#e4dcd4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4dcd4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7011 Natural Choice";
  option.attributes.componentData.display.color = "#e3ded0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3ded0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7647 Crushed Ice";
  option.attributes.componentData.display.color = "#d6d3cc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6d3cc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7646 First Star";
  option.attributes.componentData.display.color = "#dad9d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dad9d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6231 Rock Candy";
  option.attributes.componentData.display.color = "#dee1df";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dee1df";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6168 Moderne White";
  option.attributes.componentData.display.color = "#e2e0d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2e0d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7631 City Loft";
  option.attributes.componentData.display.color = "#dfdad1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dfdad1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6035 Gauzy White";
  option.attributes.componentData.display.color = "#e3dbd4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3dbd4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7637 Oyster White";
  option.attributes.componentData.display.color = "#e2ddd0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2ddd0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7671 On the Rocks";
  option.attributes.componentData.display.color = "#d0cec8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d0cec8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7648 Big Chill";
  option.attributes.componentData.display.color = "#d0cec9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d0cec9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7070 Site White";
  option.attributes.componentData.display.color = "#dcdedc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcdedc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7049 Nuance";
  option.attributes.componentData.display.color = "#e2e0d6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2e0d6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7628 Windfresh White";
  option.attributes.componentData.display.color = "#ded8cf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ded8cf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7021 Simple White";
  option.attributes.componentData.display.color = "#dfd9d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dfd9d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "pastel medium";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_pastel_1";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7103 Whitetail";
  option.attributes.componentData.display.color = "#f4efe4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4efe4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7105 Paperwhite";
  option.attributes.componentData.display.color = "#f7efde";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f7efde";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7562 Roman Column";
  option.attributes.componentData.display.color = "#f6f0e2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f6f0e2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 8917 Shell White";
  option.attributes.componentData.display.color = "#f0ebe0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0ebe0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7104 Cotton White";
  option.attributes.componentData.display.color = "#f7efe3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f7efe3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7554 Steamed Milk";
  option.attributes.componentData.display.color = "#ece1d1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ece1d1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7001 Marshmallow";
  option.attributes.componentData.display.color = "#eee9e0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eee9e0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6385 Dover White";
  option.attributes.componentData.display.color = "#f0eadc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0eadc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7106 Honied White";
  option.attributes.componentData.display.color = "#f8eedb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f8eedb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6154 Nacre";
  option.attributes.componentData.display.color = "#e8e2d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8e2d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6364 Eggwhite";
  option.attributes.componentData.display.color = "#f3e5d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3e5d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7558 Medici Ivory";
  option.attributes.componentData.display.color = "#f3e9d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3e9d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6147 Panda White";
  option.attributes.componentData.display.color = "#eae2d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eae2d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7102 White Flour";
  option.attributes.componentData.display.color = "#f4efe5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4efe5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7012 Creamy";
  option.attributes.componentData.display.color = "#efe8db";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_efe8db";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7557 Summer White";
  option.attributes.componentData.display.color = "#f4e9d6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4e9d6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7563 Restful White";
  option.attributes.componentData.display.color = "#eee8d7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eee8d7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6378 Crisp Linen";
  option.attributes.componentData.display.color = "#f3e6d4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3e6d4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6112 Biscuit";
  option.attributes.componentData.display.color = "#ebddcb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ebddcb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6140 Moderate White";
  option.attributes.componentData.display.color = "#e9decf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9decf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7002 Downy";
  option.attributes.componentData.display.color = "#efe8dd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_efe8dd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 9180 Aged White";
  option.attributes.componentData.display.color = "#e8decd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8decd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7556 Crème";
  option.attributes.componentData.display.color = "#f4e8d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f4e8d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7564 Polar Bear";
  option.attributes.componentData.display.color = "#e8dfca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8dfca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7571 Casa Blanca";
  option.attributes.componentData.display.color = "#ede1ce";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ede1ce";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6350 Intricate Ivory";
  option.attributes.componentData.display.color = "#edddca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_edddca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6105 Divine White";
  option.attributes.componentData.display.color = "#e6dccd";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6dccd";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7101 Futon";
  option.attributes.componentData.display.color = "#ede6db";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ede6db";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7568 Neutral Ground";
  option.attributes.componentData.display.color = "#e2daca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2daca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7559 Décor White";
  option.attributes.componentData.display.color = "#f2e5cf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2e5cf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6133 Muslin";
  option.attributes.componentData.display.color = "#eadfc9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eadfc9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6126 Navajo White";
  option.attributes.componentData.display.color = "#e9dcc6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9dcc6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6357 Choice Cream";
  option.attributes.componentData.display.color = "#f0e1d0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0e1d0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7516 Kestrel White";
  option.attributes.componentData.display.color = "#e0d6c8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0d6c8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6084 Modest White";
  option.attributes.componentData.display.color = "#e6ddd4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e6ddd4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7531 Canvas Tan";
  option.attributes.componentData.display.color = "#dcd1bf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcd1bf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6371 Vanillin";
  option.attributes.componentData.display.color = "#f2e3ca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2e3ca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6119 Antique White";
  option.attributes.componentData.display.color = "#e8dcc6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8dcc6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7572 Lotus Pod";
  option.attributes.componentData.display.color = "#e7d7c2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7d7c2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7553 Fragile Beauty";
  option.attributes.componentData.display.color = "#e7d7c6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7d7c6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7552 Bauhaus Buff";
  option.attributes.componentData.display.color = "#e7dbcc";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7dbcc";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6091 Reliable White";
  option.attributes.componentData.display.color = "#e8ded3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e8ded3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7555 Patience";
  option.attributes.componentData.display.color = "#e2d3bf";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2d3bf";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7573 Eaglet Beige";
  option.attributes.componentData.display.color = "#e9d9c0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9d9c0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7575 Chopsticks";
  option.attributes.componentData.display.color = "#e0d1b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0d1b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7574 Echelon Ecru";
  option.attributes.componentData.display.color = "#e7d8be";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7d8be";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7537 Irish Cream";
  option.attributes.componentData.display.color = "#e3d2b8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3d2b8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7596 Only Natural";
  option.attributes.componentData.display.color = "#e2d3c4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2d3c4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6098 Pacer White";
  option.attributes.componentData.display.color = "#e5ddd0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5ddd0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  groupSet1 = useAccordion2();
  groupChildren.push(groupSet1);
  groupSet1.attributes.componentData.display.label = "pastel low";
  groupSet1Children = groupSet1.attributes.componentData.children;
  groupSet1.attributes.componentData.key = "color_group_set_pastel_2";
  sceneEntities.addItemToUIList(groupSet1.attributes.componentData);
  groupSet1.attributes.componentData.interaction.radioGroup = colorGroupSetRadioGroup;
  flexContainer1 = useFlexContainer1();
  groupSet1Children.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6665 Gardenia";
  option.attributes.componentData.display.color = "#f3e2c9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3e2c9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6413 Restoration Ivory";
  option.attributes.componentData.display.color = "#e9e1ca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9e1ca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6161 Nonchalant White";
  option.attributes.componentData.display.color = "#deddd1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_deddd1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6476 Glimmer";
  option.attributes.componentData.display.color = "#e0e7e2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0e7e2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6504 Sky High";
  option.attributes.componentData.display.color = "#dce7e8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dce7e8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6224 Mountain Air";
  option.attributes.componentData.display.color = "#d8e0df";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d8e0df";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6056 Polite White";
  option.attributes.componentData.display.color = "#e9ddd4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9ddd4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6658 Welcome White";
  option.attributes.componentData.display.color = "#f3e3ca";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3e3ca";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6406 Ionic Ivory";
  option.attributes.componentData.display.color = "#e7dfc5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7dfc5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6434 Spinach White";
  option.attributes.componentData.display.color = "#e4e8da";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4e8da";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6189 Opaline";
  option.attributes.componentData.display.color = "#dcdfd7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dcdfd7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6511 Snowdrop";
  option.attributes.componentData.display.color = "#e0e8e7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0e8e7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6245 Quicksilver";
  option.attributes.componentData.display.color = "#dde2e0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dde2e0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6049 Gorgeous White";
  option.attributes.componentData.display.color = "#e7dbd3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e7dbd3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6644 Champagne";
  option.attributes.componentData.display.color = "#f2e3ce";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f2e3ce";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6399 Chamomile";
  option.attributes.componentData.display.color = "#e9e0c5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e9e0c5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6441 White Mint";
  option.attributes.componentData.display.color = "#e0e7da";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0e7da";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6210 Window Pane";
  option.attributes.componentData.display.color = "#d7dfd8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7dfd8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6217 Topsail";
  option.attributes.componentData.display.color = "#dae2e0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dae2e0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6238 Icicle";
  option.attributes.componentData.display.color = "#dbdfe0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dbdfe0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6042 Hush White";
  option.attributes.componentData.display.color = "#e5dad4";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e5dad4";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6336 Nearly Peach";
  option.attributes.componentData.display.color = "#efded1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_efded1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6392 Vital Yellow";
  option.attributes.componentData.display.color = "#ede0c5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ede0c5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6175 Sagey";
  option.attributes.componentData.display.color = "#e2e2d1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2e2d1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6455 Fleeting Green";
  option.attributes.componentData.display.color = "#d8e2d8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d8e2d8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6497 Blue Horizon";
  option.attributes.componentData.display.color = "#d8e7e6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d8e7e6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6525 Rarified Air";
  option.attributes.componentData.display.color = "#e1e6e6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e1e6e6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6021 Dreamy White";
  option.attributes.componentData.display.color = "#e3d9d5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e3d9d5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6329 Faint Coral";
  option.attributes.componentData.display.color = "#eeded5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_eeded5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6700 Daybreak";
  option.attributes.componentData.display.color = "#f3eac6";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3eac6";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6420 Queen Anne's Lace";
  option.attributes.componentData.display.color = "#ecead5";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_ecead5";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6462 Green Trance";
  option.attributes.componentData.display.color = "#d7e4db";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7e4db";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6784 Bravo Blue";
  option.attributes.componentData.display.color = "#d3e7e9";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d3e7e9";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6000 Snowfall";
  option.attributes.componentData.display.color = "#e0deda";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e0deda";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6007 Smart White";
  option.attributes.componentData.display.color = "#e4dbd8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4dbd8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6322 Intimate White";
  option.attributes.componentData.display.color = "#f0e1d8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f0e1d8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6693 Lily";
  option.attributes.componentData.display.color = "#f3e8c2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3e8c2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6427 Sprout";
  option.attributes.componentData.display.color = "#e4e4ce";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e4e4ce";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6749 Embellished Blue";
  option.attributes.componentData.display.color = "#d7ebe2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d7ebe2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6798 Iceberg";
  option.attributes.componentData.display.color = "#d6e4e7";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d6e4e7";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6259 Spatial White";
  option.attributes.componentData.display.color = "#dddcdb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_dddcdb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 7077 Original White";
  option.attributes.componentData.display.color = "#e2dedb";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_e2dedb";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6876 Comical Coral";
  option.attributes.componentData.display.color = "#f3d1c8";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_f3d1c8";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6901 Daffodil";
  option.attributes.componentData.display.color = "#fad97a";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_fad97a";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6928 Green Vibes";
  option.attributes.componentData.display.color = "#d4e7c3";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_d4e7c3";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6937 Tantalizing Teal";
  option.attributes.componentData.display.color = "#87dcce";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_87dcce";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6957 Undercool";
  option.attributes.componentData.display.color = "#7fc3e1";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_7fc3e1";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6968 Hyacinth Tint";
  option.attributes.componentData.display.color = "#c2cbe0";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_c2cbe0";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;
  option = useButton4();
  option.attributes.componentData.sharedKey = "paint_colors";
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "SW 6973 Free Spirit";
  option.attributes.componentData.display.color = "#cab2d2";
  option.attributes.componentData.interaction.radioGroup = colorRadioGroup;
  option.attributes.componentData.key = "paintcolor_cab2d2";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  return target;
}
